import { FC, useState } from 'react';
import axios from 'Adapter';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button, FontAwesome, SubmitButton } from 'Elements';
import { SubmitHandler, useForm } from 'react-hook-form';
import { api, clx, currencyFormatter, getDiscountFormat } from 'Utils';
import { Colors, SubscriptionCalculate, Voucher } from 'Types';
import { useSelector } from 'Hooks';
import styles from 'partials/payment/voucher-subscription.module.scss';

interface Props {
	subscriptionCalculate: SubscriptionCalculate;
	isChecked: boolean;
	setIsChecked: (isChecked: boolean) => void;
	setVoucherCode: (voucherCode: string | null) => void;
	setNewSubscriptionCalculate: (data: SubscriptionCalculate) => void;
	selectedPlanId: string;
	selectedCommunityPartnerId: string;
}

export const CreateSubscriptionVoucher: FC<Props> = ({
	subscriptionCalculate,
	isChecked,
	selectedPlanId,
	selectedCommunityPartnerId,
	setVoucherCode,
	setNewSubscriptionCalculate,
	setIsChecked
}) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const { selectedPlan } = useSelector((state) => state.subscription);
	const [invoice] = useState<Voucher | null>(null);
	const [canApplyVoucher, activateApplyVoucher] = useState(true);

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { isDirty, isSubmitting }
	} = useForm<{ code: string }>({
		defaultValues: { code: '' }
	});

	const validateVoucher = async (voucherCode: string) => {
		await axios
			.get(
				api.getSubscriptionCalculate(
					selectedCommunityPartnerId as string,
					selectedPlanId as string,
					voucherCode
				)
			)
			.then(async (res) => {
				activateApplyVoucher(voucherCode ? false : true);
				setNewSubscriptionCalculate(res.data);
			})
			.catch(() => {
				activateApplyVoucher(true);
			});
	};

	const onResetVoucher = async () => {
		setVoucherCode(null);
		setValue('code', '');
		await validateVoucher('');
	};

	const submitHandler: SubmitHandler<{ code: string }> = async (data) => {
		setVoucherCode(data.code);
		await validateVoucher(data.code);
	};

	return (
		<>
			<div className={styles.box}>
				<div className={clx(styles.summary, 'mt-2')}>
					<div className={styles.row}>
						<div className={styles.label}>
							{t('label.original-price')}
						</div>
						<div
							className={clx(
								styles.value,
								'd-flex flex-column text-end'
							)}>
							<span className="lh-base">
								{currencyFormatter(
									subscriptionCalculate?.subscriptionPrice ??
										0
								)}
							</span>
							<span className="text-gray-3 fs-small fw-400">
								{`${t('title.per')} ${
									subscriptionCalculate?.subscriptionDuration
								} ${t('title.days')}`}
							</span>
						</div>
					</div>
					{subscriptionCalculate?.discountAmount > 0 && (
						<div className={styles.row}>
							<div className={styles.label}>
								{t('label.discount')}
								{subscriptionCalculate?.discountPercent && (
									<span className="text-gray-3 fs-small">
										(
										{getDiscountFormat(
											subscriptionCalculate?.discountPercent,
											invoice?.discountType
										)}
										)
									</span>
								)}
							</div>
							<div className={styles.value}>
								{currencyFormatter(
									subscriptionCalculate?.discountAmount > 0
										? -subscriptionCalculate.discountAmount
										: 0
								)}
							</div>
						</div>
					)}
					<div className={styles.row}>
						<div className={styles.label}>
							{t('label.subtotal')}
						</div>
						<div
							className={clx(
								styles.value,
								'd-flex flex-column text-end'
							)}>
							<span className="lh-base">
								{currencyFormatter(
									subscriptionCalculate?.subTotal ?? 0
								)}
							</span>
							<span className="text-gray-3 fw-400 fs-small">
								{`${t('title.per')} ${
									subscriptionCalculate?.subscriptionDuration
								} ${t('title.days')}`}
							</span>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.label}>
							{t('label.tax')}
							<span className="text-gray-3 fs-small">
								{`${subscriptionCalculate?.taxPercent ?? 0} %`}
							</span>
						</div>
						<div className={styles.value}>
							{`+${currencyFormatter(
								subscriptionCalculate?.taxAmount ?? 0
							)}`}
						</div>
					</div>
					<div className={styles.row}>
						<h5
							className={clx(
								styles.label,
								'h5 text-gray-5 mb-0'
							)}>
							{t('title.total')}
						</h5>
						<div
							className={clx(styles.value, 'd-flex flex-column')}>
							<span className="fs-5 fw-700 text-gray-5 lh-base text-end">
								{currencyFormatter(
									subscriptionCalculate?.totalAmount ?? 0
								)}
							</span>
							{selectedPlan?.total !== 0 && (
								<span className="text-gray-3 lh-1 fs-small fw-400 text-end">
									{t('label.tax-included')}
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
			<div
				role="checkbox"
				className="d-flex gap-2 my-3"
				tabIndex={0}
				aria-checked={isChecked}>
				<input
					type="checkbox"
					id="accept"
					value="accept"
					checked={isChecked}
					name="accept"
					className={`cursor-pointer 	form-input-check`}
					onChange={(e) => {
						setIsChecked(e.target.checked);
					}}
				/>
				<span
					className="form-check-tick"
					onClick={() => {
						setIsChecked(!isChecked);
					}}>
					<FontAwesome icon="check" size="xs" />
				</span>
				<span className="fs-sm fw-400">
					I accept{' '}
					<span className="text-green cursor-pointer">
						Terms and Conditions
					</span>{' '}
					and all right by buyiing the selected subscription from
					Jobbiplace
				</span>
			</div>
			{(subscriptionCalculate?.totalAmount > 0 ||
				getValues().code !== '') && (
				<form onSubmit={handleSubmit(submitHandler)} noValidate>
					<div className="d-flex align-items-center flex-wrap gap-2 my-4">
						<Label
							htmlFor="code"
							className="fs-sm fw-400 p-0 mb-0 flex-shrink-0 flex-grow-1">
							{t('label.do-you-have-voucher-code')}
						</Label>
						{!show && (
							<div className="d-flex align-items-center gap-3 w-100 w-xs-auto">
								<div
									className="d-flex align-items-center gap-1 text-green cursor-pointer"
									onClick={() => setShow(true)}>
									<FontAwesome icon="plus" />
									{t('menu.add')}
								</div>
							</div>
						)}
						{show && (
							<div className="d-flex align-items-center gap-3 w-100 w-xs-auto">
								<div
									className={clx(
										styles.inputboxWrapper,
										'inputbox d-flex align-items-center flex-fill gap-3'
									)}>
									<input
										{...register('code')}
										id="code"
										type="text"
										placeholder={t(
											'placeholder.enter-code-here'
										)}
										className={styles.inputbox}
									/>
									{canApplyVoucher && (
										<SubmitButton
											icon={false}
											size="sm"
											color={Colors.secondary}
											className="flex-shrink-0"
											label="button.apply"
											savingLabel=""
											isDisable={!isDirty}
											isSubmitting={isSubmitting}
										/>
									)}
									{!canApplyVoucher && (
										<Button
											type="button"
											color={Colors.empty}
											className="p-0 flex-shrink-0 text-red me-2"
											label={t('button.remove') as string}
											onClick={onResetVoucher}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</form>
			)}
		</>
	);
};
