import {
	memo,
	startTransition,
	Suspense,
	useCallback,
	useEffect,
	useState
} from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import type { FieldValues, SubmitHandler } from 'react-hook-form/dist/types';
import { clx, toastify } from 'Utils';
import { names } from 'Constants';
import {
	useDelayUnmount,
	useModal,
	useResponsive,
	useUpdateInternship
} from 'Hooks';
import {
	LoadingContent,
	InternshipDecorationBox,
	Card,
	SubmitButton,
	Button,
	ModalBoxDelete,
	SaveAndPublishInternship,
	FontAwesome
} from 'Elements';
import {
	Activity,
	Colors,
	InternshipFormData,
	InternshipFormInitialData,
	Sector,
	SelectOption,
	Translate,
	JobType
} from 'Types';
import styles from 'partials/page/form.module.scss';

export const UpdateInternship = memo(() => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { jobs } = names;
	const { internship_id } = useParams();
	const { isDesktopAndBelow } = useResponsive();
	const [openModal, toggleModal] = useModal();
	const [isRemoving, setIsRemoving] = useState(false);
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const descriptionLimit = 450;

	const {
		data: fields,
		isFetching,
		updateInternship,
		removeInternship
	} = useUpdateInternship(internship_id as string);
	const name = fields?.title;

	const schema = yup.object().shape({
		title: yup.string().required(t('validation.internship-title.required')),
		sectorId: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string()
			})
			.nullable()
			.required(t('validation.select-sector.required')),
		jobTypeId: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string()
			})
			.nullable()
			.required(t('validation.select-job-type.required')),
		activityList: yup
			.array()
			.min(1, t('validation.select-activity.required'))
			.of(
				yup.object().shape({
					label: yup.string(),
					value: yup.string()
				})
			)
			.nullable()
			.required(t('validation.select-activity.required'))
	});

	const formMethods = useForm<InternshipFormInitialData>({
		resolver: yupResolver(schema),
		shouldUnregister: false,
		mode: 'onChange'
	});

	const {
		reset,
		trigger,
		handleSubmit,
		clearErrors,
		formState: { isDirty, isSubmitting }
	} = formMethods;

	const defaultCodeName: (
		section: FieldValues,
		section_name: string
	) => string = (section, section_name) => {
		const foundedName = section?.[`${section_name}Translates`]?.find(
			(translate: Translate) =>
				translate.name !== '' && translate.language === 'de'
		)?.name;
		return foundedName
			? `${foundedName} (${section?.code})`
			: section?.code;
	};

	const optionsListObject = useCallback(
		(key_obj: Sector | JobType | Activity, key_name: string) => {
			return {
				value: key_obj?.['id'],
				label: defaultCodeName(key_obj, key_name)
			} as SelectOption;
		},
		[]
	);

	const getInternshipFormData = (data: InternshipFormInitialData) => {
		return {
			...data,
			sectorId: data?.sectorId?.value,
			jobTypeId: data?.jobTypeId?.value,
			activityList: data?.activityList?.map((activity: SelectOption) => {
				return { activityId: activity.value };
			}),
			internshipTypeId: ''
		} as InternshipFormData;
	};


	const getDefaultSector = useCallback(
		(sectors?: Sector) => {
			if (sectors) {
				return optionsListObject(sectors, 'sector');
			}
			return null;
		},
		[optionsListObject]
	);


	const getDefaultJobType = useCallback(
		(jobTypes?: JobType) => {
			if (jobTypes) {
				return optionsListObject(jobTypes, 'jobType');
			}
			return null;
		},
		[optionsListObject]
	);

	const getDefaultActivity = useCallback(
		(activities?: Activity[]) => {
			const options: SelectOption[] = [];
			if (activities?.length) {
				activities.forEach((activity: Activity) => {
					options.push(optionsListObject(activity, 'activity'));
				});
			}
			return options;
		},
		[optionsListObject]
	);

	const submitForm: (
		data: InternshipFormInitialData,
		onFinal?: () => void
	) => Promise<void> = async (data, onFinal) => {
		await updateInternship(
			internship_id as string,
			getInternshipFormData(data),
			onFinal
		);
	};

	const handleRemoveInternship = async () => {
		try {
			setIsRemoving(true);
			await removeInternship(internship_id as string, () => {
				startTransition(() => {
					setIsRemoving(false);
					navigate(`${jobs.internships.path}`);
				});
			});
		} finally {
			setIsRemoving(false);
		}
	};

	const submitHandler: SubmitHandler<InternshipFormInitialData> = async (
		data
	) => {
		const isValidForm = await trigger();
		if (isValidForm) {
			await submitForm(data);
		} else {
			toastify(t('toastify.check-required-fields'));
		}
	};
	useEffect(() => {
		reset({
			...fields,
			contactPerson: {
				...fields?.contactPerson,
				image: fields?.contactPerson?.image ?? '',
				fullName: fields?.contactPerson?.fullName ?? '',
				emailAddress: fields?.contactPerson?.emailAddress ?? '',
				phoneNumber: fields?.contactPerson?.phoneNumber ?? '',
				visibleOnDetail: fields?.contactPerson?.visibleOnDetail ?? false
			},
			shortDescription: fields?.shortDescription.substring(
				0,
				descriptionLimit
			),
			benefits: fields?.benefits || '',
			requirements: fields?.requirements || '',
			responsibilities: fields?.responsibilities || '',
			description: fields?.description || '',
			capacity: fields?.capacity,
			hasLimited: fields?.capacity === 0 ? false : true,
			sectorId: getDefaultSector(fields?.sectors),
			jobTypeId: getDefaultJobType(fields?.jobTypes),
			activityList: getDefaultActivity(fields?.activityLists)
		});
	}, [reset, fields, getDefaultSector]);

	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(submitHandler)}
				className={clx(
					isDesktopAndBelow && styles.form,
					'position-relative'
				)}>
				<InternshipDecorationBox />
				<Suspense fallback={<LoadingContent />}>
					<Card className={clx(isDesktopAndBelow && 'mb-4')}>
						<Outlet context={fields} />
						{isFetching && <LoadingContent />}
					</Card>
				</Suspense>
				<Card
					className={clx(isDesktopAndBelow && styles.sticky_action)}>
					<div className="d-flex align-items-center gap-3 flex-wrap">
						<div className="d-flex align-items-center me-auto gap-2">
							{fields?.publishedVersion === null && (
								<Button
									size="sm"
									color={Colors['white-red']}
									className="gap-2"
									onClick={toggleModal}>
									<FontAwesome icon="trash-xmark" size="lg" />
									{t('button.remove')}
								</Button>
							)}
							<Button
								as="a"
								to="preview"
								size="sm"
								color={Colors['white-secondary']}
								className="gap-2">
								<FontAwesome icon="eye" size="lg" />
								{t('button.internship.preview-current-version')}
							</Button>
						</div>
						<div className="d-flex align-items-center gap-2">
							<SubmitButton
								color={Colors['white-primary']}
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
							<SaveAndPublishInternship formData={getInternshipFormData} />
						</div>
					</div>
				</Card>
			</form>
			{shouldRenderModal && (
				<ModalBoxDelete
					title={name ?? '---'}
					isOpen={openModal}
					isRemoving={isRemoving}
					onClose={toggleModal}
					onRemove={handleRemoveInternship}
				/>
			)}
		</FormProvider>
	);
});

UpdateInternship.displayName = 'UpdateInternship';
