import { updateObj } from 'Utils';

enum ActionType {
	SET_FIELDS = 'SET_FIELDS',
	SET_TITLE = 'SET_TITLE',
	SET_DESCRIPTION = 'SET_DESCRIPTION',
	SET_SLOGAN = 'SET_SLOGAN'
}

// An interface for our actions
interface Action {
	type: ActionType;
	payload: Partial<State>;
	prorfile_type?: 'businessPartner' | 'communityPartner';
}

interface State {
	businessPartnerText?: string;
	slogan?: string;
	headerTitle?: string;
	description?: string;
}

// Reducer
// @ts-ignore
export const ProfileHeroHeaderReducer: (
	state: State,
	action: Action
) => State = (state, action) => {
	const { payload, type, prorfile_type } = action;
	switch (type) {
		case ActionType.SET_FIELDS:
			if (prorfile_type === 'businessPartner') {
				return updateObj(state, {
					businessPartnerText: payload.businessPartnerText,
					description:
						payload.description,
					slogan: payload.slogan
				});
			} else {
				return updateObj(state, {
					headerTitle: payload.headerTitle,
					description: payload.description,
					slogan: payload.slogan
				});
			}
		case ActionType.SET_TITLE:
			return { ...state, businessPartnerText: payload };
		case ActionType.SET_DESCRIPTION:
			return { ...state, description: payload };
		case ActionType.SET_SLOGAN:
			return { ...state, slogan: payload };
		default:
			state;
	}
};

// Actions
export const setHeroFields: (
	payload: Partial<State>,
	type?: 'businessPartner' | 'communityPartner'
) => Action = (payload, type = 'businessPartner') => {
	return {
		type: ActionType.SET_FIELDS,
		payload,
		prorfile_type: type
	};
};

export const setTitle = (businessPartnerText: string) => {
	return {
		type: ActionType.SET_TITLE,
		payload: businessPartnerText
	};
};

export const setDescription = (description: string) => {
	return {
		type: ActionType.SET_DESCRIPTION,
		payload: description
	};
};

export const setSlogan = (slogan: string) => {
	return {
		type: ActionType.SET_SLOGAN,
		payload: slogan
	};
};
