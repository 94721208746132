import { Col, Row } from 'reactstrap';
import { clx, formatLocalAddress } from 'Utils';
import { BusinessPartnerMe, Logo } from 'Types';
import { LoadingSpinner, FontAwesome } from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';

type Props = {
	isLoadingProfile: boolean;
	businessPartnerMe: BusinessPartnerMe;
	isFetchingProfile: boolean;
	header: {
		description: number;
		headerImage: Logo;
		headerTitle: string;
		logo: Logo;
		slogan: number;
		themeColor: number;
	};
};

export const BusinessPartnerPreviewHeroHeader = ({
	isLoadingProfile,
	businessPartnerMe,
	isFetchingProfile,
	header
}: Props) => {
	const defaultLogo = '/assets/img/business-partner-default.jpg';

	return (
		<div style={{ marginTop: '-5rem' }}>
			{isLoadingProfile && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoadingProfile && header && (
				<div className="ps-lg-4">
					<Row className="g-0 mb-4 ps-xxl-4">
						<Col lg={3} xxl={2}>
							<div className={styles.headerLogo}>
								<img
									src={
										(header?.logo?.url as string) ||
										defaultLogo
									}
									alt={header?.headerTitle}
									className={styles.logo}
								/>
								{isFetchingProfile && (
									<span className={styles.overlay}>
										<LoadingSpinner
											size="xl"
											color="#fff"
										/>
									</span>
								)}
							</div>
						</Col>
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<div className="d-flex flex-column gap-2 justify-content-end h-100 pt-lg-5">
								<h3
									className={clx(
										styles.title,
										'fw-700 mb-0 text-break mt-5 pt-xl-3'
									)}>
									{header?.headerTitle ?? ''}
								</h3>
								<div className="fs-base text-gray-3 lh-md text-break">
									{header?.slogan ?? ''}
								</div>
								{businessPartnerMe?.address && (
									<div className="d-flex align-items-center gap-2 text-gray-3 fs-sm lh-base position-relative w-100">
										<div>
											<FontAwesome
												icon="location-dot"
												size="1x"
												className="me-1"
											/>
											{formatLocalAddress(
												businessPartnerMe?.address
													?.street,
												businessPartnerMe?.address
													?.houseNumber,
												businessPartnerMe?.address
													?.postalCode,
												businessPartnerMe?.address
													?.city,
												businessPartnerMe?.address
													?.state
											)}
										</div>
									</div>
								)}
							</div>
						</Col>
					</Row>
					<Row className="justify-content-end g-0 ps-xxl-4">
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<Col xl={10}>
								<div
									className="fs-base text-gray-3 lh-md text-break"
									style={{ whiteSpace: 'pre-wrap' }}
									dangerouslySetInnerHTML={{
										__html: businessPartnerMe?.description
									}}></div>
							</Col>
						</Col>
					</Row>
				</div>
			)}
		</div>
	);
};
