import { FC, PropsWithChildren } from 'react';
import { Modal } from 'reactstrap';
import { AnimatePresence, motion } from 'framer-motion';
import { ModalProps } from 'Types';
import { ModalBoxHeader } from 'Elements';
import { fadeInTopVariants } from 'Constants';

type Props = ModalProps & PropsWithChildren;

export const ModalBox: FC<Props> = ({ children, ...modalProps }) => {
	return (
		<AnimatePresence>
			<Modal centered {...modalProps} title="">
				{(modalProps.title || !!modalProps.onClose) && (
					<ModalBoxHeader
						title={modalProps.title}
						onClose={modalProps.onClose}
					/>
				)}
				<motion.div
					variants={fadeInTopVariants}
					initial="hidden"
					animate={modalProps.isOpen ? 'show' : 'hidden'}
					transition={{
						type: 'keyframes',
						delay: 0.5,
						duration: 0.35
					}}
					>
					{children}
				</motion.div>
			</Modal>
		</AnimatePresence>
	);
};
