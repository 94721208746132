import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAgentPaymentTransaction } from 'Hooks';
import { Colors, TransactionStatus } from 'Types';
import { containerVariants } from 'Constants';
import { clx, currencyFormatter, formatLocaleDateTime } from 'Utils';
import {
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	PaymentBadgeStatus,
	SystemErrorAlert
} from 'Elements';

interface Props {
	transactionId: string;
}

export const AgentViewCompanyPayments: FC<Props> = memo(({ transactionId }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data, isError, isFetching } = useAgentPaymentTransaction(transactionId);

	const redirectTo = (id: string) => {
		void navigate(`/transactions/${id}`, {
			state: { prevPath: '/transactions' }
		});
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			'transaction-number': 'w-sm-15',
			company: 'w-sm-25 fw-500',
			'created-at': 'w-sm-20',
			amount: 'w-sm-10',
			status: 'w-sm-15',
			operation: 'w-sm-15'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	return (
		<div
			className={clx(
				'd-flex flex-column gap-4',
				!isFetching && (!data || data.length === 0) && 'd-none'
			)}>
			<DataTable>
				{data && data?.length > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.transaction-number"
							cellClassName={cellClassName('transaction-number')}
						/>
						<DataTableRowCell
							cellText="table.company"
							cellClassName={cellClassName('businessPartner')}
						/>
						<DataTableRowCell
							cellText="table.created-at"
							cellClassName={cellClassName('created-at')}
						/>
						<DataTableRowCell
							cellText="table.amount"
							cellClassName={cellClassName('amount')}
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching &&
						data &&
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.map((transaction) => {
								return (
									<DataTableRow key={transaction.id}>
										<DataTableRowCell
											cellClassName={cellClassName(
												'transaction-number'
											)}
											cellHead="table.transaction-number"
											cellText={transaction.transactionCode}
										/>
										<DataTableRowCell
											cellClassName={cellClassName('businessPartner')}
											cellHead="table.company"
											cellText={transaction.name}
										/>
										<DataTableRowCell
											cellClassName={cellClassName(
												'created-at'
											)}
											cellHead="table.created-at">
											<time
												title={formatLocaleDateTime(
													transaction.purchaseDate
												)}
												dateTime={formatLocaleDateTime(
													transaction.purchaseDate
												)}>
												{formatLocaleDateTime(
													transaction.purchaseDate
												)}
											</time>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName('amount')}
											cellHead="table.amount">
											<div className="d-flex flex-column text-end text-sm-start">
												<strong
													className="fs-base"
													title={currencyFormatter(
														transaction.totalAmount
													)}>
													{currencyFormatter(
														transaction.totalAmount
													)}
												</strong>
												<span className="text-gray-3 lh-base fs-small">
													{transaction.taxIncluded
														? t('label.tax-included')
														: t('label.no-tax')}
												</span>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName('status')}
											cellHead="table.status"
											boxClassName="d-flex align-items-center flex-wrap gap-2">
											{transaction.donate && (
												<PaymentBadgeStatus
													list={[
														TransactionStatus.Donated
													]}
													title={
														TransactionStatus.Donated
													}
													color={Colors.tertiary}
												/>
											)}
											{!transaction.donate && (
												<PaymentBadgeStatus
													list={[
														TransactionStatus.UnPaid
													]}
													title={transaction.status}
													color={Colors.muted}
												/>
											)}
											<PaymentBadgeStatus
												list={[TransactionStatus.Done]}
												title={transaction.status}
												color={Colors.primary}
											/>
											<PaymentBadgeStatus
												list={[TransactionStatus.Pending]}
												title={transaction.status}
												color={Colors.warning}
											/>
											<PaymentBadgeStatus
												list={[TransactionStatus.Paid]}
												title={transaction.status}
												color={Colors.success}
											/>
											<PaymentBadgeStatus
												list={[TransactionStatus.Failed]}
												title={transaction.status}
												color={Colors.error}
											/>
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.operation"
											cellClassName={cellClassName(
												'operation'
											)}
											boxClassName="d-flex flex-wrap gap-2">
											<Button
												size="sm"
												color={Colors['white-primary']}
												label={
													t('button.details') as string
												}
												onClick={() =>
													redirectTo(
														transaction.transactionId
													)
												}
											/>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>}
					{isFetching && (
						<DataTableLoading
							widths={[15, 25, 20, 10, 15, 15]}
							count={5}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
			</DataTable>
		</div>
	);
});

AgentViewCompanyPayments.displayName = 'AgentViewCompanyPayments';
