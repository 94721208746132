import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useSubscription, useSubscriptionPayments } from 'Hooks';
import { SubscriptionDetails } from 'Types';
import {
	Card,
	DetailsSubscription,
	SubscriptionDescription,
	SubscriptionInvoice
} from 'Elements';

export const ViewSubscription = () => {
	const { subscription_id } = useParams();
	const { data: subscription, isFetching } = useSubscription(
		subscription_id as string
	);

	const { data: subscriptionPayments } = useSubscriptionPayments(
		subscription?.currentSubscriptionPaymentId ?? '',
		{
			enabled: !!subscription?.currentSubscriptionPaymentId
		}
	);

	return (
		<>
			<Card>
				<DetailsSubscription
					subscriptionPayments={subscriptionPayments as any}
					subscription={subscription as SubscriptionDetails}
					subscriptionId={subscription_id as string}
					communityPartnerInfo={subscription?.communityPartnerInfo}
					isLoading={isFetching}
				/>
			</Card>
			<Row className="mt-4">
				<Col md={6}>
					<SubscriptionInvoice
						subscriptionPayments={subscriptionPayments as any}
						isLoading={isFetching}
						subscription={subscription as SubscriptionDetails}
					/>
				</Col>
				<Col md={6} className="mt-4 mt-md-0">
					<SubscriptionDescription
						isLoading={isFetching}
						agent={subscription?.agent}
						subscription={
							subscription 
						}
						subscriptionPayments={
							subscriptionPayments
						}
					/>
				</Col>
			</Row>
		</>
	);
};
