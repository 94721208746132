import { FC, memo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { rgba } from 'polished';
import { Internship } from 'Types';
import { useTheme } from 'Hooks';
import { names } from 'Constants';
import { getTranslate } from 'Utils';
import { EditButton, FontAwesome, IconPlayer, ShimmerImage } from 'Elements';
import styles from 'partials/homepage/card/profile-job-card.module.scss';

type Props = {
	internship: Internship;
	type: 'communityPartner' | 'businessPartner';
	profileImage: string;
};

interface BgColor {
	$bgColor: string;
}

const Card = styled.article<BgColor>`
	${({ $bgColor }) =>
		$bgColor &&
		`
		&:hover {
			box-shadow: 0 5px 10px ${rgba($bgColor, 0.1)};
		}`}};
`;

export const ProfileInternshipCard: FC<Props> = memo(({ internship, type, profileImage }) => {
	const { jobs } = names;
	const { theme } = useTheme();
	const navigate = useNavigate();
	const defaultImage = '/assets/img/default-image.jpg';
	const image = internship?.featuredPhotoUrl || profileImage || defaultImage;
	const video = internship?.featuredVideoUrl;

	const handleNavigate = () => {
		navigate(`${jobs.internships.path}/${internship.id}`, { state: { prevPath: 'homepage' } });
	};

	return (
		<Card
			$bgColor={theme[type].primaryColor}
			className={styles.card}
			onClick={handleNavigate}>
			<div className={styles.media}>
				<div className={styles.imageWrapper}>
					<ShimmerImage
						src={image}
						defaultImage={defaultImage}
						alt={internship?.title}
						width={500}
						height={500}
						className={styles.image}
					/>
					{video && (
						<IconPlayer
							fill="rgba(64, 62, 80, 0.54)"
							className={styles.player}
						/>
					)}
				</div>
			</div>
			<div className="flex-grow-1 d-flex flex-column gap-2">
				{/* {getTranslate(internship?.jobTypes?.jobTypeTranslates) && (
					<div className="d-flex align-items-center gap-2 lh-base">
						<FontAwesome icon="industry" size="lg"  />
						<span className="text-gray-3 lh-1">
							{getTranslate(job?.jobTypes?.jobTypeTranslates)}
						</span>
					</div>
				)} */}
				<h4 className="text-break fs-5 mb-0 lh-sm">{internship.title}</h4>
				{internship.shortDescription && (
					<div
						className="flex-grow-1 lh-md text-break text-gray-3"
						dangerouslySetInnerHTML={{
							__html: internship.shortDescription
						}}
					/>
				)}
			</div>
			<div className="edit-overlay">
				<EditButton className="edit-overlay-icon" />
			</div>
		</Card>
	);
});
ProfileInternshipCard.displayName = 'ProfileInternshipCard';
