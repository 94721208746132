import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import {
	Button,
	Collapsible,
	FontAwesome,
	LoadingSpinner,
	ModalBoxSectionEditProfile,
	ProfileAddNewCard,
	ReactSwitch,
	SectionHeadline
} from 'Elements';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';
import { names } from 'Constants';
import { useModal } from 'src/hooks/useModal';
import { Colors } from 'Types';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	internships: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerInternshipSection = ({
	isLoadingProfile,
	onChangeValue,
	internships
}: Props) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const { jobs } = names;
	const addInternshipUrl = jobs.internships.add?.path as string;

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && internships && (
					<div className="position-relative">
						<Collapsible
							header={
								<div
									className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${stylesProfile.switcherBox}`}>
									<div className="d-flex flex-wrap align-items-center gap-3">
										<h5 className="fs-5 fw-700 d-flex align-items-center">
											{' '}
											{t('menu.internships')}
										</h5>
										<ReactSwitch
											size="md"
											checked={internships?.enabled}
											onChange={(val: boolean) => {
												onChangeValue(
													'internships',
													'enabled',
													val
												);
											}}
										/>
										<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
											{t(
												'forms.show-agent-internships-in-home'
											)}
										</span>
									</div>
									<div>
										<Button
											size="sm"
											color={Colors['white-gray']}
											onClick={() => toggleModal()}>
											<FontAwesome
												icon="circle-info"
												size="1x"
												className="text-gray me-1"
											/>
											{t('button.more')}
										</Button>
									</div>
								</div>
							}>
							<SectionHeadline
								title={internships?.headline}
								titlePlaceholder={`[${t(
									'placeholder.internship-list-headline'
								)}]`}
								titleColor="text-gray-5"
								className="mx-auto text-center"
								onUpdateTitle={(val) =>
									onChangeValue(
										'internships',
										'headline',
										val
									)
								}
							/>
							{internships && (
								<ProfileAddNewCard
									navigationUrl={addInternshipUrl}
									title="title.add-new-internship"
								/>
							)}
						</Collapsible>
					</div>
				)}
				{!internships?.enabled && (
					<div className="overlay overlay_white overlay-box" />
				)}
				<ModalBoxSectionEditProfile
					isOpen={openModal}
					onClose={toggleModal}
					title="info.internships-title"
					description="info.internships-description"
				/>
			</Container>
		</section>
	);
};
