import { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAgentTransaction } from 'Hooks';
import { Colors, TransactionStatus, TransactionType } from 'Types';
import { clx, currencyFormatter, formatLocaleDateTime } from 'Utils';
import { Card, LoadingContent, Badge, PaymentBadgeStatus } from 'Elements';
import styles from 'partials/card/subscription-card.module.scss';

export const ViewCredit = memo(() => {
	const { t } = useTranslation();
	const defaultImage = '/assets/img/business-partner-default.jpg';
	const { credit_id } = useParams();
	const { data: transaction, isLoading } = useAgentTransaction(
		credit_id as string
	);
	const status = transaction?.status;
	const company = transaction?.company;

	return (
		<Card>
			<h6 className={clx(styles.row, 'text-gray-3 fs-6 fw-400')}>
				<span className={styles.rowLabel}>{t('title.credit')}</span>
				<div className="d-flex align-items-center">
					{transaction?.donate && (
						<Badge color={Colors.tertiary} className="me-2">
							{t(`badge.donated`)}
						</Badge>
					)}
					{status && (
						<>
							{!transaction?.donate && (
								<PaymentBadgeStatus
									list={[TransactionStatus.UnPaid]}
									title={status}
									color={Colors.muted}
								/>
							)}
							<PaymentBadgeStatus
								list={[TransactionStatus.Done]}
								title={status}
								color={Colors.primary}
							/>
							{!transaction?.donate && (
								<PaymentBadgeStatus
									list={[TransactionStatus.Pending]}
									title={status}
									color={Colors.warning}
								/>
							)}
							<PaymentBadgeStatus
								list={[TransactionStatus.Paid]}
								title={status}
								color={Colors.success}
							/>
							<PaymentBadgeStatus
								list={[TransactionStatus.Failed]}
								title={status}
								color={Colors.error}
							/>
						</>
					)}
				</div>
			</h6>
			<Row>
				<Col sm={12}>
					<div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('label.transaction-number')}
						</span>
						<span className="fs-6">
							{transaction?.transactionCode ?? '---'}
						</span>
					</div>
					{transaction?.type === TransactionType.credit && (
						<div className={styles.row}>
							<span className={styles.rowLabel}>
								{t('label.credit-number')}
							</span>
							<span className="fs-6">
								{transaction?.creditNumber ?? '---'}
							</span>
						</div>
					)}
					<div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('label.created-at')}:
						</span>
						<span>
							{transaction?.createdAt
								? formatLocaleDateTime(transaction?.createdAt)
								: '---'}
						</span>
					</div>
					<div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('label.tax')}
							<span className="ms-1 text-gray-3 fs-small">
								({transaction?.taxPercent ?? 0}%)
							</span>
							:
						</span>
						<span>
							{currencyFormatter(
								transaction?.taxAmount &&
									transaction.taxAmount > 0
									? transaction.taxAmount
									: 0
							)}
						</span>
					</div>
					<div className={styles.row}>
						<span className={clx(styles.rowLabel, 'fw-500')}>
							{t('label.amount')}
						</span>
						<div className="d-flex align-items-center text-end">
							<span className="fs-6 fw-500">
								{currencyFormatter(
									transaction?.totalAmount ?? 0
								)}
							</span>
							<span className="text-gray-3 ms-2 fs-small">
								(
								{transaction?.taxIncluded
									? t('label.tax-included')
									: t('label.no-tax')}
								)
							</span>
						</div>
					</div>
					<div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('title.company')}:
						</span>
						<div className="d-flex align-items-center gap-2">
							<img
								src={company?.logo || defaultImage}
								alt={company?.name ?? '---'}
								className={clx(styles.logo, styles.logoSm)}
							/>
							<span className="fs-sm fw-400">
								{company?.name ?? '---'}
							</span>
						</div>
					</div>
				</Col>
			</Row>
			{isLoading && <LoadingContent />}
		</Card>
	);
});

ViewCredit.displayName = 'ViewCredit';
