export const enum VoucherDiscountType {
	Percent = 1,
	Amount = 2
}

export interface Voucher {
	taxAmount: number;
	taxPercent: number;
	totalAmount: number;
	voucherCode: string;
	discount: number;
	discountType: VoucherDiscountType;
	discountAmount: number;
	priceWithDiscount: number;
	priceAfterDiscount: number;
}

export interface MyVoucher {
	id: string;
	communityPartnerId: string;
	voucherCode: string;
	title: string;
	discount: number;
	usageLimitCount: number;
	usedCount: number;
	createdAt: string;
	subscriptionPlanCode: string;
	expireDate: string;
	isExpired: boolean;
	availableRegions: any;
	isAutoGenerated: boolean;
	isActive: boolean;
	voucherUsageInfos: VoucherUsageInfo[];
	discountType: VoucherDiscountType;
	availableInSubscriptionRenewal: boolean;
}

export interface VoucherUsageInfo {
	companyId: string;
	usageDateTime: string;
	transactionCode: string;
	name: string;
	email: string;
	logo: string;
}
