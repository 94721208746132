import { memo, FC, useState, startTransition, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, FieldValues, useForm } from 'react-hook-form';
import { useUpdateBenefits } from 'Hooks';
import { Benefit, Colors, ModalProps, Translate } from 'Types';
import {
	Button,
	CheckButtonToggler,
	LoadingContent,
	ModalBox,
	ModalBoxFooter,
	NoData,
	SubmitButton
} from 'Elements';

interface Props extends ModalProps {
	title: string;
	selectedBenefits: Benefit[] | [];
}

export const ModalBoxAddBenefit: FC<Props> = memo(
	({ isOpen, onClose, title, selectedBenefits }) => {
		const { t } = useTranslation();
		const { benefits, isLoading, updateBenefits } = useUpdateBenefits();
		const [currentSelectedBenefit, setSelected] = useState<Benefit[]>([]);
		const {
			handleSubmit,
			formState: { isSubmitting }
		} = useForm();

		const getTitle = (translates: Translate[]) => {
			const deTranslate = translates?.find(
				(item: Translate) => item.language === 'de'
			);

			return deTranslate?.name || translates?.[0]?.name || '----';
		};

		const getUnselectedBenefits = useMemo(() => {
			return (
				benefits?.items?.filter(
					(elem) =>
						!selectedBenefits?.some(
							(selected) => selected.id === elem.id
						)
				) ?? []
			);
		}, [benefits, selectedBenefits]);

		const checkIsSelected = (benefit: Benefit) => {
			return currentSelectedBenefit.some(
				(item) => item.id === benefit.id
			);
		};

		const handleSelect = (benefit: Benefit) => {
			let selected_list: Benefit[] = currentSelectedBenefit;
			if (!selected_list?.some((item) => item.id === benefit.id)) {
				selected_list = [...selected_list, benefit];
				setSelected(selected_list);
			} else {
				selected_list = selected_list.filter(
					(item: Benefit) => item.id !== benefit.id
				);
				setSelected(selected_list);
			}
		};

		const handleClose = () => {
			setSelected([]);
			startTransition(() => {
				onClose();
			});
		};

		const onSubmit: SubmitHandler<FieldValues> = async () => {
			const api_data = {
				benefitList:
					[
						...(selectedBenefits || []),
						...currentSelectedBenefit
					]?.map((benefit) => {
						return { benefitId: benefit.id };
					}) ?? []
			};
			await updateBenefits(api_data, () => {
				// refetch();
				handleClose();
			});
		};

		return (
			<ModalBox isOpen={isOpen} onClose={handleClose} title={title}>
				{isLoading && <LoadingContent />}
				<form onSubmit={handleSubmit(onSubmit)}>
					{!isLoading && (
						<>
							<div className="d-flex align-items-center flex-wrap pb-3">
								{getUnselectedBenefits?.map((benefit) => (
									<CheckButtonToggler
										key={benefit.id}
										title={getTitle(
											benefit.benefitTranslates
										)}
										isActive={checkIsSelected(benefit)}
										onClick={() => handleSelect(benefit)}
									/>
								))}
								{getUnselectedBenefits?.length === 0 && (
									<NoData
										message="title.no-benefit"
										boxClassName="mx-auto"
									/>
								)}
							</div>
							<ModalBoxFooter className="justify-content-end mt-3">
								<Button
									color={Colors['white-primary']}
									onClick={handleClose}>
									{t('button.cancel')}
								</Button>
								{getUnselectedBenefits?.length > 0 && (
									<SubmitButton
										className="ms-4"
										isDisable={
											currentSelectedBenefit.length === 0
										}
										isSubmitting={isSubmitting}
									/>
								)}
							</ModalBoxFooter>
						</>
					)}
				</form>
			</ModalBox>
		);
	}
);

ModalBoxAddBenefit.displayName = 'ModalBoxAddBenefit';
