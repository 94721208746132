import { memo, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'Types';
import { useAgentToSwitch } from 'Hooks';
import {
	ChooseAgentToSwitchCard,
	ChooseAgentToSwitchLoading,
	EndOfList,
	ModalBox,
	NoData,
	PageHeadingSearch,
	Pagination
} from 'Elements';

interface Props extends ModalProps {
	onSelectAgent: () => void;
	subscriptionId: string
}

export const ModalBoxSwitchAgentList: FC<Props> = memo(
	({ subscriptionId, isOpen, onClose, onSelectAgent }) => {
		const { t } = useTranslation();
		const [state, setState] = useState({
			pageSize: 5,
			currentPage: 1,
			keyword: '',
			companySubscription: subscriptionId
		});

		const { data: list, isLoading } = useAgentToSwitch(
			{
				pageSize: state.pageSize,
				pageNumber: state.currentPage,
				keyword: state.keyword,
				companySubscription: subscriptionId
			},
			{ refetchOnMount: false }
		);

		const handlePageChange = (page: number) => {
			setState((prev) => ({
				...prev,
				currentPage: page
			}));
		};

		const handleSearch = (query: string) => {
			setState((prev) => ({
				...prev,
				currentPage: 1,
				keyword: query
			}));
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				size="lg"
				title={t('title.choose-community-partner')}>
				<p className="lh-base">{t('text.choose-agent-to-switch')}</p>
				<PageHeadingSearch className="mb-2" onSubmit={handleSearch} />
				<div>
					{!isLoading &&
						list?.items?.map((company) => {
							return (
								<ChooseAgentToSwitchCard
									key={company.id}
									card={company}
									onSelectAgent={onSelectAgent}
								/>
							);
						})}
				</div>
				{isLoading && (
					<ChooseAgentToSwitchLoading count={state.pageSize} />
				)}
				{!isLoading && list?.items?.length === 0 && (
					<NoData message="title.no-agent" />
				)}
				{!isLoading && <EndOfList data={list} />}
				{!isLoading && list && list?.totalPages > 1 && (
					<Pagination
						className="mt-3"
						totalItemsCount={list.totalItems}
						activePage={parseInt(state.currentPage.toString(), 10)}
						itemsCountPerPage={state.pageSize}
						onChange={handlePageChange}
					/>
				)}
			</ModalBox>
		);
	}
);

ModalBoxSwitchAgentList.displayName = 'ModalBoxSwitchAgentList';
