import { API } from 'Types';

const accountService = '/accountservice/api';
const subscriptionservice = 'subscriptionservice-b2b/api/b2b';
const jobservice = 'jobservice-b2b/api/b2b';
const mediaService = '/mediaservice/api';
const masterdataService = '/masterdataservice/api';
const addressService = '/addressservice/api';

export const api: API = {
	// Business Partners
	getBusinessPartnerMe: `${subscriptionservice}/businesspartners/me`,
	getBusinessPartnerProfile: (businessPartnerProfileId) =>
		`${subscriptionservice}/businesspartnerprofiles?id=${businessPartnerProfileId}`,
	updateBusinessPartnerEditProfile: (businessPartnerProfileId) =>
		`${subscriptionservice}/businesspartnerprofiles?id=${businessPartnerProfileId}`,
	getCompanyAgents: `${accountService}/companies/agent-list-profile`,
	updateBusinessPartnerProfile: `${subscriptionservice}/businesspartners/me/basic-info`,
	publishBusinessPartnerProfile: (businessPartnerProfileId) =>
		`${subscriptionservice}/businesspartnerprofiles/${businessPartnerProfileId}/publish`,
	unpublishBusinessPartnerProfile: (businessPartnerProfileId) =>
		`${subscriptionservice}/businesspartnerprofiles/${businessPartnerProfileId}/unpublish`,
	updateCompanySetting: `${accountService}/companies/profile-setting`,
	updateBusinessPartnersLogo: `${subscriptionservice}/businesspartners/me/logo`,
	updateCompanyContact: `${accountService}/companies/contact-social-profile`,
	updateCompanyBenefit: `${accountService}/companies/benefitprofile`,
	updateCompanyLinkedinConfig: `${accountService}/companies/set-linkedin-configuration`,
	createLinkedinToken: `${accountService}/companies/create-linkedin-token-url`,
	validateLinkedinToken: (accessToken) =>
		`${accountService}/companies/validate-linkedin-auth-token?accessToken=${accessToken}`,
	createCompanyGallery: `${accountService}/companies/gallery-profile`,
	updateCompanyGallery: (galleryId) =>
		`${accountService}/companies/${galleryId}/update-gallery`,
	removeCompanyGallery: `${accountService}/companies/remove-gallery`,

	// Employee
	createEmployee: `${subscriptionservice}/businesspartneremployees`,
	getEmployees: `${subscriptionservice}/businesspartneremployees`,
	getEmployeeDetail: (employeeId) =>
		`${subscriptionservice}/businesspartneremployees/${employeeId}`,
	updateEmployeeDetail: (employeeId) =>
		`${subscriptionservice}/businesspartneremployees/${employeeId}`,
	removeEmployee: (employeeId) =>
		`${subscriptionservice}/businesspartneremployees/${employeeId}`,
	updateEmployeeImage: (employeeId) =>
		`${subscriptionservice}/businesspartneremployees/image/${employeeId}`,

	// Community Partners
	getCommunityPartnerMe: `${subscriptionservice}/communitypartners/me`,
	getCommunityPartnerProfile: (communityPartnerProfileId) =>
		`${subscriptionservice}/communitypartnerprofiles?id=${communityPartnerProfileId}`,
	getCommunityPartner: (communityPartnerId) =>
		`${subscriptionservice}/communitypartners/${communityPartnerId}`,
	getPlansCommunityPartner: (communityPartnerProfileId) =>
		`${subscriptionservice}/communitypartners/${communityPartnerProfileId}/get-plans`,
	updateCommunityPartnerEditProfile: (communityPartnerProfileId) =>
		`${subscriptionservice}/communitypartnerprofiles/${communityPartnerProfileId}`,
	getAgentParentAssociation: (partnerClass) =>
		`${accountService}/agents/get-agents-by-class?partnerClass=${partnerClass}&api-version=2`,
	getAgentParentArea: (parentAssociationId) =>
		`${accountService}/agents/get-agents-by-parent-id?parentAssociationId=${parentAssociationId}&api-version=2`,
	publishCommunityPartnerProfile: `${subscriptionservice}/communitypartnerprofiles/publish`,
	unpublishCommunityPartnerProfile: `${subscriptionservice}/communitypartnerprofiles/unpublish`,
	getAgentCompanies: `${accountService}/companysubscriptions/agent-companies-profile`,
	getCommunityPartnersCompanies: `${subscriptionservice}/communitypartners`,
	getCommunityPartnerTypes: `${subscriptionservice}/communitypartnertypes`,
	updateCommunityPartnersMe: `${subscriptionservice}/communitypartners/me`,
	updateCommunityPartnersMeAddress: `${subscriptionservice}/communitypartners/me/address`,
	updateCommunityPartnersMeLogo: `${subscriptionservice}/communitypartners/me/logo`,
	updateAgentBankAccount: `${subscriptionservice}/communitypartners/me/bank-account`,
	updateAgentSetting: `${accountService}/agents/profile-setting`,
	updateAgentContact: `${accountService}/agents/contact-social-profile`,
	updateAgentPlanPrice: `${accountService}/agents/profile-plan-info`,
	deleteAgentAccountRequest: `${accountService}/agents/delete-account-request`,
	deleteAgentAccount: `${accountService}/agents/delete-account`,
	updateAgentCompanyApprovalStatus: (companyId) =>
		`${accountService}/companysubscriptions/${companyId}/agent-approval-status`,
	validateVoucherCode: `${accountService}/companysubscriptions/validatevoucher?api-version=2`,
	updateVoucherCompany: (id, voucherCode) =>
		`${accountService}/companysubscriptions/${id}/update-voucher?voucherCode=${voucherCode}`,

	// Member
	createMember: `${subscriptionservice}/communitypartnermembers`,
	getMembers: `${subscriptionservice}/communitypartnermembers`,
	getMemberDetail: (memberId) =>
		`${subscriptionservice}/communitypartnermembers/${memberId}`,
	updateMemberDetail: (memberId) =>
		`${subscriptionservice}/communitypartnermembers/${memberId}`,
	removeMember: (memberId) =>
		`${subscriptionservice}/communitypartnermembers/${memberId}`,

	// Department
	createDepartment: `${subscriptionservice}/communitypartnerdepartments`,
	getDepartments: `${subscriptionservice}/communitypartnerdepartments`,
	getDepartmentDetail: (departmentId) =>
		`${subscriptionservice}/communitypartnerdepartments/${departmentId}`,
	updateDepartmentDetail: (departmentId) =>
		`${subscriptionservice}/communitypartnerdepartments/${departmentId}`,
	removeDepartment: (departmentId) =>
		`${subscriptionservice}/communitypartnerdepartments/${departmentId}`,
	updateDepartmentImage: (departmentId) =>
		`${subscriptionservice}/communitypartnerdepartments/image/${departmentId}`,

	// User
	getB2bUserProfile: `${subscriptionservice}/useraccounts/me`,
	updateB2bUserProfile: `${accountService}/users/user-profile`,
	getB2bUsers: `${subscriptionservice}/useraccounts`,
	createB2bAdmin: `${subscriptionservice}/useraccounts`,
	createB2bUser: `${subscriptionservice}/useraccounts`,
	removeB2bUser: (userId) => `${subscriptionservice}/useraccounts/${userId}`,
	getUserDetail: (userId) => `${subscriptionservice}/useraccounts/${userId}`,
	updateB2bAdmin: (userId) => `${subscriptionservice}/useraccounts/${userId}`,
	updateB2bUser: (userId) => `${subscriptionservice}/users/${userId}`,
	updateUserAvatar: (userId) =>
		`${subscriptionservice}/users/${userId}/image`,

	// Groups
	getUserGroups: `${accountService}/usergroups`,
	createUserGroup: `${accountService}/usergroups`,
	getUserGroupDetail: (id) => `${accountService}/usergroups/${id}`,
	updateUserGroup: (id) => `${accountService}/usergroups/${id}`,

	// Roles
	createUserRoles: `${accountService}/roles`,
	getUserRoles: `${accountService}/roles`,
	getUserRoleDetail: (id) => `${accountService}/roles/${id}`,
	updateUserRole: (id) => `${accountService}/roles/${id}`,

	// Permissions
	getPermissions: `${accountService}/permissions`,
	getPermissionsGraph: `${accountService}/permissions/graph`,

	// Image Media
	uploadMedia: `${mediaService}/media`,
	uploadBatchMedia: `${mediaService}/media/batch`,

	// Regions
	getAlternativeRegions: `${accountService}/regions/getregionsdetails`,
	getRegions: `${subscriptionservice}/regions`,
	updateAlternativeRegions: `${accountService}/agents/alternative-region-profile`,

	// Job
	createJob: `${jobservice}/jobs`,
	getJobs: `${jobservice}/jobs`,
	getJobTagCategories: `${jobservice}/jobtagcategories`,
	getActivityTags: `${jobservice}/jobactivities`,
	getJobTags: `${jobservice}/jobtags`,
	getJobsInternships: `${masterdataService}/jobs/organization-jobs-internships`,
	getJobDetail: (jobId) => `${jobservice}/jobs/${jobId}`,
	updateJob: (jobId) => `${jobservice}/jobs/${jobId}`,
	removeJob: (jobId) => `${masterdataService}/jobs/${jobId}`,
	jobFeature: (jobId) => `${masterdataService}/jobs/${jobId}/job-feature`,
	getJobQrCode: (jobId) => `${masterdataService}/jobs/${jobId}/qrcode`,
	downloadJobQrCode: (jobId) =>
		`${masterdataService}/jobs/${jobId}/qrcode-download`,
	updateContactImage: (jobId) =>
		`${masterdataService}/jobs/${jobId}/update-contact-image`,

	// Internship
	getInternships: `${masterdataService}/internships`,
	createInternship: `${masterdataService}/Internships`,
	addTermInternship: (internshipId) =>
		`${masterdataService}/internships/${internshipId}/add-term`,
	removeTermInternship: (internshipId, termId) =>
		`${masterdataService}/internships/${internshipId}/remove-term/${termId}`,
	getInternshipDetail: (internshipId) =>
		`${masterdataService}/internships/${internshipId}`,
	updateInternship: (internshipId) =>
		`${masterdataService}/internships/${internshipId}`,
	removeInternship: (internshipId) =>
		`${masterdataService}/internships/${internshipId}`,
	internshipFeature: (internshipId) =>
		`${masterdataService}/internships/${internshipId}/internship-feature`,
	getInternshipQrCode: (internshipId) =>
		`${masterdataService}/internships/${internshipId}/qrcode`,
	downloadInternshipQrCode: (internshipId) =>
		`${masterdataService}/internships/${internshipId}/qrcode-download`,

	// Publish Internship
	publishInternship: (internshipId) =>
		`${masterdataService}/publishInternships?jobId=${internshipId}`,
	unPublishInternship: (internshipId) =>
		`${masterdataService}/publishInternships/unpublish-internship?jobId=${internshipId}`,
	// linkedinPost: `${masterdataService}/publishjobs/linkedin-post`,

	// Publish Job
	publishJob: `${jobservice}/jobs/publish`,
	unPublishJob: `${jobservice}/jobs/unpublish`,
	linkedinPost: `${masterdataService}/publishjobs/linkedin-post`,

	// Activity
	getActivities: `${masterdataService}/activities/active-activities`,

	// Benefit
	getBenefits: `${accountService}/benefits`,

	// Sector
	getSectors: `${subscriptionservice}/businesspartnersectors`,

	// JobType
	getJobTypes: `${masterdataService}/jobtypes/active-jobtypes`,

	// Address
	searchAddress: (query) =>
		`${addressService}/address/search?query=${query}&countrySet=de&language=de-DE`,
	searchPostalCity: (query) =>
		`${addressService}/address/search-postal-city?query=${query}&countrySet=DE&language=de-DE`,

	// Subscriptions
	createPrimarySubscription: `${subscriptionservice}/subscriptions/primary`,
	getSubscriptionPlans: `${subscriptionservice}/subscriptionplans`,
	getCompanySubscriptions: `${subscriptionservice}/subscriptions`,
	getSubscriptionCalculate: (
		CommunityPartnerId,
		SubscriptionPlanId,
		VoucherCode
	) =>
		`${subscriptionservice}/subscriptions/subscription-price-calculate?CommunityPartnerId=${CommunityPartnerId}&SubscriptionPlanId=${SubscriptionPlanId}&VoucherCode=${VoucherCode}`,
	getSubscriptionPlansFeature: (
		subscriptionPlanFeatureId,
		SubscriptionPlanId
	) =>
		`${subscriptionservice}/subscriptionplans/${subscriptionPlanFeatureId}/feature?SubscriptionPlanId=${SubscriptionPlanId}`,

	createSubscription: `${subscriptionservice}/subscriptions`,
	getSubscriptionDetail: (subscriptionId) =>
		`${subscriptionservice}/subscriptions/${subscriptionId}`,
	getSubscriptionPaymentsDetail: (currentSubscriptionPaymentId) =>
		`${subscriptionservice}/subscriptionpayments/${currentSubscriptionPaymentId}`,
	subscriptionPrePay: (currentSubscriptionPaymentId) =>
		`${subscriptionservice}/subscriptionpayments/${currentSubscriptionPaymentId}/pre-pay`,
	subscriptionPay: (subscriptionPaymentId, paymentMethodId) =>
		`${subscriptionservice}/subscriptionpayments/${subscriptionPaymentId}/pay?paymentMethodId=${paymentMethodId}`,

	subscriptionCheckPayStatus: (subscriptionPaymentId) =>
		`${subscriptionservice}/subscriptionpayments/${subscriptionPaymentId}/check-pay-status`,

	updateSubscriptionRenewal: (subscriptionId) =>
		`${accountService}/companysubscriptions/${subscriptionId}/auto-renewal-profile`,
	getAgentsToSwitch: `${accountService}/companysubscriptions/agents-to-switch`,
	switchAgent: (subscriptionId) =>
		`${accountService}/companysubscriptions/${subscriptionId}/switch-agent`,

	// Applicant
	getDemands: `${masterdataService}/applicantdemands/applicant-demand-b2b`,
	getDemandDetail: (demandId) =>
		`${masterdataService}/applicantdemands/${demandId}`,
	updateDemand: (demandId) =>
		`${masterdataService}/applicantdemands/${demandId}/applicat-demand-status`,

	// Internship Registrations
	getInternshipRegistrations: `${masterdataService}/applicantdemandinternships/applicant-demand-b2b`,
	getInternshipRegistrationDetail: (internshipParticipantsId) =>
		`${masterdataService}/applicantdemandinternships/${internshipParticipantsId}`,
	updateInternshipRegistration: (internshipParticipantsId) =>
		`${masterdataService}/applicantdemandinternships/${internshipParticipantsId}/applicat-demand-status`,

	// Payments
	getPayments: `${accountService}/payments/payment-profile`,
	getPaymentStatus: (paymentId) =>
		`${subscriptionservice}/payments/${paymentId}`,
	getPaymentDetails: (payment_id) =>
		`${accountService}/payments/${payment_id}`,
	createPayment: `${accountService}/payments/payment-profile`,
	createPaymentByInvoice: (subscriptionId) =>
		`${accountService}/companysubscriptions/set-payment-method-to-invoice/${subscriptionId}`,

	// Transactions
	getAgentTransactions: `${accountService}/transactions/agent-profile`,
	getCompanyTransactions: `${accountService}/transactions/business-partner-profile`,
	getTransactionDetails: (transaction_id) =>
		`${accountService}/transactions/${transaction_id}`,
	getAgentTransactionDetails: (transactionId) =>
		`${accountService}/transactions/agent-details/${transactionId}`,
	getAgentPaymentTransactionDetails: (transactionId) =>
		`${accountService}/transactions/transaction-payment-agent-details/${transactionId}`,

	// Voucher
	applyVoucher: (subscriptionPlanId, voucherCode) =>
		`${accountService}/voucher/apply?subscriptionPlanId=${subscriptionPlanId}&voucherCode=${voucherCode}`,
	validateVoucher: `${accountService}/voucher/validate`,
	getAgentVouchers: `${subscriptionservice}/vouchers`,
	getAgentVoucherDetails: (voucherId) =>
		`${subscriptionservice}/vouchers/${voucherId}`,

	// Invitation
	getInvitations: `${subscriptionservice}/communitypartnerinvitations`,
	getAvailableVouchers: `${subscriptionservice}/communitypartnerinvitations/available-vouchers`,
	createInvitation: `${subscriptionservice}/communitypartnerinvitations`,
	getInvitationDetails: (invitationId) =>
		`${subscriptionservice}/communitypartnerinvitations/${invitationId}`,
	updateInvitation: (invitationId) =>
		`${subscriptionservice}/communitypartnerinvitations/${invitationId}`,
	deleteInvitation: (invitationId) =>
		`${subscriptionservice}/communitypartnerinvitations/${invitationId}`,

	// Extern Partners
	getExternPartners: `${accountService}/agentexternpartners`,
	createExternPartner: `${accountService}/agentexternpartners`,
	getExternPartnerDetails: (externPartnerId) =>
		`${accountService}/agentexternpartners/${externPartnerId}`,
	updateExternPartner: (externPartnerId) =>
		`${accountService}/agentexternpartners/${externPartnerId}`,
	removeExternPartner: (externPartnerId) =>
		`${accountService}/agentexternpartners/${externPartnerId}`,

	// Event

	/** Agent events */
	createEvent: `${accountService}/events`,
	getEvents: `${accountService}/events`,
	getPublicEvents: `${accountService}/events/agent-events-preview`,
	getEvent: (eventId) => `${accountService}/events/${eventId}`,
	updateEvent: (eventId) => `${accountService}/events/${eventId}`,
	publishEvent: (eventId) => `${accountService}/events/${eventId}/publish`,
	unPublishEvent: (eventId) =>
		`${accountService}/events/${eventId}/unpublish`,
	cancelEvent: (eventId) => `${accountService}/events/${eventId}/cancel`,
	cloneEvent: (eventId) => `${accountService}/events/${eventId}/clone`,
	inviteCompany: (eventId) =>
		`${accountService}/events/${eventId}/invite-company`,
	inviteGuest: (eventId) =>
		`${accountService}/events/${eventId}/invite-guest`,
	inviteGuestByContactGroups: (eventId) =>
		`${accountService}/events/${eventId}/invite-guest-by-contact-groups`,
	getCompaniesToInvite: `${accountService}/events/companies-to-invite`,
	getEventInvitations: (eventId) =>
		`${accountService}/events/${eventId}/invitations`,
	getEventAllInvitations: (eventId, isPartner, status, keyword) =>
		`${accountService}/events/${eventId}/all-invitations?isPartner=${isPartner}&status=${status}&keyword=${keyword}`,
	cancelInvitation: (eventId, participantId) =>
		`${accountService}/events/${eventId}/participants/${participantId}/cancel`,
	getParticipants: (eventId) =>
		`${accountService}/events/${eventId}/participants`,
	getEventQrCode: (eventId) => `${accountService}/events/${eventId}/qrcode`,
	downloadEventQrCode: (eventId) =>
		`${accountService}/events/${eventId}/qrcode-download`,
	exportParticipants: (eventId) =>
		`${accountService}/events/${eventId}/participants/export-participants-to-csv`,
	checkInParticipants: `${accountService}/events/participants/checkin-participant`,

	/** Company events */
	getCompanyEventInvitations: `${accountService}/companyevents/invitations`,
	getCompanyParticipatedEvent: `${accountService}/companyevents/participated-events`,
	searchEvent: `${accountService}/companyevents/events/search`,
	getEventDetails: (eventId) =>
		`${accountService}/companyevents/events/${eventId}`,
	getEventLimit: (eventId) =>
		`${accountService}/companyevents/events/${eventId}/check-limit`,
	participateEvent: (eventId) =>
		`${accountService}/companyevents/events/${eventId}/participate`,
	cancelCompanyParticipation: (eventId) =>
		`${accountService}/companyevents/events/${eventId}/participate/cancel`,

	// Marketing Asset
	getMarketingAssets: `${accountService}/marketingassets`,
	getMarketingTemplates: `${accountService}/marketingassets/get-all-by-tooltype`,
	getMarketingAssetConfig: (assetId) =>
		`${accountService}/marketingassets/${assetId}`,
	exportMarketingAssetAsPdf: `${accountService}/exportmarketingassets/export-poster-as-pdf`,
	exportMarketingAssetAsJpg: `${accountService}/exportmarketingassets/export-poster-as-jpg`,

	// Job Statistics
	getJobStatistic: `${masterdataService}/jobstatistics`,
	getTotalJobStatistic: `${masterdataService}/jobstatistics/total`,

	// Contact Groups
	getContactGroups: `${accountService}/contactgroups`,
	updateContactGroupDetails: (contactGroupId) =>
		`${accountService}/contactgroups/${contactGroupId}`,
	removeContactGroup: (contactGroupId) =>
		`${accountService}/contactgroups/${contactGroupId}`,
	createContactGroup: `${accountService}/contactgroups`,

	//Contact People
	getContactPeople: `${accountService}/contactpeople`,
	removeContactPeople: (contactPeopleId) =>
		`${accountService}/contactpeople/${contactPeopleId}`,
	createContactPeople: `${accountService}/contactpeople`,
	getContactPeopleDetails: (contactPeopleId) =>
		`${accountService}/contactpeople/${contactPeopleId}`,
	exportContactPeople: (contactGroupId) =>
		`${accountService}/contactpeople/export-contact-people-to-csv?contactGroupId=${contactGroupId}`,
	importContactPeople: (contactGroupId, deleteAllExistingItems) =>
		`${accountService}/contactpeople/import-contact-people-from-csv?contactGroupId=${contactGroupId}&deleteAllExistingItems=${deleteAllExistingItems}`,
	getContactGroupDetails: (contactGroupId) =>
		`${accountService}/contactgroups/${contactGroupId}`,

	// Agent Plan
	// createAgentPlan: `${accountService}/communitypartnerplans`,
	getAgentPlans: `${accountService}/communitypartnerplans`,
	getAgentProfilePlan: `${accountService}/communitypartnerplans`,
	getAgentPlanDetails: (planId) =>
		`${accountService}/communitypartnerplans/${planId}`,
	updateAgentProfilePlan: `${accountService}/communitypartnerplans`
};
