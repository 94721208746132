import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'Types';
import { ModalBox } from 'Elements';

interface Props extends ModalProps {
	title: string;
	description: string;
}

export const ModalBoxSectionEditProfile: FC<Props> = ({
	isOpen,
	onClose,
	title,
	description
}) => {
	const { t } = useTranslation();

	return (
		<ModalBox
			isOpen={isOpen}
			onClose={onClose}
			title={t('text.more-info-about-this')}>
			<div className='d-flex flex-column gap-2'>
				<h4 className="fs-4 fw-700">{t(title)}</h4>
				<p className="fs-6 fw-400">{t(description)}</p>
			</div>
		</ModalBox>
	);
};
