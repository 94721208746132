import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import { DataList, DataQueryParams, Media, Member } from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: true
	};
};

export const useMembers = <T = DataList<Member>>(
	{ pageSize = 12, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Member>>
): UseQueryResult<DataList<Member>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.members, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.memberList(pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const useMember = <T = Member>(
	memberId: string,
	options?: UseQueryOptions<T, any, Member>
): UseQueryResult<Member, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.member, { id: memberId }],
			queryFn: () => fetch.memberDetails(memberId),
			...config(options)
		}
	);
};

export const useUpdateMember = (memberId: string) => {
	const { refetch: refetchList } = useMembers({});
	const query = useMember(memberId);
	const member = query.data;

	const updateMember: (
		fields: Partial<Member>,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			...member,
			...fields,
			jobText: ''
		};
		await axios.put(api.updateMemberDetail(memberId), data).then((res) => {
			if (res.status === 200) {
				query.refetch();
				startTransition(() => {
					toastify('toastify.member-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		});
	};

	const removeMember: (onFinal?: () => void) => Promise<void> = async (
		onFinal
	) => {
		await axios.delete(api.removeMember(memberId as string)).then((res) => {
			if (res.status === 200) {
				onFinal?.();
				startTransition(() => {
					toastify('toastify.member-removed', {
						type: 'success'
					});
					refetchList();
				});
			}
		});
	};

	return {
		...query,
		updateMember,
		removeMember,
		member
	};
};
