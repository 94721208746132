import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, ShimmerImage, FontAwesome } from 'Elements';
import { Colors, EventInvitation, EventInvitationStatus } from 'Types';
import { clx, formatLocaleDateTime } from 'Utils';
import styles from 'partials/card/event-invited-card.module.scss';

interface Props {
	card: EventInvitation;
}

export const EventInvitedCard: FC<Props> = memo(({ card }) => {
	const { t } = useTranslation();
	const defaultLogo = '/assets/img/business-partner-default.jpg';

	const getBadgeData = (event: EventInvitation) => {
		let badgeColor = Colors.primary;
		let badgeLabel = 'draft';
		if (event) {
			if (event.status === EventInvitationStatus.NotSend) {
				badgeLabel = 'badge.not-sent';
				badgeColor = Colors.muted;
			} else if (event.status === EventInvitationStatus.Pending) {
				badgeLabel = 'badge.sent';
				badgeColor = Colors.yellow;
			} else if (event.status === EventInvitationStatus.Accepted) {
				badgeLabel = 'badge.accepted';
				badgeColor = Colors.green;
			} else if (event.status === EventInvitationStatus.Rejected) {
				badgeLabel = 'badge.rejected';
				badgeColor = Colors.red;
			}
		}
		return {
			color: badgeColor,
			label: t(badgeLabel)
		};
	};

	return (
		<div className={styles.card}>
			<div className="d-flex align-items-center">
				<div className={styles.logoBox}>
					{(!card.isExtern && card.isPartner) ||
						(!!card.companyId && (
							<ShimmerImage
								src={card.logo}
								width={50}
								height={50}
								alt={card.fullName}
								defaultImage={defaultLogo}
								className={styles.logo}
							/>
						))}
					{card.isExtern && (
						<div
							className={clx(
								styles.logo,
								'd-flex align-items-center justify-content-center text-center'
							)}>
							<FontAwesome
								icon="envelope-open-text"
								size="2x"
								className="text-secondary flex-shrink-0"
							/>
						</div>
					)}
				</div>
				<div className="flex-grow-1">
					<h2 className="fw-500 fs-sm lh-base mb-1 text-break">
						{card.fullName}
					</h2>
					<span className="text-gray-3">{card.email}</span>
				</div>
			</div>
			<div className={styles.meta}>
				<time className="d-flex align-items-center fw-300 fs-small me-auto">
					<span className="me-1">{t('label.invite-date')}:</span>
					{formatLocaleDateTime(card.inviteDate, false)}
				</time>
				<Badge color={getBadgeData(card).color}>
					{getBadgeData(card).label}
				</Badge>
			</div>
		</div>
	);
});

EventInvitedCard.displayName = 'EventInvitedCard';
