import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { UserApi } from 'Services';
import { queryKey } from 'Constants';
import { api, fetch, getUserTypeById, toastify } from 'Utils';
import {
	DataList,
	DataQueryParams,
	User,
	Media,
	UserFormData,
	B2BUser
} from 'Types';
import { id } from 'date-fns/locale';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useUsers = <T = DataList<User>>(
	{ pageSize = 12, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<User>>
): UseQueryResult<DataList<User>, unknown> => {
	return useQuery({
		queryKey: [queryKey.users, { pageSize, pageNumber, keyword }],
		queryFn: () => fetch.userList(pageSize, pageNumber, keyword),
		...config(options)
	});
};

export const useUser = <T = B2BUser>(
	id: string,
	options?: UseQueryOptions<T, any, B2BUser>
): UseQueryResult<B2BUser, unknown> => {
	return useQuery({
		queryKey: [queryKey.user, { id: id }],
		queryFn: () => fetch.userDetails(id),
		...config(options)
	});
};

export const useUpdateUser = (id: string) => {
	const query = useUser(id, { enabled: !!id });
	const user = query.data;
	// const isUser = user && getUserTypeById(user?.userType)?.type === 'b2b-user';
	const { refetch: fetchCurrentProfile } = useGetB2bUserProfileQuery();

	const api_url = api.updateB2bAdmin(id);

	const updateUser: (
		fields: UserFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data: UserFormData = {
			...user,
			...fields
		};
		await axios.put(api_url, data).then((res) => {
			if (res.status === 200) {
				if (user?.id === id) {
					fetchCurrentProfile();
				}
				query.refetch();
				startTransition(() => {
					toastify('toastify.user-updated', {
						type: 'success'
					});
					onFinal?.();
				});
			}
		});
	};

	const updateUserAvatar: (
		fields: Partial<Media>,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.patch(api.updateUserAvatar(id), fields).then((res) => {
			if (res.status === 200) {
				if (user?.id === id) {
					fetchCurrentProfile();
				}
				query.refetch();
				onFinal?.();
			}
		});
	};

	const removeUser: (onFinal: () => void) => Promise<void> = async (
		onFinal
	) => {
		await axios.delete(api.removeB2bUser(id)).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					toastify('toastify.user-removed', {
						type: 'success'
					});
					onFinal?.();
				});
			}
		});
	};

	return { ...query, user, updateUser, removeUser, updateUserAvatar };
};

export const useCreateUser = () => {
	const { data: user } = useGetB2bUserProfileQuery();

	const createUser: (
		fields: UserFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const api_url = api.createB2bAdmin;

		const data = {
			...fields,
			// userType: 4,
			address: null
			// roles: [],
			// gender: fields.gender ?? 0
		};

		await axios.post(api_url, data).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
		});
	};

	return {
		createUser
	};
};

export const { useGetB2bUserProfileQuery } = UserApi;
