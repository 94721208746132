import { FC, startTransition, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAxios, useModal, useUpdateCommunityPartner } from 'Hooks';
import {
	Address,
	Colors,
	CommunityPartnerMe,
	CommunityPartnerProfile,
	CommunityPartnerProfileFormData,
	CommunityPartnerProfileStatus,
	Media,
	Tab
} from 'Types';
import {
	FontAwesome,
	LoadingSpinner,
	ModalBoxPublish,
	ProfileDecorationBox,
	Card,
	Button
} from 'Elements';
import { api } from 'src/helpers/API';
import { toastify } from 'src/helpers/Toastify';
import { formatLocaleDateTime } from 'src/helpers/DateTime';
import { useTranslation } from 'react-i18next';

interface Props {
	communityPartnerProfile: CommunityPartnerProfile;
	communityPartnerMe: CommunityPartnerMe;
	refetchCommunityPartnerMe?: () => void;
	refetchCommunityPartnerProfile?: () => void;
}

export const CommunityPartnerDecorationBox: FC<Props> = ({
	communityPartnerProfile,
	communityPartnerMe,
	refetchCommunityPartnerProfile
}) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { reset } = useForm();
	const { sendRequest } = useAxios<any>();
	const [openModalPublish, toggleModalPublish] = useModal();
	const [openModalUnPublish, toggleModalUnPublish] = useModal();
	const {
		data: communityPartner,
		address,
		isLoading,
		refetch
	} = useUpdateCommunityPartner();

	let tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive:
				pathname === '/my-community-partner' ||
				pathname === '/my-community-partner/general'
		},
		{
			id: 1,
			title: 'tab.address',
			path: 'address',
			isActive: pathname === '/my-community-partner/address'
		},
		{
			id: 3,
			title: 'tab.bank',
			path: 'bank',
			isActive: pathname === '/my-community-partner/bank'
		}
	];

	if (
		communityPartner?.partnerClass !== 3
		// communityPartner?.parentCommunityPartnerId === '75b61de6-0784-4001-8b98-247db014e676'
	) {
		tabs = [
			{
				id: 0,
				title: 'tab.general',
				path: 'general',
				isActive:
					pathname === '/my-community-partner' ||
					pathname === '/my-community-partner/general'
			},
			{
				id: 1,
				title: 'tab.address',
				path: 'address',
				isActive: pathname === '/my-community-partner/address'
			},
			// {
			// 	id: 2,
			// 	title: 'tab.regions',
			// 	path: 'regions',
			// 	isActive: pathname === '/my-community-partner/regions'
			// },
			{
				id: 3,
				title: 'tab.bank',
				path: 'bank',
				isActive: pathname === '/my-community-partner/bank'
			}
			// {
			// 	id: 4,
			// 	title: 'tab.association',
			// 	path: 'association',
			// 	isActive: pathname === '/my-community-partner/association'
			// }
		];
	}

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			const data = {
				logo: {
					url: image_data.url,
					thumbnail: image_data.thumbnailUrl
				}
			};
			await sendRequest(
				api.updateCommunityPartnersMeLogo,
				{ data, method: 'PATCH' },
				() => {
					refetch();
					startTransition(() => {
						toastify('toastify.community-partner-updated', {
							type: 'success'
						});
					});
				}
			);
		}
	};

	const handleResetLogo = async () => {
		const data = {
			logo: {
				url: '',
				thumbnail: ''
			}
		};
		await sendRequest(
			api.updateCommunityPartnersMeLogo,
			{ data, method: 'PATCH' },
			() => {
				refetch();
				startTransition(() => {
					toastify('toastify.community-partner-updated', {
						type: 'success'
					});
				});
			}
		);
	};

	useEffect(() => {
		reset(communityPartner);
	}, [reset, communityPartner]);

	return (
		<>
			{communityPartner?.status ===
				CommunityPartnerProfileStatus.Active && (
				<Card>
					{isLoading && (
						<LoadingSpinner title="forms.loading-content" />
					)}
					{!isLoading && (
						<div>
							<div
								className={`d-flex flex-column flex-lg-row align-items-lg-center ${
									communityPartnerProfile?.auditInfo
										? 'justify-content-lg-between'
										: 'justify-content-lg-end'
								}`}>
								{communityPartnerProfile?.auditInfo && (
									<div className="d-flex flex-column flex-lg-row gap-1 gap-lg-4 align-items-lg-center">
										<div className="d-flex gap-1 ">
											<FontAwesome
												icon="code-branch"
												color={Colors.primary}
												size="sm"
											/>
											<span className="fw-400 fs-sm">
												{t(
													'text.current-profile-version'
												)}
												:
											</span>
											<span>
												{
													communityPartnerProfile?.version
												}
											</span>
										</div>
										{communityPartnerMe?.publishInfo
											?.version && (
											<div className="d-flex gap-1 flex-wrap">
												<FontAwesome
													icon="code-branch"
													color={Colors.primary}
													size="sm"
												/>
												<span className="fw-400 fs-sm">
													{' '}
													{t(
														'text.published-version'
													)}
													:
												</span>
												<span>
													{
														communityPartnerMe
															?.publishInfo
															?.version
													}
												</span>
												<span>
													{communityPartnerMe
														?.publishInfo
														?.lastUpdatedAt
														? `(${t('text.at')} 
											${formatLocaleDateTime(communityPartnerMe?.publishInfo?.lastUpdatedAt)})`
														: ''}
												</span>
											</div>
										)}
										<div className="d-flex gap-1 flex-wrap">
											<FontAwesome
												icon="calendar"
												color={Colors.primary}
												size="sm"
											/>
											<span className="fw-400 fs-sm">
												{' '}
												{t('text.last-updated-at')}:
											</span>
											<span>
												{communityPartnerProfile
													?.auditInfo?.updatedAt
													? formatLocaleDateTime(
															communityPartnerProfile
																?.auditInfo
																?.updatedAt
													  )
													: ''}
											</span>
										</div>
									</div>
								)}
								{/* {type === 'communityPartner' && (
							<DecorationDropDown>
								<DropdownItem
									className="d-flex align-items-center gap-2 text-red"
									onClick={toggleModalDelete}>
									<FontAwesome
										icon="triangle-exclamation"
										size="lg"
									/>
									{t('dropdown.delete-account')}
								</DropdownItem>
							</DecorationDropDown>
						)} */}
								<div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center gap-2">
									{communityPartner?.publishInfo?.status ===
										1 && (
										<Button
											size="sm"
											color={Colors['white-red']}
											onClick={toggleModalUnPublish}>
											<FontAwesome
												icon={'triangle-exclamation'}
												size="1x"
												className="me-2"
											/>
											{t('button.unPublish')}
										</Button>
									)}
									{communityPartnerProfile?.version !==
										communityPartnerMe?.publishInfo
											?.version && (
										<Button
											size="sm"
											color={Colors['white-primary']}
											onClick={toggleModalPublish}>
											<FontAwesome
												icon={'code-branch'}
												size="1x"
												className="me-2"
											/>
											{t('button.publish')}
										</Button>
									)}
								</div>
							</div>
							{communityPartnerProfile?.version !==
								communityPartnerMe?.publishInfo?.version && (
								<Card className="w-100 d-flex my-3 py-2 rounded-3 align-items-center shadow-none infinite gap-1 bg-yellow_o-2 text-yellow">
									<FontAwesome
										icon="info-circle"
										size="1x"
										className="me-2"
									/>
									{t(
										'text.current-version-and-the-last-published-version-different'
									)}
								</Card>
							)}
						</div>
					)}
				</Card>
			)}
			<ProfileDecorationBox
				tabs={tabs}
				type="communityPartner"
				profile={communityPartner as CommunityPartnerProfileFormData}
				isLoading={isLoading}
				title={communityPartner?.businessName ?? '---'}
				logo={communityPartner?.logo?.url ?? ''}
				thumbnailLogo={communityPartner?.logo?.thumbnail ?? ''}
				address={address as Address}
				onResetLogo={handleResetLogo}
				onChangeLogo={handleUploadImage}
				refetchPartnerProfile={refetchCommunityPartnerProfile}
			/>
			<ModalBoxPublish
				userType="communityPartner"
				profileId={communityPartner?.communityPartnerProfileId}
				status={communityPartner?.status as number}
				refetch={refetch}
				type={'publish'}
				isOpen={openModalPublish}
				onClose={toggleModalPublish}
			/>
			<ModalBoxPublish
				userType="communityPartner"
				profileId={communityPartner?.communityPartnerProfileId}
				status={communityPartner?.status as number}
				refetch={refetch}
				type={'unPublish'}
				isOpen={openModalUnPublish}
				onClose={toggleModalUnPublish}
			/>
		</>
	);
};
