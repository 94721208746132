import { FC, Suspense } from 'react';
import { Col, Row } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import { BusinessPartnerDecorationBox, LoadingContent } from 'Elements';
import { BusinessPartnerMe, BusinessPartnerProfile } from 'Types';

type Props = {
	businessPartnerProfile: BusinessPartnerProfile;
	businessPartnerMe: BusinessPartnerMe;
	refetchBusinessPartnerMe?: () => void;
	refetchBusinessPartnerProfile?: () => void;
};

export const UpdateBusinessPartner: FC<Props> = ({
	businessPartnerProfile,
	businessPartnerMe,
	refetchBusinessPartnerMe,
	refetchBusinessPartnerProfile
}) => {
	return (
		<Row>
			<Col xs={12} className="mb-4">
				<BusinessPartnerDecorationBox
					businessPartnerProfile={businessPartnerProfile}
					businessPartnerMe={businessPartnerMe}
					refetchBusinessPartnerProfile={
						refetchBusinessPartnerProfile
					}
					refetchBusinessPartnerMe={refetchBusinessPartnerMe}
				/>
			</Col>
			<Col xs={12}>
				<Suspense fallback={<LoadingContent />}>
					<Outlet />
				</Suspense>
			</Col>
		</Row>
	);
};
