import { Container } from 'reactstrap';
import { clx } from 'Utils';
import { ColorValues, Logo } from 'Types';
import {
	CommunityPartnerPreviewCompanyOverviewStandard,
	LoadingSpinner
} from 'Elements';
import styles from 'partials/homepage/section/community-partner-business-partner-overview-section.module.scss';
import styled from 'styled-components';

type Props = {
	isLoadingProfile: boolean;
	becomePartner: {
		enabled: boolean;
		headline: string;
		description: string;
		image: Logo;
	};
};

const Background = styled.div<{ $IsPremium: boolean; $image?: string }>`
	${({ $IsPremium, $image }) =>
		$IsPremium &&
		`
		position: relative;
		${
			$image &&
			`
			background-image: url(${$image});
			background-size: cover;
			background-repeat: no-repeat;
		`
		};
		&:before {
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			background-color: ${ColorValues['gray-6']};
			opacity: ${$image ? 0.85 : 1};
		}`}
	${({ $IsPremium }) =>
		!$IsPremium && `background-color: ${ColorValues['gray-3']};`}
`;

export const CommunityPartnerPreviewCompanyOverviewSection = ({
	isLoadingProfile,
	becomePartner
}: Props) => {
	return (
		<section className="position-relative mb-5">
			{isLoadingProfile && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoadingProfile && (
				<Background $IsPremium={false} $image={''}>
					<Container
						// fluid={!IsPremium ? 'lg' : false}
						fluid={false}
						className={clx(
							styles.container,
							// !IsPremium && 'g-0'
							'g-0'
						)}>
						{/* {IsPremium ? (
								<AgentCompanyOverviewPremium />
							) : (
								<AgentCompanyOverviewStandard />
							)} */}
						<CommunityPartnerPreviewCompanyOverviewStandard
							isLoadingProfile={isLoadingProfile}
							becomePartner={becomePartner}
						/>
					</Container>
				</Background>
			)}
		</section>
	);
};
