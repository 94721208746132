import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import { DataList, DataQueryParams, Media, Department } from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: true
	};
};

export const useDepartments = <T = DataList<Department>>(
	{ pageSize = 12, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Department>>
): UseQueryResult<DataList<Department>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.departments, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.departmentList(pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const useDepartment = <T = Department>(
	departmentId: string,
	options?: UseQueryOptions<T, any, Department>
): UseQueryResult<Department, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.department, { id: departmentId }],
			queryFn: () => fetch.departmentDetails(departmentId),
			...config(options)
		}
	);
};

export const useUpdateDepartment = (departmentId: string) => {
	const { refetch: refetchList } = useDepartments({});
	const query = useDepartment(departmentId);
	const department = query.data;

	const updateDepartment: (
		fields: Partial<Department>,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			...department,
			...fields,
			jobText: ''
		};
		await axios
			.put(api.updateDepartmentDetail(departmentId), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.department.updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			});
	};

	const updateDepartmentImage: (
		fields: Partial<Media>,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			image: fields.url,
			thumbnailImage: fields.thumbnailUrl
		};
		await axios
			.put(api.updateDepartmentImage(departmentId), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					onFinal?.();
				}
			});
	};

	const removeDepartment: (onFinal?: () => void) => Promise<void> = async (
		onFinal
	) => {
		await axios
			.delete(api.removeDepartment(departmentId as string))
			.then((res) => {
				if (res.status === 200) {
					onFinal?.();
					startTransition(() => {
						toastify('toastify.department.removed', {
							type: 'success'
						});
						refetchList();
					});
				}
			});
	};

	return {
		...query,
		updateDepartment,
		updateDepartmentImage,
		removeDepartment,
		department
	};
};
