import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom'
import { containerVariants } from 'Constants';
import { useAgentPartners, useAxios, useDelayUnmount, useModal } from 'Hooks';
import {
	AgentPartner,
	AgentPartnerApprovalStatus,
	BadgeStatus,
	Colors
} from 'Types';
import {
	agentPartnerApprovalStatus,
	api,
	buildUrl,
	clx,
	config,
	formatLocaleDateTime,
	toastify,
	subStatus
} from 'Utils';
import {
	Badge,
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	FontAwesome,
	ListingPage,
	ModalBoxPartnerApproval,
	NoData,
	Pagination,
	ShimmerImage,
	SystemErrorAlert
} from 'Elements';
import styles from 'partials/shared/page.module.scss';

export const ListPartner = memo(() => {
	const { t } = useTranslation();
	const { sendRequest } = useAxios();
	const [openModalApprove, toggleModalApprove] = useModal();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const approvalParam = searchParams.get('approval') || '';
	const defaultImage = '/assets/img/business-partner-default.jpg';
	const shouldRenderModalApprove = useDelayUnmount(openModalApprove, 350);
	const [selectedPartner, setSelectedPartner] = useState<AgentPartner | null>(
		null
	);
	const [selectedStatus, setSelectedStatus] = useState<number | null>();

	const [state, setState] = useState({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam
	});

	const getFilterByName = (filter_name: string) => {
		return agentPartnerApprovalStatus.find(
			(filter) => filter.title === filter_name
		);
	};

	const { data, isFetching, refetch, isError } = useAgentPartners({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword,
		agentApprovalStatus: getFilterByName(approvalParam)?.value
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterChange = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			approval: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleSubmitStatus = (
		partner: AgentPartner,
		status: AgentPartnerApprovalStatus
	) => {
		setSelectedPartner(partner);
		setSelectedStatus(status);
		toggleModalApprove();
	};

	const partnerLink = (partner: AgentPartner) => {
		return partner.company.isActive
			? buildUrl(
					config.publicUrl!,
					'businessPartner',
					partner.companyId,
					partner.company.slugUrl
			  )
			: '';
	};

	const navigateTo = (partner: AgentPartner) => {
		window.open(partnerLink(partner), '_blank', 'noreferrer');
	};

	const onSubmitStatus = async (description = '') => {
		if (selectedPartner) {
			await sendRequest(
				api.updateAgentCompanyApprovalStatus(selectedPartner.id),
				{
					method: 'PUT',
					data: {
						agentApprovedStatus: selectedStatus,
						description
					}
				},
				() => {
					if (
						selectedStatus === AgentPartnerApprovalStatus.approved
					) {
						toastify(t(`toastify.business-partner-approved`), {
							type: 'success'
						});
					} else {
						toastify(t(`toastify.business-partner-rejected`), {
							type: 'success'
						});
					}
					void refetch();
					toggleModalApprove();
				}
			);
		}
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			partner: 'w-sm-30',
			'join-date': 'w-sm-10',
			'business-partner-status': 'w-sm-20',
			status: 'w-sm-15',
			'modify-date': 'w-sm-10',
			operation: 'w-sm-15'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			filterOptions={agentPartnerApprovalStatus}
			selectedFilter={t(
				getFilterByName(approvalParam)?.label ?? 'dropdown.all'
			)}
			onFilterChange={handleFilterChange}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.partner"
							cellClassName={cellClassName('partner')}
						/>
						<DataTableRowCell
							cellText="table.join-date"
							cellClassName={cellClassName('join-date')}
						/>
						<DataTableRowCell
							cellText="table.business-partner-status"
							cellClassName={cellClassName('business-partner-status')}
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.modify-date"
							cellClassName={cellClassName('modify-date')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((partner) => {
								return (
									<DataTableRow key={partner.id}>
										<DataTableRowCell
											cellHead="table.partner"
											cellClassName={cellClassName(
												'partner'
											)}>
											<div className="d-flex align-items-center gap-3">
												<div
													className={
														styles.avatarBox
													}>
													<ShimmerImage
														width={48}
														height={48}
														defaultImage={
															defaultImage
														}
														src={
															partner?.company
																?.logo ??
															defaultImage
														}
														alt={
															partner?.company
																?.name ?? '---'
														}
														className={
															styles.avatar
														}
													/>
												</div>
												<div className="d-flex flex-column gap-1 flex-grow-1">
													<h6 className="fs-sm fw-500 mb-0 lh-base">
														{partner?.company
															?.name ?? '---'}
													</h6>
													{partner?.company
														?.branch && (
														<span className="text-gray-3 lh-sm fs-small">
															{
																partner?.company
																	?.branch
															}
														</span>
													)}
													{partner?.company
														?.email && (
														<span className="text-gray-3 lh-sm fs-small">
															{
																partner?.company
																	?.email
															}
														</span>
													)}
												</div>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'join-date'
											)}
											cellHead="table.join-date">
											<time
												dateTime={partner.purchaseDate}
												title={formatLocaleDateTime(
													partner.purchaseDate,
													false
												)}>
												{formatLocaleDateTime(
													partner.purchaseDate,
													false
												)}
											</time>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'business-partner-status'
											)}
											cellHead="table.business-partner-status">
											<div className="d-flex flex-wrap align-items-center gap-2">
												<div>
													<Badge
														color={
															partner.company
																.isActive
																? Colors.green
																: Colors.yellow
														}>
														{partner.company
															.isActive
															? t(
																	'badge.published'
															  )
															: t(
																	'badge.unPublished'
															  )}
													</Badge>
												</div>
												<div>
													<Badge
														color={
															subStatus[
																partner?.status
																	?.approveStatus
															]?.color ??
															Colors.primary
														}>
														{t(
															`badge.${
																subStatus[
																	partner
																		?.status
																		?.approveStatus
																]?.title
															}`
														)}
													</Badge>
												</div>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'status'
											)}
											cellHead="table.status">
											<Badge
												color={
													agentPartnerApprovalStatus[
														partner
															.agentApprovedStatus
													]?.color ?? Colors.primary
												}>
												{t(
													`badge.${
														agentPartnerApprovalStatus[
															partner
																.agentApprovedStatus
														]?.title
													}`
												)}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'modify-date'
											)}
											cellHead="table.modify-date"
											boxClassName="d-flex flex-column gap-1">
											<time
												dateTime={
													partner.agentApprovalStatus
														.updatedAt
												}
												title={formatLocaleDateTime(
													partner.agentApprovalStatus
														.updatedAt,
													false
												)}>
												{formatLocaleDateTime(
													partner.agentApprovalStatus
														.updatedAt,
													false
												)}
											</time>
											{partner.agentApprovalStatus
												.createdBy && (
												<span className="text-gray-3 fs-small">
													{t('label.by')}:&nbsp;
													{partner.agentApprovalStatus
														.createdBy ?? '---'}
												</span>
											)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'operation'
											)}
											cellHead="table.operation">
											{partner.agentApprovedStatus ===
												AgentPartnerApprovalStatus.pending && (
												<DataTableOperation>
													<Button
														size="sm"
														className="gap-2"
														color={
															Colors[
																'white-primary'
															]
														}
														onClick={() =>
															handleSubmitStatus(
																partner,
																AgentPartnerApprovalStatus.approved
															)
														}>
														<FontAwesome icon="check" />
														{t('button.approve')}
													</Button>
													<Button
														size="sm"
														className="gap-2"
														color={
															Colors['white-red']
														}
														onClick={() =>
															handleSubmitStatus(
																partner,
																AgentPartnerApprovalStatus.rejected
															)
														}>
														<FontAwesome
															swapOpacity
															fillOpacity={0.8}
															icon="xmark"
														/>
														{t('button.reject')}
													</Button>
												</DataTableOperation>
											)}
											{partner.agentApprovedStatus !==
												AgentPartnerApprovalStatus.pending &&
												partner.company.isActive && (
													<DataTableOperation>
														<Button
															size="sm"
															color={
																Colors[
																	'white-primary'
																]
															}
															className="gap-2"
															title={t(
																'title.view-partner-page',
																{
																	partnerName:
																		partner
																			.company
																			.name
																}
															)}
															onClick={() =>
																navigateTo(
																	partner
																)
															}>
															<FontAwesome
																icon="arrow-up-right-from-square"
																size="sm"
															/>
															{t(
																'button.view-profile'
															)}
														</Button>
													</DataTableOperation>
												)}
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[30, 10, 20, 15, 10, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-partner-yet" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{isError && <SystemErrorAlert />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
			{shouldRenderModalApprove && (
				<ModalBoxPartnerApproval
					onClose={toggleModalApprove}
					isOpen={openModalApprove}
					partnerName={selectedPartner?.company.name ?? ''}
					status={
						selectedStatus ?? AgentPartnerApprovalStatus.approved
					}
					onSubmit={onSubmitStatus}
				/>
			)}
		</ListingPage>
	);
});

ListPartner.displayName = 'ListPartner';
