import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps, SubscriptionPlan } from 'Types';
import { FontAwesome, LoadingSpinner, ModalBox } from 'Elements';
import {
	useSubscriptionCalculate,
	useSubscriptionPlansFeature
} from 'src/hooks/useSubscription';
import { currencyFormatter } from 'src/helpers/Utils';
import styles from 'partials/step/step-container.module.scss';
import { Col, Row } from 'reactstrap';

interface Props extends ModalProps {
	plan: SubscriptionPlan;
}

export const ModalBoxPlanInf: FC<Props> = ({ isOpen, onClose, plan }) => {
	const { t } = useTranslation();

	const { data: subscriptionCalculate } = useSubscriptionCalculate(
		plan?.communityPartnerId as string,
		plan?.id as string,
		'',
		{
			enabled: !!plan?.communityPartnerId
		}
	);

	const { data: subscriptionPlansFeature, isLoading: isLoadingPlansFeature } =
		useSubscriptionPlansFeature(
			plan?.subscriptionPlanFeatureId as string,
			plan?.id as string,
			{
				enabled: !!plan?.subscriptionPlanFeatureId
			}
		);

	return (
		<ModalBox
			size="xl"
			isOpen={isOpen}
			onClose={onClose}
			title={t('text.more-info-about-this')}>
			<div className="d-flex flex-column gap-2">
				<div className="d-flex align-items-center justify-content-between">
					<h2 className="fw-500 fs-3">{plan?.name}</h2>
					<div className="d-flex">
						<div className="d-flex flex-column pe-3 me-3 text-primary">
							<h5 className="fw-500 fs-3 mb-0">
								{currencyFormatter(
									subscriptionCalculate?.totalAmount ?? 0
								)}
							</h5>
							<div>
								<span className="fw-300 lh-base">
									{t('title.per')}
								</span>
								<span className="mt-auto fs-base">
									{`${
										subscriptionCalculate?.subscriptionDuration ??
										''
									} ${t('title.days')}`}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className={`${styles.boxVideo} flex-wrap `}>
					<div className={styles.video}>
						<img src="/assets/img/covervideo.png" />
					</div>
					<div className="fw-400 fs-sm w-70">
						{t('text.description-video-cp-list')}
					</div>
				</div>
				{isLoadingPlansFeature && <LoadingSpinner />}
				{!isLoadingPlansFeature && subscriptionPlansFeature && (
					<div className="border p-4 rounded">
						<Row>
							{subscriptionPlansFeature?.eventSetting
								?.eventsEnabled && (
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t('text.feature.events-enabled')}:
										</div>
										<div>
											{subscriptionPlansFeature
												?.eventSetting?.eventsEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
							)}

							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t('text.feature.max-publish-events')}:
									</div>
									<div>
										{subscriptionPlansFeature?.eventSetting
											?.maxPublishEvents ?? 0}
									</div>
								</div>
							</Col>

							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t(
											'text.feature.publish-events-enabled'
										)}
										:
									</div>
									<div>
										{subscriptionPlansFeature?.eventSetting
											?.publishEventsEnabled
											? t('label.yes')
											: t('label.no')}
									</div>
								</div>
							</Col>

							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t('text.feature.internship-enabled')}:
									</div>
									<div>
										{subscriptionPlansFeature
											?.internshipSetting
											?.internshipEnabled
											? t('label.yes')
											: t('label.no')}
									</div>
								</div>
							</Col>

							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t(
											'text.feature.internship-manager-enabled'
										)}
										:
									</div>
									<div>
										{subscriptionPlansFeature
											?.internshipSetting
											?.internshipManagerEnabled
											? t('label.yes')
											: t('label.no')}
									</div>
								</div>
							</Col>

							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t(
											'text.feature.max-publish-internships'
										)}
										:
									</div>
									<div>
										{subscriptionPlansFeature
											?.internshipSetting
											?.maxPublishInternships ?? 0}
									</div>
								</div>
							</Col>

							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t(
											'text.feature.publish-internship-enabled'
										)}
										:
									</div>
									<div>
										{subscriptionPlansFeature
											?.internshipSetting
											?.publishInternshipEnabled
											? t('label.yes')
											: t('label.no')}
									</div>
								</div>
							</Col>
							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>{t('text.feature.job-enabled')}:</div>
									<div>
										{subscriptionPlansFeature?.jobSetting
											?.jobEnabled
											? t('label.yes')
											: t('label.no')}
									</div>
								</div>
							</Col>
							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t('text.feature.jobs-google-index')}:
									</div>
									<div>
										{subscriptionPlansFeature?.jobSetting
											?.jobsGoogleIndex
											? t('label.yes')
											: t('label.no')}
									</div>
								</div>
							</Col>
							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t(
											'text.feature.limit-job-visibility'
										)}
										:
									</div>
									<div>
										{subscriptionPlansFeature?.jobSetting
											?.limitJobVisibility
											? t('label.yes')
											: t('label.no')}
									</div>
								</div>
							</Col>
							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t('text.feature.max-publish-jobs')}:
									</div>
									<div>
										{subscriptionPlansFeature?.jobSetting
											?.maxPublishJobs ?? 0}
									</div>
								</div>
							</Col>
							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t('text.feature.publish-job-enabled')}
										:
									</div>
									<div>
										{subscriptionPlansFeature?.jobSetting
											?.publishJobEnabled
											? t('label.yes')
											: t('label.no')}
									</div>
								</div>
							</Col>
							<Col sm={12} xl={6}>
								<div className="d-flex align-items-center gap-2 mb-3">
									<FontAwesome
										icon="square-check"
										size="lg"
										className="flex-shrink-0 text-secondary"
									/>
									<div>
										{t('text.feature.max-user-accounts')}:
									</div>
									<div>
										{subscriptionPlansFeature
											?.userAccountSetting
											?.maxUserAccounts ?? 0}
									</div>
								</div>
							</Col>
						</Row>
					</div>
				)}
			</div>
		</ModalBox>
	);
};
