import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	DataQueryParams,
	DataList,
	Job,
	JobFormData,
	Media,
	JobStatistic,
	JobStatisticReportRange,
	TotalJobStatistic,
	JobTagCategories,
	JobTags,
	ActivityTag
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useProfileJobs = <T = DataList<Job>>(
	{
		status = '',
		pageSize = 12,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Job>>
): UseQueryResult<DataList<Job>> => {
	return useQuery({
		queryKey: [queryKey.jobs, { status, pageSize, pageNumber, keyword }],
		queryFn: () =>
			fetch.profileJobList(status, pageSize, pageNumber, keyword),
		...config(options)
	});
};

export const useJobTagCategories = <T = DataList<JobTagCategories>>(
	{ pageSize = 200, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<JobTagCategories>>
): UseQueryResult<DataList<JobTagCategories>> => {
	return useQuery({
		queryKey: [
			queryKey.jobTagCategories,
			{ pageSize, pageNumber, keyword }
		],
		queryFn: () => fetch.jobTagCategories(pageSize, pageNumber, keyword),
		...config(options)
	});
};

export const useActivityTags = <T = DataList<ActivityTag>>(
	{ pageSize = 200, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<ActivityTag>>
): UseQueryResult<DataList<ActivityTag>> => {
	return useQuery({
		queryKey: [
			queryKey.activityTag,
			{ pageSize, pageNumber, keyword }
		],
		queryFn: () => fetch.activityTags(pageSize, pageNumber, keyword),
		...config(options)
	});
};

export const useJobTags = <T = DataList<JobTags>>(
	{
		pageSize = 200,
		pageNumber = 1,
		keyword = '',
		jobTagCategoryId = '',
		noAssignedCategory = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<JobTags>>
): UseQueryResult<DataList<JobTags>> => {
	return useQuery({
		queryKey: [
			queryKey.jobTagCategories,
			{
				pageSize,
				pageNumber,
				keyword,
				jobTagCategoryId,
				noAssignedCategory
			}
		],
		queryFn: () =>
			fetch.jobTags(
				pageSize,
				pageNumber,
				keyword,
				jobTagCategoryId,
				noAssignedCategory
			),
		...config(options)
	});
};

/**
 * Fetches a list of jobs based on the provided query parameters.
 * @param jobId The ID of the job to fetch.
 * @param options Additional options for the query.
 * @returns The result of the query.
 */
export const useJob = <T = JobFormData>(
	jobId: string,
	options?: UseQueryOptions<T, any, JobFormData>
): UseQueryResult<JobFormData> => {
	return useQuery({
		queryKey: [queryKey.job, { id: jobId }],
		queryFn: () => fetch.jobDetails(jobId),
		...config(options)
	});
};

/**
 * Provides a function to submit a job creation request.
 * @returns An object with the submitRequest function.
 */
export const useCreateJob = () => {
	/**
	 * Submits a job creation request with the provided fields.
	 * @param fields The job form data.
	 * @param onFinal Optional callback to be called after the request is completed.
	 * @returns A Promise that resolves to the ID of the created job.
	 */
	const submitRequest: (
		fields: JobFormData,
		onFinal?: () => void
	) => Promise<string> = async (fields, onFinal) => {
		const data = fields;
		return await axios.post(api.createJob, data).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
			return res.data;
		});
	};

	return {
		submitRequest
	};
};

export const useUpdateJob = (job_id?: string) => {
	const query = useJob(job_id as string, {
		enabled: !!job_id,
		staleTime: Infinity,
		refetchOnMount: false
	});
	const job = query.data;

	// Update job with given job id
	const updateJob: (
		jobId: string,
		fields: Partial<JobFormData>,
		onFinal?: () => void
	) => Promise<void> = async (jobId, fields, onFinal) => {
		const data: Partial<JobFormData> = {
			...job,
			...fields
		};
		await axios.put(api.updateJob(jobId), data).then((res) => {
			if (res.status === 200) {
				query?.refetch();
				startTransition(() => {
					toastify('toastify.job-updated', {
						type: 'success'
					});
					onFinal?.();
				});
			}
		});
	};

	// Publish job method
	const publishJob: (
		jobId: string,
		onFinal?: () => void
	) => Promise<void> = async (jobId, onFinal) => {
		await axios.post(api.publishJob, {jobId}).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					toastify('toastify.job-published', {
						type: 'success'
					});
					onFinal?.();
				});
			}
		});
	};

	// Unpublish job method
	const unPublishJob: (
		jobId: string,
		onFinal?: () => void
	) => Promise<void> = async (jobId, onFinal) => {
		await axios.post(api.unPublishJob, {jobId}).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					toastify('toastify.job-unPublished', {
						type: 'success'
					});
					onFinal?.();
				});
			}
		});
	};

	// Update and publish job simultaneously
	const saveAndPublishJob: (
		jobId: string,
		fields: JobFormData,
		onFinal?: () => void
	) => Promise<void> = async (jobId, fields, onFinal) => {
		const data: Partial<JobFormData> = {
			...job,
			...fields
		};
		await axios.put(api.updateJob(jobId), data).then(async (res) => {
			if (res.status === 200) {
				await publishJob(jobId, onFinal);
			}
		});
	};

	const updateJobFeature: (
		jobId: string,
		url: {
			featuredPhotoUrl?: string;
			featuredVideoUrl?: string;
		},
		onFinal?: () => void
	) => Promise<void> = async (jobId, url, onFinal) => {
		const data = {
			...(url.featuredPhotoUrl && {
				featuredPhotoUrl: url.featuredPhotoUrl
			}),
			...(url.featuredVideoUrl && {
				featuredVideoUrl: url.featuredVideoUrl
			})
		};
		await axios.patch(api.jobFeature(jobId), data).then(async (res) => {
			if (res.status === 200) {
				query?.refetch();
				startTransition(() => {
					onFinal?.();
				});
			}
		});
	};

	const updateContactImage: (
		jobId: string,
		fields: Partial<Media>,
		onFinal?: () => void
	) => Promise<void> = async (jobId, fields, onFinal) => {
		const data = {
			image: fields.url,
			thumbnailUrl: fields.thumbnailUrl
		};
		await axios.patch(api.updateContactImage(jobId), data).then((res) => {
			if (res.status === 200) {
				onFinal?.();
			}
		});
	};

	const removeJob: (
		jobId: string,
		onFinal: () => void
	) => Promise<void> = async (jobId, onFinal) => {
		await axios.delete(api.removeJob(jobId)).then((res) => {
			if (res.status === 200) {
				onFinal?.();
				startTransition(() => {
					toastify('toastify.job-removed', {
						type: 'success'
					});
				});
			}
		});
	};

	return {
		...query,
		updateJob,
		publishJob,
		unPublishJob,
		updateJobFeature,
		saveAndPublishJob,
		updateContactImage,
		removeJob,
		job
	};
};

export const useJobQrCode = <T = string>(
	jobId: string,
	options?: UseQueryOptions<T, any, string>
): UseQueryResult<string> => {
	return useQuery({
		queryKey: [queryKey.jobQrCode, { id: jobId }],
		queryFn: () => fetch.jobQrCode(jobId),
		...config(options)
	});
};

export const useDownloadJobQrCode = <T = string>(
	jobId: string,
	options?: UseQueryOptions<T, any, string>
): UseQueryResult<string> => {
	return useQuery({
		queryKey: [queryKey.downloadJobQrCode, { id: jobId }],
		queryFn: () => fetch.downloadJobQrCode(jobId),
		...config(options)
	});
};

/**
 * Custom hook to fetch job statistics.
 *
 * @param options - Optional query options for the useQuery hook.
 * @returns An object containing the result of the query.
 */
export const useJobStatistics = <T = JobStatistic[]>(
	reportRange?: JobStatisticReportRange,
	options?: UseQueryOptions<T, any, JobStatistic[]>
): UseQueryResult<JobStatistic[]> => {
	return useQuery({
		queryKey: [queryKey.jobStatistics, { range: reportRange }],
		queryFn: () => fetch.jobStatistics(reportRange),
		...config(options)
	});
};

export const useTotalJobStatistics = <T = TotalJobStatistic>(
	options?: UseQueryOptions<T, any, TotalJobStatistic>
): UseQueryResult<TotalJobStatistic> => {
	return useQuery({
		queryKey: [queryKey.totalJobStatistics],
		queryFn: () => fetch.totalJobStatistic(),
		...config(options)
	});
};
