import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card, LoadingContent } from 'Elements';
import styles from 'partials/card/subscription-card.module.scss';

import { CustomerDto, SubscriptionDetails, SubscriptionPayment } from 'Types';
import { clx } from 'src/helpers/Utils';
import { useSubscriptionPayments } from 'src/hooks/useSubscription';
import { formatLocaleDateTime } from 'src/helpers/DateTime';

interface Props {
	subscription?: SubscriptionDetails;
	subscriptionPayments?: SubscriptionPayment;
	agent?: CustomerDto;
	isLoading: boolean;
}

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionDescription: FC<Props> = ({
	agent,
	subscription,
	subscriptionPayments,
	isLoading
}) => {
	const { t } = useTranslation();
	const row_class = 'justify-content-between';

	const paymentMethod = subscriptionPayments?.provider?.paymentMethodInfo
		? `Card (${subscriptionPayments.provider.paymentMethodInfo.brand} ******* ${subscriptionPayments.provider.paymentMethodInfo.last4}) / Exp: ${subscriptionPayments.provider.paymentMethodInfo.expMonth}`
		: '---';

	return (
		<div>
			<CardBox className="h-100">
				<h6 className="text-gray-3 mb-3 fs-6 fw-400">
					{t('title.payment-info')}
				</h6>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('Payment Reference')}:
					</span>
					<span>
						{subscriptionPayments?.provider?.paymentReference ||
							'---'}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('ProviderStatus')}:
					</span>
					<span>
						{subscriptionPayments?.provider?.providerStatus ||
							'---'}
					</span>
				</div>

				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('Payment Method')}:
					</span>
					<span>{paymentMethod}</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>{t('Email')}:</span>
					<span>
						{subscriptionPayments?.provider?.paymentMethodInfo
							?.email || '---'}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>{t('Created at')}:</span>
					<span>
						{subscriptionPayments?.createdAt
							? formatLocaleDateTime(
									subscriptionPayments?.createdAt || ''
							  )
							: '---'}
					</span>
				</div>

				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>{t('Updated at')}:</span>
					<span>
						{subscriptionPayments?.lastUpdatedAt
							? formatLocaleDateTime(
									subscriptionPayments?.lastUpdatedAt || ''
							  )
							: '---'}
					</span>
				</div>
				{isLoading && <LoadingContent />}
			</CardBox>
		</div>
	);
};
