import { Logo, SocialNetwork } from 'Types';
import { ProfileContactSection } from 'Elements';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	socialNetworks: SocialNetwork[];
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean | Logo | SocialNetwork[]
	) => void;
	contactPerson: {
		address: string;
		description: string;
		email: string;
		fullName: string;
		image: Logo;
		phoneNumber: string;
		position: string;
	};
	validForm: any;
};

export const CommunityPartnerContactSection = ({
	isLoadingProfile,
	onChangeValue,
	contactPerson,
	socialNetworks,
	validForm
}: Props) => {
	return (
		<ProfileContactSection
			type="communityPartner"
			contact={{
				email: contactPerson?.email,
				fullName: contactPerson?.fullName,
				firstName: '',
				lastName: '',
				position: contactPerson?.position,
				tel: contactPerson?.phoneNumber,
				contactDescription: contactPerson?.description,
				contactImage: contactPerson?.image?.url,
				contactThumbnailImage: contactPerson?.image?.thumbnail
			}}
			validForm={validForm}
			address={contactPerson?.address}
			social={socialNetworks}
			isLoading={isLoadingProfile}
			onUpdate={onChangeValue}
		/>
	);
};
