import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Container } from 'reactstrap';

import { LoadingSpinner } from 'Elements';
import styles from 'partials/homepage/section/benefit-section.module.scss';
import { ColorValues } from 'Types';
import { Text, Title } from 'src/containers/Styles';
import { clx } from 'src/helpers/Utils';

const GridBox = styled('div')`
	background-color: ${({ theme }) =>
		theme && rgba(theme.businessPartner.primaryColor, 0.3)};
	border-color: ${({ theme }) => theme && theme.businessPartner.primaryColor};
`;

type Props = {
	isLoadingProfile: boolean;
	benefits: {
		enabled: boolean;
		headline: string;
		description: string | number;
	};
};

export const BusinessPartnerPreviewBenefitSection = ({
	isLoadingProfile,
	benefits
}: Props) => {
	return (
		<section className="position-relative mb-5 ">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && benefits && (
					<GridBox className={styles.grid}>
						<div
							className={clx(
								styles.title,
								'd-flex flex-column justify-content-center mx-auto'
							)}>
							<Title
								textColor={ColorValues['gray-4']}
								className="h3 fw-700 mb-0 text-center">
								{benefits?.headline}
							</Title>
							{benefits?.description && (
								<Text
									as="div"
									textColor={ColorValues['gray-4']}
									className="mt-3 lh-md text-center">
									{benefits?.description}
								</Text>
							)}
						</div>
						{/* <Row className="g-0">
							{company?.companyBenefitDto &&
								company?.companyBenefitDto
									?.slice(0, next)
									?.map((benefit) => {
										return benefit.benefitTranslates.map(
											(item) => {
												if (
													item.language.toLowerCase() ===
													'de'
												) {
													return (
														<ProfileBenefitCard
															key={benefit.id}
															benefit={item}
															isRemoving={
																isRemoving
															}
															onClick={() =>
																handleRemoveBenefit(
																	benefit.id
																)
															}
														/>
													);
												}
											}
										);
									})}
						</Row>
						<div className="d-flex align-items-center justify-content-center mt-4 gap-3">
							{company?.companyBenefitDto?.length > next && (
								<Button
									size="lg"
									color={Colors['gray-5']}
									className="fs-base rounded-pill"
									label={t('button.more-benefits') as string}
									onClick={handleMoreBenefit}
								/>
							)}
							<Button
								size="lg"
								className="gap-3 rounded-pill"
								onClick={toggleModal}>
								<FontAwesome
									icon="plus"
									size="xl"
									swapOpacity
								/>
								{t('title.add-new-benefit')}
							</Button>
						</div> */}
					</GridBox>
				)}
			</Container>
			{/* {shouldRenderModal && company && (
				<ModalBoxAddBenefit
					isOpen={openModal}
					onClose={toggleModal}
					title={t('title.choose-benefit')}
					selectedBenefits={company?.companyBenefitDto}
				/>
			)} */}
		</section>
	);
};
