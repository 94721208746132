import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAxios, useModal, useSubscriptionCalculate } from 'Hooks';
import { api, currencyFormatter } from 'Utils';
import {
	AssetConfigInputType,
	Colors,
	SubscriptionCalculate,
	SubscriptionCommunityPartner,
	SubscriptionPaymentStatus,
	SubscriptionSate
} from 'Types';
import {
	Button,
	CreateSubscriptionVoucher,
	SubmitButton,
	FontAwesome,
	LoadingSpinner,
	SubscriptionCommunityPartnerCard,
	ModalBoxPaymentResult
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';
import { setInputAssetData } from 'src/containers/Actions';
import { useDispatch } from 'react-redux';
import '../../../../../../styles/core/forms.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Props {
	fields: SubscriptionSate;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
}

export const CreateSubscriptionReview: FC<Props> = ({
	fields,
	onPrev,
	goToStep
}) => {
	const { t } = useTranslation();
	const { sendRequest } = useAxios<any>();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [openModal, toggleModal] = useModal();
	const [loading, setLoading] = useState(false);
	const [voucherCode, setVoucherCode] = useState<string | null>(null);
	const [subscriptionPaymentStatus, SetsubscriptionPaymentStatus] =
		useState<SubscriptionPaymentStatus>(1);
	const [newSubscriptionCalculate, setNewSubscriptionCalculate] =
		useState<SubscriptionCalculate>();
	const [selectedCP, setSelectedCP] =
		useState<SubscriptionCommunityPartner>();
	const [isChecked, setIsChecked] = useState(false);
	const {
		sendRequest: sendSubscriptionRequest,
		isLoading: isSubmittingSubscription
	} = useAxios<string>();
	const [searchParams] = useSearchParams();
	const communityPartnerId = searchParams.get('communityPartnerId') || '';
	const planId = searchParams.get('planId') || '';

	const selectedPlanId = fields.selectedPlan?.id ?? planId;
	const selectedVoucher = fields.selectedVoucher;
	const selectedCommunityPartner = fields.selectedCommunityPartner;
	// const navigationUrl = `${config.publicUrl}/community-partner/${selectedCommunityPartner?.id}/${selectedCommunityPartner?.id}`;

	const { data: subscriptionCalculate, isLoading } = useSubscriptionCalculate(
		selectedCP?.id as string,
		selectedPlanId as string,
		'',
		{
			enabled: !!selectedCP?.id
		}
	);

	const getCommunityPartner = () => {
		sendRequest(
			api.getCommunityPartner(communityPartnerId),
			{ method: 'GET' },
			(data) => {
				getPlanData(data);
			}
		);
	};

	const getPlanData = (communityPartner) => {
		if (communityPartner) {
			sendRequest(
				api.getPlansCommunityPartner(communityPartner?.id),
				{ method: 'GET' },
				(planData) => {
					setSelectedCP({ ...communityPartner, planData });
					setLoading(false);
				}
			);
		}
	};

	useEffect(() => {
		if (!selectedCommunityPartner) {
			setLoading(true);
			getCommunityPartner();
		} else {
			setSelectedCP(selectedCommunityPartner);
		}
	}, [selectedCommunityPartner]);

	useEffect(() => {
		setNewSubscriptionCalculate(subscriptionCalculate);
	}, [subscriptionCalculate]);

	const onRegister = async () => {
		setIsChecked(true);
		await sendSubscriptionRequest(
			api.createSubscription,
			{
				method: 'POST',
				data: {
					coummunityPartnerId: selectedCP?.id,
					subscriptionPlanId: selectedPlanId,
					voucherCode: voucherCode
				}
			},
			async (res) => {
				if (res) {
					await sendSubscriptionRequest(
						api.getBusinessPartnerMe,
						{
							method: 'GET'
						},
						(data: any) => {
							const basic_Profile_data = {
								nameElement: data?.businessName ?? '',
								mainEmailAddress: data?.mainEmailAddress ?? '',
								phoneElement: data.contact?.tel ?? '',
								SubscriptionId: data.SubscriptionId ?? ''
							};
							for (const key in basic_Profile_data) {
								dispatch(
									setInputAssetData({
										name: key as AssetConfigInputType,
										value: basic_Profile_data[key]
									})
								);
							}
							if (
								newSubscriptionCalculate?.totalAmount === 0 ||
								!selectedCP?.isAutoApprove
							) {
								toggleModal();
								SetsubscriptionPaymentStatus(
									!selectedCP?.isAutoApprove ? 0 : 1
								);
							} else {
								goToStep('step4');
								navigate(
									`/subscriptions/add?step=step4&SubscriptionId=${res}`
								);
							}
						}
					);
				} else {
					setIsChecked(false);
				}
			}
		);
	};

	let selectedPlanPrice = 0;
	let selectedPlanDurationDays = 0;
	let shortDescription = '';

	selectedCP?.planData?.map((item) => {
		if (item.id === selectedPlanId) {
			selectedPlanPrice = item.price;
			selectedPlanDurationDays = item.durationDays;
			shortDescription = item.shortDescription;
		}
	});

	return (
		<div className={styles.form}>
			<div className="position-relative">
				<SubscriptionCommunityPartnerCard
					key={selectedCP?.id}
					planDataLoading={loading}
					communityPartner={
						selectedCP as SubscriptionCommunityPartner
					}
					selected={false}
				/>
				<Button
					color={Colors['white-primary']}
					className={styles.edit}
					onClick={() => {
						goToStep('step1');
						navigate(`/subscriptions/add?step=step1`);
					}}>
					<FontAwesome icon="edit" />
					{t('text.change')}
				</Button>
			</div>
			<div className="mb-5 py-5 d-flex justify-content-between border-bottom">
				<div>
					<h6 className="fs-sm fw-400 text-gray-3 mb-3">
						{t('title.selected-plan')}
					</h6>
					<div>
						<div className="my-3">
							<div className="d-flex align-items-center">
								<h5 className="h5 mb-0 pe-2 me-2 border-end border-2 text-gray-3 fw-500">
									{currencyFormatter(selectedPlanPrice ?? 0)}
								</h5>
								<span className="text-gray-3">
									{t('title.per-n-days', {
										duration: selectedPlanDurationDays ?? ''
									})}
								</span>
							</div>
							<div
								className="text-gray-3 fs-sm fw-400 mt-1"
								dangerouslySetInnerHTML={{
									__html: shortDescription ?? ''
								}}
							/>
						</div>
						<div className="my-1">
							<div className="d-flex">
								<div className="d-flex flex-column text-end pe-3 me-3 border-end border-2 text-primary">
									<h5 className="h5 mb-0">
										{currencyFormatter(
											newSubscriptionCalculate?.totalAmount ??
												0
										)}
									</h5>
									<span className="fw-300">
										{t('title.excluded-tax')}
									</span>
								</div>
								<div className="text-gray-3 text-start d-flex flex-column">
									<span className="fw-300 lh-base">
										{t('title.per')}
									</span>
									<span className="mt-auto fs-base">
										{`${
											newSubscriptionCalculate?.subscriptionDuration ??
											''
										} ${t('title.days')}`}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="border-bottom position-relative">
					<Button
						color={Colors['white-primary']}
						className={styles.edit}
						onClick={() => {
							goToStep('step2');
							navigate(
								`/subscriptions/add?step=step2&communityPartnerId=${selectedCP?.id}`
							);
						}}>
						<FontAwesome icon="edit" />
						{t('text.change')}
					</Button>
				</div>
			</div>
			{loading || isLoading ? (
				<LoadingSpinner />
			) : (
				<CreateSubscriptionVoucher
					setIsChecked={setIsChecked}
					isChecked={isChecked}
					setVoucherCode={setVoucherCode}
					selectedPlanId={planId}
					setNewSubscriptionCalculate={setNewSubscriptionCalculate}
					selectedCommunityPartnerId={communityPartnerId}
					subscriptionCalculate={
						newSubscriptionCalculate || {
							planBasePrice: 0,
							additionalPrice: 0,
							subscriptionPrice: 0,
							subscriptionDuration: 0,
							discountAmount: 0,
							discountPercent: 0,
							subTotal: 0,
							taxPercent: 0,
							taxAmount: 0,
							totalAmount: 0
						}
					}
				/>
			)}
			<div className="d-flex align-items-center justify-content-end pt-4 gap-3">
				{onPrev && (
					<Button
						color={Colors['white-primary']}
						className="gap-2 me-auto"
						onClick={onPrev}>
						<FontAwesome
							icon="chevron-left"
							size="1x"
							swapOpacity
						/>
						{t('wizard.prev')}
					</Button>
				)}
				<SubmitButton
					icon={false}
					isDisable={!isChecked}
					color={Colors.secondary}
					label={
						selectedVoucher?.totalAmount !== 0
							? t('wizard.proceed-to-payment')
							: t('button.free-checkout')
					}
					savingLabel=""
					isSubmitting={isSubmittingSubscription}
					onClick={onRegister}
				/>
			</div>
			<ModalBoxPaymentResult
				subscriptionCalculate={
					newSubscriptionCalculate as SubscriptionCalculate
				}
				subscriptionPaymentStatus={subscriptionPaymentStatus}
				isOpen={openModal}
				onClose={toggleModal}
			/>
		</div>
	);
};
