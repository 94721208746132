import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	AgentProfile,
	DataList,
	DataQueryParams,
	Error,
	Subscription,
	SubscriptionCalculate,
	SubscriptionDetails,
	SubscriptionPayment,
	SubscriptionPlan,
	SubscriptionPlansFeature,
	SubscriptionPrimaryPlan
} from 'Types';
import { AxiosError } from 'axios';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useSubscriptionPlans = <T = DataList<SubscriptionPlan>>(
	{
		pageSize,
		pageNumber,
		keyword = '',
		planType,
		communityPartnerId
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<SubscriptionPlan>>
): UseQueryResult<DataList<SubscriptionPlan>, AxiosError<Error>> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionPlans,
			{ pageSize, pageNumber, keyword, planType, communityPartnerId }
		],
		queryFn: () =>
			fetch.subscriptionPlanList(
				pageSize,
				pageNumber,
				keyword,
				planType,
				communityPartnerId
			),
		...config(options)
	});
};


export const useSubscriptions = <T = DataList<Subscription>>(
	{ pageSize, pageNumber, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Subscription>>
): UseQueryResult<DataList<Subscription>, unknown> => {
	return useQuery({
		queryKey: [queryKey.subscriptions, { pageSize, pageNumber, keyword }],
		queryFn: () =>
			fetch.companySubscriptionList(pageSize, pageNumber, keyword),
		...config(options)
	});
};


export const useSubscription = <T = SubscriptionDetails>(
	subscriptionId: string,
	options?: UseQueryOptions<T, any, SubscriptionDetails>
): UseQueryResult<SubscriptionDetails, unknown> => {
	return useQuery({
		queryKey: [queryKey.subscription, { subscriptionId }],
		queryFn: () => fetch.subscriptionDetails(subscriptionId),
		...config(options)
	});
};

export const useSubscriptionPayments = <T = SubscriptionPayment>(
	currentSubscriptionPaymentId: string,
	options?: UseQueryOptions<T, any, SubscriptionPayment>
): UseQueryResult<SubscriptionPayment, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionPayments,
			{ currentSubscriptionPaymentId }
		],
		queryFn: () =>
			fetch.subscriptionPaymentsDetails(currentSubscriptionPaymentId),
		...config(options)
	});
};

export const useSubscriptionCalculate = <T = SubscriptionCalculate>(
	CommunityPartnerId: string,
	SubscriptionPlanId: string,
	VoucherCode?: string,
	options?: UseQueryOptions<T, any, SubscriptionCalculate>
): UseQueryResult<SubscriptionCalculate, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionCalculate,
			{ CommunityPartnerId, SubscriptionPlanId, VoucherCode }
		],
		queryFn: () =>
			fetch.subscriptionCalculate(
				CommunityPartnerId,
				SubscriptionPlanId,
				VoucherCode
			),
		...config(options)
	});
};


export const useSubscriptionPlansFeature = <T = SubscriptionPlansFeature>(
	subscriptionPlanFeatureId: string,
	SubscriptionPlanId: string,
	options?: UseQueryOptions<T, any, SubscriptionPlansFeature>
): UseQueryResult<SubscriptionPlansFeature, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionPlansFeature,
			{ subscriptionPlanFeatureId, SubscriptionPlanId }
		],
		queryFn: () =>
			fetch.subscriptionPlansFeature(
				subscriptionPlanFeatureId,
				SubscriptionPlanId
			),
		...config(options)
	});
};

export const useAgentToSwitch = <T = AgentProfile>(
	{
		pageSize,
		pageNumber,
		keyword = '',
		companySubscription
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<AgentProfile>>
): UseQueryResult<DataList<AgentProfile>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.agentToSwitch,
			{ pageSize, pageNumber, keyword, companySubscription }
		],
		queryFn: () =>
			fetch.agentsToSwitch(
				pageSize,
				pageNumber,
				keyword,
				companySubscription
			),
		...config(options)
	});
};

export const useUpdateSubscription = (
	subscriptionId: string,
	options?: UseQueryOptions<SubscriptionDetails>
) => {
	const query = useSubscription(subscriptionId, options);

	const updateSubscription: (
		field: boolean,
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (field, onFinal, onCatch) => {
		const data = {
			autoRenewal: field
		};
		await axios
			.put(api.updateSubscriptionRenewal(subscriptionId), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify(
							field
								? 'toastify.auto-renewal-activated'
								: 'toastify.auto-renewal-canceled',
							{
								type: 'success'
							}
						);
					});
					onFinal?.();
				}
			})
			.catch(() => onCatch?.());
	};
	return { ...query, updateSubscription };
};
