import {
	AuthenticationResult,
	BrowserCacheLocation,
	Configuration,
	EventMessage,
	EventType,
	InteractionRequiredAuthError,
	PublicClientApplication,
	SilentRequest
} from '@azure/msal-browser';
import { UserType } from 'Types';
import { clearStorage, config } from 'Utils';

// check this link for help on how to properly implement msal:
// https://ecfmg.gitbook.io/azure-serverless-quickstart/application-design/adding-authentication/adding-msal-and-react

type AuthHeader = {
	'Ocp-Apim-Subscription-Key': string;
	Authorization: string;
};

export const authorityDomain = `${config.tenant}.b2clogin.com`;

const authConfig: Configuration = {
	auth: {
		clientId: config.clientId as string,
		authority: `https://${authorityDomain}/${config.b2cTenant}/${config.b2cSigningPolicy}`,
		knownAuthorities: [authorityDomain],
		redirectUri: config.redirectUrl,
		postLogoutRedirectUri: config.redirectUrl
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage,
		storeAuthStateInCookie: true,
		secureCookies: true
	}
};

export const authInstance: PublicClientApplication =
	new PublicClientApplication(authConfig);

const accounts = authInstance.getAllAccounts();

void authInstance.initialize().then(() => {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	if (accounts.length > 0) {
		authInstance.setActiveAccount(accounts[0]);
	}

	authInstance.addEventCallback((event: EventMessage) => {
		if (
			event.eventType === EventType.LOGIN_START ||
			event.eventType === EventType.LOGOUT_START
		) {
			clearStorage();
		}
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			const payload = event.payload as AuthenticationResult;
			const account = payload.account;
			authInstance.setActiveAccount(account);
		}
	});
});

export const loginRequest: SilentRequest = {
	account: accounts[0],
	scopes: config?.scope ? [config.scope] : [],
	forceRefresh: true
};

export const getAcquireToken: () => Promise<string | void> = async () => {
	const account = authInstance.getActiveAccount();
	if (!account) {
		throw Error(
			'No active account! Verify a user has been signed in and setActiveAccount has been called.'
		);
	} else {
		return await authInstance
			.acquireTokenSilent({
				...loginRequest,
				account
			})
			.then((res) => {
				return res.idToken;
			})
			.catch(async (error) => {
				if (error instanceof InteractionRequiredAuthError) {
					clearStorage();
					return authInstance.acquireTokenRedirect(loginRequest);
				}
			});
	}
};

export const getAuthHeader = async () => {
	const idToken = await getAcquireToken();
	if (idToken) {
		const account = authInstance.getActiveAccount();
		if (
			account &&
			(account?.idTokenClaims?.['extension_usertype'] ===
				UserType.b2bAdmin ||
				account?.idTokenClaims?.['extension_usertype'] ===
					UserType.b2bUser)
		) {
			return {
				'Ocp-Apim-Subscription-Key': config.subscriptionKey,
				Authorization: `Bearer ${idToken}`
			} as AuthHeader;
		}
	}
	return null;
};

export const logoutRedirect: () => Promise<void> = async () => {
	const account = authInstance.getActiveAccount();
	const idToken = await getAcquireToken();
	if (idToken) {
		await authInstance.logoutRedirect({
			idTokenHint: idToken,
			account,
			postLogoutRedirectUri: '/'
		});
	}
};
