import { FC, memo } from 'react';
import { clx } from 'Utils';
import { useModal } from 'Hooks';
import { AspectRatio, Colors, Media } from 'Types';
import {
	Button,
	LoadingContent,
	ModalBoxChangeImage,
	FontAwesome
} from 'Elements';
import styles from 'partials/homepage/card/gallery.module.scss';

interface Props {
	image: string;
	title: string;
	imageClassName?: string;
	boxClassName?: string;
	isRemoving?: boolean;
	isUpdating?: boolean;
	onEdit: (image_data: Media) => void;
	onRemove?: () => void;
	isEdit?: boolean;
}

export const GallerySlide: FC<Props> = memo(
	({
		image,
		title,
		imageClassName,
		boxClassName,
		isRemoving,
		isUpdating,
		onEdit,
		onRemove,
		isEdit = true
	}) => {
		const [openModal, toggleModal] = useModal();
		return (
			<div className={clx(styles.slide, boxClassName)}>
				{isUpdating && (
					<LoadingContent
						title="title.uploading"
						className={styles.loading}
					/>
				)}
				{isEdit && (
					<div className="edit-overlay">
						<div className="d-flex align-items-center">
							<Button
								disabled={isRemoving}
								color={Colors['white-red']}
								className="mx-2 p-3"
								onClick={onRemove}>
								{isRemoving && (
									<FontAwesome
										spin
										spinPulse
										icon="spinner"
										size="2x"
									/>
								)}
								{!isRemoving && (
									<FontAwesome icon="trash-xmark" size="2x" />
								)}
							</Button>
							<Button
								color={Colors['white-primary']}
								className="mx-2 p-3"
								disabled={isRemoving}
								onClick={toggleModal}>
								<FontAwesome icon="pen" size="2x" />
							</Button>
						</div>
					</div>
				)}
				<img className={imageClassName} src={image} alt={title} />
				<ModalBoxChangeImage
					name="gallery"
					title="title.change-image"
					image={image}
					aspectRatio={AspectRatio['16/9']}
					isOpen={openModal}
					onImageChange={onEdit}
					onRemove={onRemove}
					onClose={toggleModal}
				/>
			</div>
		);
	}
);

GallerySlide.displayName = 'GallerySlide';
