import { Container } from 'reactstrap';
import { LoadingSpinner } from 'Elements';
import { Title } from 'Styles';

type Props = {
	isLoadingProfile: boolean;
	jobs: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerPreviewJobSection = ({
	isLoadingProfile,
	jobs
}: Props) => {
	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && jobs && (
					<div className="position-relative">
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-0 text-center">
							{jobs?.headline}
						</Title>
					</div>
				)}
			</Container>
		</section>
	);
};
