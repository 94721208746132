import styled from 'styled-components';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Title } from 'Styles';
import { useModal, useTheme } from 'Hooks';
import {
	FontAwesome,
	LoadingSpinner,
	ModalBoxSectionEditProfile,
	ReactSwitch,
	Button,
	Collapsible
} from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';
import { Colors } from 'Types';

const Box = styled.div`
	background-color: ${({ theme }) =>
		theme && rgba(theme.businessPartner.primaryColor, 0.05)};
`;

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	jobAds: {
		description: string | number;
		enabled: boolean;
		headline: string;
	};
};

export const BusinessPartnerJobHeadlineSection = ({
	isLoadingProfile,
	onChangeValue,
	jobAds
}: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const [openModal, toggleModal] = useModal();

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center my-5 py-5">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && jobAds && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${styles.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700">
										{' '}
										{t('text.job-common')}
									</h5>
									<ReactSwitch
										size="md"
										checked={jobAds?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'jobAds',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-job-common-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<Row className="justify-content-center">
							<Col lg={10} xl={8} className="position-relative">
								<Box className="p-4 position-relative">
									<div className="d-flex align-items-center gap-2">
										<FontAwesome
											icon="grid-4"
											size="3x"
											color={
												theme.businessPartner
													.primaryColor
											}
										/>
										<Title
											textColor="text-gray-5"
											className="h3 mb-0 flex-grow-1">
											<input
												type="text"
												onChange={(e) => {
													onChangeValue(
														'jobAds',
														'headline',
														e.target.value
													);
												}}
												placeholder={`[${t(
													'placeholder.job-common-headline'
												)}]`}
												value={jobAds?.headline ?? ''}
												className={`${styles.inputEdit} w-100`}
											/>
										</Title>
									</div>
									<div className="mt-2">
										<textarea
											rows={3}
											onChange={(e) => {
												onChangeValue(
													'jobAds',
													'description',
													e.target.value
												);
											}}
											placeholder={`[${t(
												'placeholder.job-common-desc'
											)}]`}
											value={jobAds?.description ?? ''}
											className={`${styles.inputEdit} w-100`}
										/>
									</div>
									{!jobAds?.enabled && (
										<div className="overlay overlay_white overlay-box" />
									)}
								</Box>
							</Col>
						</Row>
					</Collapsible>
				)}
			</Container>
			<ModalBoxSectionEditProfile
				isOpen={openModal}
				onClose={toggleModal}
				title="info.job-common-title"
				description="info.job-common-description"
			/>
		</section>
	);
};
