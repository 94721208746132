import { memo, FC } from 'react';
import { Colors } from 'Types';
import { Button, TButtonProps, FontAwesome } from 'Elements';

export const EditButton: FC<TButtonProps> = memo(({ ...props }) => {
	return (
		<Button {...props} color={Colors.empty}>
			<FontAwesome
				icon="pen-to-square"
				size="xl"
				
				className="text-primary"
			/>
		</Button>
	);
});

EditButton.displayName = 'EditButton';
