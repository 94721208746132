import { FC, memo, PropsWithChildren } from 'react';
import {
	BusinessPartnerMe,
	BusinessPartnerProfile,
	CommunityPartnerMe,
	CommunityPartnerProfile,
	UserCategory
} from 'Types';
import { HomepageHeader, PrivateRoute } from 'Elements';
import styles from 'partials/homepage/layout.module.scss';
import { clx } from 'src/helpers/Utils';

interface Props extends PropsWithChildren {
	previewUrl: string;
	type: UserCategory;
	data: BusinessPartnerProfile | CommunityPartnerProfile;
	dataPartnerMe: BusinessPartnerMe | CommunityPartnerMe;
	partnerProfileId: string;
	isFetchingProfile: boolean;
	isDirtyForm: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	refetch: () => void;
	refetchPartnerMe?: () => void;
	validForm: any;
}

export const ProfilePageLayout: FC<Props> = ({
	children,
	previewUrl,
	type,
	isFetchingProfile,
	data,
	partnerProfileId,
	dataPartnerMe,
	onChangeValue,
	isDirtyForm,
	validForm,
	refetch,
	refetchPartnerMe
}) => {
	return (
		<PrivateRoute role={type}>
			<main className={styles.wrapper}>
				<div className="position-relative d-flex flex-grow-1">
					<HomepageHeader
						previewUrl={previewUrl}
						type={type}
						data={data}
						validForm={validForm}
						isFetchingProfile={isFetchingProfile}
						isDirtyForm={isDirtyForm}
						onChangeValue={onChangeValue}
						refetch={refetch}
						refetchPartnerMe={refetchPartnerMe}
						dataPartnerMe={dataPartnerMe}
						partnerProfileId={partnerProfileId}
					/>
					<div
						className={clx(
							styles.headerEditProfile,
							'w-100 flex-1'
						)}>
						{children}
					</div>
				</div>
			</main>
		</PrivateRoute>
	);
};
