import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	useAxios,
	useBusinessPartnerMe,
	useModal,
	useSubscription
} from 'Hooks';
import { api, currencyFormatter } from 'Utils';
import { Colors, SubscriptionCalculate, SubscriptionSate } from 'Types';
import { Button, FontAwesome, ModalBoxPaymentResult } from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: SubscriptionSate;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
}

export const CreateSubscriptionPaymentMethod: FC<Props> = ({ fields }) => {
	const { t } = useTranslation();
	const { sendRequest } = useAxios<any>();
	const [searchParams, _setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const [subscriptionPaymentStatus, setSubscriptionPaymentStatus] =
		useState(-1);
	const [disabled, setDisabled] = useState(false);
	const [loadingLabel, setLoadingLabel] = useState(true);
	const [subscriptionCalculate, setSubscriptionCalculate] =
		useState<SubscriptionCalculate>();
	const [communityPartnerId, setCommunityPartnerId] = useState();
	const [currentSubscriptionPaymentId, setCurrentSubscriptionPaymentId] =
		useState();
	const [subscriptionPlanId, setSubscriptionPlanId] = useState();

	const [openModal, toggleModal] = useModal();
	const subscriptionId =
		searchParams.get('SubscriptionId') ??
		fields?.selectedCommunityPartner?.id;

	const res: any = useSubscription(subscriptionId as string, {
		enabled: !!subscriptionId
	});
	const { data: businessPartnerMe, isLoading: isLoadingBusinessPartnerMe } =
		useBusinessPartnerMe();

	useEffect(() => {
		setCommunityPartnerId(
			res?.data?.communityPartnerInfo?.communityPartnerId ??
				fields?.selectedCommunityPartner?.id
		);
		setCurrentSubscriptionPaymentId(
			res?.data?.currentSubscriptionPaymentId ??
				searchParams.get('subscriptionPaymentId')
		);
		setSubscriptionPlanId(
			res?.data?.subscriptionPlanInfo?.subscriptionPlanId ??
				fields?.selectedPlan?.id
		);
	}, [res]);

	useEffect(() => {
		if (communityPartnerId && subscriptionPlanId) {
			sendRequest(
				api.getSubscriptionCalculate(
					communityPartnerId as string,
					subscriptionPlanId as string,
					''
				),
				{ method: 'GET' },
				(subscriptionCalculate) => {
					setSubscriptionCalculate(subscriptionCalculate);
					setLoadingLabel(false);
					setLoading(false);
				}
			);
		}
	}, [communityPartnerId]);

	const checkPayStatus = () => {
		sendRequest(
			api.subscriptionCheckPayStatus(currentSubscriptionPaymentId),
			{ method: 'GET' },
			(data) => {
				setSubscriptionPaymentStatus(data);
				toggleModal();
			}
		);
	};

	useEffect(() => {
		if (subscriptionPaymentStatus !== -1) {
			const intervalId = setInterval(() => {
				checkPayStatus();
			}, 5000);
			if (subscriptionPaymentStatus !== 3) {
				toggleModal();
				clearInterval(intervalId);
			}
			return () => clearInterval(intervalId);
		}
	}, [subscriptionPaymentStatus]);

	// useEffect(() => {
	// 	const myInterval = () => {
	// 		setInterval(function () {
	// 			checkPayStatus();
	// 		}, 5000);
	// 	};
	// 	if (subscriptionPaymentStatus === 3) {
	// 		checkPayStatus();
	// 	} else {
	// 		toggleModal();
	// 		clearInterval(myInterval);
	// 	}
	// }, [subscriptionPaymentStatus]);

	const onClickPayment = async (paymentMethodId) => {
		setDisabled(true);
		await sendRequest(
			api.subscriptionPay(currentSubscriptionPaymentId, paymentMethodId),
			{},
			async () => {
				checkPayStatus();
			}
		);
	};

	const onClickAddPayment = async () => {
		setDisabled(true);
		await sendRequest(
			api.subscriptionPrePay(currentSubscriptionPaymentId),
			{},
			(data: any) => {
				window.location = data;
			}
		);
	};

	return (
		<div className={styles.form}>
			<div className="d-flex flex-column px-3">
				<h2 className="fs-base fw-500 mb-4">
					Choose your payment method
				</h2>
				<div className="d-flex flex-column gap-1">
					<span className="fs-sm fw-400">Total price</span>
					<span className="fs-small fw-400">
						Mit diesem Abo kannst Du weitere Community Partner
						unterstützen.
					</span>
				</div>
				<div className="my-4 pb-3 border-bottom">
					<div className="d-flex">
						<div className="d-flex flex-column text-end pe-3 me-3 border-end border-2 text-primary">
							{!loadingLabel && (
								<h5 className="h5 mb-0">
									{currencyFormatter(
										subscriptionCalculate?.totalAmount ?? 0
									)}
								</h5>
							)}
							{loadingLabel && (
								<div className={styles.labelSkeleton}></div>
							)}
							<span className="fw-300">
								{t('title.excluded-tax')}
							</span>
						</div>
						<div className="text-gray-3 text-start d-flex flex-column">
							<span className="fw-300 lh-base">
								{t('title.per')}
							</span>
							<span className="mt-auto fs-base">
								{`${
									subscriptionCalculate?.subscriptionDuration ??
									''
								} ${t('title.days')}`}
							</span>
						</div>
					</div>
				</div>
				<div className="d-flex flex-column gap-4">
					{!loading &&
						!isLoadingBusinessPartnerMe &&
						businessPartnerMe?.paymentMethods?.map((item) => {
							return (
								<div
									key={item.paymentMethodId}
									className={
										disabled
											? styles.disabledCardPayment
											: styles.cardPayment
									}>
									<div className="d-flex align-items-center justify-content-between">
										<div>
											<div className="d-flex align-items-center gap-2">
												<FontAwesome
													icon={'credit-card'}
													className={
														disabled
															? 'text-gray-2'
															: 'text-primary'
													}
													size="1x"
													swapOpacity
												/>
												<span
													className={`fs-5 fw-500 ${
														disabled
															? 'text-gray-2'
															: 'text-primary'
													}`}>
													{`${item.type}  ${
														item.last4 ?? ''
													}`}
												</span>
											</div>
											<div className="mt-2">
												{item.expYear &&
													item.expYear !== '0' && (
														<span
															className={`fw-400 fs-sm 	${
																disabled
																	? 'text-gray-2'
																	: 'text-gray-3'
															} mx-3`}>
															{`Expired ${item.expMonth}/${item.expYear}`}
														</span>
													)}
												{item.expYear === '0' && (
													<span className="fw-400 fs-sm text-gray-3 mx-3">
														{item.email}
													</span>
												)}
											</div>
										</div>
										<div>
											<Button
												onClick={() => {
													if (!disabled) {
														onClickPayment(
															item.paymentMethodId
														);
													}
												}}
												color={
													disabled
														? Colors['gray-2']
														: Colors[
																'white-primary'
														  ]
												}>
												{t('button.pay-with-this')}
											</Button>
										</div>
									</div>
								</div>
							);
						})}
					{loading &&
						isLoadingBusinessPartnerMe &&
						[1, 2, 3].map((item) => {
							return (
								<div
									key={item}
									className={
										styles.cardPaymentSkeleton
									}></div>
							);
						})}
					{!loading && (
						<div
							className={
								disabled
									? styles.disabledCardPayment
									: styles.addCardPayment
							}
							onClick={onClickAddPayment}>
							<div className="d-flex align-items-center gap-2">
								<FontAwesome
									icon="plus"
									className={
										disabled
											? 'text-gray-2'
											: 'text-primary'
									}
									size="1x"
									swapOpacity
								/>
								<span
									className={`fs-5 fw-500  ${
										disabled
											? 'text-gray-2'
											: 'text-primary'
									}`}>
									Add payment method
								</span>
							</div>

							<div className="mt-2">
								<span
									className={`fw-400 fs-sm mx-3 ${
										disabled ? 'text-gray-2' : 'text-gray-3'
									}`}>
									Add new payment method
								</span>
							</div>
						</div>
					)}
					{loading && (
						<div className={styles.cardPaymentSkeleton}></div>
					)}
				</div>
			</div>
			<ModalBoxPaymentResult
				subscriptionCalculate={
					subscriptionCalculate as SubscriptionCalculate
				}
				subscriptionPaymentStatus={subscriptionPaymentStatus}
				isOpen={res?.data?.status === 1 ? true : openModal}
				onClose={toggleModal}
			/>
		</div>
	);
};
