import { useEffect } from 'react';
import dragula from 'dragula';
import 'dragula/dist/dragula.css';
import { FontAwesome } from 'src/containers/Elements';

type CardDraggableProps = {
	children: any;
	setItems?: CallableFunction;
};

function CardDraggable({ children, setItems }: CardDraggableProps) {
	useEffect(() => {
		const drake: any = dragula(
			[document.getElementById('cardDrag')] as any,
			{
				moves: function (el: any, container: any, handle: any) {
					return handle.classList.contains('handle');
				}
			}
		);
		drake.on('drop', (el: any, container: any, handle: any) => {
			const arr: any = [];
			for (let i = 0; i < container?.childNodes.length; ++i) {
				arr.push({ jobTagId: container?.childNodes[i]?.id,title:"", order: i + 1 });
			}
			setItems && setItems(arr);
		});
	}, []);

	return (
		<div id="cardDrag" className="d-flex gap-3 mx-2">
			{children?.map((child: any, index: string) => {
				return (
					<div
						id={child.key}
						key={child.key}
						className="d-flex my-2 align-items-center bg-primary"
						style={{
							height: '41px',
							borderRadius: '8px'
						}}>
						{child}
						<div
							id={child.key}
							className="handle cursor-pointer flex-center pe-3 bg-gray-200 overflow-hidden">
							<FontAwesome
								id={child.key}
								icon="arrows-maximize"
								size="1x"
								className="handle w-100 text-white handle cursor-pointer"
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default CardDraggable;
