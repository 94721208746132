import { Logo, SocialNetwork } from 'Types';
import { PreviewProfileContactSection } from 'Elements';

type Props = {
	isLoadingProfile: boolean;
	socialNetworks: SocialNetwork[];
	contactPerson: {
		address: string;
		description: string;
		email: string;
		fullName: string;
		image: Logo;
		phoneNumber: string;
		position: string;
	};
};

export const BusinessPartnerPreviewContactSection = ({
	isLoadingProfile,
	contactPerson,
	socialNetworks
}: Props) => {
	return (
		<PreviewProfileContactSection
			type="businessPartner"
			contact={{
				email: contactPerson?.email,
				fullName: contactPerson?.fullName,
				firstName: '',
				lastName: '',
				position: contactPerson?.position,
				tel: contactPerson?.phoneNumber,
				contactDescription: contactPerson?.description,
				contactImage: contactPerson?.image?.url,
				contactThumbnailImage: contactPerson?.image?.thumbnail
			}}
			address={contactPerson?.address}
			social={socialNetworks}
			isLoading={isLoadingProfile}
		/>
	);
};
