import { FC, memo, useState, useMemo, useCallback } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAfter, isSameDay, parseISO } from 'date-fns';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { addDays } from 'Utils';
import { usePrompt, useSectors } from 'Hooks';
import {
	InternshipFormGeneral,
	SelectOption,
	Sector,
	InternshipType,
	Activity,
	Translate
} from 'Types';
import {
	DatePickerController,
	FormInlineTip,
	StepNavigation,
	SelectController
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: InternshipFormGeneral;
	onNext: (data: InternshipFormGeneral) => void;
}

export const CreateInternshipGeneral: FC<Props> = memo(({ fields, onNext }) => {
	const { t } = useTranslation();
	const descriptionLimit = 450;
	const [hasLimited, setLimited] = useState(fields.hasLimited);

	const schema = yup.object().shape({
		title: yup.string().required(t('validation.internship-title.required')),
		capacity: yup.number().when(['hasLimited'], {
			is: true,
			then: yup.number().required(t('validation.start-date.required'))
		})
	});

	const { data: sectorList, isLoading: isLoadingSectors } = useSectors({
		refetchOnMount: false
	});

	const {
		watch,
		control,
		setValue,
		getValues,
		register,
		handleSubmit,
		formState: { errors, isDirty }
	} = useForm<InternshipFormGeneral>({
		resolver: yupResolver(schema),
		defaultValues: fields
	});

	const defaultCodeName = (section: any, section_name: string) => {
		const foundedName = section?.[`${section_name}Translates`].find(
			(translate: Translate) => translate.name !== ''
		)?.name;
		return foundedName
			? `${foundedName} (${section?.code})`
			: section?.code;
	};

	const optionsListObject = useCallback(
		(key_obj: Sector | InternshipType | Activity, key_name: string) => {
			return {
				value: key_obj?.['id'],
				label: defaultCodeName(key_obj, key_name)
			};
		},
		[]
	);

	const getSectorListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		sectorList?.forEach((sector: Sector) => {
			options.push(optionsListObject(sector, 'sector'));
		});
		return options;
	}, [sectorList, optionsListObject]);

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const onSubmit: SubmitHandler<InternshipFormGeneral> = (data) => {
		onNext?.(data);
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={styles.form}
			noValidate>
			<Row>
				<Col xxl={8}>
					<FormGroup>
						<Label htmlFor="title">
							{t('forms.internship-title')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<input
							{...register('title')}
							type="text"
							id="title"
							aria-invalid={!!errors.title}
							className="inputbox w-100"
						/>
						{errors.title && (
							<div className="invalid-feedback d-block">
								{errors.title.message}
							</div>
						)}
					</FormGroup>
					<FormGroup>
						<Label htmlFor="code">
							{t('forms.reference-number')}
						</Label>
						<input
							{...register('code')}
							type="text"
							id="code"
							aria-invalid={!!errors.code}
							className="inputbox w-100"
						/>
					</FormGroup>
					<FormGroup className="position-relative">
						<Label htmlFor="shortDescription">
							{t('forms.internship.short-desc')}
						</Label>
						<textarea
							{...register('shortDescription', {
								maxLength: descriptionLimit
							})}
							maxLength={descriptionLimit}
							id="shortDescription"
							className="inputbox w-100"
							cols={10}
							rows={5}
						/>
						<div className="position-absolute end-0">
							<small className="ms-auto mt-1">
								{watch('shortDescription')?.length || 0}/
								{descriptionLimit}
							</small>
						</div>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="numberOfSeat">
							{t('forms.event.number-of-seat')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<div className="d-flex align-items-center gap-2">
							<FormGroup
								check
								className="d-flex align-items-center gap-2">
								<input
									{...register('hasLimited')}
									type="radio"
									checked={!hasLimited}
									onClick={() => {
										setLimited(false);
										setValue('hasLimited', false);
										setValue('capacity', 1);
									}}
									id="hasLimited"
									value="true"
									className="form-radio-input"
								/>
								<label
									htmlFor="capacity"
									className="form-radio-label">
									{t('forms.event.limited')}
								</label>
								<input
									{...register('capacity')}
									type="text"
									id="limitInput"
									disabled={hasLimited}
									onChange={(e) => {
										if (!e.target.value.match(/^\d*[1-9]\d*$/)) {
											setValue('capacity', "");
										}
									}}
									aria-invalid={!!errors.capacity}
									style={{ maxWidth: '6rem' }}
									className="inputbox"
								/>
							</FormGroup>
							<FormGroup check className="ms-3">
								<input
									{...register('hasLimited')}
									type="radio"
									checked={hasLimited}
									onClick={() => {
										setLimited(true);
										setValue('hasLimited', true);
										setValue('capacity', 0);
									}}
									id="unlimited"
									value="false"
									className="form-radio-input"
								/>
								<label
									htmlFor="unlimited"
									className="form-radio-label">
									{t('forms.event.unlimited')}
								</label>
							</FormGroup>
						</div>
						{errors.limit?.message && (
							<div className="invalid-feedback d-block">
								{errors.limit?.message.toString()}
							</div>
						)}
					</FormGroup>
					<StepNavigation />
				</Col>
			</Row>
		</form>
	);
});
CreateInternshipGeneral.displayName = 'CreateInternshipGeneral';
