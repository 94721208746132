import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionSate } from 'Types';
import reducers from '../subscription/SubscriptionReducer';

export const SubscriptionState: SubscriptionSate = {
	selectedAgent: null,
	selectedCommunityPartner: null,
	selectedPlan: null,
	selectedPrimaryPlan: null,
	selectedVoucher: null
};

const SubscriptionSlice = createSlice({
	name: 'subscription',
	initialState: SubscriptionState,
	reducers
});

export const {
	setSubscriptionAgent,
	setSubscriptionCommunityPartner,
	setSubscriptionPlan,
	setSubscriptionPrimaryPlan,
	setSubscriptionVoucher,
	resetSubscription
} = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
