import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useSubscriptions, useAxios, useBusinessPartnerMe } from 'Hooks';
import {
	FontAwesome,
	ListingPage,
	SubscriptionCard,
	SkeletonWrapper,
	LoadingMask,
	NoData,
	EndOfList,
	Pagination
} from 'Elements';
import { useTranslation } from 'react-i18next';
import { api, clx } from 'Utils';
import styles from 'partials/shared/page.module.scss';
import { Subscription } from 'src/types/Subscription';
import { Row } from 'reactstrap';

export const ListSubscription = () => {
	const { sendRequest } = useAxios<any>();
	const [subscriptionsList, setSubscriptionsList] =
		useState<Subscription[]>();
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = searchParams.get('status') || '';

	const [state, setState] = useState({
		pageSize: 100,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: statusParam
	});

	const {
		data: subscriptions,
		isFetching,
		isLoading
	} = useSubscriptions({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	useEffect(() => {
		if (subscriptions) {
			subscriptions?.items?.forEach((item) => {
				if (item.status === 1) {
					localStorage.setItem('showPm', 'false');
				}
			});
		}
	}, [subscriptions, isFetching]);

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = useCallback(
		(query: string) => {
			setSearchParams({
				...params,
				page: '1',
				q: query
			});
			setState((prev) => ({
				...prev,
				currentPage: 1,
				keyword: query
			}));
		},
		[params, setSearchParams]
	);

	const handleFilterBy = (selected_filter: any) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const Skeleton = () => {
		return (
			<div className={styles.card}>
				<LoadingMask className={styles.logoBox} />
			</div>
		);
	};

	const getPaymentData = () => {
		const clone: any = [];
		if (subscriptions) {
			const urls = subscriptions?.items?.map((subscription: any) =>
				sendRequest(
					api.getSubscriptionPaymentsDetail(
						subscription?.currentSubscriptionPaymentId
					),
					{ method: 'GET' },
					(paymentData) =>
						clone.push({ paymentData, ...subscription })
				)
			);
			Promise.all(urls).finally(() => {
				setSubscriptionsList(clone);
			});
		}
	};

	useEffect(() => {
		if (subscriptions) {
			getPaymentData();
		}
	}, [subscriptions]);

	return (
		<>
			{!isLoading && !isFetching && subscriptions?.items?.length !== 0 ? (
				<ListingPage
					totalItems={subscriptions?.totalItems}
					pageSizes={[4, 8, 12]}
					selectedPageSize={state.pageSize}
					onPageSizeChange={onChangePageSize}
					onFilterChange={handleFilterBy}
					onSearchChange={handleSearch}>
					{!isFetching && (
						<div className="d-flex justify-content-end align-items-center mb-2">
							<Link
								to="/subscriptions/add"
								state={{
									prevPath: 'subscriptions'
								}}
								className={clx(
									styles.action,
									styles.action_lg,
									styles.action_primary
								)}>
								<FontAwesome
									icon="plus"
									className="me-2"
									size="lg"
									swapOpacity
								/>
								{t('button.add-subscription')}
							</Link>
						</div>
					)}
					{!isFetching &&
						subscriptionsList &&
						subscriptionsList?.map((subscription) => (
							<SubscriptionCard
								subscriptionPayments={
									subscription?.paymentData as []
								}
								key={subscription?.id}
								subscription={subscription}
							/>
						))}
					{!isFetching && <EndOfList data={subscriptions} />}
					{!isFetching && subscriptions?.totalItems === 0 && (
						<NoData message="title.no-subscription" />
					)}
					{!isFetching &&
						subscriptions &&
						subscriptions?.totalPages > 1 && (
							<Pagination
								className="mt-3"
								totalItemsCount={subscriptions?.totalItems}
								activePage={parseInt(
									state.currentPage.toString(),
									10
								)}
								itemsCountPerPage={state.pageSize}
								onChange={handlePageChange}
							/>
						)}
				</ListingPage>
			) : (
				!isFetching && (
					<div className="d-flex flex-column text-center gap-2 mb-5">
						<span className="fw-500 fs-6">
							{t('text.you-havent-subscription')}
						</span>
						<p className="fw-400 fs-small">
							{t(
								'text.please-informed-that-publish-your-jobs-business-partner-you-must-complete-steps-your-registration-selecting-your-subscription-plans'
							)}
						</p>
						<Link
							to="/subscriptions/add"
							state={{
								prevPath: 'subscriptions'
							}}
							className={clx(
								'm-auto',
								styles.action,
								styles.action_lg,
								styles.action_primary
							)}>
							<FontAwesome
								icon="plus"
								className="me-2"
								size="lg"
								swapOpacity
							/>
							{t('button.add-subscription')}
						</Link>
					</div>
				)
			)}

			{isLoading ||
				isFetching &&
				[1, 2, 3].map((item) => {
					return (
						<Row className="position-relative" key={item}>
							<SkeletonWrapper
								skeleton={Skeleton}
								count={1}
								wrapperClassName="row"
							/>
						</Row>
					);
				})}
		</>
	);
};
