import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from 'partials/shared/collapsible/collapsible.module.scss';
import { FontAwesome } from 'src/containers/Elements';

export const Collapsible = ({ header, children }) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [height, setHeight] = useState<string>();
	const ref = useRef<any>();

	const handleToggle = (e) => {
		e.preventDefault();
		setIsExpanded(!isExpanded);
		setHeight(ref.current.clientHeight);
	};

	const classes = `${styles.listGroupItem} ${
		isExpanded ? 'is-expanded' : null
	}`;
	const currentHeight = isExpanded ? height : 0;

	useEffect(() => {
		setHeight(ref.current.clientHeight);
	}, [currentHeight]);

	return (
		<div className={`${styles.boxSection} ${classes} position-relative`}>
			<div className={styles.cardTitle}>
				<div className={styles.headerCollapsible}>{header}</div>
				<div
					className={styles.buttonCollapsible}
					onClick={handleToggle}>
					<FontAwesome
						icon={isExpanded ? 'chevron-up' : 'chevron-down'}
						size="xl"
					/>
				</div>
			</div>
			<div
				className={styles.cardCollapse}
				style={{ height: currentHeight + 'px' }}>
				<div className="card-body" ref={ref}>
					{children}
				</div>
			</div>
		</div>
	);
};

Collapsible.propTypes = {
	header: PropTypes.element
};
