import { memo, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUpdateJob } from 'Hooks';
import { ColorValues, Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

export const ModalBoxUnPublishJob: FC<ModalProps> = 
	({ isOpen, onClose }) => {
		const { t } = useTranslation();
		const { job_id } = useParams() as { job_id: string };
		const { refetch, unPublishJob } = useUpdateJob(job_id);
		const [unPublishing, setUnPublish] = useState(false);

		const handleUnPublishJob = async () => {
			try {
				setUnPublish(true);
				await unPublishJob(job_id, refetch);
				onClose && onClose();
			} finally {
				setUnPublish(false);
			}
		};

		return (
			<ModalBox isOpen={isOpen} onClose={onClose}>
				<header className="d-flex flex-column align-items-center text-center">
					<FontAwesome
						icon="triangle-exclamation"
						size="8x"
						color={ColorValues.red}
						className="mb-4"
					/>
					<h4 className="h6 lh-base">
						{t('text.are-you-sure-to-unPublish-your-job')}
					</h4>
					<p className="text-gray-3 lh-md">
						{t('text.job-unPublish-sub-text')}
					</p>
				</header>
				<ModalBoxFooter className="justify-content-between pt-3">
					<Button color={Colors['white-gray']} onClick={onClose}>
						{t('button.cancel')}
					</Button>
					<SubmitButton
						icon={false}
						color="white-red"
						isSubmitting={unPublishing}
						label="button.unPublish"
						savingLabel="button.unPublishing"
						onClick={handleUnPublishJob}
					/>
				</ModalBoxFooter>
			</ModalBox>
		);
	}
