import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { Colors } from 'Types';
import {
	SectionHeadline,
	LoadingSpinner,
	ProfileAddNewCard,
	ReactSwitch,
	ModalBoxSectionEditProfile,
	FontAwesome,
	Button,
	Collapsible
} from 'Elements';
import styles from 'partials/homepage/section/community-partner-department-section.module.scss';
import { useModal } from 'src/hooks/useModal';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	departments: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerDepartmentSection = ({
	isLoadingProfile,
	onChangeValue,
	departments
}: Props) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const navigationUrl = '/departments/create';

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${styles.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700 d-flex align-items-center">
										{' '}
										{t('pages.departments')}
									</h5>
									<ReactSwitch
										size="md"
										checked={departments?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'departments',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-departments-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<SectionHeadline
							title={departments?.headline}
							titlePlaceholder={`[${t(
								'placeholder.our-departments'
							)}]`}
							titleColor="text-gray-5"
							className="mx-auto text-center"
							onUpdateTitle={(val) =>
								onChangeValue('departments', 'headline', val)
							}
						/>
						{departments && (
							<ProfileAddNewCard
								navigationUrl={navigationUrl}
								title="title.add-new-department"
							/>
						)}
					</Collapsible>
				)}
				<ModalBoxSectionEditProfile
					isOpen={openModal}
					onClose={toggleModal}
					title="info.departments-title"
					description="info.departments-description"
				/>
			</Container>
			{!departments?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
};
