import { FC, useState } from 'react';
import { names } from 'Constants';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatLocalAddress, formatTimeToCET, clx } from 'Utils';
import { useDelayUnmount, useModal, useMyEventInvitation } from 'Hooks';
import {
	Colors,
	CompanyParticipatedEvent,
	EventRegisterForm,
	EventType,
	RegistrationStatus
} from 'Types';
import {
	Badge,
	Card,
	CompanyParticipatedEventStatusBox,
	FontAwesome,
	ModalBoxEventParticipation
} from 'Elements';
import styles from 'partials/card/business-partner-event-card.module.scss';

interface Props {
	card: CompanyParticipatedEvent;
	refetch: () => void;
}

export const CompanyParticipatedEventsCard: FC<Props> = ({ card, refetch }) => {
	const { t } = useTranslation();
	const { events } = names;
	const [isSubmitting, setSubmitting] = useState(false);
	const [openModal, toggleModal] = useModal();
	const { cancelParticipateEvent, participateEvent } = useMyEventInvitation(
		card.eventId
	);
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const eventInfo = card.eventInfo;
	const address = eventInfo?.address;
	const defaultImage = '/assets/img/default-event.webp';
	const navigateUrl = `${events.path}/${card?.eventId}/${eventInfo.slugTitle}`;
	const hasBadge =
		card.partnerRegistrationStatus === RegistrationStatus.Finished ||
		card.isCanceled ||
		card.isFinished;

	const getCoverBadgeLabel = () => {
		let badgeLabel = '';
		switch (eventInfo.eventType) {
			case EventType.Online:
				badgeLabel = 'badge.event.online';
				break;
			case EventType.InPerson:
				badgeLabel = 'badge.event.in-person';
				break;
			case EventType.Hybrid:
				badgeLabel = 'badge.event.hybrid';
				break;

			default:
				badgeLabel = 'badge.event.online';
				break;
		}
		return t(badgeLabel);
	};

	const getBadgeData = () => {
		let badgeLabel = 'badge.canceled';
		let badgeColor = Colors['white-gray'];

		if (card.partnerRegistrationStatus === RegistrationStatus.Finished) {
			badgeLabel = 'badge.event.registration-finished';
		}

		if (card.isFinished) {
			badgeLabel = 'badge.finished';
		}
		if (card.isCanceled) {
			badgeLabel = 'badge.canceled';
			badgeColor = Colors.red;
		}

		return {
			color: badgeColor,
			label: t(badgeLabel)
		};
	};

	const handleAccept = async (data: EventRegisterForm) => {
		try {
			const api_data = {
				...data,
				InvitationId: card.eventInvitationId || ''
			};
			setSubmitting(true);
			await participateEvent(api_data, () => {
				refetch();
				setSubmitting(false);
				toggleModal();
			});
		} finally {
			setSubmitting(false);
		}
	};

	const handleReject = async () => {
		try {
			setSubmitting(true);
			await cancelParticipateEvent(
				() => {
					refetch();
					setSubmitting(false);
				},
				() => setSubmitting(false)
			);
		} finally {
			// setSubmitting(false);
		}
	};

	return (
		<Card className={clx(card.isFinished && styles.finished)}>
			<Row>
				<Col xl={9}>
					<Row>
						<Col sm={5}>
							<Link
								to={navigateUrl}
								state={{ prevPath: `${events.path}` }}
								className={styles.coverBox}>
								<img
									src={
										eventInfo.featuredPhoto || defaultImage
									}
									alt={eventInfo.title}
									className={styles.cover}
								/>
								{hasBadge && (
									<Badge
										color={getBadgeData().color}
										className={styles.coverBadge}>
										{getBadgeData().label}
									</Badge>
								)}
							</Link>
						</Col>
						<Col
							sm={7}
							className="d-flex flex-column position-relative">
							<h1 className="fs-6 fw-500 text-primary c-pointer lh-sm d-flex align-items-start">
								<Link
									to={navigateUrl}
									state={{ prevPath: `${events.path}` }}
									className="pe-2 flex-grow-1">
									{eventInfo.title}
								</Link>
								<Badge
									color={Colors.muted}
									className={styles.badge}>
									{getCoverBadgeLabel()}
								</Badge>
							</h1>
							<div className={styles.meta}>
								<div className={styles.metaItem}>
									<FontAwesome
										icon="calendar-clock"
										size="lg"
										
										className="text-primary me-2"
									/>
									<time
										dateTime={eventInfo.startDate.toString()}>
										{formatTimeToCET(eventInfo.startDate)}
									</time>
								</div>
								{address && (
									<div className={styles.metaItem}>
										<FontAwesome
											icon="location-dot"
											size="lg"
											
											className="text-primary me-2"
										/>
										{formatLocalAddress(
											address.street,
											address.houseNo,
											address.postalCode,
											address.city,
											address.state
										)}
									</div>
								)}
							</div>
							<p
								className={clx(
									styles.text,
									'fw-300 mb-0 flex-grow-1 lh-base'
								)}>
								{eventInfo.shortDescription || '---'}
							</p>
						</Col>
					</Row>
				</Col>
				<Col xl={3} className="mt-3 mt-xl-0">
					<CompanyParticipatedEventStatusBox
						event={card}
						isSubmitting={isSubmitting}
						onAccept={toggleModal}
						onReject={handleReject}
					/>
				</Col>
			</Row>
			{shouldRenderModal && (
				<ModalBoxEventParticipation
					isOpen={openModal}
					onClose={toggleModal}
					eventId={card.eventId}
					onParticipate={handleAccept}
				/>
			)}
		</Card>
	);
};

CompanyParticipatedEventsCard.displayName = 'CompanyParticipatedEventsCard';
