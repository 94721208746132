import { FC, ReactNode } from 'react';
import { clx } from 'Utils';
import { Text, Title } from 'Styles';
import { ColorValues } from 'Types';
import styles from 'partials/homepage/headline.module.scss';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';

interface Props {
	children?: ReactNode;
	description?: string | number;
	descriptionPlaceholder?: string;
	titlePlaceholder: string;
	title: string;
	className?: string;
	titleColor?: string;
	textColor?: string;
	onUpdateTitle: (val: string) => void;
	onUpdateDescription?: (val: string) => void;
}

export const SectionHeadline: FC<Props> = ({
	children,
	title,
	description,
	className,
	titlePlaceholder,
	descriptionPlaceholder,
	titleColor = ColorValues['gray-4'],
	textColor = ColorValues['gray-4'],
	onUpdateTitle,
	onUpdateDescription
}) => {
	return (
		<header className={clx('mb-4', className)} style={{ minWidth: '50%' }}>
			<div
				className={clx(
					styles.title,
					'd-flex flex-column justify-content-center mx-auto'
				)}>
				<Title
					textColor={titleColor}
					className="h3 fw-700 mb-0 text-center">
					<input
						type="text"
						onChange={(e) => {
							onUpdateTitle(e.target.value);
						}}
						placeholder={titlePlaceholder}
						value={title ?? ""}
						className={`${stylesProfile.inputEdit} w-100 text-center`}
					/>
				</Title>
				{onUpdateDescription && (
					<Text
						as="div"
						textColor={textColor}
						className="mt-3 lh-md text-center">
						<textarea
							rows={3}
							onChange={(e) => {
								onUpdateDescription(e.target.value);
							}}
							placeholder={descriptionPlaceholder}
							value={description ?? ""}
							className={`${stylesProfile.inputEdit} w-100 text-center`}
						/>
					</Text>
				)}
			</div>
			{children}
		</header>
	);
};
