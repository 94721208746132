import { useReducer } from 'react';
import Swiper from 'swiper';
import { Container } from 'reactstrap';
import { SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';
import { clx } from 'Utils';
import { AspectRatio, Colors, Gallery, Logo, Media } from 'Types';
import { GalleryReducer, setSwiper } from 'Reducers';
import { useDelayUnmount, useModal, useTheme } from 'Hooks';
import {
	Button,
	Carousel,
	GallerySlide,
	LoadingContent,
	ModalBoxChangeImage,
	FontAwesome,
	ReactSwitch,
	ModalBoxSectionEditProfile,
	Collapsible
} from 'Elements';
import 'swiper/css/effect-fade';
import styles from 'partials/homepage/section/gallery-section.module.scss';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean | Logo | Gallery[]
	) => void;
	gallery: {
		title: string;
		showInHomePage: boolean;
	};
	galleryItems: [
		{
			url: string;
			thumbnail: string;
		}
	];
};

const GlobalStyle = createGlobalStyle<{ bgColor: string }>`
	${({ bgColor }) =>
		bgColor &&
		`.swiperNav {
			background-color: ${bgColor} !important;
		}
		.swiper-pagination-current {
			color: ${bgColor} !important;
			font-weight: 700;
		}
	`}
`;

export const BusinessPartnerGallerySection = ({
	isLoadingProfile,
	onChangeValue,
	gallery,
	galleryItems
}: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const [openModalImage, toggleModalImage] = useModal();
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModalImage, 350);

	const initialState = {
		swiper: null,
		nextImage: '',
		prevImage: '',
		imageLoading: false,
		imageRemoving: false,
		imageUpdating: false
	};
	const [state, dispatch] = useReducer(GalleryReducer, initialState);

	const initSwiper = (swiper: Swiper) => {
		dispatch(setSwiper(swiper));
	};

	const updateSwiper = () => {
		if (state.swiper) {
			state.swiper.update();
		}
	};

	const handleDeleteItem = (gallery_id: string | number) => {
		const data: Gallery[] = [];
		galleryItems.map((item, index) => {
			if (index !== gallery_id) {
				data.push(item);
			}
		});

		onChangeValue('galleryItems', '', data ? data : []);
	};

	const handleAddItem = (image_data: Media) => {
		const data = galleryItems;
		data.push({
			url: image_data.url,
			thumbnail: image_data.thumbnailUrl
		});
		onChangeValue('galleryItems', '', data);
	};

	const handleEditItem = (gallery_id: string | number, image_data: Media) => {
		const data = galleryItems;
		galleryItems.forEach((item, index) => {
			if (index === gallery_id) {
				item.url = image_data.url;
				item.thumbnail = image_data.thumbnailUrl;
			}
		});

		onChangeValue('galleryItems', '', data);
	};

	return (
		<section className="position-relative mb-5">
			{!isLoadingProfile && gallery && (
				<Container>
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${stylesProfile.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700 d-flex align-items-center">
										{' '}
										{t('forms.gallery')}
									</h5>
									<ReactSwitch
										size="md"
										checked={gallery?.showInHomePage}
										onChange={(val: boolean) => {
											onChangeValue(
												'gallery',
												'showInHomePage',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-gallery-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<GlobalStyle
							bgColor={theme.businessPartner.primaryColor}
						/>

						<Carousel
							effect="fade"
							observeParents
							fadeEffect={{
								crossFade: true
							}}
							navigationGroup={galleryItems?.length > 1}
							className={styles.carousel}
							enabled={galleryItems?.length > 1}
							modules={[Pagination, EffectFade]}
							navigation
							navigationClass={clx(styles.swiperNav, 'swiperNav')}
							pagination={{
								enabled: galleryItems?.length > 1,
								type: 'fraction',
								horizontalClass: `${styles.pagination}`,
								bulletClass: `${styles.page} gallery-page`,
								bulletActiveClass: `gallery-page-active`
							}}
							onInit={initSwiper}
							onSwiper={initSwiper}
							onSlideChange={updateSwiper}
							onUpdate={initSwiper}
							onActiveIndexChange={updateSwiper}>
							{galleryItems
								?.slice(0)
								?.reverse()
								?.map((item, index) => {
									return (
										<SwiperSlide
											key={index}
											className={styles.slide}>
											<GallerySlide
												title={gallery.title}
												image={item.url}
												imageClassName={
													styles.slideImage
												}
												isUpdating={state.imageUpdating}
												isRemoving={state.imageRemoving}
												onEdit={(image_data: Media) =>
													handleEditItem(
														index,
														image_data
													)
												}
												onRemove={() =>
													handleDeleteItem(index)
												}
											/>
										</SwiperSlide>
									);
								})}
						</Carousel>

						{galleryItems?.length < 1 && (
							<div
								role="button"
								className={styles.add_slide}
								onClick={toggleModalImage}>
								<FontAwesome
									icon="upload"
									size="3x"
									className="mb-3"
								/>
								{t('button.add-new-image')}
							</div>
						)}
						{state.imageLoading && (
							<LoadingContent title="title.uploading" />
						)}
						{galleryItems?.length > 0 && (
							<div className="d-flex align-items-center justify-content-center mt-3 mt-md-4">
								<Button
									size="lg"
									className="gap-3 rounded-pill"
									disabled={state.imageLoading}
									onClick={toggleModalImage}>
									<FontAwesome
										icon="plus"
										size="xl"
										swapOpacity
									/>
									{t('button.add-new-image')}
								</Button>
							</div>
						)}
					</Collapsible>
				</Container>
			)}
			{!isLoadingProfile && !gallery?.showInHomePage && (
				<div className="overlay overlay_white overlay-box" />
			)}
			{shouldRenderModal && (
				<ModalBoxChangeImage
					name="companyHeaderLogo"
					isOpen={openModalImage}
					title="title.add-new-image"
					image={''}
					aspectRatio={AspectRatio['16/9']}
					onClose={toggleModalImage}
					onImageChange={handleAddItem}
				/>
			)}
			<ModalBoxSectionEditProfile
				isOpen={openModal}
				onClose={toggleModal}
				title="info.gallery-title"
				description="info.gallery-description"
			/>
		</section>
	);
};
