import { FC } from 'react';
import { Badge } from 'Elements';
import { Colors, BusinessPartnerProfileInitialData } from 'Types';
import { useTranslation } from 'react-i18next';
import { getFilterByValue } from 'src/helpers/Utils';
import { businessPartnerStatus } from 'src/helpers/FilterBy';

interface Props {
	profile: BusinessPartnerProfileInitialData;
}

export const ProfileDecorationBusinessPartnerBadge: FC<Props> = ({
	profile
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Badge
				color={
					profile
						? getFilterByValue(
								businessPartnerStatus,
								profile?.status?.toString()
						  )?.color ?? Colors.primary
						: Colors.primary
				}>
				{profile
					? t(
							getFilterByValue(
								businessPartnerStatus,
								profile?.status?.toString()
							)?.label ?? ''
					  )
					: '...'}
			</Badge>
			{profile?.publishInfo?.status !== 0 && (
				<Badge
					color={
						profile?.publishInfo?.status === 1
							? Colors.green
							: Colors.yellow
					}>
					{profile?.publishInfo?.status === 1
						? t('badge.published')
						: t('badge.not-published')}
				</Badge>
			)}
		</>
	);
};
