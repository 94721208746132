import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { clx, formatLocaleDateTime } from 'Utils';
import { containerVariants, names } from 'Constants';
import { useTranslation } from 'react-i18next';
import { useExternPartners, useExternPartner, useModal, useDelayUnmount } from 'Hooks';
import { ExternPartner } from 'Types';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	ListingPage,
	NoData,
	Pagination,
	DataTableOperation,
	ShimmerImage,
	ModalBoxDelete,
	SystemErrorAlert
} from 'Elements';
import styles from 'partials/shared/page.module.scss';

export const ListExternPartner = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { externPartners } = names;
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const defaultImage = '/assets/img/business-partner-default.jpg';
	const [openModalDelete, toggleModalDelete] = useModal();
	const { removeExternPartner } = useExternPartner();
	const shouldRenderModalDelete = useDelayUnmount(openModalDelete, 350);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const [state, setState] = useState<{
		pageSize: number;
		currentPage: string | number;
		keyword: string;
		isRemoving: boolean;
		selectedExternPartner: ExternPartner | null;
	}>({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam,
		isRemoving: false,
		selectedExternPartner: null
	});


	const { data, isFetching, isError, refetch } = useExternPartners({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};


	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			name: 'w-md-20',
			description: 'w-md-35',
			date: 'w-md-20',
			link: 'w-md-15',
			operation: 'w-md-10'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};


	const handleSelectRow = (row_id: string) => {
		return navigate(`${externPartners.path}/${row_id}`, {
			state: { prevPath: '${externPartner.path}' }
		});
	};


	const onRemoveExternPartner = async (ExternPartner: ExternPartner) => {
		setState((prev) => ({
			...prev,
			selectedExternPartner: ExternPartner
		}));
		toggleModalDelete();
	};

	const handleRemoveExternPartner = async () => {
		setState((prev) => ({
			...prev,
			isRemoving: true
		}));
		await removeExternPartner(state?.selectedExternPartner?.id as string, refetch).then(() => {
			toggleModalDelete();
		});
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.name"
							cellClassName={cellClassName('name')}
						/>
						<DataTableRowCell
							cellText="table.desc"
							cellClassName={cellClassName('description')}
						/>
						<DataTableRowCell
							cellText="table.date"
							cellClassName={cellClassName('date')}
						/>
						<DataTableRowCell
							cellText="table.link"
							cellClassName={cellClassName('link')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((item) => {
								return (
									<DataTableRow key={item.id}>
										<DataTableRowCell
											cellClassName={cellClassName('name')}
											cellHead="table.name">
											<Link
												to={`${externPartners.path}/${item.id}`}
												state={{
													prevPath: `/${externPartners.path}`
												}}
												title={item.name}>
												<div className="fw-500 py-1 ps-2 ps-sm-0 text-end text-sm-start">
													<div className="d-flex align-items-center gap-3">
														<div
															className={
																styles.avatarBox
															}>
															<ShimmerImage
																width={70}
																height={70}
																defaultImage={
																	defaultImage
																}
																src={
																	item?.logoUrl ??
																	defaultImage
																}
																alt={
																	item?.name ?? '---'
																}
																className={
																	styles.avatar
																}
															/>
														</div>
														<div className="d-flex flex-column gap-1 flex-grow-1">
															<h6 className="fs-sm fw-500 mb-0 lh-base">
																{item?.name}
															</h6>
														</div>
													</div>
												</div>
											</Link>
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.desc"
											cellClassName={cellClassName(
												'description'
											)}
											cellText={
												item.shortDescription ||
												'---'
											}
										/>
										<DataTableRowCell
											cellHead="table.date"
											cellClassName={cellClassName(
												'date'
											)}
										>
											<div className='mb-1'>
												<span className='fw-300 text-gray-3'>{t('table.created-at')}</span>:
												<span className='ms-2'>{formatLocaleDateTime(
													item.createdAt,
													false
												)}</span>
											</div>
											<div>
												<span className='fw-300 text-gray-3'>{t('table.expire-date')}</span>:
												<span className='ms-2'>
													{item.expireDateTime ?
														formatLocaleDateTime(item.expireDateTime, false)
														: '---'}
												</span>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.link"
											cellClassName={cellClassName(
												'link'
											)}
										>
											{item.link &&
												<a
													href={
														item?.link
													}
													rel="noreferrer"
													target="_blank"
													className={clx(
														'flex-fill',
														styles.openLink,
														styles.action_lg,
													)}
												>
													{t('button.open-link')}
												</a>
											}
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.operation"
											cellClassName={cellClassName(
												'operation'
											)}>
											<DataTableOperation
												onEditRow={() =>
													handleSelectRow(item.id)
												}
												onDeleteRow={() =>
													onRemoveExternPartner(item)
												}
											/>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[20, 35, 20, 15, 10]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-extern-partner" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{
				!isFetching && data && data?.totalPages > 1 && (
					<Pagination
						className="mt-3"
						totalItemsCount={data.totalItems}
						activePage={parseInt(state.currentPage.toString(), 10)}
						itemsCountPerPage={state.pageSize}
						onChange={handlePageChange}
					/>
				)
			}
			{
				shouldRenderModalDelete && (
					<ModalBoxDelete
						isOpen={openModalDelete}
						onClose={toggleModalDelete}
						onRemove={handleRemoveExternPartner}
						isRemoving={state?.isRemoving}
						title={state?.selectedExternPartner?.name ?? ''}
					/>
				)
			}
		</ListingPage >
	);
});

ListExternPartner.displayName = 'ListExternPartner';
