import { memo, FC } from 'react';
import { object, string } from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useUpdateCommunityPartner } from 'Hooks';
import { logoutRedirect } from 'Utils';
import { Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	OTPController,
	SubmitButton
} from 'Elements';

export const ModalBoxDeleteAccountVerification: FC<ModalProps> = memo(
	({ isOpen, onClose }) => {
		const { t } = useTranslation();
		const { deleteAgentAccount } = useUpdateCommunityPartner();

		const schema = object().shape({
			confirmationCode: string()
				.min(
					6,
					t('validation.min', {
						count: 6
					})
				)
				.required(t('validation.confirmation-code.required'))
		});

		const {
			control,
			handleSubmit,
			formState: { errors, isSubmitting, isDirty }
		} = useForm({
			resolver: yupResolver(schema),
			defaultValues: {
				confirmationCode: ''
			}
		});

		const handleDelete: SubmitHandler<{
			confirmationCode: string;
		}> = async (data) => {
			await deleteAgentAccount(data.confirmationCode, async () => {
				await logoutRedirect();
				onClose();
			});
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title={t('title.confirm-delete')}>
				<form onSubmit={handleSubmit(handleDelete)}>
					<header className="d-flex flex-column align-items-center text-center mb-3">
						<p>{t('text.delete-account.check-email')}</p>
						<OTPController
							name="confirmationCode"
							numInputs={6}
							shouldAutoFocus
							control={control}
							error={errors?.confirmationCode}
							containerStyle="mt-2 d-flex gap-2 align-items-center"
							inputStyle="otp inputbox"
						/>
					</header>
					<ModalBoxFooter className="justify-content-between pt-4">
						<Button color={Colors['white-gray']} onClick={onClose}>
							{t('button.cancel-delete-account')}
						</Button>
						<SubmitButton
							icon={false}
							color={Colors.red}
							label="button.delete-account"
							savingLabel="button.deleting"
							isDisable={!isDirty}
							isSubmitting={isSubmitting}
						/>
					</ModalBoxFooter>
				</form>
			</ModalBox>
		);
	}
);

ModalBoxDeleteAccountVerification.displayName =
	'ModalBoxDeleteAccountVerification';
