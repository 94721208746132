import { Logo } from 'Types';
import { LoadingContent, LoadingSpinner } from 'Elements';
import styles from 'partials/homepage/section/profile-hero-image.module.scss';
import { clx } from 'src/helpers/Utils';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	header: {
		description: number;
		headerImage: Logo;
		headerTitle: string;
		logo: Logo;
		slogan: number;
		themeColor: number;
	};
};

export const BusinessPartnerPreviewHeroImage = ({
	isLoadingProfile,
	isFetchingProfile,
	header
}: Props) => {
	return !isLoadingProfile ? (
		<div
			className={clx(
				styles.headerImage,
				'px-3 px-md-4 pt-3 pt-md-4 pb-0'
			)}>
			<div>
				{isFetchingProfile && <LoadingContent />}
				{!isFetchingProfile && header && (
					<>
						<img
							src={
								(header?.headerImage?.url as string) ||
								'/assets/img/default-image.jpg'
							}
							alt={header?.headerTitle}
							className={styles.image}
						/>
					</>
				)}
			</div>
		</div>
	) : (
		<div className="d-flex align-items-center justify-content-center">
			<LoadingSpinner />
		</div>
	);
};
