import { memo, FC } from 'react';
import { clx } from 'Utils';

type IconProps = {
	id?: string;
	icon: string;
	fixedWidth?: boolean;
	className?: string;
	color?: string;
	set?: string;
	title?: string;
	width?: number;
	height?: number;
	swapOpacity?: boolean;
	spin?: boolean;
	spinPulse?: boolean;
	rotation?: number;
	opacity?: number;
	fillOpacity?: number;
	strokeWidth?: number;
	size?:
		| '2xs'
		| 'xs'
		| 'sm'
		| 'lg'
		| 'xl'
		| '2xl'
		| '1x'
		| '2x'
		| '3x'
		| '4x'
		| '5x'
		| '6x'
		| '7x'
		| '8x'
		| '9x'
		| '10x';
	onClick?: () => void;
};

export const FontAwesome: FC<IconProps> = memo(
	({
		id,
		icon,
		size = 'lg',
		className,
		width,
		height,
		color,
		title,
		set = 'fad',
		strokeWidth,
		rotation,
		opacity,
		fillOpacity,
		swapOpacity,
		spin,
		spinPulse,
		...props
	}) => {
		return (
			<span id={id} className={clx(className, 'flex-shrink-0')}>
				<i
					id={id}
					{...props}
					style={{
						width: width,
						height: height,
						opacity: opacity,
						color: color,
						strokeWidth: strokeWidth
					}}
					className={`handle fa-${icon} fa-${size} ${set} ${
						swapOpacity ? 'fa-swap-opacity' : ''
					} ${spin ? 'fa-spin' : ''} ${
						spinPulse ? 'fa-spin-pulse' : ''
					} ${rotation ? `fa-rotate-${rotation}` : ''} ${
						color ? color : ''
					}`}
					{...props}
				/>
				{/* {title} */}
			</span>
		);
	}
);

FontAwesome.displayName = 'FontAwesome';
