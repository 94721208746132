import {
	Address,
	CustomPermission,
	Logo,
	SelectableAddress,
	SelectOption
} from 'Types';

export type UserCategory = 'communityPartner' | 'businessPartner';

export enum UserType {
	b2bUser = 8,
	b2bAdmin = 4
}

export interface UserProfile {
	id: string;
	userGroupId: string;
	userGroupName: string;
	companyId: string;
	companyName: string;
	userType: UserType;
	partnerType: number;
	firstName: string;
	lastName: string;
	username: string;
	address: Address;
	emailAddress: string;
	dateOfBirth: string;
	gender: string | number;
	userCategory: UserCategory;
	street: string;
	houseNumber: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
	mobileNumber: string;
	phoneNumber: string;
	imageUrl: string;
	permissions: string[];
}

export interface B2BUser {
	id: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	dateOfBirth: string;
	gender: string | number;
	avatar: Logo;
	address: Address;
	mobileNumber: number;
	phoneNumber: number;
}

export interface User {
	id: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	dateOfBirth: string;
	gender: string | number;
	avatar: Logo;
	address: Address;
	mobileNumber: number;
	phoneNumber: number;
}

export type UserAddress = SelectableAddress;

export interface UserGeneral {
	id: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	dateOfBirth: string;
	gender: string | number;
	avatar: Logo;
	address: Address;
	mobileNumber: number;
	phoneNumber: number;
}

export type UserInitialData = UserGeneral & UserAddress;

export interface UserFormData {
	firstName: string;
	lastName: string;
	emailAddress: string;
	dateOfBirth: string;
	gender: string | number;
	avatar: Logo;
	address: Address;
	mobileNumber: number;
	phoneNumber: number;
}

// User Group Interfaces
export interface UserGroup {
	id: string;
	name: string;
	description: string;
	roles: {
		id: string;
		name: string;
	}[];
}

export interface UserGroupForm {
	name: string;
	description?: string;
	roles: SelectOption[];
}

export interface UserGroupFormData {
	name: string;
	description?: string;
	roles: string[];
}

// User Role Interfaces
export interface UserRole {
	id: string;
	name: string;
	description: string;
	permissions: CustomPermission[];
}

export interface UserRoleInformation {
	name: string;
	description: string;
}

export interface UserRolePermissions {
	permissions: CustomPermission[];
}

export interface UserRoleForm {
	step1: UserRoleInformation;
	step2: UserRolePermissions;
}

export interface UserRoleFormData {
	name: string;
	description: string;
	permissions: CustomPermission[];
}
