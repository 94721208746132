import { memo, FC, useEffect, useState } from 'react';
import { isAfter, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { formatTimeToCET, getCurrentLocale } from 'Utils';
import { AgentEvent, EventFormRegistrationType, RadioOption } from 'Types';
import {
	Card,
	EventDatePicker,
	FontAwesome,
	FormInlineTip,
	RadioController,
	SwitchController
} from 'Elements';

export const UpdateEventRegistration: FC = memo(() => {
	const { t } = useTranslation();
	const currentLocaleLang = getCurrentLocale().lang;
	const fields = useOutletContext<AgentEvent>();
	const [startDateCET, setStartCET] = useState({
		visitor: '',
		partner: ''
	});
	const [endDateCET, setEndCET] = useState({
		visitor: '',
		partner: ''
	});

	const {
		watch,
		control,
		getValues,
		setValue,
		clearErrors,
		register,
		formState: { errors }
	} = useFormContext<EventFormRegistrationType>();

	const updateDateState = (
		fieldName: 'visitor' | 'partner',
		val: string,
		dateState: 'start' | 'end' = 'start'
	) => {
		if (dateState === 'start') {
			setStartCET((prev) => ({
				...prev,
				[fieldName]: val
			}));
		} else {
			setEndCET((prev) => ({
				...prev,
				[fieldName]: val
			}));
		}
	};

	const getParticipationType: () => RadioOption[] = () => {
		return [
			{
				label: t('forms.event.participation-public'),
				value: 0
			},
			{
				label: t('forms.event.participation-with-registration'),
				value: 1
			},
			{
				label: t('forms.event.participation-vip'),
				value: 2
			}
		];
	};

	const getLimitType: () => RadioOption[] = () => {
		return [
			{
				label: t('forms.event.limited'),
				value: 1
			},
			{
				label: t('forms.event.unlimited'),
				value: 0
			}
		];
	};

	const onParticipationChange = () => {
		setValue('hasParticipantLimit', 0);
		setValue('participantLimit', '-1');
		setValue('registrationStartDate', '');
		setValue('registrationEndDate', '');
		updateDateState('visitor', '');
		updateDateState('visitor', '', 'end');
		clearErrors();
	};

	const onEventPartnerChange = () => {
		setValue('hasPartnerLimit', 0);
		setValue('partnerNeedInvitation', false);
		setValue('partnerLimit', '-1');
		setValue('seatPerPartnerLimit', '-1');
		setValue('partnerRegistrationStartDate', '');
		setValue('partnerRegistrationEndDate', '');
		setValue('showPartnersDetail', true);
		updateDateState('partner', '');
		updateDateState('partner', '', 'end');
		clearErrors();
	};

	const handleLimitChange = (
		value: string | number,
		type: 'partner' | 'visitor' = 'partner'
	) => {
		if (type === 'partner') {
			if (value) {
				setValue(
					'partnerLimit',
					fields?.partnerLimit === '-1' ? '1' : fields?.partnerLimit
				);
				setValue(
					'seatPerPartnerLimit',
					fields?.partnerLimit === '-1'
						? '1'
						: fields?.seatPerPartnerLimit
				);
			} else {
				setValue('partnerLimit', '-1');
				setValue('seatPerPartnerLimit', '-1');
			}
		} else if (type === 'visitor') {
			if (value) {
				setValue(
					'participantLimit',
					fields?.participantLimit === '-1'
						? '1'
						: fields?.participantLimit
				);
			} else {
				setValue('participantLimit', '-1');
			}
		}
	};

	const onStartDateChange = (
		val: Date,
		fieldName: 'partner' | 'visitor',
		endDateFieldName: 'registrationEndDate' | 'partnerRegistrationEndDate'
	) => {
		if (val) {
			const startDate = val;
			const endDate = new Date(getValues(endDateFieldName));
			updateDateState(fieldName, formatTimeToCET(startDate));
			if (
				(endDate && isAfter(startDate, endDate)) ||
				isSameDay(startDate, endDate)
			) {
				setValue(endDateFieldName, '');
			}
		} else {
			updateDateState(fieldName, '');
		}
	};

	const onEndDateChange = (val: Date, fieldName: 'visitor' | 'partner') => {
		updateDateState(fieldName, val ? formatTimeToCET(val) : '', 'end');
	};

	const filterPassedTime = (time: Date) => {
		const currentDate = new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};

	useEffect(() => {
		if (fields) {
			updateDateState(
				'visitor',
				watch('registrationStartDate') || fields?.registrationStartDate
					? formatTimeToCET(
							watch('registrationStartDate') ||
								fields?.registrationStartDate
					  )
					: ''
			);
			updateDateState(
				'partner',
				watch('partnerRegistrationStartDate') ||
					fields?.partnerRegistrationStartDate
					? formatTimeToCET(
							watch('partnerRegistrationStartDate') ||
								fields?.partnerRegistrationStartDate
					  )
					: ''
			);
			updateDateState(
				'visitor',
				watch('registrationEndDate') || fields?.registrationEndDate
					? formatTimeToCET(
							watch('registrationEndDate') ||
								fields?.registrationEndDate
					  )
					: '',
				'end'
			);
			updateDateState(
				'partner',
				watch('partnerRegistrationEndDate') ||
					fields?.partnerRegistrationEndDate
					? formatTimeToCET(
							watch('partnerRegistrationEndDate') ||
								fields?.registrationEndDate
					  )
					: '',
				'end'
			);
		}
	}, [fields, watch, currentLocaleLang]);

	return (
		<Card className="mb-4">
			<Row>
				<Col md={8} xxl={6}>
					<section className="mb-3 pb-2">
						<h4 className="fs-sm d-flex align-items-center text-primary mb-3 gap-2">
							<FontAwesome icon="users" size="lg"  />
							{t('title.event.visitors')}
						</h4>
						<div className="ps-sm-4 ps-lg-0 ps-xl-4 ms-sm-1 ms-lg-0 ms-xl-1">
							<FormGroup>
								<RadioController
									control={control}
									name="participationType"
									option={getParticipationType()}
									defaultSelected={0}
									radioClassName="d-flex flex-column gap-3 pb-1"
									error={errors.participationType}
									onRadioChange={onParticipationChange}
								/>
							</FormGroup>
							{watch('participationType') !== 0 && (
								<>
									<FormGroup>
										<Label htmlFor="participantLimit">
											{t('forms.event.total-visitor')}
											<small className="ms-1">
												({t('validation.required')})
											</small>
										</Label>
										<RadioController
											control={control}
											name="hasParticipantLimit"
											option={getLimitType()}
											radioClassName="d-flex align-items-center pt-1 gap-3"
											onRadioChange={(value) =>
												handleLimitChange(
													value,
													'visitor'
												)
											}
										/>
									</FormGroup>
									{!!watch('hasParticipantLimit') && (
										<FormGroup row>
											<Col sm={6}>
												<input
													{...register(
														'participantLimit'
													)}
													type="text"
													id="participantLimit"
													placeholder={t(
														'placeholder.number-of-visitors'
													)}
													className="inputbox w-100"
												/>
												{errors.participantLimit && (
													<div className="invalid-feedback d-block">
														{
															errors
																.participantLimit
																.message
														}
													</div>
												)}
											</Col>
										</FormGroup>
									)}
									<FormGroup row>
										<Col
											sm={6}
											lg={12}
											xl={6}
											className="mb-3 mb-sm-0 mb-lg-3 mb-xl-0">
											<Label htmlFor="startDate">
												{t(
													'forms.event.start-registration'
												)}
											</Label>
											<EventDatePicker
												control={control}
												name="registrationStartDate"
												maxDate={
													new Date(fields?.endDate)
												}
												defaultSelected={
													fields?.registrationStartDate
												}
												isClearable={watch(
													'registrationStartDate'
												)}
												filterTime={filterPassedTime}
												onDateChange={(val: Date) =>
													onStartDateChange(
														val,
														'visitor',
														'registrationEndDate'
													)
												}
											/>
											{startDateCET.visitor && (
												<div className="text-gray-3 fs-small mt-2">
													{startDateCET.visitor}
												</div>
											)}
										</Col>
										<Col sm={6} lg={12} xl={6}>
											<Label htmlFor="registrationEndDate">
												{t(
													'forms.event.end-registration'
												)}
											</Label>
											<EventDatePicker
												control={control}
												name="registrationEndDate"
												minDate={
													watch(
														'registrationStartDate'
													) || new Date()
												}
												maxDate={
													new Date(fields?.endDate)
												}
												defaultSelected={
													fields?.registrationEndDate
												}
												isClearable={watch(
													'registrationEndDate'
												)}
												filterTime={filterPassedTime}
												onDateChange={(val: Date) =>
													onEndDateChange(
														val,
														'visitor'
													)
												}
											/>
											{endDateCET.visitor && (
												<div className="text-gray-3 fs-small mt-2">
													{endDateCET.visitor}
												</div>
											)}
										</Col>
									</FormGroup>
									<FormInlineTip tip="forms.event.date-info" />
								</>
							)}
						</div>
					</section>
					<section className="mb-3 pb-2">
						<h4 className="fs-sm d-flex align-items-center text-primary mb-3 gap-2">
							<FontAwesome
								icon="buildings"
								size="lg"
								
							/>
							{t('title.event.event-partners')}
						</h4>
						<div className="ps-sm-4 ps-lg-0 ps-xl-4 ms-sm-1 ms-lg-0 ms-xl-1">
							<FormGroup>
								<SwitchController
									control={control}
									name="hasPartner"
									boxClassName="pt-2"
									label="forms.event.enable-event-partner-registration"
									onSwitchChange={onEventPartnerChange}
								/>
							</FormGroup>
							{watch('hasPartner') && (
								<>
									<FormGroup>
										<SwitchController
											control={control}
											name="partnerNeedInvitation"
											boxClassName="py-2 mb-2"
											label="forms.event.only-with-invitation"
										/>
										<FormInlineTip tip="forms.event.enable-event-partner-registration-desc" />
									</FormGroup>
									<FormGroup>
										<Label htmlFor="partnerLimit">
											{t(
												'forms.event.total-event-partners'
											)}
											<small className="ms-1">
												({t('validation.required')})
											</small>
										</Label>
										<RadioController
											control={control}
											name="hasPartnerLimit"
											option={getLimitType()}
											radioClassName="d-flex align-items-center pt-1 gap-3"
											onRadioChange={(value) =>
												handleLimitChange(
													value,
													'partner'
												)
											}
										/>
									</FormGroup>
									{!!watch('hasPartnerLimit') && (
										<>
											<FormGroup row>
												<Col sm={6}>
													<input
														{...register(
															'partnerLimit'
														)}
														type="text"
														id="partnerLimit"
														placeholder={t(
															'placeholder.number-of-event-partners'
														)}
														className="inputbox w-100"
													/>
													{errors.partnerLimit && (
														<div className="invalid-feedback d-block">
															{
																errors
																	.partnerLimit
																	.message
															}
														</div>
													)}
												</Col>
											</FormGroup>
											<FormGroup row>
												<Col xs={12}>
													<Label htmlFor="seatPerPartnerLimit">
														{t(
															'forms.event.event-partners-max-seat'
														)}
														<small className="ms-1">
															(
															{t(
																'validation.required'
															)}
															)
														</small>
													</Label>
												</Col>
												<Col sm={6}>
													<input
														{...register(
															'seatPerPartnerLimit'
														)}
														type="text"
														id="seatPerPartnerLimit"
														className="inputbox w-100"
													/>
													{errors.seatPerPartnerLimit && (
														<div className="invalid-feedback d-block">
															{
																errors
																	.seatPerPartnerLimit
																	.message
															}
														</div>
													)}
												</Col>
											</FormGroup>
										</>
									)}
									<FormGroup row>
										<Col
											sm={6}
											lg={12}
											xl={6}
											className="mb-3 mb-sm-0 mb-lg-3 mb-xl-0">
											<Label htmlFor="startDate">
												{t(
													'forms.event.start-registration'
												)}
											</Label>
											<EventDatePicker
												control={control}
												name="partnerRegistrationStartDate"
												maxDate={
													new Date(fields?.endDate)
												}
												defaultSelected={
													fields?.partnerRegistrationStartDate
												}
												isClearable={watch(
													'partnerRegistrationStartDate'
												)}
												filterTime={filterPassedTime}
												onDateChange={(val: Date) =>
													onStartDateChange(
														val,
														'partner',
														'partnerRegistrationEndDate'
													)
												}
											/>
											{startDateCET.partner && (
												<div className="text-gray-3 fs-small mt-2">
													{startDateCET.partner}
												</div>
											)}
										</Col>
										<Col sm={6} lg={12} xl={6}>
											<Label htmlFor="registrationEndDate">
												{t(
													'forms.event.end-registration'
												)}
											</Label>
											<EventDatePicker
												control={control}
												name="partnerRegistrationEndDate"
												minDate={
													watch(
														'partnerRegistrationStartDate'
													) || new Date()
												}
												maxDate={
													new Date(fields?.endDate)
												}
												defaultSelected={
													fields?.partnerRegistrationEndDate
												}
												isClearable={watch(
													'partnerRegistrationEndDate'
												)}
												filterTime={filterPassedTime}
												onDateChange={(val: Date) =>
													onEndDateChange(
														val,
														'partner'
													)
												}
											/>
											{endDateCET.partner && (
												<div className="text-gray-3 fs-small mt-2">
													{endDateCET.partner}
												</div>
											)}
										</Col>
									</FormGroup>
									<FormInlineTip tip="forms.event.date-info" />
									<FormGroup>
										<SwitchController
											control={control}
											name="showPartnersDetail"
											label="forms.event.show-participant-details"
											boxClassName="pt-2"
										/>
									</FormGroup>
								</>
							)}
						</div>
					</section>
				</Col>
			</Row>
		</Card>
	);
});

UpdateEventRegistration.displayName = 'UpdateEventRegistration';
