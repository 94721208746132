
import { Container, Pagination } from 'reactstrap';
import {
	LoadingSpinner,
	Carousel,
	ProfileDepartmentCard
} from 'Elements';
import styles from 'partials/homepage/section/community-partner-department-section.module.scss';
import { useDepartments } from 'src/hooks/useDepartment';
import { clx } from 'src/helpers/Utils';
import { Autoplay, EffectFade } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { useResponsive } from 'src/hooks/useResponsive';
import { Title } from 'src/containers/Styles';

type Props = {
	isLoadingProfile: boolean;
	departments: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerPreviewDepartmentSection = ({
	isLoadingProfile,
	departments
}: Props) => {
	const { isDesktopAndBelow } = useResponsive();
	const { data } = useDepartments({
		pageSize: 100,
		pageNumber: 1,
		keyword: ''
	});

	return (
		<section className="position-relative mb-5 mt-6">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<>
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-4 text-center">
							{departments?.headline ?? ''}
						</Title>
						{data && data?.totalItems > 0 && (
							<Carousel
								loop
								effect="fade"
								followFinger={false}
								direction={
									isDesktopAndBelow
										? 'horizontal'
										: 'vertical'
								}
								slidesPerView={1}
								speed={800}
								autoplay={{
									delay: 3500,
									pauseOnMouseEnter: true,
									disableOnInteraction: false
								}}
								autoHeight={isDesktopAndBelow}
								pagination={{
									enabled: data.totalItems > 1,
									clickable: true,
									horizontalClass: `${styles.bullets}`,
									verticalClass: `${styles.bullets}`,
									bulletClass: `${styles.bullet} department-bullet`,
									bulletActiveClass: `${styles.active}`
								}}
								modules={[Pagination, EffectFade, Autoplay]}
								className={clx(
									styles.carousel,
									'department-container'
								)}
								enabled={data.totalItems > 1}>
								{data.items?.reverse()?.map((department) => (
									<SwiperSlide
										key={department.id}
										className={styles.slide}>
										<ProfileDepartmentCard
											key={department.id}
											department={department}
										/>
									</SwiperSlide>
								))}
							</Carousel>
						)}
					</>
				)}
			</Container>
		</section>
	);
};
