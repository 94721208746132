import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	FontAwesome,
	LoadingMask
} from 'Elements';
import { useActivityTags } from 'src/hooks/useJob';
import { useSearchParams } from 'react-router-dom';
import { clx } from 'src/helpers/Utils';
import styles from 'partials/job/job.module.scss';

interface Props extends ModalProps {
	onClickActivityTags: (tags: any) => void;
	selectedActivityTags: any;
}

export const ModalBoxActivityTags: FC<Props> = ({
	isOpen,
	onClose,
	selectedActivityTags,
	onClickActivityTags
}) => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const [selectedActivityTagsId, setSelectedActivityTagsId] =
		useState<any>(selectedActivityTags);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const [state] = useState<{
		pageSize: number;
		keyword: string;
		currentPage: string | number;
	}>({
		pageSize: 100,
		currentPage: pageParam,
		keyword: queryParam
	});

	const { data: dataActivityTags, isFetching: isFetchingActivityTags } =
		useActivityTags({
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword
		});

	const getActiveJobTag = (id: string) => {
		let res = false;
		selectedActivityTagsId?.map((item) => {
			const jobTagId = item?.jobTagId ?? item;
			if (jobTagId === id) {
				res = true;
			}
		});
		return res;
	};

	const getActivityTitle = (id: any) => {
		const newId = id?.jobTagId ?? id;
		let title = '';
		dataActivityTags?.items?.forEach((item) => {
			if (newId === item.id) {
				title = item.title;
			}
		});
		return title;
	};

	return (
		<ModalBox isOpen={isOpen} size="xl">
			<header className="d-flex flex-column gap-3 pt-2 pb-4">
				<span className="fw-500 fs-base">
					{t('forms.activity-tags')}
				</span>
				<span className="fw-400 fs-base">
					{t('text.choose-tags-describe-your-job-position-better')}
				</span>
			</header>
			<div className="d-flex flex-column gap-4">
				<div className="d-flex gap-4 w-100">
					<div
						className="bg-[#FFFFFF] d-flex flex-column gap-4 py-4 px-3 w-100"
						style={{
							boxShadow: '0px 1px 15px 0px #c2c2c2',
							borderRadius: '16px',
							height: '350px'
						}}>
						<h5 className="fw-500 fs-5 text-color-gray-5">
							{t('forms.activity-type')}
						</h5>
						<div className="d-flex align-items-center flex-wrap flex-sm-nowrap gap-3 mb-1">
							<div className="p-0 inputbox">
								<input
									type="search"
									defaultValue={queryParam ?? ''}
									className={clx(styles.inputSearch, 'w-100')}
									placeholder={t('menu.search')}
									onChange={() => {}}
								/>
								<Button
									type="button"
									color={Colors.empty}
									className={clx('p-0', styles.submitSearch)}
									onClick={() => {}}>
									<FontAwesome
										size="sm"
										icon="magnifying-glass"
									/>
								</Button>
							</div>
						</div>
						<div className="d-flex flex-wrap align-items-center gap-2">
							{!isFetchingActivityTags &&
								dataActivityTags?.items?.map((item) => {
									return (
										<div
											key={item.id}
											className={`d-flex align-items-center gap-2 cursor-pointer px-3 ${
												getActiveJobTag(item.id ?? item)
													? 'bg-primary'
													: 'bg-gray-1'
											}`}
											style={{
												height: '41px',
												borderRadius: '8px'
											}}
											onClick={() => {
												if (
													!getActiveJobTag(
														item.id ?? item
													)
												) {
													setSelectedActivityTagsId([
														...selectedActivityTagsId,
														{
															jobTagId: item.id,
															title: item.title,
															order:
																selectedActivityTagsId.length +
																1
														}
													]);
												}
											}}>
											{!getActiveJobTag(
												item.id ?? item
											) && (
												<FontAwesome
													icon="plus"
													size="1x"
													color="text-primary"
												/>
											)}
											<span
												className={`fw-400 fs-base ${
													getActiveJobTag(
														item.id ?? item
													) && 'text-white'
												}`}>
												{item.title}
											</span>
										</div>
									);
								})}
							{isFetchingActivityTags &&
								[1, 2, 3].map((item) => {
									return (
										<div key={item}>
											<LoadingMask
												className={styles.titleJobTags}
											/>
										</div>
									);
								})}
						</div>
					</div>
				</div>
				<div
					className="bg-[#FFFFFF] d-flex flex-column gap-2 py-4 px-3 w-100"
					style={{
						boxShadow: '0px 1px 15px 0px #c2c2c2',
						borderRadius: '16px',
						minHeight: '200px'
					}}>
					<h5 className="fw-500 fs-5">{t('text.your-choices')}</h5>
					<p className="fw-400 fs-small">
						{t(
							'text.you-can-drag-and-drop-resort-tags-with-your-priority'
						)}
					</p>
					<div className="d-flex flex-wrap align-items-center gap-2">
						{selectedActivityTagsId?.map((item) => {
							return (
								<div
									key={item.jobTagId ?? item}
									className={`d-flex align-items-center gap-2  px-3 bg-primary`}
									style={{
										height: '41px',
										borderRadius: '8px'
									}}>
									<FontAwesome
										icon="close"
										size="1x"
										color="text-white cursor-pointer"
										onClick={() => {
											const list: any = [];
											selectedActivityTagsId.forEach(
												(jobTag) => {
													const newJobTag =
														jobTag.id ?? jobTag;
													const id = item.id ?? item;
													if (newJobTag !== id) {
														list.push(jobTag);
													}
												}
											);
											setSelectedActivityTagsId(list);
										}}
									/>
									<span
										className={`fw-400 fs-base text-white`}>
										{getActivityTitle(item) ?? item.title}
									</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<ModalBoxFooter className="flex-row justify-content-end pt-3 gap-2">
				<Button
					color={Colors['white-red']}
					label={t('button.cancel') as string}
					onClick={onClose}
				/>
				<Button
					color={Colors.primary}
					label={t('button.submit-tags') as string}
					onClick={() => {
						onClose && onClose();
						onClickActivityTags(selectedActivityTagsId);
					}}
				/>
			</ModalBoxFooter>
		</ModalBox>
	);
};
