import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';
import { ModalBox, ModalBoxFooter, Button, FontAwesome } from 'Elements';
import support from 'data/support.json';

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

export const ModalBoxSupport: FC<Props> = memo(({ isOpen, onClose }) => {
	const { t } = useTranslation();
	const {
		support: { name, avatar, email, tel }
	} = support;

	return (
		<ModalBox
			isOpen={isOpen}
			onClose={onClose}
			title={t('title.contact-information')}>
			<div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3">
				<div className="bg-primary rounded-circle w-30">
					<img
						src={avatar}
						alt={name}
						className="rounded-circle p-2"
					/>
				</div>
				<div className="d-flex flex-column">
					<h4 className="h5 mb-0">{name}</h4>
					<hr className="bg-gray-3 my-2" />
					<div className="d-flex flex-column gap-2">
						<div className="d-flex align-items-center text-gray-3 gap-3 lh-lg">
							<FontAwesome icon="envelope" size="lg" />
							<a
								href={`mailto:${email}`}
								className="text-break fs-sm fw-400">
								{email}
							</a>
						</div>
						<div className="d-flex align-items-center text-gray-3 gap-3 lh-lg">
							<FontAwesome icon="phone" size="lg" />
							<a
								className="text-break fs-sm fw-400"
								href={`tel:${tel}`}>
								{tel}
							</a>
						</div>
					</div>
				</div>
			</div>
			<ModalBoxFooter className="justify-content-center mt-4">
				<Button
					color={Colors['gray-4']}
					label={t('button.close') as string}
					onClick={onClose}
				/>
			</ModalBoxFooter>
		</ModalBox>
	);
});

ModalBoxSupport.displayName = 'ModalBoxSupport';
