import { memo, FC, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { clx, config } from 'Utils';
import { RadioOption } from 'Types';
import { useCommunityPartnerMe } from 'Hooks';
import { Card, CopyButton, LoadingSpinner, RadioGroup } from 'Elements';
import styles from 'partials/marketing/integration-jobbiplace.module.scss';
import integrationStyles from 'partials/marketing/integration.module.scss';

interface JobbiplacePartner {
	agentProfileUrl: string;
}

export const JobbiplacePartnerScript: FC = memo(() => {
	const { data, isLoading } = useCommunityPartnerMe();
	const { t } = useTranslation();
	const scriptUrl = config.widgetScriptUrl;
	const [state, setState] = useState<{
		theme: string;
		scriptData: JobbiplacePartner | null;
		isLoading: boolean;
	}>({
		theme: 'dark',
		scriptData: null,
		isLoading: false
	});
	const agentId = data?.id;

	const htmlContent = `<script src="${scriptUrl}/jobbiplace-partner/jobbiplace-partner-widget.min.js" id="jobbiplace-partner-widget" data-agent-id="${agentId}" data-widget-url="${config.widgetApiUrl}" data-script-url="${scriptUrl}" data-theme="${state.theme}"></script>`;

	const themes: RadioOption[] = [
		{
			label: t('label.integration.dark'),
			value: 'dark'
		},
		{
			label: t('label.integration.light'),
			value: 'light'
		}
	];

	const handleRadioChange = (value: string | number) => {
		setState((prev) => ({
			...prev,
			theme: value.toString()
		}));
	};

	const getPreview = useCallback(async () => {
		const api_url = `${config.widgetApiUrl}/widget/agent/${agentId}`;
		setState((prev) => ({
			...prev,
			isLoading: true
		}));
		await axios
			.get(api_url)
			.then((res) => {
				setState((prev) => ({
					...prev,
					isLoading: false,
					scriptData: res.data
				}));
			})
			.finally(() => {
				setState((prev) => ({
					...prev,
					isLoading: false
				}));
			});
	}, [agentId]);

	useEffect(() => {
		if (agentId) {
			getPreview();
		}
	}, [getPreview, agentId]);

	return (
		<div className="mb-4">
			<Card>
				<Row>
					<Col md={4} className="mb-3 mb-md-0">
						<div className={integrationStyles.filter_section}>
							<h6 className="mb-3">
								{t('title.integration.theme')}
							</h6>
							<RadioGroup
								name="theme"
								boxClassName="d-flex align-items-center gap-4 mt-3"
								options={themes}
								defaultSelected={state.theme}
								onChange={handleRadioChange}
							/>
						</div>
					</Col>
					<Col md={8}>
						<div className="d-flex flex-column">
							<h6 className="mb-3">
								{t('title.integration.your-script')}
							</h6>
							<pre className={integrationStyles.pre}>
								{isLoading ? <LoadingSpinner /> : htmlContent}
							</pre>
							<div className="d-flex align-items-center justify-content-end">
								{!isLoading && (
									<CopyButton textToCopy={htmlContent} />
								)}
							</div>
						</div>
					</Col>
				</Row>
			</Card>
			<div>
				<h6 className="mb-3 fs-6 text-gray-3">{t('title.preview')}</h6>
				{state.isLoading && (
					<div className={clx(styles.loading, 'm-3')}>
						<LoadingSpinner />
					</div>
				)}
				{!state.isLoading && state.scriptData?.agentProfileUrl && (
					<a
						href={state.scriptData.agentProfileUrl}
						target="_blank"
						rel="noreferrer"
						className={clx(
							styles.imageWrapper,
							state.theme === 'light' && styles.imageWrapper_white
						)}>
						<img
							src={`/assets/img/integration/jobbiplace-communityPartner-${state.theme}.svg`}
							className={styles.image}
							alt=""
						/>
					</a>
				)}
			</div>
		</div>
	);
});

JobbiplacePartnerScript.displayName = 'JobbiplacePartnerScript';
