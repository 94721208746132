import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Colors } from 'Types';
import { useDelayUnmount, useModal } from 'Hooks';
import {
	Button,
	FontAwesome,
	ModalBoxInviteGuest,
	ModalBoxInviteGuestsContactGroups
} from 'Elements';
import {
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import styles from 'partials/page/event.module.scss';

interface Props {
	isPartner?: boolean;
}

export const InviteGuestBar: FC<Props> = memo(({ isPartner = true }) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const [openModalInviteContacts, toggleModalInviteContacts] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const shouldRenderModalInviteContacts = useDelayUnmount(
		openModalInviteContacts,
		350
	);

	return (
		<div className={clx(styles.guestBar, 'mb-3')}>
			<div className="d-flex align-items-center flex-grow-1">
				<FontAwesome
					icon="envelope-open-text"
					size="2x"
					className="text-secondary me-3"
				/>
				<p className="lh-base mb-0">
					{t('title.invite-not-jobbiplace-parties')}
				</p>
			</div>
			<UncontrolledDropdown
				className={clx('d-inline-flex flex-shrink-0')}>
				<DropdownToggle tag="span" role="button">
					<Button
						type="button"
						size="sm"
						className="flex-shrink-0 gap-2"
						color={Colors.primary}>
						<span>{t('button.invite-contacts') as string}</span>
						<FontAwesome
							icon="chevron-down"
							size="sm"
							
							swapOpacity
						/>
					</Button>
				</DropdownToggle>
				<DropdownMenu className={styles.menu} end>
					<DropdownItem
						className="d-flex align-items-center gap-2 text-primary"
						onClick={toggleModal}>
						{t('button.invite-by-email')}
					</DropdownItem>
					<DropdownItem
						className="d-flex align-items-center gap-2 text-primary"
						onClick={toggleModalInviteContacts}>
						{t('button.invite-contact-group')}
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
			{shouldRenderModal && (
				<ModalBoxInviteGuest
					isOpen={openModal}
					onClose={toggleModal}
					isPartner={isPartner}
				/>
			)}
			{shouldRenderModalInviteContacts && (
				<ModalBoxInviteGuestsContactGroups
					isOpen={openModalInviteContacts}
					onClose={toggleModalInviteContacts}
					isPartner={isPartner}
				/>
			)}
		</div>
	);
});

InviteGuestBar.displayName = 'InviteGuestBar';
