import { Col, Container, Row } from 'reactstrap';
import { useTheme } from 'Hooks';
import { LoadingSpinner, FontAwesome } from 'Elements';
import { clx } from 'src/helpers/Utils';
import { useTranslation } from 'react-i18next';

type Props = {
	isLoadingProfile: boolean;
	statistics: {
		branch: string;
		employees: string;
		enabled: boolean;
		establishedDate: string;
		location: string;
	};
};

export const BusinessPartnerPreviewStatisticSection = ({
	isLoadingProfile,
	statistics
}: Props) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center my-4 py-4">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && statistics && (
					<Row className="d-flex justify-content-center mx-lg-5 pt-3">
						{statistics?.establishedDate && (
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<div
									className={clx(
										'd-flex flex-column align-items-center justify-content-center text-center gap-2'
									)}>
									<FontAwesome
										icon="calendar"
										size="3x"
										color={
											theme.businessPartner.primaryColor
										}
									/>
									<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">
										{t('label.established-date')}
									</h5>
									<div className="text-break lh-base w-100 fs-base">
										{statistics?.establishedDate}
									</div>
								</div>
							</Col>
						)}
						{statistics?.employees && (
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<div
									className={clx(
										'd-flex flex-column align-items-center justify-content-center text-center gap-2'
									)}>
									<FontAwesome
										icon="users"
										size="3x"
										color={
											theme.businessPartner.primaryColor
										}
									/>
									<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">
										{t('label.employees')}
									</h5>
									<div className="text-break lh-base w-100 fs-base">
										{statistics?.employees}
									</div>
								</div>
							</Col>
						)}
						{statistics?.location && (
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<div
									className={clx(
										'd-flex flex-column align-items-center justify-content-center text-center gap-2'
									)}>
									<FontAwesome
										icon="earth-america"
										size="3x"
										color={
											theme.businessPartner.primaryColor
										}
									/>
									<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">
										{t('label.locations')}
									</h5>
									<div className="text-break lh-base w-100 fs-base">
										{statistics?.location}
									</div>
								</div>
							</Col>
						)}
						{statistics?.branch && (
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<div
									className={clx(
										'd-flex flex-column align-items-center justify-content-center text-center gap-2'
									)}>
									<FontAwesome
										icon="buildings"
										size="3x"
										color={
											theme.businessPartner.primaryColor
										}
									/>
									<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">
										{t('label.branch')}
									</h5>
									<div className="text-break lh-base w-100 fs-base">
										{statistics?.branch}
									</div>
								</div>
							</Col>
						)}
					</Row>
				)}
			</Container>
			{!isLoadingProfile && !statistics?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
};
