import { FC, Suspense } from 'react';
import { Col, Row } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import { CommunityPartnerDecorationBox, LoadingContent } from 'Elements';
import {
	CommunityPartnerMe,
	CommunityPartnerProfile
} from 'src/types/CommunityPartner';

type Props = {
	communityPartnerProfile: CommunityPartnerProfile;
	communityPartnerMe: CommunityPartnerMe;
	refetchCommunityPartnerMe?: () => void;
	refetchCommunityPartnerProfile?: () => void;
};

export const UpdateCommunityPartner: FC<Props> = ({
	communityPartnerProfile,
	communityPartnerMe,
	refetchCommunityPartnerMe,
	refetchCommunityPartnerProfile
}) => {
	return (
		<Row>
			<Col xs={12} className="mb-4">
				<CommunityPartnerDecorationBox 
					communityPartnerProfile={communityPartnerProfile}
					communityPartnerMe={communityPartnerMe}
					refetchCommunityPartnerMe={
						refetchCommunityPartnerMe
					}
					refetchCommunityPartnerProfile={refetchCommunityPartnerProfile}
				
				/>
			</Col>
			<Col xs={12}>
				<Suspense fallback={<LoadingContent />}>
					<Outlet />
				</Suspense>
			</Col>
		</Row>
	);
};
