import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { ColorValues, Tab } from 'Types';
import { Card, DecorationTab, FontAwesome } from 'Elements';

export const IntegrationAppDecorationBox: FC = memo(() => {
	// const { t } = useTranslation();
	const { pathname } = useLocation();
	// const {
	// 	data: company,
	// 	isFetching,
	// 	updateCompanyLinkedin
	// } = useUpdateBusinessPartner();

	// const redirectUrl = buildUrl(config.redirectUrl as string, 'my-apps');

	// const schema = object().shape({
	// 	clientIdentify: string().required(t('validation.client-id.required')),
	// 	clientSecret: string().required(t('validation.client-secret.required'))
	// });

	// const submitHandler: SubmitHandler<LinkedinConfig> = async (data) => {
	// 	const api_data = {
	// 		linkedinConfig: data
	// 	};
	// 	await updateCompanyLinkedin(api_data);
	// };

	// const {
	// 	reset,
	// 	register,
	// 	handleSubmit,
	// 	formState: { errors, isDirty, isSubmitting }
	// } = useForm<LinkedinConfig>({
	// 	resolver: yupResolver(schema)
	// });

	// usePrompt(t('forms.leave-screen-massage'), isDirty);

	// useEffect(() => {
	// 	reset({
	// 		...company?.linkedinConfig
	// 	});
	// }, [reset, company?.linkedinConfig]);
	const defaultTab =
		pathname === '/integration' || pathname === '/integration/linkedin';

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.linkedin',
			path: 'linkedin',
			isActive: defaultTab
		}
	];

	return (
		<Card tab>
			<div className="d-flex align-items-center gap-4 mb-4">
				<FontAwesome
					icon="grid-2"
					size="6x"
					className="flex-shrink-0"
					color={ColorValues.primary}
				/>
				<Row className="flex-grow-1">
					<Col md={7} lg={6} xxl={9}>
						<h3 className="h5 lh-sm fw-400">
							Social App Integration Hub
						</h3>
						<p className="text-gray-3 lh-base mb-0 fw-300 fs-sm">
							Connect Your Social Networks to Improve Your App
							Experience: Easily link your social accounts to
							access extra features and personalized interactions.
							Share, connect, and engage conveniently, all within
							one app.
						</p>
					</Col>
				</Row>
			</div>
			<DecorationTab tabs={tabs} />
		</Card>
	);
});

IntegrationAppDecorationBox.displayName = 'IntegrationAppDecorationBox';
