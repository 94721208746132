import { memo } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTransaction } from 'Hooks';
import {
	SubscriptionDetails,
	TransactionCustomer,
	TransactionDetails,
	TransactionStatus,
	TransactionType
} from 'Types';
import {
	Card,
	CompanyTransactionInvoice,
	CompanyTransactionInvoicePayment,
	CompanyTransactionPaymentProvider,
	CompanyTransactionPayment,
	LoadingContent,
	DetailsSubscription
} from 'Elements';

const TransactionCard = styled(Card)`
	height: 100%;
	min-height: 250px;
`;

export const CompanyViewTransaction = memo(() => {
	const { t } = useTranslation();
	const { transaction_id } = useParams();
	const { data: transaction, isLoading } = useTransaction(
		transaction_id as string
	);
	const type = transaction?.type;
	const status = transaction?.status;

	return (
		<>
			<Row className="mb-4">
				<Col md={6}>
					<TransactionCard>
						{!isLoading && type === TransactionType.invoice && (
							<CompanyTransactionInvoice
								transaction={transaction as TransactionDetails}
							/>
						)}
						{!isLoading && type === TransactionType.payment && (
							<CompanyTransactionPayment
								transaction={transaction as TransactionDetails}
							/>
						)}
						{isLoading && <LoadingContent />}
					</TransactionCard>
				</Col>
				{status !== TransactionStatus.UnPaid && (
					<Col md={6} className="mt-4 mt-md-0">
						<TransactionCard>
							{!isLoading && type === TransactionType.invoice && (
								<CompanyTransactionInvoicePayment
									transaction={
										transaction as TransactionDetails
									}
								/>
							)}
							{!isLoading && type === TransactionType.payment && (
								<CompanyTransactionPaymentProvider
									transaction={
										transaction as TransactionDetails
									}
								/>
							)}
							{isLoading && <LoadingContent />}
						</TransactionCard>
					</Col>
				)}
			</Row>
			<Card>
				<h6 className="text-gray-3 mb-3 fs-6 fw-400">
					{t('title.product')}
				</h6>
				<DetailsSubscription
					subscription={
						transaction?.companySubscription as SubscriptionDetails
					}
					subscriptionId={
						transaction?.companySubscription.id as string
					}
					communityPartnerInfo={transaction?.agent as TransactionCustomer}
					isLoading={isLoading}
					canAutoRenew={false}
				/>
			</Card>
		</>
	);
});

CompanyViewTransaction.displayName = 'CompanyViewTransaction';
