import { memo, FC, ReactNode } from 'react';
import { Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { createGlobalStyle } from 'styled-components';
import { clx } from 'Utils';
import { Carousel } from 'Elements';
import { useResponsive } from 'Hooks';
import { DataList, Employee, Member } from 'Types';
import 'swiper/css/pagination';
import styles from 'partials/homepage/section/staff-section.module.scss';

const GlobalStyle = createGlobalStyle<{ profileType: 'communityPartner' | 'businessPartner' }>`
	${({ theme, profileType }) =>
		theme[profileType].primaryColor &&
		`.staff-bullet {
			background-color: ${theme[profileType].primaryColor} !important;
		}
	`}
`;

interface Props {
	children: ReactNode;
	className?: string;
	profileType: 'communityPartner' | 'businessPartner';
	list: DataList<Employee | Member>;
}

export const ProfileStaffSection: FC<Props> = memo(
	({ children, list, profileType, className }) => {
		const { isTabletAndBelow } = useResponsive();

		const breakpoints: Record<number, SwiperOptions> = {
			0: {
				slidesPerView: 1,
				centeredSlides: true
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 28
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 40
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 48
			}
		};

		return (
			<div className={clx('position-relative', className)}>
				<GlobalStyle profileType={profileType} />
				<Carousel
					enabled={isTabletAndBelow || list?.items?.length >= 5}
					breakpoints={breakpoints}
					navigationGroup={
						isTabletAndBelow || list?.items?.length >= 5
					}
					modules={[Pagination]}
					pagination={{
						enabled: list?.items?.length >= 5,
						clickable: true,
						horizontalClass: `${styles.bullets}`,
						bulletClass: `${styles.bullet} staff-bullet`,
						bulletActiveClass: `${styles.active}`
					}}
					className={styles.carousel}>
					{children}
				</Carousel>
			</div>
		);
	}
);

ProfileStaffSection.displayName = 'ProfileStaffSection';
