import { memo, FC } from 'react';
import { NavItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { clx } from 'Utils';
import { MenuItem } from 'Types';
import { FontAwesome, SidebarSubItem } from 'Elements';
import styles from 'partials/sidebar/sidebar-item.module.scss';

interface Props {
	menu: MenuItem;
	activeMenu: MenuItem;
	IsPremium?: boolean;
	index: string | number;
	onClick: (menu: MenuItem) => void;
}

type LinkTagProps = {
	isLink: boolean;
	end?: boolean;
	to?: string;
	onClick?: () => void;
	isOpen?: boolean;
	children: React.ReactNode;
};

const LinkTag = memo(({ isLink, ...props }: LinkTagProps) => {
	return isLink ? (
		<NavLink
			end={props.end}
			to={props.to!}
			className={({ isActive }) =>
				isActive ? clx(styles.isActive, styles.item) : styles.item
			}
			onClick={props.onClick}>
			{props.children}
		</NavLink>
	) : (
		<div
			className={clx(props.isOpen && styles.isActive, styles.item)}
			onClick={props.onClick}>
			{props.children}
		</div>
	);
});

export const SidebarMainItem: FC<Props> = memo(
	({ menu, activeMenu, index, IsPremium, onClick }) => {
		const { t } = useTranslation();
		const { pathname } = useLocation();
		const label = menu.label;

		const visibleSubMenuItems = menu?.subs?.filter((submenu) => {
			return IsPremium
				? submenu.isPrime !== false
				: submenu.isPrime === false || submenu.isPrime === undefined;
		});

		const isActive =
			activeMenu?.id === index ||
			!!menu.subs?.find((menu) => menu.to === pathname);

		return (
			<NavItem className={clx(styles.itemBox, isActive && styles.active)}>
				<LinkTag
					isOpen={isActive}
					isLink={!menu?.subs}
					end={!menu?.subs}
					to={menu.to?.toString()}
					onClick={() => onClick(menu)}>
					<FontAwesome
						icon={menu.icon}
						size="xl"
						swapOpacity={
							menu?.iconSwapOpacity && !!isActive
								? !isActive
								: menu?.iconSwapOpacity || !!isActive
						}
						opacity={isActive ? 1 : 0.7}
					/>
					<span className="flex-grow-1">{t(label)}</span>
					{menu?.subs && (
						<FontAwesome
							icon="chevron-down"
							className="ms-2"
							size='sm'
							swapOpacity
							rotation={isActive ? 180 : undefined}
							opacity={isActive ? 1 : 0.7}
						/>
					)}
				</LinkTag>
				{isActive && menu?.subs && menu.subs?.length > 0 && (
					<ul className={styles.subList}>
						{visibleSubMenuItems?.map((sub, index) => {
							return (
								<SidebarSubItem
									key={`${menu.id}_${index}`}
									menu={sub}
								/>
							);
						})}
					</ul>
				)}
			</NavItem>
		);
	}
);

LinkTag.displayName = 'LinkTag';
SidebarMainItem.displayName = 'SidebarMainItem';
