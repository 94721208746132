import { FC, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { clx, currencyFormatter, formatLocalAddress } from 'Utils';
import { Button, Card, Badge, FontAwesome, ModalBoxPlanInf } from 'Elements';
import { Colors, SubscriptionCommunityPartner, SubscriptionPlan } from 'Types';
import styles from 'partials/card/subscription-agent-card.module.scss';
import { useModal } from 'src/hooks/useModal';

interface Props {
	communityPartner: SubscriptionCommunityPartner;
	selected: boolean;
	planDataLoading: boolean;
	onSelect?: (communityPartner: SubscriptionCommunityPartner) => void;
}

export const SubscriptionCommunityPartnerCard: FC<Props> = ({
	communityPartner,
	selected,
	planDataLoading,
	onSelect
}) => {
	const { t } = useTranslation();
	const navigationUrl = '';
	const [planSelected, setPlanSelected] = useState({});
	const [openModal, toggleModal] = useModal();
	// `${config.publicUrl}/agent/${agent.agentId}/${agent.slugUrl}`;

	return (
		<Card
			// tag="articlsettinge"
			className={`${clx(selected && styles.selected)} ${
				!onSelect && styles.notShadow
			}`}>
			<Row>
				<div className="d-flex flex-wrap gap-3">
					<div className={styles.boxLogo}>
						<img
							src={
								communityPartner?.logo?.url ||
								'/assets/img/default-image.jpg'
							}
							alt={communityPartner?.businessName}
							className={styles.logoWrapper}
						/>
					</div>
					<div className="w-60">
						<div className="d-flex flex-wrap align-items-center gap-3 mb-2">
							<div className="flex-grow-1 d-flex flex-column justify-content-center">
								<h4 className="fs-base lh-base mb-0">
									<a
										target="_blank"
										rel="noopener noreferrer"
										className="d-inline-flex"
										href={navigationUrl}>
										{communityPartner?.businessName}
									</a>
								</h4>
								{communityPartner?.slogan && (
									<span className="text-gray-3 fs-small lh-md">
										{communityPartner?.slogan}
									</span>
								)}
							</div>
						</div>
						<div className="mb-3">
							{communityPartner?.commissionGroupInfo
								?.isPremium ? (
								<Badge color={Colors.premium}>
									{
										communityPartner?.commissionGroupInfo
											?.premiumLable
									}
								</Badge>
							) : (
								<Badge color={Colors.association}>
									{t('badge.standard-partner')}
								</Badge>
							)}
						</div>
						<div className="d-flex align-items-center mb-3">
							<FontAwesome
								icon="location"
								size="1x"
								className="text-primary me-2"
							/>
							<span className="text-gray-3 fw-400 fs-sm lh-base">
								{`${t('card.region')} ${
									communityPartner?.regionCode
								}XXX`}
							</span>
						</div>
						<div>
							{communityPartner?.address && (
								<div className="text-gray-3 fw-400 fs-sm d-flex flex-wrap lh-base mb-3">
									<FontAwesome
										icon="location-dot"
										size="1x"
										className="text-primary me-2"
									/>
									{formatLocalAddress(
										communityPartner?.address?.street,
										communityPartner?.address?.houseNumber,
										communityPartner?.address?.postalCode,
										communityPartner?.address?.city,
										communityPartner?.address?.state
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="mb-3 mt-3">
					{communityPartner?.description && (
						<div
							className="lh-md"
							dangerouslySetInnerHTML={{
								__html: communityPartner?.description
							}}
						/>
					)}
				</div>
				{!planDataLoading &&
					communityPartner?.planData &&
					communityPartner?.planData?.length > 0 && (
						<div className="d-flex flex-wrap align-items-center gap-3 mb-3">
							<span>{t('text.available-plans')}:</span>
							{communityPartner?.planData?.map((plan) => {
								return (
									<div
										key={plan.id}
										onClick={() => {
											setPlanSelected({
												...plan,
												communityPartnerId:
													communityPartner?.id
											});
											toggleModal();
										}}
										className={`d-flex align-items-end justify-content-between gap-1 cursor-pointer ${
											plan.type === 0
												? styles.boxPlans
												: styles.boxPlansPremium
										}`}>
										<div className="d-flex flex-column gap-1">
											<span className="text-wrap">
												{plan?.name}
											</span>
											<span>
												{currencyFormatter(
													plan?.price ?? 0
												)}
											</span>
										</div>
										<div>
											<FontAwesome
												icon={'info-circle'}
												color="#4C5C84"
												size="1x"
											/>
										</div>
									</div>
								);
							})}
						</div>
					)}

				{planDataLoading && (
					<div className="d-flex align-items-center gap-3 mb-3">
						<span>{t('text.available-plans')}:</span>
						{[1, 2, 3].map((_item, index) => {
							return (
								<div
									key={index}
									className={`d-flex align-items-end justify-content-between gap-1 ${styles.boxPlansSkeleton}`}></div>
							);
						})}
					</div>
				)}
			</Row>
			<div>
				{!communityPartner?.isAutoApprove && (
					<Badge color={Colors['warning']}>
						<FontAwesome icon={'info-circle'} size="1x" />
						<span className="ms-1">{t('badge.no-approval')}</span>
					</Badge>
				)}
			</div>
			{onSelect && (
				<Row className="mt-3">
					<Col
						sm={6}
						lg={12}
						xl={8}
						className="mb-3 mb-sm-0 mb-lg-3 mb-xl-0">
						<a
							target="_blank"
							rel="noreferrer noopener"
							href={navigationUrl}
							className={clx(
								styles.more,
								'w-100 h-100 w-lg-auto'
							)}>
							{t('button.more-about-agent')}
						</a>
					</Col>
					<Col sm={6} lg={12} xl={4}>
						<Button
							disabled={planDataLoading}
							color={Colors.secondary}
							className="w-100 h-100"
							onClick={() => onSelect(communityPartner)}>
							{t('button.choose-community-partner')}
						</Button>
					</Col>
				</Row>
			)}
			<ModalBoxPlanInf
				isOpen={openModal}
				onClose={toggleModal}
				plan={planSelected as SubscriptionPlan}
			/>
		</Card>
	);
};
