import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Card } from 'Elements';
import { EventDetails } from 'Types';
import styles from 'partials/event/event-organizer-box.module.scss';

interface Props {
	event: EventDetails;
}

export const EventOrganizerBox: FC<Props> = memo(({ event }) => {
	const { t } = useTranslation();
	const defaultLogo = '/assets/img/business-partner-default.jpg';

	return (
		<Card className={clx(styles.box, 'd-flex align-items-center')}>
			<div className={styles.logoWrapper}>
				<img
					src={event?.agentLogo || defaultLogo}
					alt={event?.agentName}
					className={styles.logo}
				/>
			</div>
			<div className="flex-grow-1">
				<h4 className="fw-300 fs-sm">{t('title.event.hosted-by')}</h4>
				<h3 className="mb-0 fs-6">{event?.agentName}</h3>
			</div>
		</Card>
	);
});

EventOrganizerBox.displayName = 'EventOrganizerBox';
