import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	FontAwesome,
	LoadingMask
} from 'Elements';
import { useJobTagCategories, useJobTags } from 'src/hooks/useJob';
import { useSearchParams } from 'react-router-dom';
import { clx } from 'src/helpers/Utils';
import styles from 'partials/job/job.module.scss';
import CardDraggable from '../job/create/step/CardDraggable';

interface Props extends ModalProps {
	onClickJobTags: (tags: any) => void;
	selectedJobTags: any;
}

export const ModalBoxJobTags: FC<Props> = ({
	isOpen,
	onClose,
	selectedJobTags,
	onClickJobTags
}) => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const [selectedJobTagCategoryId, setSelectedJobTagCategoryId] =
		useState('');
	const [selectedJobTagsId, setSelectedJobTagsId] =
		useState<any>(selectedJobTags);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const [state] = useState<{
		pageSize: number;
		keyword: string;
		currentPage: string | number;
	}>({
		pageSize: 100,
		currentPage: pageParam,
		keyword: queryParam
	});

	const {
		data: dataJobTagCategories,
		isFetching: isFetchingJobTagCategories
	} = useJobTagCategories({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const { data: dataJobsTags, isFetching: isFetchingJobTags } = useJobTags(
		{
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword,
			jobTagCategoryId: selectedJobTagCategoryId,
			noAssignedCategory: false
		},
		{ enabled: selectedJobTagCategoryId !== '' ? true : false }
	);

	useEffect(() => {
		if (dataJobTagCategories) {
			setSelectedJobTagCategoryId(
				dataJobTagCategories?.items[0].id as string
			);
		}
	}, [dataJobTagCategories]);

	const getActiveJobTag = (id: string) => {
		let res = false;
		selectedJobTagsId?.map((item) => {
			if (item?.jobTagId === id) {
				res = true;
			}
		});
		return res;
	};

	const getTitle = (id: any) => {
		let title = '';
		dataJobsTags?.items?.forEach((item) => {
			if (id === item.id) {
				title = item.title;
			}
		});
		return title;
	};

	const dragTagsRes = (arr) => {
		setSelectedJobTagsId(arr);
	};

	return (
		<ModalBox isOpen={isOpen} size="xl">
			<header className="d-flex flex-column gap-3 pt-2 pb-4">
				<span className="fw-500 fs-base">{t('forms.job-tags')}</span>
				<span className="fw-400 fs-base">
					{t('text.choose-tags-describe-your-job-position-better')}
				</span>
			</header>
			<div className="d-flex flex-column gap-4">
				<div className="d-flex gap-4 w-100">
					<div
						className="bg-[#FFFFFF] d-flex flex-column gap-3 py-4 px-3"
						style={{
							boxShadow: '0px 1px 15px 0px #c2c2c2',
							borderRadius: '16px',
							width: '360px'
						}}>
						{isFetchingJobTagCategories &&
							[1, 2, 3].map((item) => {
								return (
									<div key={item}>
										<LoadingMask
											className={styles.titleCategories}
										/>
									</div>
								);
							})}
						{!isFetchingJobTagCategories &&
							dataJobTagCategories?.items?.map((item) => {
								return (
									<div
										key={item.id}
										onClick={() => {
											setSelectedJobTagCategoryId(
												item.id
											);
										}}
										className={`d-flex align-items-center gap-2 cursor-pointer ${
											selectedJobTagCategoryId ===
												item.id && 'text-primary'
										}`}>
										<div
											className={`d-flex align-items-center gap-2 pb-2 ${
												selectedJobTagCategoryId ===
													item.id &&
												'border-bottom border-3 border-primary'
											}`}>
											<FontAwesome
												size="1x"
												icon={item.icon}
											/>
											<span className="fw-500 fs-base">
												{item.title}
											</span>
										</div>
										<FontAwesome
											size="1x"
											icon="info-circle"
											className="pb-2"
										/>
									</div>
								);
							})}
					</div>
					<div
						className="bg-[#FFFFFF] d-flex flex-column gap-4 py-4 px-3 w-100"
						style={{
							boxShadow: '0px 1px 15px 0px #c2c2c2',
							borderRadius: '16px',
							height: '350px'
						}}>
						<h5 className="fw-500 fs-5 text-color-gray-5">
							{t('forms.job-type')}
						</h5>
						<div className="d-flex align-items-center flex-wrap flex-sm-nowrap gap-3 mb-1">
							<div className="p-0 inputbox">
								<input
									type="search"
									defaultValue={queryParam ?? ''}
									className={clx(styles.inputSearch, 'w-100')}
									placeholder={t('menu.search')}
									onChange={() => {}}
								/>
								<Button
									type="button"
									color={Colors.empty}
									className={clx('p-0', styles.submitSearch)}
									onClick={() => {}}>
									<FontAwesome
										size="sm"
										icon="magnifying-glass"
									/>
								</Button>
							</div>
						</div>
						<div className="d-flex flex-wrap align-items-center gap-2">
							{!isFetchingJobTags &&
								dataJobsTags?.items?.map((item) => {
									return (
										<div
											key={item.id}
											className={`d-flex align-items-center gap-2 cursor-pointer px-3 ${
												getActiveJobTag(item.id)
													? 'bg-primary'
													: 'bg-gray-1'
											}`}
											style={{
												height: '41px',
												borderRadius: '8px'
											}}
											onClick={() => {
												if (!getActiveJobTag(item.id)) {
													setSelectedJobTagsId([
														...selectedJobTagsId,
														{
															jobTagId: item.id,
															title: item.title,
															order:
																selectedJobTagsId.length +
																1
														}
													]);
												}
											}}>
											{!getActiveJobTag(item.id) && (
												<FontAwesome
													icon="plus"
													size="1x"
													color="text-primary"
												/>
											)}
											<span
												className={`fw-400 fs-base ${
													getActiveJobTag(item.id) &&
													'text-white'
												}`}>
												{item.title}
											</span>
										</div>
									);
								})}
							{isFetchingJobTags &&
								[1, 2, 3].map((item) => {
									return (
										<div key={item}>
											<LoadingMask
												className={styles.titleJobTags}
											/>
										</div>
									);
								})}
						</div>
					</div>
				</div>
				<div
					className="bg-[#FFFFFF] d-flex flex-column gap-2 py-4 px-3 w-100"
					style={{
						boxShadow: '0px 1px 15px 0px #c2c2c2',
						borderRadius: '16px',
						minHeight:"200px"
					}}>
					<h5 className="fw-500 fs-5">{t('text.your-choices')}</h5>
					<p className="fw-400 fs-small">
						{t(
							'text.you-can-drag-and-drop-resort-tags-with-your-priority'
						)}
					</p>
					<div className="d-flex flex-row gap-2">
						<CardDraggable
							setItems={(arr) => {
								dragTagsRes(arr);
							}}>
							{selectedJobTagsId?.map((item) => {
								return (
									<div
										key={item.jobTagId}
										className={`d-flex align-items-center gap-2 px-3`}>
										<FontAwesome
											icon="close"
											size="1x"
											color="text-white cursor-pointer"
											onClick={() => {
												const list: any = [];
												let flag = 0;
												selectedJobTagsId.forEach(
													(jobTag) => {
														if (
															jobTag.jobTagId !==
															item.jobTagId
														) {
															flag += 1;
															jobTag.order = flag;
															list.push(jobTag);
														}
													}
												);
												setSelectedJobTagsId(list);
											}}
										/>
										<span
											className={`fw-400 fs-base text-white`}>
											{getTitle(item.jobTagId)}
										</span>
									</div>
								);
							})}
						</CardDraggable>
					</div>
				</div>
			</div>
			<ModalBoxFooter className="flex-row justify-content-end pt-3 gap-2">
				<Button
					color={Colors['white-red']}
					label={t('button.cancel') as string}
					onClick={onClose}
				/>
				<Button
					color={Colors.primary}
					label={t('button.submit-tags') as string}
					onClick={() => {
						onClose && onClose();
						selectedJobTagsId?.forEach((element) => {
							dataJobsTags?.items?.forEach((item) => {
								if (element.jobTagId === item.id) {
									element.title = item.title;
								}
							});
						});

						onClickJobTags(selectedJobTagsId);
					}}
				/>
			</ModalBoxFooter>
		</ModalBox>
	);
};
