import { Col, Row } from 'reactstrap';
import { clx, formatLocalAddress } from 'Utils';
import { useModal } from 'Hooks';
import { CommunityPartnerMe, Logo, Media } from 'Types';
import {
	LoadingSpinner,
	ModalBoxChangeImage,
	EditButton,
	FontAwesome
} from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';

type Props = {
	isLoadingProfile: boolean;
	communityPartnerMe: CommunityPartnerMe;
	initialState: {
		headerTitle: string;
		description: string;
		slogan: string;
	};
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean | Logo
	) => void;
	header: {
		description: number;
		headerImage: Logo;
		headerTitle: string;
		logo: Logo;
		slogan: number;
		themeColor: number;
	};
};

export const CommunityPartnerHeroHeader = ({
	isLoadingProfile,
	communityPartnerMe,
	onChangeValue,
	initialState,
	isFetchingProfile,
	header
}: Props) => {
	const sloganLimit = 90;
	const descriptionLimit = 480;

	const defaultLogo = '/assets/img/business-partner-default.jpg';
	const [openModal, toggleModal] = useModal();

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			onChangeValue('header', 'logo', {
				url: image_data?.url,
				thumbnail: image_data?.thumbnailUrl
			} as Logo);
		}
	};

	const handleResetLogo = async () => {
		onChangeValue('header', 'logo', {
			url: '',
			thumbnail: ''
		} as Logo);
	};

	return (
		<div style={{ marginTop: '-5rem' }}>
			{isLoadingProfile && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoadingProfile && header && (
				<div className="ps-lg-4">
					<Row className="g-0 mb-4 ps-xxl-4">
						<Col lg={3} xxl={2}>
							<div
								className={styles.headerLogo}
								role="button"
								onClick={toggleModal}>
								<img
									src={
										(header?.logo?.thumbnail as string) ||
										defaultLogo
									}
									alt={header?.headerTitle}
									className={styles.logo}
								/>
								<div className="edit-overlay">
									<EditButton className="edit-overlay-icon" />
								</div>
								{isFetchingProfile && (
									<span className={styles.overlay}>
										<LoadingSpinner
											size="xl"
											color="#fff"
										/>
									</span>
								)}
							</div>
						</Col>
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<div className="d-flex flex-column gap-2 justify-content-end h-100 pt-lg-5">
								<h3
									className={clx(
										styles.title,
										'fw-700 mb-0 text-break mt-5 pt-xl-3'
									)}>
									<input
										type="text"
										onChange={(e) =>
											onChangeValue(
												'header',
												'headerTitle',
												e.target.value
											)
										}
										value={header?.headerTitle ?? ''}
										id="headerTitle"
										placeholder={initialState?.headerTitle}
										className={`${styles.inputEdit} w-90`}
									/>
								</h3>
								{communityPartnerMe?.slogan && (
									<div className="d-flex align-items-center gap-2">
										<div className="fs-base text-gray-3 lh-md text-break">
											{communityPartnerMe?.slogan}
										</div>
										<div
											onClick={() => {
												window.open(
													'/my-community-partner/general'
												);
											}}>
											<FontAwesome
												icon="pen-to-square"
												size="lg"
												className="text-primary cursor-pointer"
											/>
										</div>
									</div>
								)}

								{communityPartnerMe?.address && (
									<div className="d-flex align-items-center gap-2 text-gray-3 fs-sm lh-base position-relative w-100">
										<div>
											<FontAwesome
												icon="location-dot"
												size="1x"
												className="me-1"
											/>
											{formatLocalAddress(
												communityPartnerMe?.address
													?.street,
												communityPartnerMe?.address
													?.houseNumber,
												communityPartnerMe?.address
													?.postalCode,
												communityPartnerMe?.address
													?.city,
												communityPartnerMe?.address
													?.state
											)}
										</div>
										<div
											onClick={() => {
												window.open(
													'/my-community-partner/address'
												);
											}}>
											<FontAwesome
												icon="pen-to-square"
												size="lg"
												className="text-primary cursor-pointer"
											/>
										</div>
									</div>
								)}
							</div>
						</Col>
					</Row>
					{communityPartnerMe?.description && (
						<Row className="justify-content-end g-0 ps-xxl-4">
							<Col lg={9} xxl={10} className="ps-xxl-4">
								<div className="d-flex align-items-center gap-2">
									<div
										className="fs-base text-gray-3 lh-md text-break"
										style={{whiteSpace:"pre-wrap"}}
										dangerouslySetInnerHTML={{
											__html: communityPartnerMe?.description
										}}></div>
									<div
										onClick={() => {
											window.open(
												'/my-community-partner/general'
											);
										}}>
										<FontAwesome
											icon="pen-to-square"
											size="lg"
											className="text-primary cursor-pointer"
										/>
									</div>
								</div>
							</Col>
						</Row>
					)}
				</div>
			)}
			<ModalBoxChangeImage
				name="logo"
				isOpen={openModal}
				title="title.change-logo"
				image={(header?.logo?.url as string) || ''}
				defaultImage="/assets/img/business-partner-default.jpg"
				onClose={toggleModal}
				onRemove={handleResetLogo}
				onImageChange={handleUploadImage}
			/>
		</div>
	);
};
