import { memo, FC } from 'react';
import { Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, EditButton, FontAwesome } from 'Elements';
import { clx, formatLocalAddress, formatTimeToCET } from 'Utils';
import {
	AgentEvent,
	EventType,
	RegistrationStatus,
	EventStatus,
	Colors
} from 'Types';
import styles from 'partials/homepage/card/agent-event-card.module.scss';

interface Props {
	card: AgentEvent;
}

export const ProfileEventCard: FC<Props> = memo(({ card }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const defaultImage = '/assets/img/default-event.webp';

	const getCoverBadgeLabel = () => {
		let badgeLabel = '';
		switch (card.eventType) {
			case EventType.Online:
				badgeLabel = 'badge.event.online';
				break;
			case EventType.InPerson:
				badgeLabel = 'badge.event.in-person';
				break;
			case EventType.Hybrid:
				badgeLabel = 'badge.event.hybrid';
				break;

			default:
				badgeLabel = 'badge.event.online';
				break;
		}
		return t(badgeLabel);
	};

	const getBadgeData = () => {
		let badgeLabel = 'badge.canceled';
		let badgeColor = Colors.muted;

		if (card.partnerRegistrationStatus === RegistrationStatus.Finished) {
			badgeLabel = 'badge.event.registration-finished';
		}

		if (card.isFinished) {
			badgeLabel = 'badge.finished';
		}
		if (card.eventStatus === EventStatus.Canceled) {
			badgeLabel = 'badge.canceled';
			badgeColor = Colors.red;
		}

		return {
			color: badgeColor,
			label: t(badgeLabel)
		};
	};

	const handleNavigate = () => {
		navigate(`/events/${card.id}`, {
			state: {
				prevPath: 'homepage'
			}
		});
	};

	const isEventDisabled =
		card.isFinished || card.eventStatus === EventStatus.Canceled;

	return (
		<Col md={6} lg={4} className="mb-4">
			<article
				className={clx(styles.card, isEventDisabled && styles.deActive)}
				onClick={handleNavigate}>
				<div className={styles.imgBox}>
					<img
						src={card.featuredPhoto || defaultImage}
						alt={card.title}
						className={styles.img}
					/>
					<Badge
						color={Colors['white-gray']}
						className={styles.badge}>
						{getCoverBadgeLabel()}
					</Badge>
					{(card.isFinished ||
						card.eventStatus === EventStatus.Canceled) && (
						<h6 className="h6 mb-0 fw-500 position-absolute start-50 text-white top-50 translate-middle">
							{getBadgeData().label}
						</h6>
					)}
				</div>
				<div className="flex-grow-1 d-flex flex-column gap-2">
					<h5 className="h4 lh-sm mb-0 text-gray-5">{card.title}</h5>
					{card.shortDescription && (
						<p className={styles.description}>
							{card.shortDescription}
						</p>
					)}
					<hr className="mb-1 mt-2 bg-gray-1 opacity-100" />
					<div className="d-flex flex-column gap-2">
						<time className="d-flex align-items-center gap-2 text-gray-3">
							<FontAwesome
								icon="calendar"
								size="lg"
								
								className="flex-shrink-0"
							/>
							<span className="flex-grow-1">
								{formatTimeToCET(card.startDate)}
							</span>
						</time>
						{card.eventType !== EventType.Online && (
							<div className="d-flex align-items-center gap-2 text-gray-3">
								<FontAwesome
									icon="location-dot"
									size="lg"
									
									className="flex-shrink-0"
								/>
								<span className="flex-grow-1">
									{formatLocalAddress(
										card.address?.street,
										card.address?.houseNo,
										card.address?.postalCode,
										card.address?.city,
										card.address?.state
									)}
								</span>
							</div>
						)}
					</div>
				</div>
				<div className="edit-overlay">
					<EditButton className="edit-overlay-icon" />
				</div>
			</article>
		</Col>
	);
});

ProfileEventCard.displayName = 'ProfileEventCard';
