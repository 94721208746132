import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Editor, FontAwesome } from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';

interface Props {
	icon: any;
	title: string;
	desc: string;
	fill: string;
	className?: string;
	onUpdate: (val: string) => void;
}

export const ProfileStatisticCard: FC<Props> = ({
	icon,
	title,
	desc,
	fill,
	className,
	onUpdate
}) => {
	const { t } = useTranslation();
	return (
		<div
			className={clx(
				'd-flex flex-column align-items-center justify-content-center text-center gap-2',
				className
			)}>
			<FontAwesome icon={icon} size="3x" color={fill} />
			<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">{t(title)}</h5>
			<div className="text-break lh-base w-100 fs-base">
				<input
					type="text"
					onChange={(e) => {
						onUpdate(e.target.value);
					}}
					value={desc}
					placeholder={`[${t(title)}]`}
					className={`${styles.inputEdit} w-90 text-center`}
				/>
			</div>
		</div>
	);
};
