import { useEffect, useState } from 'react';
import {
	useBusinessPartnerMe,
	useBusinessPartnerProfile,
	usePrompt,
	useTheme
} from 'Hooks';
import {
	PreviewProfilePageLayout,
	LoadingContent,
	BusinessPartnerPreviewHeroImage,
	BusinessPartnerPreviewHeroHeader,
	BusinessPartnerPreviewStatisticSection,
	BusinessPartnerPreviewEmployeeSection,
	BusinessPartnerPreviewJobHeadlineSection,
	BusinessPartnerPreviewJobSection,
	BusinessPartnerPreviewInternshipSection,
	BusinessPartnerPreviewBenefitSection,
	BusinessPartnerPreviewGallerySection,
	BusinessPartnerPreviewContactSection
} from 'Elements';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import styles from 'partials/homepage/section/profile-hero-section.module.scss';
import { useTranslation } from 'react-i18next';
import {
	BusinessPartnerHeader,
	BusinessPartnerMe,
	BusinessPartnerProfile
} from 'Types';

const MyBusinessPartnerPreviewProfilePage = () => {
	const { theme, setTheme } = useTheme();
	const [dirtyForm, setDirtyForm] = useState({});
	const [data, setData] = useState<BusinessPartnerProfile>(
		{} as BusinessPartnerProfile
	);
	const { t } = useTranslation();

	usePrompt(
		t('forms.leave-screen-massage'),
		Object.values(dirtyForm).some((item) => item)
	);

	const { data: businessPartnerMe, isLoading } = useBusinessPartnerMe();
	const {
		data: businessPartnerProfile,
		isLoading: isLoadingProfile,
		isFetching: isFetchingProfile
	} = useBusinessPartnerProfile(
		businessPartnerMe?.businessPartnerProfileId as string,
		{
			enabled: businessPartnerMe?.businessPartnerProfileId ? true : false
		}
	);

	useEffect(() => {
		if (!isFetchingProfile) {
			setData(businessPartnerProfile as BusinessPartnerProfile);
			setTheme({
				...theme,
				businessPartner: {
					...theme.businessPartner,
					primaryColor:
						businessPartnerProfile?.header?.themeColor ||
						theme.businessPartner.defaultPrimaryColor
				}
			});
			setDirtyForm({});
		}
	}, [isFetchingProfile]);

	const Decoration = styled.div<{
		profileType: 'businessPartner';
	}>`
		${({ theme, profileType }) =>
			theme && `background-color: ${theme[profileType].primaryColor}`};
	`;

	return (
		<>
			{isLoading && <LoadingContent />}
			{!isLoading && (
				<PreviewProfilePageLayout type="businessPartner">
					<Decoration
						className={styles.decoration}
						profileType="businessPartner"
					/>
					<Container>
						<div className={`${styles.content} mb-5`}>
							<BusinessPartnerPreviewHeroImage
								header={data?.header as BusinessPartnerHeader}
								isLoadingProfile={isLoadingProfile}
								isFetchingProfile={isFetchingProfile}
							/>
							<BusinessPartnerPreviewHeroHeader
								header={data?.header as BusinessPartnerHeader}
								businessPartnerMe={
									businessPartnerMe as BusinessPartnerMe
								}
								isLoadingProfile={isLoadingProfile}
								isFetchingProfile={isFetchingProfile}
							/>
						</div>
					</Container>
					{data?.statistics?.enabled && (
						<BusinessPartnerPreviewStatisticSection
							isLoadingProfile={isLoadingProfile}
							statistics={data?.statistics}
						/>
					)}
					{data?.employee?.enabled && (
						<BusinessPartnerPreviewEmployeeSection
							isLoadingProfile={isLoadingProfile}
							employee={data?.employee}
						/>
					)}
					{data?.jobAds?.enabled && (
						<BusinessPartnerPreviewJobHeadlineSection
							isLoadingProfile={isLoadingProfile}
							jobAds={data?.jobAds}
						/>
					)}
					{data?.jobs?.enabled && (
						<BusinessPartnerPreviewJobSection
							isLoadingProfile={isLoadingProfile}
							jobs={data?.jobs}
						/>
					)}
					{data?.internships?.enabled && (
						<BusinessPartnerPreviewInternshipSection
							isLoadingProfile={isLoadingProfile}
							internships={data?.internships}
						/>
					)}
					{data?.benefits?.enabled && (
						<BusinessPartnerPreviewBenefitSection
							isLoadingProfile={isLoadingProfile}
							benefits={data?.benefits}
						/>
					)}
					{data?.gallery?.showInHomePage && (
						<BusinessPartnerPreviewGallerySection
							isLoadingProfile={isLoadingProfile}
							gallery={data?.gallery}
							galleryItems={data?.galleryItems}
						/>
					)}
					<BusinessPartnerPreviewContactSection
						isLoadingProfile={isLoadingProfile}
						contactPerson={data?.contactPerson}
						socialNetworks={data?.socialNetworks}
					/>
				</PreviewProfilePageLayout>
			)}
		</>
	);
};

export default MyBusinessPartnerPreviewProfilePage;
