import { FC, memo } from 'react';
import { clx } from 'Utils';
import { SwiperButton, FontAwesome } from 'Elements';
import styles from 'partials/homepage/swiper-button.module.scss';

interface Props {
	disablePrev: boolean;
	disableNext: boolean;
	className?: string;
	darkBackground?: boolean;
}

export const SwiperButtonGroup: FC<Props> = memo(
	({ disablePrev, disableNext, className, darkBackground = false }) => {
		return (
			<div
				className={clx(
					className,
					styles.navigation,
					!darkBackground && styles.light,
					darkBackground && styles.dark
				)}>
				<SwiperButton
					type="prev"
					disabled={disablePrev}
					className={clx(
						styles.arrow,
						styles.prev,
						disablePrev && styles.disable
					)}>
					<FontAwesome
						icon="chevron-left"
						size="2x"
						swapOpacity
						
					/>
				</SwiperButton>
				<SwiperButton
					type="next"
					disabled={disableNext}
					className={clx(
						styles.arrow,
						styles.next,
						disableNext && styles.disable
					)}>
					<FontAwesome
						icon="chevron-right"
						size="2x"
						swapOpacity
						
					/>
				</SwiperButton>
			</div>
		);
	}
);

SwiperButtonGroup.displayName = 'SwiperButtonGroup';
