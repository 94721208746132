import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, ModalProps } from 'Types';

import { Button, ModalBox, ModalBoxFooter, FontAwesome } from 'Elements';

import { useNavigate } from 'react-router-dom';

interface Props extends ModalProps {
	type: 'publish' | 'unPublish';
	profileId?: string;
	status?: number;
	userType: 'communityPartner' | 'businessPartner';
	refetch?: () => void;
	refetchPartnerMe?: () => void;
}
export const ModalBoxPublishJob: FC<Props> = ({
	isOpen,
	onClose,
	userType
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<ModalBox size="lg" isOpen={isOpen} onClose={onClose}>
			{userType === 'businessPartner' ? (
				<>
					<header className="d-flex flex-column align-items-center text-center">
						<>
							<FontAwesome
								icon={'lock'}
								size="2x"
								className="mb-4"
							/>
							<h4 className="h6 lh-base">
								{t(`text.you-can-not-publish-job-session`)}
							</h4>
							<p className="text-gray-3 lh-md">
								{t(`text.not-publish-job-sub-text`)}
							</p>
						</>
					</header>
					<ModalBoxFooter className="justify-content-between pt-3">
						<Button color={Colors['white-gray']} onClick={onClose}>
							{t('button.cancel')}
						</Button>
						<Button
							color={Colors['white-primary']}
							onClick={() => navigate('/subscriptions')}>
							{t('button.start-registration-process')}
						</Button>
					</ModalBoxFooter>
				</>
			) : (
				<>
					<header className="d-flex flex-column align-items-center text-center">
						<>
							<FontAwesome
								icon={'lock'}
								size="2x"
								className="mb-4"
							/>
							<h4 className="h6 lh-base">
								{t(`text.you-can-not-publish-job-session`)}
							</h4>
							<p className="text-gray-3 lh-md">
								{t(`text.not-publish-job-cp-text`)}
							</p>
						</>
					</header>
					<ModalBoxFooter className="justify-content-between pt-3">
						<Button color={Colors['white-gray']} onClick={onClose}>
							{t('button.cancel')}
						</Button>
					</ModalBoxFooter>
				</>
			)}
		</ModalBox>
	);
};
