import {
	AgentPartnerApprovalStatus,
	AgentSetting,
	CustomerDto,
	PaymentMethod,
	Voucher,
	VoucherDiscountType,
	Address,
	Logo
} from 'Types';

export const enum SubscriptionStatus {
	pendingApproval = 0,
	active = 1,
	rejected = 2,
	pendingPayment = 3,
	expired = 4
}
export const enum SubscriptionPaymentStatus {
	Pending = 0,
	Paid = 1,
	Failed = 2,
	InProcess = 3,
	Canceled = 4
}

export interface SubscriptionPlan {
	id: string;
	communityPartnerId?: string;
	code: string;
	name: string;
	shortDescription: string;
	description: string;
	durationDays: number;
	remainDuration: number;
	price: number;
	planBasePrice: number;
	total: number;
	taxAmount: number;
	isActive: boolean;
	agentPercentPay: number;
	taxPercent: number;
	subscriptionType: number;
	type: number;
	featureData?: SubscriptionPlansFeature;
}

export interface SubscriptionPrimaryPlan {
	id: string;
	code: string;
	name: string;
	shortDescription: string;
	description: string;
	durationDays: number;
	remainDuration: number;
	price: number;
	planBasePrice: number;
	total: number;
	taxAmount: number;
	isActive: boolean;
	agentPercentPay: number;
	taxPercent: number;
	subscriptionType: number;
	type: number;
}

export interface SubscriptionSate {
	selectedAgent: SubscriptionAgentSetting | null;
	selectedCommunityPartner: SubscriptionCommunityPartner | null;
	selectedPlan: SubscriptionPlan | null;
	selectedPrimaryPlan: SubscriptionPrimaryPlan | null;
	selectedVoucher: Voucher | null;
}

export interface SubscriptionForm {
	step1: SubscriptionFormAgent;
	step2: SubscriptionPlan | null;
}

export interface SubscriptionFormAgent {
	agent: SubscriptionAgentSetting | null;
}

export interface SubscriptionAgent {
	id: string;
	agentMainList: SubscriptionAgentSetting[];
	agentAlternativeList: SubscriptionAgentSetting[];
	agentId: string;
	regionId: string;
}

export interface SubscriptionPlan {
	active: boolean;
	code: string;
	creditPercentage: number;
	description: string;
	durationDays: number;
	id: string;
	isFree: boolean;
	name: string;
	price: number;
	shortDescription: string;
	subscriptionPlanFeatureId: string;
	taxPercentage: number;
	type: number;
}

export interface SubscriptionCommunityPartner {
	additionalPlanPrice: number;
	address: Address;
	businessName: string;
	communityPartnerTypeCode: string;
	firstName: string;
	slogan: string;
	description: string;
	id: string;
	isAutoApprove: boolean;
	lastName: string;
	commissionGroupInfo: any;
	logo: Logo;
	mainEmailAddress: string;
	partnerClass: number;
	regionCode: string;
	smallBusiness: boolean;
	tenantId: string;
	planData?: SubscriptionPlan[];
}

export interface SubscriptionAgentSetting {
	id: string;
	name: string;
	slugUrl: string;
	agentSetting: AgentSetting;
	companyApprovalStatus?: boolean;
}

export interface Subscription {
	id: string;
	paymentData?: any;
	agentApprovedStatus: AgentPartnerApprovalStatus;
	agentApprovalStatus: {
		agentApprovedStatus: string;
		updatedAt: string;
		description: string;
		createdBy: string;
		createdByIdentify: string;
	};
	subscriptionPlanInfo: any;
	companyId: string;
	name: string;
	currentSubscriptionPaymentId: string;
	agentId: string;
	purchaseDate: string;
	renewalDate: string;
	totalAmount: number;
	totalPrice: number;
	endDate: string;
	company: CustomerDto;
	code: string;
	title: string;
	remainDuration: number;
	remainDays: string;
	autoRenewal: boolean;
	agent: CustomerDto;
	communityPartnerInfo: CustomerDto;
	status: number;
	payment: SubscriptionPayment;
	paymentIds: { paymentId: string }[];
	duration: number;
	shortDescription: string;
	description: string;
	paymentStatus: string;
	transaction: SubscriptionTransaction;
	plan: SubscriptionPlan;
	price: number;
	paymentMethod: PaymentMethod;
	subscriptionType: string;
	parentId: string;
	payUrl: string;
	priceForXDays: number;
	isSubCompanySubscription: boolean;
	subCompanySubscription: Subscription[];
	subTotal: number;
	discountAmount: number;
	taxAmount: number;
	taxPercent: number;
	discount: number;
	priceAfterDiscount: number;
	discountType: VoucherDiscountType;
	applyVoucher: Voucher;
}
export interface SubscriptionDetails
	extends Omit<Subscription, 'subCompanySubscription'> {
	subCompanySubscription: Subscription;
	communityPartnerInfo: any;
	subscriptionPlanInfo: any;
}

export interface SubscriptionCalculate {
	planBasePrice: number;
	additionalPrice: number;
	subscriptionPrice: number;
	subscriptionDuration: number;
	discountAmount: number;
	discountPercent: number;
	subTotal: number;
	taxPercent: number;
	taxAmount: number;
	totalAmount: number;
}

export interface SubscriptionPlansFeature {
	id: string;
	subscriptionPlanId: string;
	jobSetting: {
		jobEnabled: boolean;
		publishJobEnabled: boolean;
		maxPublishJobs: number;
		limitJobVisibility: boolean;
		jobsGoogleIndex: boolean;
	};
	internshipSetting: {
		internshipEnabled: boolean;
		publishInternshipEnabled: boolean;
		maxPublishInternships: 0;
		internshipManagerEnabled: boolean;
	};
	eventSetting: {
		eventsEnabled: boolean;
		publishEventsEnabled: boolean;
		maxPublishEvents: number;
	};
	userAccountSetting: {
		maxUserAccounts: number;
	};
}

export interface SubscriptionPayment {
	businessPartnerId: string;
	createdAt: string;
	id: string;
	lastUpdatedAt: string;
	price: any;
	provider: any;
	paymentMethodInfo: any;
	paymentProvider: string;
	paymentReference: string;
	providerStatus: string;
	status: number;
	subscriptionId: string;
	tenantId: string;
}

export interface SubscriptionTransaction {
	id: string;
	amount: number;
	taxAmount: number;
	totalAmount: number;
	taxPercent: number;
	priceAfterDiscount: number;
	applyVoucher: Voucher;
	discountAmount: number;
	subTotal: number;
	discount: number;
	discountType: VoucherDiscountType;
}
