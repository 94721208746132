import { FC, Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'reactstrap';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateJob, useSelector } from 'Hooks';
import { clx, formatLocaleDateTime } from 'Utils';
import { Button, StepNavigation, FontAwesome } from 'Elements';
import { Colors, ColorValues, JobForm, JobFormData } from 'Types';
import styles from 'partials/step/step-container.module.scss';
import coverStyles from 'partials/control/cover-controller.module.scss';

interface Props {
	fields: JobForm;
	onNext: () => void;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
	setJobId: Dispatch<SetStateAction<string>>;
}

export const CreateJobPreviewSubmit: FC<Props> = ({
	fields,
	onPrev,
	onNext,
	goToStep,
	setJobId
}) => {
	const { t } = useTranslation();
	const { assetConfig } = useSelector((state) => state.marketing);
	const { submitRequest } = useCreateJob();
	const {
		handleSubmit,
		formState: { isSubmitting }
	} = useForm();

	const onSubmit = async () => {
		if (fields.step3.jobTags && fields.step3.jobActivities) {
			const api_data: JobFormData = {
				...fields.step1,
				...fields.step2,
				...fields.step4,
				contactPerson: null,
				kind: 0,
				featuredPhoto: fields?.step2?.featuredPhoto?.url
					? fields?.step2?.featuredPhoto
					: null,
				featuredVideo: fields?.step2?.featuredVideo?.url
					? fields?.step2?.featuredVideo
					: null,
				partnerName: assetConfig?.nameElement as string,
				primaryJobLocation: {
					regionCode: assetConfig?.regionCode as number,
					title: assetConfig?.address?.countryName as string,
					locationPoint: {
						longitude: assetConfig?.address?.location
							?.longitude as number,
						latitude: assetConfig?.address?.location
							?.latitude as number
					}
				},

				startDate: fields.step1.startDate
					? format(
							new Date(fields.step1.startDate as string),
							'yyyy-MM-dd'
					  )
					: '',
				expiredDate: fields.step1.expiredDate
					? format(
							new Date(fields.step1.expiredDate as string),
							'yyyy-MM-dd'
					  )
					: '',
				// jobTypeId: fields.step3.jobTypeId.value.toString(),
				jobTags: fields.step3.jobTags.map((job: any) => {
					return { jobTagId: job?.jobTagId, order: job.order };
				}),
				jobActivities: fields.step3.jobActivities.map(
					(activity: any) => {
						return activity?.jobTagId;
					}
				)
			};

			const jobId = await submitRequest(api_data, onNext);
			setJobId(jobId);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
			<Row>
				<Col sm={5} md={12} xl={5} xxl={4} className="mb-4">
					<img
						src={
							fields.step2.featuredPhoto.url ||
							'/assets/img/default-image.jpg'
						}
						alt={fields.step1.title}
						className={clx(coverStyles.cover, 'h-auto')}
					/>
				</Col>
				<Col sm={7} md={12} xl={7} xxl={8} className="mb-4">
					<div className="d-flex flex-column gap-2 mb-2">
						<h4 className="fw-500 fs-base mb-0 lh-base">
							{fields.step1.title}
						</h4>
						<span className="text-gray-3 fs-small d-flex align-items-center">
							{t('forms.code')}:&nbsp;
							{fields.step1.code || '---'}
						</span>
					</div>
					<p className="text-gray-3 mb-0 lh-base">
						{fields.step1.shortDescription || '---'}
					</p>
				</Col>
				<Col sm={6} md={12} xl={6} className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.start-date')}</h4>
					<time className="text-gray-3">
						{fields.step1?.hasStartDate
							? formatLocaleDateTime(
									fields.step1?.startDate as string,
									false
							  )
							: '---'}
					</time>
				</Col>
				<Col sm={6} md={12} xl={6} className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.expire-date')}</h4>
					<time className="text-gray-3">
						{fields.step1.hasExpireDate
							? formatLocaleDateTime(
									fields.step1?.expiredDate as string,
									false
							  )
							: '---'}
					</time>
				</Col>
			</Row>
			<div className="my-4 py-4 border-bottom position-relative">
				<Button
					color={Colors['white-gray']}
					className={styles.edit}
					onClick={() => goToStep('step1')}>
					<FontAwesome icon="pen" />
				</Button>
			</div>
			<section className="mb-3 pb-2">
				<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
					{t('wizard.step-job-3')}
				</h4>
				<Row>
					<Col sm={6} md={12} xl={6} xxl={4} className="mb-4">
						<h4 className="fw-500 fs-sm">{t('forms.job-tags')}</h4>
						{fields.step3.jobTags?.map((activity) => {
							return (
								<div
									className="d-flex align-items-center"
									key={activity?.jobTagId}>
									<p className="mb-2 d-flex">
										<FontAwesome
											icon="check"
											color={ColorValues.green}
											size="sm"
											className="mt-1 me-2 flex-shrink-0"
										/>
										<span className="text-gray-3">
											{activity?.title}
										</span>
									</p>
								</div>
							);
						})}
					</Col>
				</Row>
				<Row>
					<Col sm={6} md={12} xl={6} xxl={4} className="mb-4">
						<h4 className="fw-500 fs-sm">
							{t('forms.activity-tags')}
						</h4>
						{fields.step3.jobActivities?.map((activity) => {
							return (
								<div
									className="d-flex align-items-center"
									key={activity?.jobTagId}>
									<p className="mb-2 d-flex">
										<FontAwesome
											icon="check"
											color={ColorValues.green}
											size="sm"
											className="mt-1 me-2 flex-shrink-0"
										/>
										<span className="text-gray-3">
											{activity?.title}
										</span>
									</p>
								</div>
							);
						})}
					</Col>
				</Row>
				<div className="my-4 py-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step3')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
			</section>
			<section className="mb-3 pb-2">
				<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
					{t('wizard.step-job-4')}
				</h4>
				<div className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.desc')}</h4>
					<div
						className="text-gray-3"
						dangerouslySetInnerHTML={{
							__html: fields.step4.description || '---'
						}}
					/>
				</div>
				<div className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.benefits')}</h4>
					<div
						className="text-gray-3"
						dangerouslySetInnerHTML={{
							__html: fields.step4.benefitsText || '---'
						}}
					/>
				</div>
				<div className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.requirements')}</h4>
					<div
						className="text-gray-3"
						dangerouslySetInnerHTML={{
							__html: fields.step4.requirementsText || '---'
						}}
					/>
				</div>
				<div className="mb-4">
					<h4 className="fw-500 fs-sm">
						{t('forms.responsibilities')}
					</h4>
					<div
						className="text-gray-3"
						dangerouslySetInnerHTML={{
							__html: fields.step4.responsibilitiesText || '---'
						}}
					/>
				</div>
				<div className="my-4 py-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step4')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
			</section>
			<StepNavigation
				type="submit"
				label="button.job.create-job"
				savingLabel=""
				onPrev={onPrev}
				isSubmitting={isSubmitting}
			/>
		</form>
	);
};
