import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	DataList,
	DataQueryParams,
	ContactPeople,
	ContactPeopleFormData
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useContactPeopleTable = <T = DataList<ContactPeople>>(
	{
		contactGroupId = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<ContactPeople>>
): UseQueryResult<DataList<ContactPeople>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.contactPeople,
			{ contactGroupId, pageSize, pageNumber, keyword }
		],
		queryFn: () =>
			fetch.listContactPeople(
				contactGroupId,
				pageSize,
				pageNumber,
				keyword
			),
		...config(options)
	});
};

export const useContactPeopleDetails = <T = ContactPeople>(
	contactPeopleId: string,
	options?: UseQueryOptions<T, any, ContactPeople>
): UseQueryResult<ContactPeople, unknown> => {
	return useQuery({
		queryKey: [queryKey.contactPeople, { id: contactPeopleId }],
		queryFn: () => fetch.contactPeopleDetails(contactPeopleId),
		...config(options)
	});
};

export const useContactPeople = (
	contactPeopleId?: string,
) => {
	const query = useContactPeopleDetails(contactPeopleId as string, {
		enabled: !!contactPeopleId
	});
	const contactPeople = query.data;

	const createContactPeople: (
		fields: ContactPeopleFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.post(api.createContactPeople, fields).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					toastify('toastify.contact-people.create', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		});
	};

	const updateContactPeople: (
		fields: ContactPeopleFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			...contactPeople,
			...fields
		};
		await axios
			.put(api.getContactPeopleDetails(contactPeopleId as string), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.contact-people.updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			});
	};

	const removeContactPeople: (
		contactPeopleId: string,
		onFinal: () => void
	) => Promise<void> = async (contactPeopleId, onFinal) => {
		await axios
			.delete(api.removeContactPeople(contactPeopleId))
			.then((res) => {
				if (res.status === 200) {
					onFinal?.();
					startTransition(() => {
						toastify('toastify.contact-people.removed', {
							type: 'success'
						});
					});
				}
			});
	};

	return {
		...query,
		removeContactPeople,
		updateContactPeople,
		createContactPeople
	};
};
