import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from 'Elements';
import styles from 'partials/homepage/section/staff-section.module.scss';
import { Text, Title } from 'Styles';
import { clx } from 'src/helpers/Utils';

type Props = {
	isLoadingProfile: boolean;
	partners: {
		enabled: boolean;
		headline: string;
		description: string;
	};
};

export const CommunityPartnerPreviewPartnerSection = ({
	isLoadingProfile,
	partners
}: Props) => {
	const { t } = useTranslation();
	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<div
						className={clx(
							styles.title,
							'd-flex flex-column justify-content-center mx-auto'
						)}>
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-0 text-center">
							{partners?.headline}
						</Title>
						{partners?.description && (
							<Text
								as="div"
								textColor="text-gray-5"
								className="mt-3 lh-md text-center">
								{partners?.description}
							</Text>
						)}
					</div>
				)}
			</Container>
		</section>
	);
};
