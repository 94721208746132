import { FC, memo } from 'react';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { clx } from 'Utils';
import { names } from 'Constants';
import { Colors, Department } from 'Types';
import { Badge, Button, Card, FontAwesome, ShimmerImage } from 'Elements';
import styles from 'partials/card/department-card.module.scss';

interface Props {
	card: Department;
}

export const DepartmentCard: FC<Props> = memo(({ card }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { departments } = names;
	const navigateUrl = `${departments.path}/${card.id}`;
	const defaultImage = '/assets/img/business-partner-default.jpg';
	const title = card.title;
	const pathState = { prevPath: departments.path };

	const handleNavigate = () => {
		void navigate(navigateUrl, { state: pathState });
	};

	return (
		<Col sm={6} xxl={4} className="mb-4">
			<Card size="sm" className="d-flex flex-column gap-3">
				<Link
					to={navigateUrl}
					state={pathState}
					className={styles.shimmerBox}>
					<ShimmerImage
						src={card?.image?.url}
						width={500}
						height={500}
						defaultImage={defaultImage}
						alt={title}
						className={styles.shimmer}
					/>
				</Link>
				<div className="flex-grow-1 d-flex flex-column gap-2">
					<h4 className="fs-6 lh-base fw-400 mb-0 text-truncate">
						<Link state={pathState} to={navigateUrl}>
							{title}
						</Link>
					</h4>
					{card.description && (
						<p
							className={clx(
								'text-gray-3 mb-0 lh-base',
								styles.description
							)}>
							{card.description}
						</p>
					)}
					<div className="d-flex align-items-center justify-content-between mt-auto">
						<Badge
							color={
								card.isActive ? Colors.green : Colors['gray-3']
							}>
							{t(
								card.isActive
									? 'badge.published'
									: 'badge.unPublished'
							)}
						</Badge>
						<Button
							size="sm"
							className="gap-2"
							color={Colors['white-primary']}
							onClick={handleNavigate}>
							<FontAwesome icon="pen" />
							{t('button.edit')}
						</Button>
					</div>
				</div>
			</Card>
		</Col>
	);
});

DepartmentCard.displayName = 'DepartmentCard';
