import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AgentProfile, BusinessPartnerMe, UserProfile } from 'Types';
import { api, getAuthHeader, config, logoutRedirect } from 'Utils';

export const UserApi = createApi({
	reducerPath: 'userApi',
	baseQuery: fetchBaseQuery({
		baseUrl: config.baseUrl,
		prepareHeaders: async (headers) => {
			const authHeader = await getAuthHeader();
			if (authHeader) {
				headers.set(
					'Authorization',
					authHeader?.['Authorization'] as string
				);
				headers.set(
					'Ocp-Apim-Subscription-Key',
					authHeader?.['Ocp-Apim-Subscription-Key'] as string
				);
			} else {
				await logoutRedirect();
			}
			return headers;
		}
	}),
	endpoints: (builder) => ({
		getB2bUserProfile: builder.query<UserProfile, void>({
			query: () => api.getB2bUserProfile,
			keepUnusedDataFor: 60 * 60 * 24 * 30 // 1 month
		}),
		getCommunityPartnerMe: builder.query<AgentProfile, void>({
			query: () => api.getCommunityPartnerMe
		}),
		getBusinessPartnerMe: builder.query<BusinessPartnerMe, void>({
			query: () => api.getBusinessPartnerMe
		})
	})
});
