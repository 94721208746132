import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wizard, Steps, Step } from 'react-albus-react18';
import { names } from 'Constants';
import { clx } from 'Utils';
import {
	IStep,
	JobForm,
	JobFormDescription,
	JobFormFeatured,
	JobFormGeneral,
	JobFormInfo
} from 'Types';
import {
	CreateJobDescription,
	CreateJobFeatured,
	CreateJobGeneral,
	CreateJobInfo,
	CreateJobPreviewSubmit,
	FontAwesome,
	FormSuccess,
	StepContainer
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

export const CreateJob = memo(() => {
	const { t } = useTranslation();
	const { jobs } = names;
	const [jobId, setJobId] = useState('');

	const steps: IStep[] = [
		{
			id: 'step0',
			title: t('wizard.step-job-0')
		},
		{
			id: 'step1',
			title: t('wizard.step-job-1')
		},
		{
			id: 'step2',
			title: t('wizard.step-job-2')
		},
		{
			id: 'step3',
			title: t('wizard.step-job-3')
		},
		{
			id: 'step4',
			title: t('wizard.step-job-4')
		},
		{
			id: 'step5',
			title: t('wizard.step-preview')
		}
	];

	const defaultFields: JobForm = {
		step1: {
			code: '',
			title: '',
			shortDescription: '',
			expiredDate: '',
			startDate: '',
			isActive: true,
			hasStartDate: false,
			hasExpireDate: false
		},
		step2: {
			featuredPhoto: null as any,
			featuredVideo: null as any
		},
		step3: {
			jobTags: null,
			jobActivities: null
		},
		step4: {
			description: '',
			benefitsText: '',
			requirementsText: '',
			responsibilitiesText: '',
			showDescription: true,
			showRequirementsText: true,
			showResponsibilitiesText: true,
			showBenefitsText: true
		}
	};
	const [fields, setFields] = useState(defaultFields);

	const handleNext = <T,>(
		goToNext: () => void,
		data: T,
		step_number: number
	) => {
		setFields((prev) => ({
			...prev,
			[`step${step_number.toString()}`]: data
		}));
		goToNext();
	};

	return (
		<Wizard
			render={({ step, ...props }) => (
				<div
					className={clx(
						styles.wizard,
						'd-flex flex-column flex-md-row'
					)}>
					<StepContainer
						steps={steps}
						currentIndex={props.steps.indexOf(step)}
					/>
					<Steps>
						<Step
							id="step0"
							render={({ next }) => (
								<div style={{ margin: '74px auto' }}>
									<p
										className="fs-sm fw-500"
										style={{ marginBottom: '24px' }}>
										Choose job type
									</p>
									<p className="fs-small fw-400">
										Choose type of the job that you want to
										create:
									</p>
									<div
										onClick={() => {
											handleNext<JobFormGeneral>(
												next,
												{} as JobFormGeneral,
												1
											);
										}}
										className="d-flex align-items-center gap-4 mt-3 mb-3"
										style={{
											cursor: 'pointer',
											padding: '15px 16px 15px 16px',
											gap: '24px',
											borderRadius: '8px',
											opacity: '0px',
											boxShadow:
												'0px 4px 10px 0px #3E552333'
										}}>
										<FontAwesome
											icon="briefcase"
											size="2xl"
											className="text-primary"
										/>
										<div>
											<h1 className="fs-5 fw-500 text-primary">
												Normal job
											</h1>
											<span className="fs-small fw-400 text-primary">
												Lorem Ipsum is simply dummy text
												of the printing and typesetting
												industry
											</span>
										</div>
										<FontAwesome
											icon="chevron-right"
											size="2xl"
											className="text-primary"
										/>
									</div>
									<div
										className="d-flex align-items-center gap-4"
										style={{
											padding: '15px 16px 15px 16px',
											gap: '24px',
											borderRadius: '8px',
											opacity: '0px',
											boxShadow:
												'0px 4px 10px 0px #3E552333'
										}}>
										<FontAwesome
											icon="chalkboard-user"
											size="2xl"
											className="text-gray-2"
										/>
										<div>
											<h1 className="fs-5 fw-500 text-gray-2">
												Internship
											</h1>
											<span className="fs-small fw-400 text-gray-2">
												Lorem Ipsum is simply dummy text
												of the printing and typesetting
												industry
											</span>
										</div>
										<FontAwesome
											icon="chevron-right"
											size="2xl"
											className="text-gray-2"
										/>
									</div>
								</div>
							)}
						/>
						<Step
							id="step1"
							render={({ next }) => (
								<CreateJobGeneral
									fields={fields.step1}
									onNext={(data) =>
										handleNext<JobFormGeneral>(
											next,
											data,
											1
										)
									}
								/>
							)}
						/>
						<Step
							id="step2"
							render={({ next, previous }) => (
								<CreateJobFeatured
									fields={fields.step2}
									onPrev={previous}
									onNext={(data) =>
										handleNext<JobFormFeatured>(
											next,
											data,
											2
										)
									}
								/>
							)}
						/>
						<Step
							id="step3"
							render={({ next, previous }) => (
								<CreateJobInfo
									fields={fields.step3}
									onPrev={previous}
									onNext={(data) =>
										handleNext<JobFormInfo>(next, data, 3)
									}
								/>
							)}
						/>
						<Step
							id="step4"
							render={({ next, previous }) => (
								<CreateJobDescription
									fields={fields.step4}
									onPrev={previous}
									onNext={(data) =>
										handleNext<JobFormDescription>(
											next,
											data,
											4
										)
									}
								/>
							)}
						/>
						<Step
							id="step5"
							render={({ next, previous, push }) => (
								<CreateJobPreviewSubmit
									fields={fields}
									onPrev={previous}
									onNext={next}
									goToStep={push}
									setJobId={setJobId}
								/>
							)}
						/>
						<Step
							id="step6"
							render={({ push }) => (
								<div className={clx('d-flex', styles.form)}>
									<FormSuccess
										addButtonTitle="button.add-job"
										listTitle="button.check-details"
										successTitle="forms.job-created"
										className="flex-grow-1 align-self-center"
										listUrl={`${jobs.path}/${jobId}`}
										onClickAdd={() => {
											push('step1');
											setFields(defaultFields);
										}}
									/>
								</div>
							)}
						/>
					</Steps>
				</div>
			)}
		/>
	);
});

CreateJob.displayName = 'CreateJob';
