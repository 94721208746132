import { FC, memo } from 'react';
import { Col, Row } from 'reactstrap';
import { XOR } from 'Types';
import { useTheme } from 'Hooks';
import { JobStatisticCard } from 'Elements';

interface SharedProps {
	establishedDate: string;
}

type Props = SharedProps &
	XOR<
		{
			userType: 'businessPartner';
			branch: string;
			employees: string;
			location: string;
		},
		{
			userType: 'communityPartner';
			departments: string;
			memberNumber: string;
		}
	>;

export const JobStatisticSection: FC<Props> = ({
	userType,
	branch,
	employees,
	location,
	establishedDate,
	departments,
	memberNumber
}) => {
	const { theme } = useTheme();

	return (
		<Row>
			{userType === 'businessPartner' && (
				<>
					{!!establishedDate && (
						<Col sm={6}>
							<JobStatisticCard
								icon="calendar"
								// fill={theme.businessPartner.primaryColor}
								title="label.established-date"
								desc={establishedDate}
								className="mb-4"
							/>
						</Col>
					)}
					{!!employees && (
						<Col sm={6}>
							<JobStatisticCard
								icon="users"
								title="label.employees"
								desc={employees}
								// fill={theme.businessPartner.primaryColor}
								className="mb-4"
							/>
						</Col>
					)}
					{!!location && (
						<Col sm={6}>
							<JobStatisticCard
								icon="earth-americas"
								title="label.locations"
								desc={location}
								// fill={theme.businessPartner.primaryColor}
							/>
						</Col>
					)}
					{!!branch && (
						<Col sm={6}>
							<JobStatisticCard
								icon="industry"
								title="label.branch"
								desc={branch}
								// fill={theme.businessPartner.primaryColor}
							/>
						</Col>
					)}
				</>
			)}
			{userType === 'communityPartner' && (
				<>
					{!!establishedDate && (
						<Col sm={6} xl={4}>
							<JobStatisticCard
								icon="calendar"
								// fill={theme.agent.primaryColor}
								title="label.established-date"
								desc={establishedDate}
								className="mb-4 mb-xl-0"
							/>
						</Col>
					)}
					{!!memberNumber && (
						<Col sm={6} xl={4}>
							<JobStatisticCard
								icon="users"
								// fill={theme.agent.primaryColor}
								title="label.members"
								desc={memberNumber}
								className="mb-4 mb-xl-0"
							/>
						</Col>
					)}
					{!!departments && (
						<Col sm={6} xl={4}>
							<JobStatisticCard
								icon="building"
								// fill={theme.agent.primaryColor}
								title="label.department"
								desc={departments}
							/>
						</Col>
					)}
				</>
			)}
		</Row>
	);
};
