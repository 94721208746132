import { Container } from 'reactstrap';
import { clx } from 'Utils';
import { Colors, Logo } from 'Types';
import {
	Button,
	Collapsible,
	CommunityPartnerCompanyOverviewStandard,
	FontAwesome,
	LoadingSpinner,
	ModalBoxSectionEditProfile,
	ReactSwitch
} from 'Elements';
import styles from 'partials/homepage/section/community-partner-business-partner-overview-section.module.scss';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';
import { useTranslation } from 'react-i18next';
import { useModal } from 'src/hooks/useModal';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean | Logo
	) => void;
	becomePartner: {
		enabled: boolean;
		headline: string;
		description: string;
		image: Logo;
	};
};

export const CommunityPartnerCompanyOverviewSection = ({
	isLoadingProfile,
	onChangeValue,
	becomePartner
}: Props) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${stylesProfile.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700 d-flex align-items-center">
										{' '}
										{t('text.partner-overview')}
									</h5>
									<ReactSwitch
										size="md"
										checked={becomePartner?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'becomePartner',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t(
											'forms.show-business-partner-overview-in-home'
										)}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<div
						// $IsPremium={!!IsPremium}
						// $IsPremium={false}
						// $image={becomePartner?.image?.thumbnail}
						>
							<Container
								// fluid={!IsPremium ? 'lg' : false}
								fluid={false}
								className={clx(
									styles.container,
									// !IsPremium && 'g-0'
									'g-0'
								)}>
								{/* {IsPremium ? (
								<AgentCompanyOverviewPremium />
							) : (
								<AgentCompanyOverviewStandard />
							)} */}
								<CommunityPartnerCompanyOverviewStandard
									isLoadingProfile={isLoadingProfile}
									onChangeValue={onChangeValue}
									becomePartner={becomePartner}
								/>
							</Container>
						</div>
					</Collapsible>
				)}
			</Container>
			{!becomePartner?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
			<ModalBoxSectionEditProfile
				isOpen={openModal}
				onClose={toggleModal}
				title="info.partner-overview-title"
				description="info.partner-overview-description"
			/>
		</section>
	);
};
