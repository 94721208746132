import axios from 'Adapter';
import { api } from 'Utils';
import { DataQueryParams, Fetcher, JobStatisticReportRange } from 'Types';

export const fetcher = async (url: string) =>
	await axios.get(url).then((res) => res.data);

export const postFetcher = async (url: string, body?: Record<string, never>) =>
	await axios.post(url, body).then((res) => res.data);

const fetcherParam = async (api_url: string, data: DataQueryParams) => {
	const searchParams = new URLSearchParams(data)?.toString();
	return await fetcher(`${api_url}?${searchParams}`);
};

export const fetch: Fetcher | any = {
	// Permission Fetcher
	permissions: (localization = 'en') =>
		fetcherParam(api.getPermissions, { localization }),
	permissionsGraph: () => fetcher(api.getPermissionsGraph),

	// User type Fetcher
	businessPartnerProfile: (businessPartnerProfileId) =>
		fetcher(api.getBusinessPartnerProfile(businessPartnerProfileId)),
	communityPartnerProfile: (communityPartnerProfileId) =>
		fetcher(api.getCommunityPartnerProfile(communityPartnerProfileId)),
	plansCommunityPartner: (communityPartnerProfileId) =>
		fetcher(api.getPlansCommunityPartner(communityPartnerProfileId)),
	businessPartnerMe: () => fetcher(api.getBusinessPartnerMe),
	communityPartnerMe: () => fetcher(api.getCommunityPartnerMe),

	// Employee Fetcher
	employeeList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getEmployees, { pageSize, pageNumber, keyword }),
	employeeDetails: (id) => fetcher(api.getEmployeeDetail(id)),

	// Member Fetcher
	memberList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getMembers, { pageSize, pageNumber, keyword }),
	memberDetails: (id) => fetcher(api.getMemberDetail(id)),

	// Department Fetcher
	departmentList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getDepartments, { pageSize, pageNumber, keyword }),
	departmentDetails: (id) => fetcher(api.getDepartmentDetail(id)),

	// Activity Fetcher
	activityList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getActivities, { pageSize, pageNumber, keyword }),

	// Benefit Fetcher
	benefitList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getBenefits, { pageSize, pageNumber, keyword }),

	// Sector Fetcher
	sectorList: () => fetcher(api.getSectors),

	// Job type Fetcher
	jobTypeList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getJobTypes, { pageSize, pageNumber, keyword }),

	// Job Tag Categories
	jobTagCategories: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getJobTagCategories, {
			pageSize,
			pageNumber,
			keyword
		}),

	// Activity Tags
	activityTags: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getActivityTags, {
			pageSize,
			pageNumber,
			keyword
		}),

	// Job Tags
	jobTags: (
		pageSize,
		pageNumber,
		keyword,
		jobTagCategoryId,
		noAssignedCategory
	) =>
		fetcherParam(api.getJobTags, {
			pageSize,
			pageNumber,
			keyword,
			jobTagCategoryId,
			noAssignedCategory
		}),

	// Job Fetcher
	profileJobList: (status, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getJobs, { status, pageSize, pageNumber, keyword }),
	jobDetails: (id) => fetcher(api.getJobDetail(id)),
	jobQrCode: (jobId) => fetcher(api.getJobQrCode(jobId)),
	downloadJobQrCode: (jobId) => fetcher(api.downloadJobQrCode(jobId)),

	// Internship Fetcher
	internshipList: (status, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getInternships, {
			status,
			pageSize,
			pageNumber,
			keyword
		}),
	internshipDetails: (id) => fetcher(api.getInternshipDetail(id)),
	internshipQrCode: (internshipId) =>
		fetcher(api.getInternshipQrCode(internshipId)),
	downloadInternshipQrCode: (internshipId) =>
		fetcher(api.downloadInternshipQrCode(internshipId)),

	// User Fetcher
	userList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getB2bUsers, { pageSize, pageNumber, keyword }),
	userDetails: (id) => fetcher(api.getUserDetail(id)),
	userGroupList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getUserGroups, { pageSize, pageNumber, keyword }),
	userGroupDetails: (id) => fetcher(api.getUserGroupDetail(id)),
	userRoleList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getUserRoles, { pageSize, pageNumber, keyword }),
	userRoleDetails: (id) => fetcher(api.getUserRoleDetail(id)),

	// Partner Fetcher
	agentPartners: (pageSize, pageNumber, keyword, agentApprovalStatus) =>
		fetcherParam(api.getAgentCompanies, {
			pageSize,
			pageNumber,
			keyword,
			agentApprovalStatus
		}),
	communityPartners: (pageSize, pageNumber, keyword, regionCode, IsPremium) =>
		fetcherParam(api.getCommunityPartnersCompanies, {
			pageSize,
			pageNumber,
			keyword,
			regionCode,
			IsPremium
		}),
	companyAgents: () => fetcher(api.getCompanyAgents),
	agentParentAssociationList: (partnerClass) =>
		fetcher(api.getAgentParentAssociation(partnerClass)),
	agentPartnerAreaList: (parentAssociationId) =>
		fetcher(api.getAgentParentArea(parentAssociationId)),
	// Subscription Fetcher
	subscriptionPlanList: (
		pageSize,
		pageNumber,
		keyword,
		planType,
		communityPartnerId
	) =>
		fetcherParam(api.getSubscriptionPlans, {
			planType,
			communityPartnerId,
			pageSize,
			pageNumber,
			keyword
		}),
	companySubscriptionList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getCompanySubscriptions, {
			pageSize,
			pageNumber,
			keyword
		}),
	subscriptionCalculate: (
		CommunityPartnerId,
		SubscriptionPlanId,
		VoucherCode
	) =>
		fetcher(
			api.getSubscriptionCalculate(
				CommunityPartnerId,
				SubscriptionPlanId,
				VoucherCode
			)
		),
	subscriptionPlansFeature: (subscriptionPlanFeatureId, SubscriptionPlanId) =>
		fetcher(
			api.getSubscriptionPlansFeature(
				subscriptionPlanFeatureId,
				SubscriptionPlanId
			)
		),
	subscriptionDetails: (subscriptionId) =>
		fetcher(api.getSubscriptionDetail(subscriptionId)),
	subscriptionPaymentsDetails: (currentSubscriptionPaymentId) =>
		fetcher(
			api.getSubscriptionPaymentsDetail(currentSubscriptionPaymentId)
		),
	agentsToSwitch: (pageSize, pageNumber, keyword, companySubscription) =>
		fetcherParam(api.getAgentsToSwitch, {
			pageSize,
			pageNumber,
			keyword,
			companySubscription
		}),

	// Demands Fetcher
	demandList: (status, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getDemands, { status, pageSize, pageNumber, keyword }),
	demandDetails: (demandId) => fetcher(api.getDemandDetail(demandId)),

	// Internship Registrations Fetcher
	internshipRegistrationList: (status, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getInternshipRegistrations, {
			status,
			pageSize,
			pageNumber,
			keyword
		}),
	internshipRegistrationDetails: (internshipParticipantsId) =>
		fetcher(api.getInternshipRegistrationDetail(internshipParticipantsId)),

	// Region Fetcher
	alternativeRegionList: (regionId) =>
		fetcherParam(api.getAlternativeRegions, {
			regionId
		}),

	regionsList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getRegions, {
			pageSize,
			pageNumber,
			keyword
		}),

	// Payment Fetcher
	paymentList: (status, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getPayments, {
			status,
			pageSize,
			pageNumber,
			keyword
		}),
	paymentDetails: (id) => fetcher(api.getPaymentDetails(id)),

	// Transaction Fetcher
	agentTransactionList: (status, type, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getAgentTransactions, {
			status,
			type,
			pageSize,
			pageNumber,
			keyword
		}),
	companyTransactionList: (status, type, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getCompanyTransactions, {
			status,
			type,
			pageSize,
			pageNumber,
			keyword
		}),
	transactionDetails: (transactionId) =>
		fetcher(api.getTransactionDetails(transactionId)),
	agentTransactionDetails: (transactionId) =>
		fetcher(api.getAgentTransactionDetails(transactionId)),
	agentPaymentTransactionDetails: (transactionId) =>
		fetcher(api.getAgentPaymentTransactionDetails(transactionId)),
	paymentStatus: (paymentId) => fetcher(api.getPaymentStatus(paymentId)),

	// Voucher Fetcher
	agentVouchers: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getAgentVouchers, {
			pageSize,
			pageNumber,
			keyword
		}),
	availableVouchers: () => fetcher(api.getAvailableVouchers),
	communityPartnerTypes: (pageSize, pageNumber, keyword, active) =>
		fetcherParam(api.getCommunityPartnerTypes, {
			pageSize,
			pageNumber,
			keyword,
			active
		}),
	agentVoucherDetails: (voucherId) =>
		fetcher(api.getAgentVoucherDetails(voucherId)),

	// Invitation Fetcher
	invitationList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getInvitations, {
			pageSize,
			pageNumber,
			keyword
		}),
	invitationDetails: (invitation) =>
		fetcher(api.getInvitationDetails(invitation)),

	// Event Fetcher

	/** Agent event fetcher */
	eventList: (status, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getEvents, {
			status,
			pageSize,
			pageNumber,
			keyword
		}),
	publishEventList: (isFinished, pageSize, pageNumber) =>
		fetcherParam(api.getPublicEvents, {
			isFinished,
			pageSize,
			pageNumber
		}),
	eventDetails: (eventId) => fetcher(api.getEvent(eventId)),
	eventInvitations: (eventId, pageSize, pageNumber) =>
		fetcherParam(api.getEventInvitations(eventId), {
			pageSize,
			pageNumber
		}),
	eventAllInvitations: (eventId, isPartner, status, keyword) =>
		fetcher(
			api.getEventAllInvitations(eventId, isPartner, status, keyword)
		),
	companiesToInvite: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getCompaniesToInvite, {
			pageSize,
			pageNumber,
			keyword
		}),
	participantList: (
		eventId,
		isPartner,
		status,
		pageSize,
		pageNumber,
		keyword
	) =>
		fetcherParam(api.getParticipants(eventId), {
			isPartner,
			status,
			pageSize,
			pageNumber,
			keyword
		}),
	eventQrCode: (eventId) => fetcher(api.getEventQrCode(eventId)),
	downloadEventQrCode: (eventId) => fetcher(api.downloadEventQrCode(eventId)),

	/** Company event fetcher */
	companyEventDetails: (eventId) => fetcher(api.getEventDetails(eventId)),
	companyEventLimit: (eventId) => fetcher(api.getEventLimit(eventId)),
	companyEventInvitations: (pageSize, pageNumber, finishedOnly, status) =>
		fetcherParam(api.getCompanyEventInvitations, {
			pageSize,
			pageNumber,
			finishedOnly,
			status
		}),
	companyParticipatedEvent: (pageSize, pageNumber, status) =>
		fetcherParam(api.getCompanyParticipatedEvent, {
			pageSize,
			pageNumber,
			status
		}),

	// Toolbox Fetcher
	marketingAssets: (useFor) =>
		fetcherParam(api.getMarketingAssets, { useFor }),
	marketingTemplates: (toolType, useFor) =>
		fetcherParam(api.getMarketingTemplates, { toolType, useFor }),
	marketingAssetConfig: (assetId) =>
		fetcher(api.getMarketingAssetConfig(assetId)),

	// Linkedin Fetcher
	linkedinAuthToken: (accessToken) =>
		fetcher(api.validateLinkedinToken(accessToken)),

	// Job Statistics Fetcher
	jobStatistics: (reportRange = JobStatisticReportRange.Monthly) =>
		fetcherParam(api.getJobStatistic, { reportRange }),
	totalJobStatistic: () => fetcher(api.getTotalJobStatistic),

	//Contact Groups
	listContactGroup: (isActive, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getContactGroups, {
			isActive,
			pageSize,
			pageNumber,
			keyword
		}),
	contactGroupDetails: (contactGroupId) =>
		fetcher(api.getContactGroupDetails(contactGroupId)),

	//Contact People
	listContactPeople: (contactGroupId, pageSize, pageNumber, keyword) =>
		fetcherParam(api.getContactPeople, {
			contactGroupId,
			pageSize,
			pageNumber,
			keyword
		}),
	contactPeopleDetails: (contactPeopleId) =>
		fetcher(api.getContactPeopleDetails(contactPeopleId)),

	// Agent Plan Fetcher
	agentPlanList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getAgentPlans, {
			pageSize,
			pageNumber,
			keyword
		}),
	agentProfilePlan: () => fetcher(api.getAgentProfilePlan),
	agentPlanDetails: (agentPlanId) =>
		fetcher(api.getAgentPlanDetails(agentPlanId)),

	// externPartner Fetcher
	externPartnerList: (pageSize, pageNumber, keyword) =>
		fetcherParam(api.getExternPartners, {
			pageSize,
			pageNumber,
			keyword
		}),
	externPartnerDetails: (externPartner) =>
		fetcher(api.getExternPartnerDetails(externPartner))
};
