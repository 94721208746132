import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ColorValues,
	Colors,
	ModalProps,
	SubscriptionCalculate,
	SubscriptionPaymentStatus
} from 'Types';
import { Button, ModalBox, ModalBoxFooter, FontAwesome } from 'Elements';
import { useNavigate } from 'react-router-dom';
import styles from 'partials/step/step-container.module.scss';
import { currencyFormatter } from 'src/helpers/Utils';

interface Props extends ModalProps {
	subscriptionCalculate: SubscriptionCalculate;
	subscriptionPaymentStatus?: SubscriptionPaymentStatus;
}

export const ModalBoxPaymentResult: FC<Props> = ({
	isOpen,
	subscriptionCalculate,
	subscriptionPaymentStatus
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const getStatus = (status: SubscriptionPaymentStatus) => {
		switch (status) {
			case 0:
				return {
					title: t('badge.pending-approval'),
					icon: 'loader',
					description: t('text.payment.pending-approval-description'),
					color: ColorValues.yellow,
					bgColor: '#ff8c004d'
				};
			case 2:
				return {
					title: t('badge.payment-not-successfully'),
					icon: 'close',
					description: t(
						'text.payment.payment-not-successfully-description'
					),
					color: ColorValues.red,
					bgColor: '#FDD9D7'
				};
			case 3:
				return {
					title: t('badge.processing'),
					icon: 'loader',
					description: t('text.payment.processing-description'),
					color: ColorValues['gray-3'],
					bgColor: 'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
				};
			case 4:
				return {
					title: t('badge.payment-canceled'),
					icon: 'close',
					description: t('text.payment.payment-canceled-description'),
					color: ColorValues.red,
					bgColor: '#FDD9D7'
				};
			default:
				return {
					title: t('badge.payment-successfully'),
					icon: 'check',
					description: t(
						'text.payment.payment-successfully-description'
					),
					color: ColorValues.primary,
					bgColor: '#DBEFDC'
				};
		}
	};

	return (
		<ModalBox isOpen={isOpen}>
			<div className="d-flex align-items-center gap-2 mb-4">
				<div>
					<FontAwesome
						icon={'credit-card'}
						className={'text-primary'}
						size="2x"
						swapOpacity
					/>
				</div>
				<div>
					<span className="fw-500 fs-base">Payment confirmation</span>
				</div>
			</div>
			<div
				className={`d-flex align-items-center gap-2 ${styles.boxPaymentResult}`}
				style={{
					background: getStatus(
						subscriptionPaymentStatus as SubscriptionPaymentStatus
					).bgColor
				}}>
				<FontAwesome
					icon={
						getStatus(
							subscriptionPaymentStatus as SubscriptionPaymentStatus
						).icon
					}
					size="2x"
					color={
						getStatus(
							subscriptionPaymentStatus as SubscriptionPaymentStatus
						).color
					}
				/>
				<h1
					className={`fw-500 fs-5`}
					style={{
						color: getStatus(
							subscriptionPaymentStatus as SubscriptionPaymentStatus
						).color
					}}>
					{
						getStatus(
							subscriptionPaymentStatus as SubscriptionPaymentStatus
						).title
					}
				</h1>
			</div>
			<div>
				<p className="fs-small fw-400">
					{
						getStatus(
							subscriptionPaymentStatus as SubscriptionPaymentStatus
						).description
					}
				</p>
			</div>
			<div className="my-4 pb-3">
				<div className="d-flex">
					<div className="d-flex flex-column text-end pe-3 me-3 border-end border-2 text-gray-3">
						<h5 className="h5 mb-0">
							{currencyFormatter(
								subscriptionCalculate?.totalAmount ?? 0
							)}
						</h5>
						<span className="fw-300">
							{t('title.excluded-tax')}
						</span>
					</div>
					<div className="text-gray-3 text-start d-flex flex-column">
						<span className="fw-300 lh-base">{t('title.per')}</span>
						<span className="mt-auto fs-base">
							{`${
								subscriptionCalculate?.subscriptionDuration ??
								''
							} ${t('title.days')}`}
						</span>
					</div>
				</div>
			</div>
			<ModalBoxFooter className="pt-3">
				<Button
					color={Colors['secondary']}
					label={'Go to subscription plans'}
					onClick={() => {
						navigate(`/subscriptions`);
					}}
					className="w-100"
				/>
			</ModalBoxFooter>
		</ModalBox>
	);
};
