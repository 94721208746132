import styled from 'styled-components';
import { rgba } from 'polished';
import { Col, Container, Row } from 'reactstrap';
import { Title } from 'Styles';
import { useTheme } from 'Hooks';
import { FontAwesome, LoadingSpinner } from 'Elements';

const Box = styled.div`
	background-color: ${({ theme }) =>
		theme && rgba(theme.businessPartner.primaryColor, 0.05)};
`;

type Props = {
	isLoadingProfile: boolean;
	jobAds: {
		description: string | number;
		enabled: boolean;
		headline: string;
	};
};

export const BusinessPartnerPreviewJobHeadlineSection = ({
	isLoadingProfile,
	jobAds
}: Props) => {
	const { theme } = useTheme();

	return (
		<section className="position-relative mb-5 mt-6">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center my-5 py-5">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && jobAds && (
					<Row className="justify-content-center">
						<Col lg={10} xl={8} className="position-relative">
							<Box
								className="p-4 position-relative"
								style={{ borderRadius: '30px' }}>
								<div className="d-flex align-items-center gap-2">
									<FontAwesome
										icon="grid-4"
										size="3x"
										color={
											theme.businessPartner.primaryColor
										}
									/>
									<Title
										textColor="text-gray-5"
										className="h3 mb-0 flex-grow-1">
										{jobAds?.headline ?? ''}
									</Title>
								</div>
								<div className="mt-2">
									{jobAds?.description ?? ''}
								</div>
							</Box>
						</Col>
					</Row>
				)}
			</Container>
		</section>
	);
};
