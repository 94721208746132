export const queryKey = {
	permissions: 'permissions',
	graph: 'graph',
	group: 'group',
	groups: 'groups',
	role: 'role',
	roles: 'roles',
	agentPartnersArea: 'agentPartnersArea',
	alternativeRegion: 'alternativeRegion',
	businessPartnerProfile: 'businessPartnerProfile',
	communityPartnerProfile: 'communityPartnerProfile',
	subscriptionPlansFeature: 'subscriptionPlansFeature',
	businessPartnerMe: 'businessPartnerMe',
	companyAgents: 'companyAgents',
	communityPartnerMe: 'communityPartnerMe',
	agentPartners: 'agentPartners',
	communityPartners: 'communityPartners',
	communityPartnerTypes: 'communityPartnerTypes',
	profile: 'profile',
	employee: 'employee',
	employees: 'employees',
	member: 'member',
	members: 'members',
	activityTag: 'activityTag',
	regions: 'regions',
	activity: 'activity',
	activities: 'activities',
	sector: 'sector',
	sectors: 'sectors',
	jobType: 'jobType',
	jobTypes: 'jobTypes',
	job: 'job',
	jobs: 'jobs',
	jobTagCategories: 'jobTagCategories',
	internship: 'internship',
	internships: 'internships',
	internshipQrCode: 'internshipQrCode',
	internshipParticipants: 'internshipParticipants',
	downloadInternshipQrCode: 'downloadInternshipQrCode',
	jobQrCode: 'jobQrCode',
	downloadJobQrCode: 'downloadJobQrCode',
	user: 'user',
	users: 'users',
	benefit: 'benefit',
	benefits: 'benefits',
	subscriptionPlans: 'subscriptionPlans',
	subscriptionPrimaryPlans: 'subscriptionPrimaryPlans',
	subscription: 'subscription',
	subscriptionCalculate: 'subscriptionCalculate',
	subscriptions: 'subscriptions',
	subscriptionPayments: 'subscriptionPayments',
	subscriptionsPrimary: 'subscriptionsPrimary',
	demands: 'demands',
	demand: 'demand',
	transaction: 'transaction',
	transactions: 'transactions',
	payment: 'payment',
	pay: 'pay',
	payments: 'payments',
	paymentStatus: 'paymentStatus',
	paymentTransaction: 'paymentTransaction',
	voucher: 'voucher',
	vouchers: 'vouchers',
	availableVouchers: 'availableVouchers',
	invitation: 'invitation',
	invitations: 'invitations',
	event: 'event',
	eventDetails: 'eventDetails',
	eventLimit: 'eventLimit',
	events: 'events',
	eventQrCode: 'eventQrCode',
	downloadEventQrCode: 'downloadEventQrCode',
	publicEvents: 'publicEvents',
	companiesToInvite: 'companiesToInvite',
	participants: 'participants',
	eventInvitations: 'eventInvitations',
	eventAllInvitations: 'eventAllInvitations',
	companyEventInvitations: 'companyEventInvitations',
	companyParticipatedEvent: 'companyParticipatedEvent',
	marketingAssets: 'marketingAssets',
	marketingTemplates: 'marketingTemplates',
	marketingAssetConfig: 'marketingAssetConfig',
	agentToSwitch: 'agentToSwitch',
	department: 'department',
	departments: 'departments',
	linkedinToken: 'linkedinToken',
	jobStatistics: 'jobStatistics',
	totalJobStatistics: 'totalJobStatistics',
	contactGroup: 'contactGroup',
	contactPeople: 'contactPeople',
	agentPlans: 'agentPlans',
	agentPlan: 'agentPlan',
	agentProfilePlan: 'agentProfilePlan',
	externPartner: 'externPartner',
	aboCalculator: 'aboCalculator'
};
