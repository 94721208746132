import { memo, FC, useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { LoadingContent, SelectController, StepNavigation } from 'Elements';
import { useActivities, useJobTypes, usePrompt, useSectors } from 'Hooks';
import {
	Activity,
	InternshipFormInfo,
	JobType,
	Sector,
	SelectOption,
	Translate
} from 'Types';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: InternshipFormInfo;
	onPrev: () => void;
	onNext: (data: InternshipFormInfo) => void;
}

export const CreateInternshipInfo: FC<Props> = memo(({ fields, onNext, onPrev }) => {
	const { t } = useTranslation();
	const { data: sectorList, isLoading: isLoadingSectors } = useSectors({
		refetchOnMount: false
	});
	const { data: jobTypeList, isLoading: isLoadingJobTypes } = useJobTypes({
		pageNumber: 1,
		pageSize: 500
	});
	const { data: activityList, isLoading: isLoadingActivities } =
		useActivities({
			pageNumber: 1,
			pageSize: 500
		});

	const schema = yup.object().shape({
		sectorId: yup
			.object({
				label: yup.string(),
				value: yup.string()
			})
			.nullable()
			.required(t('validation.select-sector.required')),
		jobTypeId: yup
			.object({
				label: yup.string(),
				value: yup.string()
			})
			.nullable()
			.required(t('validation.select-job-type.required')),
		activityList: yup
			.array()
			.min(1, t('validation.select-activity.required'))
			.of(
				yup.object().shape({
					label: yup.string(),
					value: yup.string()
				})
			)
			.nullable()
			.required(t('validation.select-activity.required'))
	});

	const defaultCodeName = (section: any, section_name: string) => {
		const foundedName = section?.[`${section_name}Translates`].find(
			(translate: Translate) => translate.name !== ''
		)?.name;
		return foundedName
			? `${foundedName} (${section?.code})`
			: section?.code;
	};

	const optionsListObject = useCallback(
		(key_obj: Sector | JobType | Activity, key_name: string) => {
			return {
				value: key_obj?.['id'],
				label: defaultCodeName(key_obj, key_name)
			};
		},
		[]
	);

	const getSectorListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		sectorList?.forEach((sector: Sector) => {
			options.push(optionsListObject(sector, 'sector'));
		});
		return options;
	}, [sectorList, optionsListObject]);

	const getJobTypeListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		jobTypeList?.items?.forEach((jobType: JobType) => {
			if (jobType.jobKind === 2) {
				options.push(optionsListObject(jobType, 'jobType'));
			}
		});
		return options;
	}, [jobTypeList?.items, optionsListObject]);

	const getActivityListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		activityList?.items?.forEach((activity: Activity) => {
			options.push(optionsListObject(activity, 'activity'));
		});
		return options;
	}, [activityList?.items, optionsListObject]);

	const {
		control,
		handleSubmit,
		formState: { errors, isDirty }
	} = useForm<InternshipFormInfo>({
		resolver: yupResolver(schema),
		defaultValues: fields
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const onSubmit: SubmitHandler<InternshipFormInfo> = (data) => {
		onNext?.(data);
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={styles.form}
			noValidate>
			<Row>
				<Col xxl={8}>
					<FormGroup>
						<Label htmlFor="jobTypeId">
							{t('forms.job-type')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<SelectController
							name="jobTypeId"
							isDisabled={isLoadingJobTypes}
							control={control}
							placeholder={
								isLoadingJobTypes
									? 'placeholder.loading'
									: 'placeholder.select-job-type'
							}
							options={getJobTypeListOptions}
							error={errors.jobTypeId?.message?.toString()}
						/>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="sectorId">
							{t('forms.sector')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<SelectController
							name="sectorId"
							control={control}
							placeholder={
								isLoadingSectors
									? 'placeholder.loading'
									: 'placeholder.select-sector'
							}
							isDisabled={isLoadingSectors}
							options={getSectorListOptions}
							error={errors.sectorId?.message?.toString()}
						/>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="activityList">
							{t('forms.activity')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<SelectController
							isMulti
							isDisabled={isLoadingActivities}
							name="activityList"
							control={control}
							placeholder={
								isLoadingActivities
									? 'placeholder.loading'
									: 'placeholder.select-activities'
							}
							options={getActivityListOptions}
							error={errors.activityList?.message?.toString()}
						/>
					</FormGroup>
					<StepNavigation onPrev={onPrev} />
				</Col>
			</Row>
			{isLoadingActivities && isLoadingJobTypes && isLoadingSectors && (
				<LoadingContent />
			)}
		</form>
	);
});

CreateInternshipInfo.displayName = 'CreateInternshipInfo';
