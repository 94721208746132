import { useReducer } from 'react';
import Swiper from 'swiper';
import { Container } from 'reactstrap';
import { SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper/modules';
import { createGlobalStyle } from 'styled-components';
import { clx } from 'Utils';
import { Media } from 'Types';
import { GalleryReducer, setSwiper } from 'Reducers';
import { useTheme } from 'Hooks';
import { Carousel, GallerySlide } from 'Elements';
import 'swiper/css/effect-fade';
import styles from 'partials/homepage/section/gallery-section.module.scss';

type Props = {
	isLoadingProfile: boolean;
	gallery: {
		title: string;
		showInHomePage: boolean;
	};
	galleryItems: [
		{
			url: string;
			thumbnail: string;
		}
	];
};

const GlobalStyle = createGlobalStyle<{ bgColor: string }>`
	${({ bgColor }) =>
		bgColor &&
		`.swiperNav {
			background-color: ${bgColor} !important;
		}
		.swiper-pagination-current {
			color: ${bgColor} !important;
			font-weight: 700;
		}
	`}
`;

export const BusinessPartnerPreviewGallerySection = ({
	isLoadingProfile,
	gallery,
	galleryItems
}: Props) => {
	const { theme } = useTheme();

	const initialState = {
		swiper: null,
		nextImage: '',
		prevImage: '',
		imageLoading: false,
		imageRemoving: false,
		imageUpdating: false
	};
	const [state, dispatch] = useReducer(GalleryReducer, initialState);

	const initSwiper = (swiper: Swiper) => {
		dispatch(setSwiper(swiper));
	};

	const updateSwiper = () => {
		if (state.swiper) {
			state.swiper.update();
		}
	};


	return (
		<section className="position-relative mb-5 mt-6">
			{!isLoadingProfile && gallery && (
				<Container>
					<GlobalStyle bgColor={theme.businessPartner.primaryColor} />
					<Carousel
						effect="fade"
						observeParents
						fadeEffect={{
							crossFade: true
						}}
						navigationGroup={galleryItems?.length > 1}
						className={styles.carousel}
						enabled={galleryItems?.length > 1}
						modules={[Pagination, EffectFade]}
						navigation
						navigationClass={clx(styles.swiperNav, 'swiperNav')}
						pagination={{
							enabled: galleryItems?.length > 1,
							type: 'fraction',
							horizontalClass: `${styles.pagination}`,
							bulletClass: `${styles.page} gallery-page`,
							bulletActiveClass: `gallery-page-active`
						}}
						onInit={initSwiper}
						onSwiper={initSwiper}
						onSlideChange={updateSwiper}
						// onUpdate={initSwiper}
						onActiveIndexChange={updateSwiper}>
						{galleryItems
							?.slice(0)
							?.reverse()
							?.map((item, index) => {
								return (
									<SwiperSlide
										key={index}
										className={styles.slide}>
										<GallerySlide
											title={gallery.title}
											image={item.url}
											isEdit={false}
											imageClassName={styles.slideImage}
											isUpdating={false}
											isRemoving={false}
											onEdit={(image_data: Media) => {}}
											onRemove={() => {}}
										/>
									</SwiperSlide>
								);
							})}
					</Carousel>
				</Container>
			)}
		</section>
	);
};
