import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Button, ModalBox, ModalBoxFooter, SubmitButton } from 'Elements';
import { AgentPlanFeature, Colors, ModalProps } from 'Types';

interface Props extends ModalProps {
	// index: number;
	// onSubmit: (data: AgentPlanFeature) => void;
}

export const ModalBoxAddAgentFeatureService: FC<Props> = memo(
	({ isOpen, onClose }) => {
		const descriptionLimit = 270;
		const { t } = useTranslation();
		const {
			handleSubmit,
			register,
			watch,
			// control,
			formState: { isDirty, isSubmitting }
		} = useForm<AgentPlanFeature>({
			defaultValues: {
				title: '',
				description: ''
			}
		});

		// const { fields, append } = useFieldArray({
		// 	control,
		// 	name: 'features'
		// });

		// const [features, setFeatures] = useState<AgentPlanFeature>({

		// })

		const submitHandler: SubmitHandler<AgentPlanFeature> = async (data) => {
			// onSubmit(data);
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title="title.plan-price.add-new-feature">
				<form onSubmit={handleSubmit(submitHandler)}>
					<FormGroup>
						<Label htmlFor="featureTitle">
							{t('forms.plan-price.feature-title')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<input
							{...register(`title`)}
							type="text"
							id="featureTitle"
							className="inputbox w-100"
							placeholder={t('placeholder.enter-title')}
						/>
					</FormGroup>
					<FormGroup className="position-relative">
						<Label htmlFor="featureDescription">
							{t('forms.plan-price.feature-description')}
						</Label>
						<textarea
							{...register(`description`, {
								maxLength: descriptionLimit
							})}
							maxLength={descriptionLimit}
							id="featureDescription"
							className="inputbox w-100"
							cols={10}
							rows={5}
						/>
						<div className="position-absolute end-0">
							<small className="ms-auto mt-1">
								{watch(`description`)?.length || 0}/
								{descriptionLimit}
							</small>
						</div>
					</FormGroup>
					<ModalBoxFooter className="justify-content-between pt-4">
						<Button
							color={Colors['white-gray']}
							label={t('button.cancel') as string}
							onClick={onClose}
						/>
						<SubmitButton
							isDisable={!isDirty}
							isSubmitting={isSubmitting}
						/>
					</ModalBoxFooter>
				</form>
			</ModalBox>
		);
	}
);

ModalBoxAddAgentFeatureService.displayName = 'ModalBoxAddAgentFeatureService';
