import { Container } from 'reactstrap';
import {
	LoadingSpinner,
	PreviewProfileStaffSection,
	PreviewProfileStaffCard
} from 'Elements';
import { useEmployees } from 'src/hooks/useEmployee';
import { SwiperSlide } from 'swiper/react';
import styles from 'partials/homepage/section/staff-section.module.scss';
import { Title } from 'src/containers/Styles';

type Props = {
	isLoadingProfile: boolean;
	employee: { enabled: boolean; headline: string };
};
export const BusinessPartnerPreviewEmployeeSection = ({
	isLoadingProfile,
	employee
}: Props) => {
	const { data } = useEmployees({
		pageSize: 20,
		pageNumber: 1,
		keyword: ''
	});

	return (
		<section className="position-relative mb-5 mt-6">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<>
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-0 text-center">
							{employee?.headline}
						</Title>

						{data && data?.items?.length > 0 && (
							<PreviewProfileStaffSection
								list={data}
								profileType="businessPartner">
								{data.items.reverse()?.map((employee) => {
									return (
										<SwiperSlide
											key={employee.id}
											className={styles.card}>
											<PreviewProfileStaffCard
												id={employee.id}
												name={employee.fullName}
												staffType="employees"
												image={
													employee.image?.url ??
													''
												}
												position={employee.jobPosition}
											/>
										</SwiperSlide>
									);
								})}
							</PreviewProfileStaffSection>
						)}
					</>
				)}
			</Container>
		</section>
	);
};
