import { memo, FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useModal } from 'Hooks';
import {
	FeaturePhoto,
	FeatureVideo,
	ModalBoxChangeImage,
	StepNavigation
} from 'Elements';
import { AspectRatio, JobFormFeatured, Media } from 'Types';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: JobFormFeatured;
	onPrev: () => void;
	onNext: (data: JobFormFeatured) => void;
}

export const CreateJobFeatured: FC<Props> = memo(
	({ fields, onNext, onPrev }) => {
		const { t } = useTranslation();
		const [openModal, toggleModal] = useModal();
		const [featuredPhoto, setPhoto] = useState<
			Partial<{ url: string; thumbnail: string }>
		>({
			url: fields.featuredPhoto?.url,
			thumbnail: fields.featuredPhoto?.thumbnail
		});
		const [featuredVideo, setVideo] = useState<{
			url: string;
			thumbnail: string;
			mimeType: string;
		} | null>(null);

		const onUploadPhoto = (image_data: Partial<Media>) => {
			if (image_data)
				setPhoto({
					url: image_data.url,
					thumbnail: image_data.thumbnailUrl
				});
		};

		const handleRemovePhoto = () => {
			const defaultImage = {
				url: '',
				thumbnail: ''
			};
			setPhoto(defaultImage);
		};

		const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
			e.preventDefault();
			onNext?.({
				featuredPhoto: {
					url: featuredPhoto.url,
					thumbnail: featuredPhoto.thumbnail
				} as any,
				featuredVideo: featuredVideo
					? {
							url: featuredVideo.url,
							thumbnail: featuredVideo.thumbnail,
							mimeType: featuredVideo.mimeType
					  }
					: (null as any)
			});
		};

		return (
			<form onSubmit={handleSubmit} className={styles.form}>
				<Row>
					<Col xxl={8}>
						<FormGroup>
							<Label htmlFor="featuredPhoto">
								{t('forms.job.photo')}
							</Label>
							<FeaturePhoto
								image={featuredPhoto as any}
								onClick={toggleModal}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="featuredPhoto">
								{t('forms.job.video')}
							</Label>
							<FeatureVideo
								videoUrl={
									featuredVideo?.url === ''
										? null
										: featuredVideo
								}
								onUpload={(videoUrl) => {
									setVideo({
										url: videoUrl,
										thumbnail: videoUrl,
										mimeType: 'video/mp4'
									});
								}}
								onRemove={() =>
									setVideo({
										url: '',
										thumbnail: '',
										mimeType: ''
									})
								}
							/>
						</FormGroup>
						<StepNavigation onPrev={onPrev} />
					</Col>
				</Row>
				<ModalBoxChangeImage
					name="featuredPhotoUrl"
					title="title.change-image"
					isOpen={openModal}
					aspectRatio={AspectRatio['16/9']}
					image={featuredPhoto?.url || ''}
					onClose={toggleModal}
					onRemove={handleRemovePhoto}
					onImageChange={onUploadPhoto}
				/>
			</form>
		);
	}
);

CreateJobFeatured.displayName = 'CreateJobFeatured';
