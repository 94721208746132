import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { AssetUseFor, MarketingAssetConfig } from 'Types';
import { Card, LoadingSpinner, Scrollbar, ToolboxTemplateCard } from 'Elements';
import {
	clearAssetConfig,
	setSelectedTemplate,
	clearFieldValidationErrors,
	setLogo
} from 'Actions';
import {
	useDispatch,
	useGetB2bUserProfileQuery,
	useMarketingTemplate,
	useResponsive,
	useSelector
} from 'Hooks';
import styles from 'partials/marketing/toolbox.module.scss';

interface Props {
	className?: string;
}

export const ToolboxTemplateSection: FC<Props> = memo(({ className }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const defaultLogo = '/assets/img/business-partner-default.jpg';
	const { isTabletAndAbove } = useResponsive();
	const { selectedAsset, hasErrors, originalLogo } = useSelector(
		(state) => state.marketing
	);
	const { data: user } = useGetB2bUserProfileQuery();

	let userType = '';
	if (user?.partnerType === 2) {
		userType = 'businessPartner';
	}
	if (user?.partnerType === 1) {
		userType = 'communityPartner';
	}

	const { data, isLoading } = useMarketingTemplate(
		{
			toolType: selectedAsset?.id,
			useFor:
				userType === 'communityPartner'
					? AssetUseFor.agent
					: AssetUseFor.company
		},
		{
			enabled: Boolean(user && selectedAsset)
		}
	);
	const handleSelectTemplate = (template: MarketingAssetConfig) => {
		dispatch(setSelectedTemplate(template));
		dispatch(clearAssetConfig());
		// Set logo back to original company/agent logo
		dispatch(setLogo(originalLogo ?? defaultLogo));
		if (hasErrors) {
			dispatch(clearFieldValidationErrors());
		}
	};

	return (
		<Card className={clx(className, 'h-100')}>
			<h6 className="fs-6 lh-base mb-3">
				{t('title.marketing.templates')}
			</h6>
			<Scrollbar
				className={styles.template_scrollbar}
				style={{ paddingBottom: 15 }}
				options={{
					suppressScrollX: isTabletAndAbove,
					suppressScrollY: !isTabletAndAbove
				}}>
				{isLoading && <LoadingSpinner />}
				{!isLoading && data && data.length > 0 && (
					<div className={styles.template_grid}>
						{data.map((template) => (
							<ToolboxTemplateCard
								key={template.id}
								card={template}
								index={template.id}
								onSelect={() => handleSelectTemplate(template)}
							/>
						))}
					</div>
				)}
				{!isLoading && data && data.length === 0 && (
					<div className="text-center p-3">
						{t('title.no-template')}
					</div>
				)}
			</Scrollbar>
		</Card>
	);
});

ToolboxTemplateSection.displayName = 'ToolboxTemplateSection';
