import { Col, Container, Row } from 'reactstrap';
import { useModal, useTheme } from 'Hooks';
import {
	ProfileStatisticCard,
	LoadingSpinner,
	ReactSwitch,
	Button,
	FontAwesome,
	ModalBoxSectionEditProfile,
	Collapsible
} from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	statistics: {
		memberCount: string;
		enabled: boolean;
		establishedDate: string;
		departments: string;
	};
};

export const CommunityPartnerStatisticSection = ({
	isLoadingProfile,
	onChangeValue,
	statistics
}: Props) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();

	return (
		<section className="position-relative mb-5 mt-6">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center my-4 py-4">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && statistics && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3 ${styles.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3 ">
									<h5 className="fs-5 fw-700">
										{' '}
										{t('text.statistics')}
									</h5>
									<ReactSwitch
										size="md"
										checked={statistics?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'statistics',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-statistic-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<Row className="d-flex justify-content-center mx-lg-5 pt-3">
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<ProfileStatisticCard
									icon="calendar"
									fill={theme.communityPartner.primaryColor}
									title="label.established-date"
									desc={statistics?.establishedDate}
									onUpdate={(val) =>
										onChangeValue(
											'statistics',
											'establishedDate',
											val
										)
									}
								/>
							</Col>
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<ProfileStatisticCard
									icon="users"
									fill={theme.communityPartner.primaryColor}
									title="label.members"
									desc={statistics?.memberCount}
									onUpdate={(val) =>
										onChangeValue(
											'statistics',
											'memberCount',
											val
										)
									}
								/>
							</Col>
							<Col sm={6} md={3} className="mb-4 mb-md-0">
								<ProfileStatisticCard
									icon="earth-america"
									fill={theme.communityPartner.primaryColor}
									title="label.departments"
									desc={statistics?.departments}
									onUpdate={(val) =>
										onChangeValue(
											'statistics',
											'departments',
											val
										)
									}
								/>
							</Col>
						</Row>
						<ModalBoxSectionEditProfile
							isOpen={openModal}
							onClose={toggleModal}
							title="info.statistics-title"
							description="info.statistics-description"
						/>
					</Collapsible>
				)}
			</Container>
			{!isLoadingProfile && !statistics?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
};
