import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';
import styles from 'partials/payment/payment-method.module.scss';

interface Props {
	title: string;
	description: string;
	icon: any;
	isSelected: boolean;
	isDisabled?: boolean;
	onSelect: () => void;
}

export const PaymentMethodCard: FC<Props> = memo(
	({ title, description, icon, isSelected, isDisabled, onSelect }) => {
		const { t } = useTranslation();
		return (
			<div
				className={clx(
					styles.card,
					isSelected && styles.selected,
					isDisabled && styles.disabled
				)}
				onClick={onSelect}>
				<span className={styles.radio}>
					<i className={styles.dot} />
				</span>
				<div className="d-flex flex-column flex-grow-1">
					<h6 className="fs-base lh-base mb-2 d-flex align-items-center gap-2">
						<FontAwesome
							size="lg"
							
							set={icon === 'paypal' ? 'fab' : 'fad'}
							icon={icon}
							swapOpacity={isSelected}
							className={clx(
								'flex-shrink-0',
								isSelected && 'text-primary'
							)}
						/>
						{t(title)}
					</h6>
					<p className="lh-base fs-small text-gray-3 mb-0 flex-grow-1">
						{t(description)}
					</p>
				</div>
			</div>
		);
	}
);

PaymentMethodCard.displayName = 'PaymentMethodCard';
