import { FC } from 'react';
import { clx, formatLocalAddress } from 'Utils';
import { useDelayUnmount, useModal, useResponsive } from 'Hooks';
import {
	Address,
	CommunityPartnerProfileFormData,
	BusinessPartnerProfileInitialData,
	Media,
	Tab,
	XOR,
	BusinessPartnerMe,
	CommunityPartnerMe
} from 'Types';
import {
	Card,
	TabNavigation,
	ShimmerImage,
	ModalBoxChangeImage,
	LoadingContent,
	ModalBoxDeleteAccount,
	ProfileDecorationBusinessPartnerBadge,
	FontAwesome,
	ProfileDecorationCommunityPartnerBadge
} from 'Elements';
import pageStyles from 'partials/shared/page.module.scss';
import styles from 'partials/decoration/profile-decoration-box.module.scss';
import { useTranslation } from 'react-i18next';

interface SharedProps {
	logo: string;
	thumbnailLogo: string;
	address: Address;
	isLoading: boolean;
	title: string;
	tabs: Tab[];
	onResetLogo: () => void;
	onChangeLogo: (image_data: Media) => void;
	refetchPartnerProfile?: () => void;
}
type CommunityPartner = {
	type: 'communityPartner';
	communityPartnerMe?: CommunityPartnerMe;
	profile: CommunityPartnerProfileFormData;
};
type BusinessPartner = {
	type: 'businessPartner';
	businessPartnerMe?: BusinessPartnerMe;
	profile: BusinessPartnerProfileInitialData;
};
type Props = SharedProps & XOR<CommunityPartner, BusinessPartner>;

export const ProfileDecorationBox: FC<Props> = ({
	isLoading,
	logo,
	thumbnailLogo,
	address,
	title,
	tabs,
	type,
	profile,
	onResetLogo,
	onChangeLogo
}): JSX.Element => {
	const { t } = useTranslation();
	const [openModalLogo, toggleModalLogo] = useModal();
	const [openModalDelete, toggleModalDelete] = useModal();
	const { isTabletAndAbove, isDesktopAndAbove } = useResponsive();
	const shouldRenderModalDelete = useDelayUnmount(openModalDelete, 350);
	const defaultImage = '/assets/img/business-partner-default.jpg';
	const getImageSize = () => {
		return isDesktopAndAbove ? 160 : isTabletAndAbove ? 136 : 112;
	};

	return (
		<Card tab>
			{isLoading && <LoadingContent />}
			<div
				className={clx(
					styles.body,
					'd-flex flex-column flex-md-row gap-3'
				)}>
				<div
					role="button"
					tabIndex={-1}
					className={styles.logo}
					onClick={toggleModalLogo}>
					<div className={styles.logoBox}>
						<ShimmerImage
							width={getImageSize()}
							height={getImageSize()}
							src={thumbnailLogo ?? defaultImage}
							defaultImage={defaultImage}
							className={styles.shimmer}
							alt={title ?? ''}
						/>
					</div>
					<span className={styles.edit}>
						<FontAwesome icon="pen" size="xl" />
					</span>
				</div>
				<div className="flex-grow-1">
					<h3 className="h5 lh-sm fw-400 text-break">{title}</h3>
					<div className="d-flex align-items-center flex-wrap gap-3 mb-3">
						{type === 'communityPartner' && (
							<ProfileDecorationCommunityPartnerBadge
								profile={profile}
							/>
						)}
						{type === 'businessPartner' && profile?.publishInfo && (
							<ProfileDecorationBusinessPartnerBadge
								profile={profile}
							/>
						)}
						{profile?.webSite && (
							<a
								href={profile?.webSite}
								target="_blank"
								rel="noreferrer"
								className="fw-300 text-gray-3">
								{profile?.webSite}
							</a>
						)}
					</div>
					{address && (
						<div className="text-gray-3 fw-300 fs-sm d-flex lh-base mb-3">
							<FontAwesome
								icon="location-dot"
								size="1x"
								className="me-2"
							/>
							{formatLocalAddress(
								address?.street,
								address?.houseNumber,
								address?.postalCode,
								address?.city,
								address?.state
							)}
						</div>
					)}
					<div>
						<span className='text-gray-3 fw-300 fs-sm d-flex lh-base mb-3'>{`${t("card.region-code")}: ${profile?.regionCode}XXX`}</span>
					</div>
				</div>
			</div>
			<div className={pageStyles.tab}>
				{tabs?.map((tab) => {
					return (
						<TabNavigation
							key={tab.id}
							to={tab.path}
							title={tab.title}
							isActive={tab.isActive}
						/>
					);
				})}
			</div>

			<ModalBoxChangeImage
				name="logo"
				isOpen={openModalLogo}
				title="title.change-logo"
				image={logo}
				defaultImage={defaultImage}
				onRemove={onResetLogo}
				onClose={toggleModalLogo}
				onImageChange={onChangeLogo}
			/>
			{shouldRenderModalDelete && (
				<ModalBoxDeleteAccount
					isOpen={openModalDelete}
					onClose={toggleModalDelete}
				/>
			)}
		</Card>
	);
};
