import { Address } from "./GeoLocation";

export enum AssetToolType {
	poster = 1,
	sticker = 2,
	flyer = 3,
	coaster = 4,
	socialMedia = 5
}

export enum AssetUseFor {
	agent = 1,
	company = 2
}

export enum AssetExportType {
	pdf = 1,
	jpg = 2,
	png = 3
}

export enum AssetConfigType {
	sizeElement = 'sizeElement',
	textColorElement = 'textColorElement',
	textBgColorElement = 'textBgColorElement',
	headlineElement = 'headlineElement',
	imageElement = 'imageElement',
	qrCodeElement = 'qrCodeElement',
	nameElement = 'nameElement',
	emailElement = 'emailElement',
	phoneElement = 'phoneElement',
	simpleLogoElement = 'simpleLogoElement'
}

export type AssetConfigInputType =
	| 'nameElement'
	| 'emailElement'
	| 'phoneElement'
	| 'qrCodeElement';

export interface MarketingState {
	selectedAsset: MarketingAsset | null;
	selectedTemplate: MarketingAssetConfig | null;
	errors: Record<AssetConfigType, any>;
	hasErrors: boolean;
	showLogo: boolean;
	originalLogo: string | null;
	assetConfig: {
		sizeElement: SizeElement | null;
		textColorElement: ColorElement | null;
		textBgColorElement: ColorElement | null;
		headlineElement: HeadlineElement | null;
		imageElement: ImageElement | null;
		qrCodeElement: string | null;
		nameElement: string | null;
		address?: Address;
		regionCode?: number;
		partnerType?: number;
		partnerStatus?: number;
		emailElement: string | null;
		phoneElement: string | null;
		simpleLogoElement: string | null;

	};
}

export interface MarketingAsset {
	id: AssetToolType;
	title: string;
	icon: string;
	toolType: AssetToolType;
	// isActive: boolean;
}
export interface MarketingAssetFormData {
	id: string;
	toolType: AssetToolType;
	validExportableType: AssetExportType;
	emailElementInputItem: InputTextElement | null;
	headlineElementInputItem: HeadlineElement | null;
	imageElementInputItem: ImageElement | null;
	nameElementInputItem: InputTextElement | null;
	phoneElementInputItem: InputTextElement | null;
	qrCodeElementInputItem: InputTextElement | null;
	simpleLogoElementInputItem: LogoElement | null;
	sizeElementInputItem: SizeElement | null;
	textColorElementInputItem: ColorElement | null;
	textBgColorElementInputItem: ColorElement | null;
}
export interface MarketingAssetConfig {
	id: string;
	title: string;
	materialTitle: string;
	materialDescription: string;
	orderView: number;
	imageUrl: string;
	toolType: AssetToolType;
	useFor: AssetUseFor;
	validExportableTypes: AssetExportType[];
	emailElement: AssetConfigElement<InputTextElement>;
	headlineElement: AssetConfigElement<HeadlineElement>;
	imageElement: AssetConfigElement<ImageElement>;
	nameElement: AssetConfigElement<InputTextElement>;
	phoneElement: AssetConfigElement<InputTextElement>;
	qrCodeElement: AssetConfigElement<InputTextElement>;
	simpleLogoElement: AssetConfigElement<LogoElement>;
	sizeElement: AssetConfigElement<SizeElement>;
	textColorElement: AssetConfigElement<ColorElement>;
	textBgColorElement: AssetConfigElement<ColorElement>;
}

export interface AssetConfig {
	emailElement: AssetConfigElement<InputTextElement>;
	headlineElement: AssetConfigElement<HeadlineElement>;
	imageElement: AssetConfigElement<ImageElement>;
	nameElement: AssetConfigElement<InputTextElement>;
	phoneElement: AssetConfigElement<InputTextElement>;
	qrCodeElement: AssetConfigElement<InputTextElement>;
	simpleLogoElement: AssetConfigElement<LogoElement>;
	sizeElement: AssetConfigElement<SizeElement>;
	textColorElement: AssetConfigElement<ColorElement>;
	textBgColorElement: AssetConfigElement<ColorElement>;
}

export interface AssetConfigElement<T> {
	title: string;
	elementType: string;
	inputType: string;
	isRequired: boolean;
	haveItems: boolean;
	inputItems: T[];
}

export interface HeadlineElement {
	label: string;
	value: string | number;
	isDefault: boolean;
}

export interface ImageElement {
	thumbnail: string;
	original: string;
	isDefault: boolean;
}

export interface SizeElement {
	label: string;
	value: string;
	width: number;
	height: number;
	unit: string;
	rotation: boolean;
	isDefault: boolean;
}

export interface ColorElement {
	name: string;
	hex: string;
	isDefault: boolean;
}

export interface InputTextElement {
	label: string;
	value: string;
	isDefault: boolean;
	isReplacementable: boolean;
}
export interface LogoElement extends InputTextElement {
	isUsedAnotherRequest: boolean;
}

// export interface QrCodeElement {
// 	label: string;
// 	value: string;
// 	isDefault: boolean;
// 	isReplacementable: boolean;
// }

// export interface NameElement {
// 	label: string;
// 	value: string;
// 	isDefault: boolean;
// 	isReplacementable: boolean;
// }

// export interface PhoneElement {
// 	label: string;
// 	value: string;
// 	isDefault: boolean;
// 	isReplacementable: boolean;
// }
// export interface EmailElement {
// 	label: string;
// 	value: string;
// 	isDefault: boolean;
// 	isReplacementable: boolean;
// }
