import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useResponsive } from 'Hooks';
import { ColorValues, Colors, Job } from 'Types';
import { Badge, Button, FontAwesome } from 'Elements';
import { clx, formatLocaleDateTime, intlDateFormat } from 'Utils';
import styles from 'partials/shared/dropdown/job-info-dropdown.module.scss';

interface Props {
	job: Job;
}

export const JobInfoDropDown: FC<Props> = memo(({ job }) => {
	const { t } = useTranslation();
	const { isMobileAndBelow } = useResponsive();

	const isPublished = !!job.publishedVersion;
	const getBadgeData = (job: Job) => {
		let badgeColor = Colors.yellow;
		let badgeLabel = 'draft';

		if (!job) {
			return { color: badgeColor, label: t(badgeLabel) };
		}

		const { jobStatus, isExpired, publishedVersion } = job;

		if (jobStatus.statusTypes === 0) {
			badgeLabel = 'badge.draft';
		} else if (jobStatus.statusTypes === 2) {
			badgeLabel = 'badge.unPublished';
			badgeColor = Colors.muted;
		}

		if (isExpired) {
			badgeLabel = 'badge.expired';
			badgeColor = Colors.muted;
		}

		if (publishedVersion) {
			badgeLabel = 'badge.published';
			badgeColor = Colors.green;
		}

		return { color: badgeColor, label: t(badgeLabel) };
	};

	return (
		<UncontrolledDropdown>
			<DropdownToggle tag="div" role="button">
				<Button
					title={t('title.job.job-info')}
					size={isMobileAndBelow ? 'sm' : 'md'}
					color={Colors['white-gray']}
					className="gap-2">
					<span
						className={clx(
							'd-flex align-items-center flex-grow-1 gap-2',
							!isMobileAndBelow && 'me-3'
						)}>
						<FontAwesome icon="info-circle" size="lg" />
						{!isMobileAndBelow && t('title.job.job-info')}
					</span>
					<FontAwesome
						icon="chevron-down"
						swapOpacity
						size="sm"
						className="ms-auto"
						color={ColorValues['gray-3']}
					/>
				</Button>
			</DropdownToggle>
			<DropdownMenu className={styles.menu} end>
				<div className="d-flex align-items-center lh-base justify-content-between">
					<span className="text-gray-3 fs-6">
						{t('title.job.job-info')}
					</span>
					<Badge color={getBadgeData(job).color}>
						{getBadgeData(job).label}
					</Badge>
				</div>
				<div className="d-flex align-items-center lh-base justify-content-between pt-2 mt-2 gap-2">
					<span className="text-gray-3 d-flex align-items-center gap-2 lh-1">
						<FontAwesome icon="code-branch" size="lg"  />
						{t('card.current-version')}:
					</span>
					<span className="text-gray-5 text-end">
						{job.currentVersion ?? '...'}
					</span>
				</div>
				{isPublished && (
					<div className="d-flex align-items-center lh-base justify-content-between pt-2 mt-2 border-top border-gray-1 gap-2">
						<span className="text-gray-3 fw-400 d-flex align-items-center gap-2 lh-1">
							<FontAwesome
								icon="code-branch"
								size="lg"
								
							/>
							{t('card.published-version')}:
						</span>
						<span className="text-gray-5 text-end">
							{job.publishedVersion ?? '...'}
						</span>
					</div>
				)}
				{isPublished && (
					<div className="d-flex align-items-center lh-base justify-content-between pt-2 mt-2 border-top border-gray-1 gap-2">
						<span className="text-gray-3 d-flex align-items-center gap-2 lh-1">
							<FontAwesome
								icon="calendar-days"
								size="lg"
								
							/>
							{t('card.published-date')}:
						</span>
						<span className="text-gray-5 text-end">
							{job?.publishedAt
								? formatLocaleDateTime(job?.publishedAt)
								: '...'}
						</span>
					</div>
				)}
				{job.modifiedAt && (
					<div className="d-flex align-items-center lh-base justify-content-between pt-2 mt-2 border-top border-gray-1 gap-2">
						<span className="d-flex align-items-center text-gray-3 gap-2 lh-1">
							<FontAwesome
								icon="calendar-days"
								size="lg"
								
							/>
							{job.jobStatus?.statusTypes === 0
								? t('card.created-date')
								: t('card.last-update')}
							:
						</span>
						<span className="text-gray-5 text-end">
							{intlDateFormat(job.modifiedAt, {
								day: 'numeric',
								month: 'short',
								year: 'numeric',
								hour: 'numeric',
								minute: 'numeric'
							}) || '...'}
						</span>
					</div>
				)}
				{job?.isAutoPublish && (
					<div className="d-flex align-items-center lh-base justify-content-between pt-2 mt-2 border-top border-gray-1 gap-2">
						<span className="d-flex align-items-center gap-2 text-gray-3 lh-sm">
							<FontAwesome icon="timer" size="lg"  />
							{t('text.auto-publish-on')}:
						</span>
						<span className="text-gray-5 text-end">
							{formatLocaleDateTime(job.startDate, false)}
						</span>
					</div>
				)}
				<div className="d-flex align-items-center lh-base justify-content-between pt-2 mt-2 border-top border-gray-1 gap-2">
					<span className="d-flex align-items-center gap-2 text-gray-3 lh-1">
						<FontAwesome icon="user" size="lg"  />
						{t('card.by')}:
					</span>
					<span className="text-gray-5 text-end">
						{job?.jobStatus.userName ?? '...'}
					</span>
				</div>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
});

JobInfoDropDown.displayName = 'JobInfoDropDown';
