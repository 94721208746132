import { memo, FC, useRef, ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';
import styles from 'partials/feature/file-uploader.module.scss';

interface Props {
	onUpload: (data: FormData) => Promise<void>;
	progress: number;
	nameKey?: string;
}

export const FileUploader: FC<Props> = memo(
	({ progress, nameKey="formFile", onUpload }) => {
		const { t } = useTranslation();
		const fileInput = useRef<HTMLInputElement>(null);

		const onClick = () => {
			fileInput.current?.click();
		};

		const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
			const formData = new FormData();
			const { files } = e.target;
			if (files && files[0]) {
				formData.append(nameKey, files[0]);
			}
			try {
				await onUpload(formData);
			} finally {
				e.target.value = '';
			}
		};

		const progressWidth = useMemo(() => {
			return {
				width: `${progress}%`
			};
		}, [progress]);

		return (
			<div
				role="button"
				className={clx(styles.box, 'gap-3 w-100 p-4')}
				onClick={onClick}>
				<div
					className={clx(styles.progress, progress === 0 && 'd-none')}
					style={progressWidth}>
					<span
						className={clx(
							styles.progressValue,
							'text-primary fs-small fw-500'
						)}>
						{progressWidth.width}
					</span>
				</div>
				<input
					ref={fileInput}
					type="file"
					className="d-none"
					onChange={onChange}
				/>
				<FontAwesome icon="upload" size="2xl"  />
				<span className="lh-md fs-small">
					{t('button.upload-new-file')}
				</span>
			</div>
		);
	}
);

FileUploader.displayName = 'FileUploader';
