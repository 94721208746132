
import { FC, useEffect, useState } from 'react';
import { setSubscriptionPlan } from 'Actions';
import { Colors, SubscriptionCommunityPartner, SubscriptionPlan } from 'Types';
import { useAxios, useDispatch } from 'Hooks';
import {
	FontAwesome,
	NoData,
	Button,
	StepNavigation,
	SubscriptionPlanCard,
	SubscriptionCommunityPartnerCard,
	LoadingSpinner
} from 'Elements';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from 'partials/step/step-container.module.scss';
import { useTranslation } from 'react-i18next';
import { SwiperSlide, Swiper } from 'swiper/react';
import '../../../../../../assets/css/plan-sub.css';

// import required modules
import { Navigation } from 'swiper/modules';
import { api } from 'src/helpers/API';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clx } from 'src/helpers/Utils';

interface Props {
	fields: SubscriptionPlan | null;
	selectedCommunityPartner?: SubscriptionCommunityPartner | null;
	onPrev: () => void;
	onNext: () => void;
	goToStep: (stepId: string) => void;
}

export const CreateSubscriptionPlan: FC<Props> = ({
	fields,
	selectedCommunityPartner,
	onPrev,
	onNext,
	goToStep
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { sendRequest } = useAxios<any>();
	const [loading, setLoading] = useState(true);
	const [loadingPlansFeature, setLoadingPlansFeature] = useState(false);
	const [selectedCP, setSelectedCP] =
		useState<SubscriptionCommunityPartner>();
	const [searchParams] = useSearchParams();
	const communityPartnerId = searchParams.get('communityPartnerId') || '';
	const handleSelectPlan = (plan: SubscriptionPlan) => {
		dispatch(setSubscriptionPlan(plan));
		onNext();
		navigate(
			`/subscriptions/add?step=step3&communityPartnerId=${selectedCP?.id}&planId=${plan?.id}`
		);
	};

	const navigationUrl = '';
	// `${config.publicUrl}/agent/${agent.agentId}/${agent.slugUrl}`;

	const breakpoints = {
		640: {
			slidesPerView: 1,
			spaceBetween: 20
		},
		768: {
			slidesPerView: 1,
			spaceBetween: 40
		},
		900: {
			slidesPerView: 2,
			spaceBetween: 50
		}
	};

	const getCommunityPartner = () => {
		sendRequest(
			api.getCommunityPartner(communityPartnerId),
			{ method: 'GET' },
			(data) => {
				getPlanData(data);
			}
		);
	};

	const getPlanData = (communityPartner) => {
		const planData: any = [];
		if (communityPartner) {
			sendRequest(
				api.getPlansCommunityPartner(communityPartner?.id),
				{ method: 'GET' },
				(data) => {
					const urls = data?.map((plan: any) =>
						sendRequest(
							api.getSubscriptionPlansFeature(
								plan?.subscriptionPlanFeatureId,
								plan?.id
							),
							{ method: 'GET' },
							(featureData) =>
								planData.push({ featureData, ...plan })
						)
					);
					Promise.all(urls).finally(() => {
						setSelectedCP({ ...communityPartner, planData });
						setLoadingPlansFeature(false);
					});
					setLoading(false);
				}
			);
		}
	};

	useEffect(() => {
		if (!selectedCommunityPartner) {
			getCommunityPartner();
		} else {
			getPlanData(selectedCommunityPartner);
		}
	}, [selectedCommunityPartner]);


	return (
		<div className={styles.form}>
			<div className="d-flex align-items-center justify-content-center">
				<h2 className="fs-6 fw-500">{t('title.selected-agent')}</h2>
			</div>
			<div className="position-relative">
				<SubscriptionCommunityPartnerCard
					key={selectedCP?.id}
					planDataLoading={loading}
					communityPartner={
						selectedCP as SubscriptionCommunityPartner
					}
					selected={false}
				/>
				<Button
					color={Colors['white-primary']}
					className={styles.edit}
					onClick={() => {
						navigate(`/subscriptions/add?step=step1`);
						goToStep('step1');
					}}>
					<FontAwesome icon="edit" />
					{t('text.change')}
				</Button>
			</div>
			<div className="text-center gap-2 mt-4">
				<h3 className="fw-500 fs-base text-gray-4">
					{t('text.technikerschcule-grundig-akademie-plans')}
				</h3>
				<p className="fs-small fw-400 text-gray-4">
					{t('text.description-choose-plan')}
				</p>
			</div>
			{!loading && (
				<div className={styles.boxSwiper}>
					{selectedCP?.planData && selectedCP?.planData?.length > 0 && (
						<div>
							<Swiper
								breakpoints={breakpoints}
								navigation={true}
								modules={[Navigation]}
								className={styles.mySwiper}>
								{selectedCP?.planData?.map((plan) => (
									<SwiperSlide
										key={plan.id}
										className={clx(
											styles.swiperSlide,
											fields?.id === plan.id &&
												styles.selected
										)}>
										<SubscriptionPlanCard
											key={plan.id}
											card={plan}
											loadingPlansFeature={
												loadingPlansFeature
											}
											selected={fields?.id === plan.id}
											onSelect={handleSelectPlan}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					)}
				</div>
			)}
			{!loading && selectedCP?.planData?.length === 0 && (
				<NoData message="title.no-subscription-plan" />
			)}
			{loading && <LoadingSpinner />}
			<h2 className="fw-500 fs-6 mt-4 text-gray-4">
				{t('text.upgrade-rules')}:
			</h2>
			<p className="fw-400 fs-small mb-2 text-gray-4">
				{t('text.can-upgraded-any-time')}
			</p>
			<p className="fw-400 fs-small mb-4 text-gray-4">
				{t('text.description-upgrade-rules')}
			</p>
			<h2 className="fw-500 fs-6 text-gray-4">
				{t('text.upgrade-and-switch-CP')}
			</h2>
			<p className="fw-400 fs-small text-gray-4">
				{t('text.description-upgrade-and-switch-CP')}
			</p>
			<p className="fw-500 fs-small text-gray-4">
				{t('text.description-upgrade-and-switch-CP-2')}
			</p>
			<StepNavigation onPrev={onPrev} showNext={false} />
		</div>
	);
};
