import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { clx } from 'Utils';
import { Colors } from 'Types';
import { toggleSidebar } from 'Actions';
import {
	Button,
	IconLogo,
	LocaleDropDown,
	UserDropDown,
	FontAwesome
} from 'Elements';
import { useDispatch, useResponsive, useSelector } from 'Hooks';
import classes from 'partials/shared/header.module.scss';

export const Header = (props) => {
	const dispatch = useDispatch();
	const sidebar = useSelector((state) => state.sidebar);
	const { isDesktopAndBelow } = useResponsive();

	const defaultImage = '/assets/img/business-partner-default.jpg';

	const profileLogoUrl =
		props.userType === 'businessPartner'
			? props.businessPartnerMe?.companySetting?.logo
			: props.communityPartnerMe?.agentSetting?.logo;

	const profileLogoAlt =
		props.userType === 'businessPartner'
			? props.businessPartnerMe?.businessName
			: props.communityPartnerMe?.businessName;

	const onToggleSidebar = () => {
		dispatch(toggleSidebar(!sidebar.isOpen));
	};

	return (
		<header className={classes.header}>
			<Link className={clx(classes.logo, 'd-none d-lg-flex')} to="/">
				<IconLogo fill="#fff" className={classes.logo_image} />
			</Link>
			<div className={classes.navbar}>
				<Container fluid className="d-flex align-items-center">
					{isDesktopAndBelow && (
						<Button
							color={Colors.empty}
							className={clx(classes.trigger, 'p-0')}
							onClick={onToggleSidebar}>
							<FontAwesome
								icon={!sidebar.isOpen ? 'bars' : 'arrow-left'}
								size="lg"
							/>
						</Button>
					)}
					<Link to="/" className={classes.logo_profile}>
						<img
							src={profileLogoUrl || defaultImage}
							alt={profileLogoAlt}
							title={profileLogoAlt}
							className={classes.logo_profile_image}
						/>
					</Link>
					<div className="d-flex align-items-center gap-4 ms-auto">
						<LocaleDropDown />
						<UserDropDown />
					</div>
				</Container>
			</div>
		</header>
	);
};
