import { PayloadAction } from '@reduxjs/toolkit';
import {
	SubscriptionAgentSetting,
	SubscriptionPlan,
	SubscriptionSate,
	Voucher,
	SubscriptionCommunityPartner,
	SubscriptionPrimaryPlan
} from 'Types';
import { SubscriptionState } from './SubscriptionSlice';

const reducers = {
	setSubscriptionAgent(
		state: SubscriptionSate,
		action: PayloadAction<SubscriptionAgentSetting>
	) {
		const { payload } = action;
		state.selectedAgent = payload;
	},
	setSubscriptionCommunityPartner(
		state: SubscriptionSate,
		action: PayloadAction<SubscriptionCommunityPartner>
	) {
		const { payload } = action;
		state.selectedCommunityPartner = payload;
	},
	setSubscriptionPlan(
		state: SubscriptionSate,
		action: PayloadAction<SubscriptionPlan>
	) {
		const { payload } = action;
		state.selectedPlan = payload;
	},
	setSubscriptionPrimaryPlan(
		state: SubscriptionSate,
		action: PayloadAction<SubscriptionPrimaryPlan>
	) {
		const { payload } = action;
		state.selectedPrimaryPlan = payload;
	},
	setSubscriptionVoucher(
		state: SubscriptionSate,
		action: PayloadAction<Voucher>
	) {
		const { payload } = action;
		state.selectedVoucher = payload;
	},
	resetSubscription: () => SubscriptionState
};

export default reducers;
