import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FontAwesome, LoadingSpinner } from 'Elements';
import { currencyFormatter } from 'Utils';
import { Colors, SubscriptionPlan } from 'Types';
// import styles from 'partials/card/subscription-plan-card.module.scss';

interface Props {
	card: SubscriptionPlan;
	selected: boolean;
	loadingPlansFeature: boolean;
	onSelect: (plan: SubscriptionPlan) => void;
}

export const SubscriptionPlanCard: FC<Props> = ({
	card,
	loadingPlansFeature,
	onSelect
}) => {
	const { t } = useTranslation();

	const handleSelectPlan = () => {
		onSelect(card);
	};

	return (
		<>
			<div>
				<h1 className="h4 fs-5 text-gray-4">{card.name}</h1>
				<h4 className="fw-500 fs-5">{currencyFormatter(card.price)}</h4>
				<span className="text-gray-3 fw-500 ">
					{`${t('title.per')} ${card.durationDays} ${t(
						'title.days'
					)}`}
				</span>
				<div
					className="pt-2 lh-base"
					dangerouslySetInnerHTML={{
						__html: card.shortDescription
					}}
				/>
				<div
					className="lh-base"
					dangerouslySetInnerHTML={{
						__html: card.description
					}}
				/>
				{loadingPlansFeature && <LoadingSpinner />}
				{!loadingPlansFeature && card?.featureData && (
					<div className="px-2">
						{card?.featureData?.eventSetting?.eventsEnabled && (
							<div className="d-flex align-items-center gap-2 mb-3">
								<FontAwesome
									icon="square-check"
									size="lg"
									className="flex-shrink-0 text-secondary"
								/>
								<div>{t('text.feature.events-enabled')}:</div>
								<div>
									{card?.featureData?.eventSetting
										?.eventsEnabled
										? t('label.yes')
										: t('label.no')}
								</div>
							</div>
						)}

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.max-publish-events')}:</div>
							<div>
								{card?.featureData?.eventSetting
									?.maxPublishEvents ?? 0}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.publish-events-enabled')}:</div>
							<div>
								{card?.featureData?.eventSetting
									?.publishEventsEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.internship-enabled')}:</div>
							<div>
								{card?.featureData?.internshipSetting
									?.internshipEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.internship-manager-enabled')}:</div>
							<div>
								{card?.featureData?.internshipSetting
									?.internshipManagerEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.max-publish-internships')}:</div>
							<div>
								{card?.featureData?.internshipSetting
									?.maxPublishInternships ?? 0}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.publish-internship-enabled')}:</div>
							<div>
								{card?.featureData?.internshipSetting
									?.publishInternshipEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.job-enabled')}:</div>
							<div>
								{card?.featureData?.jobSetting?.jobEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.jobs-google-index')}:</div>
							<div>
								{card?.featureData?.jobSetting?.jobsGoogleIndex
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.limit-job-visibility')}:</div>
							<div>
								{card?.featureData?.jobSetting
									?.limitJobVisibility
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.max-publish-jobs')}:</div>
							<div>
								{card?.featureData?.jobSetting
									?.maxPublishJobs ?? 0}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.publish-job-enabled')}:</div>
							<div>
								{card?.featureData?.jobSetting
									?.publishJobEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.max-user-accounts')}:</div>
							<div>
								{card?.featureData?.userAccountSetting
									?.maxUserAccounts ?? 0}
							</div>
						</div>
					</div>
				)}
			</div>
			<div>
				<Button
					color={Colors.primary}
					className="w-100"
					onClick={handleSelectPlan}>
					{t('button.select')}
				</Button>
			</div>
		</>
	);
};
