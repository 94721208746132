import styled from 'styled-components';
import { t } from 'i18next';
import { darken } from 'polished';
import { Col, Row } from 'reactstrap';
import { Colors, Logo } from 'Types';
import { Button, ButtonProps, LoadingSpinner } from 'Elements';
import styles from 'partials/homepage/section/community-partner-business-partner-overview-section.module.scss';

type Props = {
	isLoadingProfile: boolean;
	becomePartner: {
		enabled: boolean;
		headline: string;
		description: string;
		image: Logo;
	};
};

const PartnerButton = styled((props: ButtonProps) => (
	<Button color={Colors.empty} size="lg" {...props} />
))`
	${({ theme }) =>
		theme &&
		`
		background-color: #fff !important;
		color: #000 !important;
		&:hover {
			color: #000;
			background-color: ${darken(0.07, '#fff')} !important;
		}
	`}
`;

export const CommunityPartnerPreviewCompanyOverviewStandard = ({
	becomePartner,
	isLoadingProfile
}: Props) => {
	return (
		<Row className="g-0">
			<Col md={7} xl={6} className="order-1 order-md-0">
				<div className="px-3 py-5 ps-lg-0 d-flex flex-column gap-3 justify-content-center h-100">
					<h3 className="text-white h3 mb-0">
						{becomePartner?.headline ?? ''}
					</h3>
					<p className="text-white mb-0 fs-base lh-md fw-300">
						{becomePartner?.description ?? ''}
					</p>
					<div className="d-flex align-items-center">
						<PartnerButton
							className="rounded-pill px-md-5"
							label={t('button.become-partner') as string}
						/>
					</div>
				</div>
			</Col>
			<Col md={5} xl={6}>
				<div role="button" className={styles.editable}>
					{isLoadingProfile && (
						<div className="d-flex align-items-center justify-content-center h-100">
							<LoadingSpinner />
						</div>
					)}
					{!isLoadingProfile && (
						<>
							<img
								src={
									becomePartner?.image?.url ||
									'/assets/img/default-image.jpg'
								}
								alt={becomePartner?.headline}
								className={styles.image}
							/>
						</>
					)}
				</div>
			</Col>
		</Row>
	);
};
