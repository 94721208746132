import { FC, memo } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'Elements';
import { clx, currencyFormatter } from 'Utils';
import { Colors, SubscriptionPrimaryPlan } from 'Types';
import styles from 'partials/card/subscription-plan-card.module.scss';

interface Props {
	card: SubscriptionPrimaryPlan;
	selected: boolean;
	onSelect: (plan: SubscriptionPrimaryPlan) => void;
}

export const SubscriptionPrimaryPlanCard: FC<Props> = memo(
	({ card, selected, onSelect }) => {
		const { t } = useTranslation();

		const handleSelectPlan = () => {
			onSelect(card);
		};

		return (
			<Card
				tag="article"
				className={clx('mt-4', selected && styles.selected)}>
				<Row>
					<h1 className="h4 fs-5 text-gray-4">{card.name}</h1>
					<h4 className="fw-500 fs-5">
						{currencyFormatter(card.price)}
					</h4>
					<span className="text-gray-3 fw-500 ">
						{`${t('title.per')} ${card.durationDays} ${t(
							'title.days'
						)}`}
					</span>
					<p className="mt-4 fs-small fw-400">
						{card.shortDescription}
					</p>
					<Button
						color={Colors.primary}
						className="w-lg-100 ms-auto"
						onClick={handleSelectPlan}>
						{t('button.select')}
					</Button>
				</Row>
			</Card>
		);
	}
);

SubscriptionPrimaryPlanCard.displayName = 'SubscriptionPrimaryPlanCard';
