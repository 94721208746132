import { memo, FC } from 'react';
import { clx } from 'Utils';
import { ColorValues } from 'Types';
import { FontAwesome } from 'Elements';
import styles from 'partials/decoration/featured-photo.module.scss';

interface Props {
	image?: {
		url: string;
		thumbnail: string;
	};
	width?: number;
	height?: number;
	className?: string;
	defaultImage?: string;
	onClick?: () => void;
}

export const FeaturePhoto: FC<Props> = memo(
	({
		image,
		className,
		defaultImage = '/assets/img/default-image.jpg',
		onClick
	}) => {
		return (
			<div
				role="button"
				tabIndex={-1}
				className={clx(styles.feature, className)}
				onClick={onClick}>
				<div className={styles.featureBox}>
					<img
						src={image?.url || defaultImage}
						alt=""
						className={styles.shimmer}
					/>
				</div>
				{onClick && (
					<span className={styles.edit}>
						<FontAwesome
							icon="pen"
							size="xl"
							color={ColorValues['gray-1']}
						/>
					</span>
				)}
			</div>
		);
	}
);

FeaturePhoto.displayName = 'FeaturePhoto';
