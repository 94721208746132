import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext, FieldErrors } from 'react-hook-form';
import { clx } from 'Utils';
import { socialList } from 'Data';
import { FontAwesome, SelectController } from 'Elements';

interface Props {
	className?: string;
	errors: FieldErrors;
}

export const SocialController: FC<Props> = ({ className, errors }) => {
	const { t } = useTranslation();
	const { control, register } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'socialNetwork'
	});

	const appendSocial = () => {
		append({
			name: '',
			url: ''
		});
	};

	const formatOptionLabel = ({
		label,
		icon
	}: {
		label: string;
		icon: any;
	}) => {
		return (
			<div className="d-flex align-items-center gap-2">
				<FontAwesome icon={icon} set="fab" size="lg"  />
				<div className="text-truncate text-capitalize">{label}</div>
			</div>
		);
	};

	return (
		<Fragment>
			{fields?.map((field, index) => {
				return (
					<div
						className={clx(
							'd-flex flex-column w-100 flex-sm-row mb-3',
							className
						)}
						key={field.id}>
						<SelectController
							control={control}
							name={`socialNetwork.${index}.name`}
							options={socialList}
							error={errors?.socialNetwork?.[index]?.name}
							className="flex-shrink-0 w-100 w-xs-35 me-sm-2 mb-2 mb-sm-0"
							formatOptionLabel={formatOptionLabel}
						/>
						<div className="d-flex flex-grow-1 flex-column w-100 w-xs-65">
							<div className="d-flex align-items-center flex-grow-1 gap-2">
								<input
									{...register(`socialNetwork.${index}.url`)}
									type="text"
									inputMode="url"
									aria-invalid={
										!!errors?.socialNetwork?.[index]?.url
									}
									placeholder={t('placeholder.url')}
									className="inputbox"
								/>
								<span
									className="d-flex align-items-center justify-content-center flex-shrink-0 p-1 text-red"
									role="button"
									tabIndex={0}
									onClick={() => remove(index)}>
									<FontAwesome icon="trash-xmark" size="lg" />
								</span>
							</div>
							{errors?.socialNetwork?.[index]?.url && (
								<div className="invalid-feedback d-block">
									{
										errors?.socialNetwork?.[index]?.url
											.message
									}
								</div>
							)}
						</div>
					</div>
				);
			})}
			<span
				role="button"
				tabIndex={0}
				className="text-primary d-flex align-items-center gap-2 lh-base"
				onClick={appendSocial}>
				<FontAwesome icon="plus-circle" size="lg"  />
				{t('forms.add-social-link')}
			</span>
		</Fragment>
	);
};

SocialController.displayName = 'SocialController';
