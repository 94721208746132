import { memo, FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'Utils';
import { useAxios } from 'Hooks';
import { Colors, Event, Media } from 'Types';
import { Button, FontAwesome } from 'Elements';
import styles from 'partials/decoration/featured-photo.module.scss';

interface Props {
	videoUrl: any;
	onUpload?: (videoUrl: any) => void;
	onRemove?: () => void;
}

export const FeatureVideo: FC<Props> = memo(
	({ videoUrl, onUpload, onRemove }) => {
		const { t } = useTranslation();
		const { sendRequest, isLoading } = useAxios<Media>();
		const imageFileInput = useRef<HTMLInputElement>(null);

		const onClickUpload = () => {
			if (!isLoading) imageFileInput?.current?.click();
		};

		const uploadVideo = async (file_data: FormData) => {
			await sendRequest(api.uploadMedia, { data: file_data }, (data) => {
				onUpload?.(data.url);
			});
		};

		const handleUploadVideo = async (e: Event<HTMLInputElement>) => {
			const formData = new FormData();
			const { files } = e.target;
			if (files && files[0]) {
				formData.append('formFile', files[0]);
			}
			try {
				await uploadVideo(formData);
			} finally {
				e.target.value = '';
			}
		};

		const handleRemoveVideo = () => {
			onRemove?.();
		};

		return (
			<div className={styles.videoWrapper}>
				{!videoUrl && (
					<div
						className={styles.videoUploader}
						onClick={onClickUpload}>
						<input
							ref={imageFileInput}
							type="file"
							multiple
							accept="video/mp4, video/webm"
							className="d-none"
							onChange={(event) => handleUploadVideo(event)}
						/>
						{!isLoading && (
							<FontAwesome
								icon="cloud-arrow-up"
								color={Colors['primary-light']}
								size="3x"
							/>
						)}
						{isLoading && (
							<FontAwesome
								spin
								spinPulse
								icon="spinner"
								size="3x"
							/>
						)}
						<p className="text-gray-3 mb-0 mt-3">
							{t('forms.video-accept-type')}
						</p>
					</div>
				)}
				{videoUrl && (
					<div className="d-flex justify-content-center flex-column">
						<video controls className={styles.video}>
							<source src={videoUrl} />
						</video>
						<div className="w-auto text-center">
							<Button
								color={Colors['white-red']}
								className="mt-3 gap-2"
								onClick={handleRemoveVideo}>
								<FontAwesome icon="trash-xmark" size="lg" />
								{t('button.remove')}
							</Button>
						</div>
					</div>
				)}
			</div>
		);
	}
);

FeatureVideo.displayName = 'FeatureVideo';
