import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useModal, useSelector, useUpdateJob } from 'Hooks';
import { ModalBoxPublishJob, SubmitButton } from 'Elements';
import {
	BusinessPartnerProfileStatus,
	CommunityPartnerProfileStatus,
	JobFormData,
	JobFormInitialData
} from 'Types';

interface Props {
	formData: (data: JobFormInitialData) => JobFormData;
}

export const SaveAndPublishJob: FC<Props> = ({ formData }) => {
	const { job_id } = useParams();
	const { assetConfig } = useSelector((state) => state.marketing);
	const [publishing, setPublishing] = useState(false);
	const [openModalPublish, toggleModalPublish] = useModal();
	const {
		saveAndPublishJob,
		data: job,
		refetch
	} = useUpdateJob(job_id as string);
	const {
		watch,
		trigger,
		getValues,
		formState: { isDirty }
	} = useFormContext<JobFormInitialData>();
	const watchAutoPublish = watch('hasStartDate');

	const handleClick = async () => {
		if (
			assetConfig?.partnerType === 2 &&
			assetConfig?.partnerStatus !== BusinessPartnerProfileStatus.Active
		) {
			toggleModalPublish();
			return false;
		}
		if (
			assetConfig?.partnerType === 1 &&
			assetConfig?.partnerStatus !== CommunityPartnerProfileStatus.Active
		) {
			toggleModalPublish();
			return false;
		}

		try {
			const isValidForm = await trigger();
			if (isValidForm) {
				setPublishing(true);
				if (!watchAutoPublish) {
					const values = getValues();
					await saveAndPublishJob(
						job_id as string,
						formData(values),
						() => {
							refetch();
							setPublishing(false);
						}
					);
				}
			}
		} finally {
			setPublishing(false);
		}
	};

	const checkDisabled = () => {
		let check = false;
		if (job?.publishedVersion !== job?.version) {
			check = false;
		}
		if (watchAutoPublish || job?.publishedVersion === job?.version) {
			check = true;
		}
		if (isDirty && !watchAutoPublish) {
			check = false;
		}

		return check;
	};

	return (
		<>
			<SubmitButton
				type="button"
				icon="upload"
				label="button.save-publish"
				savingLabel="button.publishing"
				isDisable={checkDisabled()}
				isSubmitting={publishing}
				onClick={handleClick}
			/>
			<ModalBoxPublishJob
				userType="businessPartner"
				refetch={refetch}
				type={'publish'}
				isOpen={openModalPublish}
				onClose={toggleModalPublish}
			/>
		</>
	);
};
