import { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Badge, Button, FontAwesome } from 'Elements';
import { names } from 'Constants';
import { useAxios, useResponsive } from 'Hooks';
import { Colors, PaymentStatus, Subscription, SubscriptionStatus } from 'Types';
import {
	agentPartnerApprovalStatus,
	api,
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	paymentsStatus
} from 'Utils';
import styles from 'partials/card/subscription-card.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
	subscription: Subscription;
	isLast?: boolean;
	subscriptionPayments?: any;
	isLoadingPayments?: boolean;
}

const Label = styled.label`
	@media (min-width: 1200px) {
		min-width: 60px;
	}
`;

export const SubscriptionCard: FC<Props> = ({
	subscription,
	isLast,
	subscriptionPayments,
	isLoadingPayments
}) => {
	const navigate = useNavigate();
	const { subscriptions } = names;
	const { sendRequest, isLoading } = useAxios<any>();
	const { t } = useTranslation();
	const { isMobileAndBelow } = useResponsive();
	const status = subscription?.status;
	const agent = subscription?.communityPartnerInfo;
	const navigateUrl = `${subscriptions?.path}/${subscription?.id}`;
	const defaultImage = '/assets/img/business-partner-default.jpg';

	const getStatusData = () => {
		return agentPartnerApprovalStatus.find((elem) => elem.value === status);
	};

	const getPaymentsStatusData = () => {
		return paymentsStatus.find((elem) => elem.value === status);
	};

	const cardClass = clx(
		styles.card,
		isLast && styles.last,
		(isLast || subscription?.subCompanySubscription?.length === 0) &&
			'mb-4',
		subscription?.subCompanySubscription && styles.switchedParent,
		subscription?.isSubCompanySubscription && styles.switched,
		status === SubscriptionStatus.active && styles.paid,
		status === SubscriptionStatus.expired && styles.expired,
		status === SubscriptionStatus.pendingApproval && styles.pending,
		status === SubscriptionStatus.pendingPayment && styles.pending,
		status === SubscriptionStatus.expired && styles.expired
	);

	const onClickPay = async (subscriptionId) => {
		navigate(
			`/subscriptions/add?step=step4&SubscriptionId=${subscriptionId}`
		);
		// await sendRequest(
		// 	api.subscriptionPrePay(currentSubscriptionPaymentId),
		// 	{},
		// 	(data: any) => {
		// 		window.location = data;
		// 	}
		// );
	};

	return (
		<>
			<article className={cardClass}>
				<Row className="position-relative">
					<Col xl={6}>
						<div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3 flex-wrap">
							{subscription?.isSubCompanySubscription && (
								<div
									className={clx(
										styles.col,
										'text-center text-sm-start'
									)}>
									<span className="ps-sm-4">
										<FontAwesome
											icon={
												isMobileAndBelow
													? 'arrow-down'
													: 'arrow-turn-down-right'
											}
											size="2x"
											className="flex-shrink-0"
										/>
									</span>
									<span className="fs-sm fw-400 color-gray-4">
										{t('label.switched-to')}
									</span>
									<div
										className={clx(
											styles.col,
											`text-${getStatusData()?.color}`
										)}>
										<span className="fs-base d-flex align-items-center">
											<span className="fw-500">
												{!isLoadingPayments ? (
													currencyFormatter(
														subscriptionPayments
															?.price?.totalAmount
															? subscriptionPayments
																	?.price
																	?.totalAmount
															: 0
													)
												) : (
													<div
														className={
															styles.skeletonLabel
														}></div>
												)}
											</span>
											<span className="text-gray-3 ms-2 fs-small">
												({t('label.tax-included')})
											</span>
										</span>
									</div>
								</div>
							)}
							{!subscription?.isSubCompanySubscription && (
								<div
									className={clx(
										styles.col,
										`text-${getStatusData()?.color}`
									)}>
									<span className="fs-base fw-500">
										{
											subscription?.subscriptionPlanInfo
												?.name
										}
									</span>
									<span className="fs-base d-flex align-items-center">
										<span className="fw-500">
											{!isLoadingPayments ? (
												currencyFormatter(
													subscriptionPayments?.price
														?.totalAmount
														? subscriptionPayments
																?.price
																?.totalAmount
														: 0
												)
											) : (
												<div
													className={
														styles.skeletonLabel
													}></div>
											)}
										</span>
										<span className="text-gray-3 ms-2 fs-small">
											({t('label.tax-included')})
										</span>
									</span>
								</div>
							)}
							<div
								className={clx(
									styles.col,
									'text-sm-center text-xl-start flex-row flex-sm-column'
								)}>
								<span className="fs-sm fw-400 color-gray-4">
									{status === SubscriptionStatus.active &&
										(subscription?.autoRenewal
											? t('title.renew-at')
											: t('title.expire-on'))}
									{(status ===
										SubscriptionStatus.pendingApproval ||
										status === SubscriptionStatus.expired ||
										status ===
											SubscriptionStatus.pendingPayment) &&
										t('title.expire-on')}
									{status === SubscriptionStatus.expired &&
										t('title.switch-on')}
									:
								</span>
								<span className="fs-sm fw-500 color-gray-4 ps-2 ps-sm-0">
									{status === SubscriptionStatus.active &&
										(subscription?.renewalDate
											? `${formatLocaleDateTime(
													subscription?.renewalDate,
													false
											  )} (${
													subscription?.paymentData
														?.price
														?.subscriptionDuration
											  } ${t('label.days')})`
											: `${formatLocaleDateTime(
													subscription?.endDate,
													false
											  )} (${
													subscription?.paymentData
														?.price
														?.subscriptionDuration
											  } ${t('label.days')})`)}
									{(status ===
										SubscriptionStatus.pendingApproval ||
										status === SubscriptionStatus.expired ||
										status ===
											SubscriptionStatus.pendingPayment) &&
										formatLocaleDateTime(
											subscription?.endDate,
											false
										)}
									{/* {status === SubscriptionStatus.expired &&
										formatLocaleDateTime(
											subscription?.purchaseDate ?? '',
											false
										)} */}
								</span>
							</div>
							<div className={styles.col}>
								<div className="fs-sm fw-400 color-gray-4 d-flex align-items-center justify-content-sm-end justify-content-xl-start">
									<Label>{t('label.status')}:</Label>
									<span
										className={clx(
											'fw-500 ps-2 flex-xl-grow-1',
											`text-${getStatusData()?.color}`
										)}>
										{t(`${getStatusData()?.label}`)}
									</span>
								</div>
								{subscriptionPayments && (
									<div className="fs-sm fw-400 color-gray-4 d-flex align-items-center justify-content-sm-end justify-content-xl-start">
										<Label>{t('label.payment')}:</Label>
										<span
											className={clx(
												'fw-500 ps-2 flex-xl-grow-1',
												`text-${
													getPaymentsStatusData()
														?.color
												}`
											)}>
											{t(
												`${
													getPaymentsStatusData()
														?.label
												}`
											)}
										</span>
									</div>
								)}
							</div>
						</div>
					</Col>
					<Col xl={6}>
						<div className="mt-4 mt-xl-0 d-flex align-items-center h-lg-100 flex-wrap gap-3 gap-sm-2">
							{agent && (
								<div className="d-flex align-items-center flex-grow-1 gap-2">
									<img
										src={agent.logo || defaultImage}
										alt={agent.organizationName}
										className={clx(
											styles.logo,
											styles.logoMd
										)}
									/>
									<span className="fs-sm fw-400">
										<div>{agent.organizationName}</div>
										{/* {agent?.commissionGroup?.IsPremium && (
											<Badge
												title={t(
													'title.commission-group.key',
													{
														commissionGroupName:
															agent
																?.commissionGroup
																.label
													}
												)}
												color={Colors.premium}
												className="gap-2">
												<FontAwesome
													icon={'medal'}
													size="lg"
												/>
												{agent?.commissionGroup.label}
											</Badge>
										)} */}
									</span>

									{/* <Badge
										color={
											getApprovalStatusData()?.color ??
											Colors.muted
										}>
										{t(`${getApprovalStatusData()?.label}`)}
									</Badge> */}
								</div>
							)}
							<div className="d-flex align-items-center gap-2">
								{subscription?.status === 3 &&
									(subscriptionPayments?.status ===
										PaymentStatus.Pending ||
										subscriptionPayments?.status ===
											PaymentStatus.Failed) && (
										<Button
											disabled={isLoading}
											onClick={() => {
												onClickPay(subscription?.id);
											}}
											size="sm"
											className="px-4"
											label={t('button.pay') as string}
										/>
									)}
								<Button
									as="a"
									to={navigateUrl}
									state={{
										prevPath: subscriptions.path
									}}
									size="sm"
									color={Colors['white-primary']}
									label={t('button.details') as string}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</article>
			{subscription?.subCompanySubscription &&
				subscription?.subCompanySubscription.map((sub, index) => (
					<SubscriptionCard
						key={sub.id}
						subscription={sub}
						isLast={
							index ===
							subscription?.subCompanySubscription.length - 1
						}
					/>
				))}
		</>
	);
};
