import { memo, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAxios } from 'Hooks';
import { api, toastify, currencyFormatter, clx } from 'Utils';
import { AgentProfile, Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	ShimmerImage,
	SubmitButton,
	Badge,
	FontAwesome
} from 'Elements';
import styles from 'partials/card/invite-business-partner-card.module.scss';

interface Props extends ModalProps {
	selectedAgent: AgentProfile;
	onSelectAgent: () => void;
}

export const ModalBoxSwitchAgent: FC<Props> = memo(
	({ isOpen, onClose, selectedAgent, onSelectAgent }) => {
		const { t } = useTranslation();
		const { subscription_id } = useParams();
		const { sendRequest } = useAxios();
		const navigate = useNavigate();
		const defaultLogo = '/assets/img/agent-default.jpg';

		const {
			register,
			handleSubmit,
			formState: { isSubmitting }
		} = useForm({
			defaultValues: {
				description: ''
			}
		});

		const submitHandler: SubmitHandler<{
			description: string;
		}> = async (data) => {
			const api_data = {
				...data,
				agentId: selectedAgent.id
			};
			await sendRequest(
				api.switchAgent(subscription_id as string),
				{
					data: api_data
				},
				() => {
					toastify('toastify.agent-switched', {
						type: 'success'
					});
					onClose();
					onSelectAgent();
					navigate(`/subscriptions`);

				}
			);
		};

		return (
			<ModalBox
				size='lg'
				isOpen={isOpen}
				onClose={onClose}
				title={t('title.confirm-agent')}>
				<div className="d-flex align-items-center mb-3">
					<div className={styles.logoBox}>
						<ShimmerImage
							src={selectedAgent.agentSetting.logo}
							width={50}
							height={50}
							alt={selectedAgent.agentName}
							defaultImage={defaultLogo}
							className={styles.logo}
						/>
					</div>
					<div className="flex-grow-1">
						<h2 className="fw-500 fs-sm lh-base mb-0 text-break">
							{selectedAgent.agentName}
						</h2>
						{selectedAgent.mainRegion?.name && (
							<div className="d-flex align-items-center text-gray-3 mt-1 fs-small">
								{selectedAgent.mainRegion.name}
							</div>
						)}
						{selectedAgent?.commissionGroup?.IsPremium && (
							<Badge
								title={t('title.commission-group.key', {
									commissionGroupName: selectedAgent?.commissionGroup.label
								})}
								color={Colors.premium}
								className="gap-2">
								<FontAwesome
									icon={'medal'}
									size="lg"
								/>
								{selectedAgent?.commissionGroup.label}
							</Badge>
						)}
					</div>
				</div>
				<div className={`${styles.boxPrice}`}>
					<div className="d-flex align-center lh-base justify-content-between mb-2">
						<span className={styles.rowLabel}>
							{t('label.price')}:
						</span>
						<span className="fs-6">
							{currencyFormatter(
								selectedAgent?.companySubscription?.subTotal ?? 0
							)}
						</span>
					</div>
					<div className="d-flex align-center lh-base justify-content-between border-bottom pb-4">
						<span className={styles.rowLabel}>
							{t('label.tax')}
							<span className="ms-1 text-gray-3 fs-sm">
								({selectedAgent?.companySubscription?.taxPercent ?? 0}%)
							</span>
							:
						</span>
						<span className="fs-6">
							{selectedAgent?.companySubscription?.taxAmount > 0
								? `+ ${currencyFormatter(selectedAgent?.companySubscription?.taxAmount)}`
								: currencyFormatter(0)}
						</span>
					</div>
					<div className="d-flex align-center lh-base justify-content-between pt-4">
						<span className={`${styles.rowLabel} fs-6 fw-700 text-gray-5 lh-base`}>
							{t('label.total')}
						</span>
						<span className="fs-6 fw-700 text-gray-5 lh-base">
							{currencyFormatter(
								selectedAgent?.companySubscription?.total ?? 0
							)}
						</span>
					</div>
				</div>
				<p className="lh-base">{t('text.switch-agent-warning')}</p>
				<form onSubmit={handleSubmit(submitHandler)}>
					<FormGroup>
						<Label>
							{t('forms.any-reason-to-switch-agent')}&nbsp;(
							{t('placeholder.optional')})
						</Label>
						<textarea
							{...register('description')}
							id="description"
							placeholder={t(
								'placeholder.switch-agent-explanation'
							)}
							className="inputbox w-100"
							cols={10}
							rows={5}
						/>
					</FormGroup>
					<ModalBoxFooter className="justify-content-between mt-3">
						<Button
							type="button"
							color={Colors['white-gray']}
							onClick={onClose}>
							{t('button.cancel')}
						</Button>
						<SubmitButton
							icon={false}
							isSubmitting={isSubmitting}
							label="button.choose-as-my-community-partner"
							savingLabel="button.switching-agent"
						/>
					</ModalBoxFooter>
				</form>
			</ModalBox>
		);
	}
);

ModalBoxSwitchAgent.displayName = 'ModalBoxSwitchAgent';
