import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx, toastify } from 'Utils';
import { useDelayUnmount, useModal } from 'Hooks';
import { Button, ModalBoxAddSocial, SocialItem, FontAwesome } from 'Elements';
import { ColorValues, Colors, SocialFields, SocialNetwork } from 'Types';
import styles from 'partials/homepage/social-item.module.scss';

interface Props {
	list: SocialNetwork[];
	className?: string;
	onRemoveSocial: (social: SocialNetwork) => void;
	onAddSocial: (data: SocialFields[]) => void;
}

export const SocialList: FC<Props> = ({
	list,
	className,
	onRemoveSocial,
	onAddSocial
}) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const checkSelectedSocial = (socialFields: SocialFields[]) => {
		return socialFields.find((elem) => {
			return list?.some(
				(item) => item.socialNetwork === elem?.name?.value
			);
		});
	};

	const handleAddSocial = (socialFields: SocialFields[]) => {
		const selectedSocial = checkSelectedSocial(socialFields);
		if (selectedSocial) {
			toastify(
				t('toastify.social-selected-before', {
					socialName: selectedSocial?.name?.label
				})
			);
		} else {
			onAddSocial(socialFields);
			toggleModal();
		}
	};
	return (
		<ul
			className={clx(
				'd-inline-flex align-items-center',
				styles.list,
				className
			)}>
			{list?.map((social, idx) => {
				return (
					<li
						key={`${social.socialNetwork}_${idx}_${social.socialNetworkUrl}`}>
						<SocialItem
							social={social}
							onRemove={() => onRemoveSocial(social)}
						/>
					</li>
				);
			})}
			<li>
				<Button
					color={Colors.empty}
					className={styles.add}
					onClick={toggleModal}>
					<FontAwesome
						swapOpacity
						icon="plus"
						size="lg"
						color={ColorValues.primary}
					/>
				</Button>
			</li>
			{shouldRenderModal && (
				<ModalBoxAddSocial
					isOpen={openModal}
					onClose={toggleModal}
					onSubmit={handleAddSocial}
				/>
			)}
		</ul>
	);
};
