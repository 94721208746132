import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	AgentApproveStatus,
	CommunityPartnerProfileFormData,
	Colors
} from 'Types';
import { Badge, FontAwesome } from 'Elements';
import { agentStatus, getFilterByValue } from 'Utils';
import { communityPartnerStatus } from 'src/helpers/FilterBy';

interface Props {
	profile: CommunityPartnerProfileFormData;
}

export const ProfileDecorationCommunityPartnerBadge: FC<Props> = ({
	profile
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Badge
				color={
					profile
						? getFilterByValue(
								communityPartnerStatus,
								profile?.status?.toString()
						  )?.color ?? Colors.primary
						: Colors.primary
				}>
				{profile
					? t(
							getFilterByValue(
								communityPartnerStatus,
								profile?.status?.toString()
							)?.label ?? ''
					  )
					: '...'}
			</Badge>
			{profile?.publishInfo?.status !== 0 && (
				<Badge
					color={
						profile?.publishInfo?.status === 1
							? Colors.green
							: Colors.yellow
					}>
					{profile?.publishInfo?.status === 1
						? t('badge.published')
						: t('badge.not-published')}
				</Badge>
			)}
		</>
	);
};
