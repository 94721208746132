import { Logo, Media } from 'Types';
import { useModal } from 'Hooks';
import {
	EditButton,
	LoadingContent,
	LoadingSpinner,
	ModalBoxChangeImage
} from 'Elements';
import styles from 'partials/homepage/section/profile-hero-image.module.scss';
import { clx } from 'src/helpers/Utils';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean | Logo
	) => void;
	header: {
		description: number;
		headerImage: Logo;
		headerTitle: string;
		logo: Logo;
		slogan: number;
		themeColor: number;
	};
};

export const BusinessPartnerHeroImage = ({
	isLoadingProfile,
	onChangeValue,
	isFetchingProfile,
	header
}: Props) => {
	const [openModal, toggleModal] = useModal();

	const handleResetLogo = () => {
		onChangeValue('header', 'headerImage', {
			url: '',
			thumbnail: ''
		} as Logo);
	};

	const handleUploadImage = (image_data: Partial<Media>) => {
		if (image_data) {
			onUpdate(image_data);
		}
	};

	const onUpdate = async (image_data: Partial<Media>) => {
		if (image_data) {
			onChangeValue('header', 'headerImage', {
				url: image_data?.url,
				thumbnail: image_data?.thumbnailUrl
			} as Logo);
		}
	};

	return !isLoadingProfile ? (
		<div
			className={clx(
				styles.headerImage,
				'px-3 px-md-4 pt-3 pt-md-4 pb-0'
			)}>
			<div
				className={styles.editable}
				role="button"
				onClick={toggleModal}>
				{isFetchingProfile && <LoadingContent />}
				{!isFetchingProfile && header && (
					<>
						<img
							src={
								(header?.headerImage?.url as string) ||
								'/assets/img/default-image.jpg'
							}
							alt={header?.headerTitle}
							className={styles.image}
						/>
						<div className="edit-overlay">
							<EditButton className="edit-overlay-icon" />
						</div>
					</>
				)}
			</div>
			<ModalBoxChangeImage
				name="companyHeaderLogo"
				isOpen={openModal}
				title="title.change-image"
				image={(header?.headerImage?.url as string) || ''}
				onClose={toggleModal}
				onRemove={handleResetLogo}
				onImageChange={handleUploadImage}
			/>
		</div>
	) : (
		<div className="d-flex align-items-center justify-content-center">
			<LoadingSpinner />
		</div>
	);
};
