import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { api, fetch, toastify } from 'Utils';
import { queryKey } from 'Constants';
import { DataList, DataQueryParams, Employee, Media } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: true },
		...options
	};
};

export const useEmployees = <T = DataList<Employee>>(
	{ pageSize = 12, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Employee>>
): UseQueryResult<DataList<Employee>, unknown> => {
	return useQuery({
		queryKey: [queryKey.employees, { pageSize, pageNumber, keyword }],
		queryFn: () => fetch.employeeList(pageSize, pageNumber, keyword),
		...config(options)
	});
};



export const useEmployee = <T = Employee>(
	employeeId: string,
	options?: UseQueryOptions<T, any, Employee>
): UseQueryResult<Employee, unknown> => {
	return useQuery({
		queryKey: [queryKey.employee, { id: employeeId }],
		queryFn: () => fetch.employeeDetails(employeeId),
		...config(options)
	});
};

export const useUpdateEmployee = (employeeId: string) => {
	const { refetch: refetchList } = useEmployees({});
	const query = useEmployee(employeeId);
	const employee = query.data;

	const updateEmployee: (
		fields: Partial<Employee>,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			...employee,
			...fields,
			isActive: true
		};
		await axios
			.put(api.updateEmployeeDetail(employeeId), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.employee-updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			});
	};

	const updateEmployeeImage: (
		fields: Partial<Media>,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			image: fields.url,
			thumbnailUrl: fields.thumbnailUrl
		};
		await axios
			.put(api.updateEmployeeImage(employeeId), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					onFinal?.();
				}
			});
	};

	const removeEmployee: (onFinal?: () => void) => Promise<void> = async (
		onFinal
	) => {
		await axios.delete(api.removeEmployee(employeeId)).then((res) => {
			if (res.status === 200) {
				onFinal?.();
				startTransition(() => {
					toastify('toastify.employee-removed', {
						type: 'success'
					});
					refetchList();
				});
			}
		});
	};

	return {
		...query,
		updateEmployee,
		updateEmployeeImage,
		removeEmployee,
		employee
	};
};
