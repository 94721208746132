import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { DataList, DataQueryParams, Region } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useRegion = <T = DataList<Region>>(
	{ pageSize = 2000, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Region>>
): UseQueryResult<DataList<Region>, unknown> => {
	return useQuery({
		queryKey: [queryKey.regions, { pageSize, pageNumber, keyword }],
		queryFn: () => fetch.regionsList(pageSize, pageNumber, keyword),
		...config(options)
	});
};

export const useAlternativeRegion = <T = Region>(
	regionId: string,
	options?: UseQueryOptions<T, any, Region>
): UseQueryResult<Region, unknown> => {
	return useQuery({
		queryKey: [queryKey.alternativeRegion, { regionId }],
		queryFn: () => fetch.alternativeRegionList(regionId),
		...config(options)
	});
};
