import {
	Colors,
	BadgeStatus,
	TransactionType,
	AgentApproveStatus,
	CompanyApproveStatus,
	TransactionStatus,
	AgentPartnerApprovalStatus,
	SubscriptionStatus,
	PaymentStatus,
	BusinessPartnerProfileStatus,
	CommunityPartnerProfileStatus,
	SubscriptionPaymentStatus
} from 'Types';

const dropdown_all = 'dropdown.all';

/**
 * Merges an array of BadgeStatus objects into a single array, combining objects with the same value.
 * @param array - The array of BadgeStatus objects to merge.
 * @returns The merged array of BadgeStatus objects.
 */
const mergeBadgeStatuses = (array: BadgeStatus[]) => {
	const map = new Map();
	for (const item of array) {
		const value = item.value;
		const existingItem = map.get(value);
		if (existingItem) {
			Object.assign(existingItem, item);
		} else {
			map.set(value, { ...item });
		}
	}
	return Array.from(map.values());
};

export const agentStatus: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: AgentApproveStatus.pending,
		label: 'badge.pending-approval',
		color: Colors.warning
	},
	{
		value: AgentApproveStatus.approved,
		label: 'badge.approved',
		color: Colors.primary
	},
	{
		value: AgentApproveStatus.rejected,
		label: 'badge.rejected',
		color: Colors.red
	},
	{
		value: AgentApproveStatus.canceled,
		label: 'badge.canceled',
		color: Colors.muted
	},
	{
		value: AgentApproveStatus.inactive,
		label: 'badge.removed',
		color: Colors.muted
	}
];

export const businessPartnerStatus: BadgeStatus[] = [
	{
		value: BusinessPartnerProfileStatus.Registration,
		label: 'badge.registration',
		color: Colors.warning
	},
	{
		value: BusinessPartnerProfileStatus.Active,
		label: 'badge.active',
		color: Colors.primary
	},
	{
		value: BusinessPartnerProfileStatus.Expired,
		label: 'badge.expired',
		color: Colors.red
	},
	{
		value: BusinessPartnerProfileStatus.NoSubscription,
		label: 'badge.no-subscription',
		color: Colors.muted
	}
];
export const communityPartnerStatus: BadgeStatus[] = [
	{
		value: CommunityPartnerProfileStatus.Registration,
		label: 'badge.registration',
		color: Colors.warning
	},
	{
		value: CommunityPartnerProfileStatus.Active,
		label: 'badge.active',
		color: Colors.primary
	},
	{
		value: CommunityPartnerProfileStatus.PendingApproval,
		label: 'badge.pending-approval',
		color: Colors.warning
	},
	{
		value: CommunityPartnerProfileStatus.Rejected,
		label: 'badge.rejected',
		color: Colors.red
	}
];

export const companyPaymentStatus: BadgeStatus[] = [
	{
		value: '',
		label: 'badge.active',
		title: 'active',
		color: Colors.primary
	},
	{
		value: PaymentStatus.Pending,
		label: 'badge.pending',
		title: 'pending',
		color: Colors.warning
	},
	{
		value: PaymentStatus.Failed,
		label: 'badge.failed',
		title: 'failed',
		color: Colors.red
	},
	{
		value: PaymentStatus.Paid,
		label: 'badge.paid',
		title: 'paid',
		color: Colors.primary
	}
];

export const agentPaymentStatus: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: TransactionStatus.Pending,
		label: 'badge.pending',
		title: 'pending',
		color: Colors.warning
	},
	{
		value: TransactionStatus.Paid,
		label: 'badge.paid',
		title: 'paid',
		color: Colors.success
	}
];

export const invoiceStatus: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: TransactionStatus.UnPaid,
		label: 'badge.unpaid',
		title: 'unpaid',
		color: Colors.muted
	},
	{
		value: TransactionStatus.Paid,
		label: 'badge.paid',
		title: 'paid',
		color: Colors.success
	},
	{
		value: TransactionStatus.Pending,
		label: 'badge.unpaid',
		title: 'pending',
		color: Colors.muted
	},
	{
		value: TransactionStatus.SepaPendingPayment,
		label: 'badge.SepaPendingPayment',
		title: 'processing',
		color: Colors.warning
	}
];

export const creditStatus: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: TransactionStatus.UnPaid,
		label: 'badge.unpaid',
		title: 'unpaid',
		color: Colors.muted
	},
	{
		value: TransactionStatus.Done,
		label: 'badge.done',
		title: 'done',
		color: Colors.primary
	}
	// {
	// 	value: TransactionStatus.Donated,
	// 	label: 'badge.donated',
	// 	title: 'donated',
	// 	color: Colors.tertiary
	// }
];

export const companySettlementStatus = mergeBadgeStatuses([
	...invoiceStatus,
	...companyPaymentStatus
]);

export const agentSettlementStatus = mergeBadgeStatuses([
	...creditStatus,
	...agentPaymentStatus
]);

export const transactionType: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: TransactionType.payment,
		label: 'label.payment',
		title: 'payment',
		color: Colors.primary
	},
	{
		value: TransactionType.credit,
		label: 'label.credit',
		title: 'credit',
		color: Colors.primary
	},
	{
		value: TransactionType.invoice,
		label: 'label.invoice',
		title: 'invoice',
		color: Colors.primary
	}
];

export const jobStatus: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: '0',
		label: 'dropdown.draft',
		title: 'draft',
		color: Colors.yellow
	},
	{
		value: '1',
		label: 'dropdown.published',
		title: 'published',
		color: Colors.success
	},
	{
		value: '2',
		label: 'dropdown.unPublished',
		title: 'unPublished',
		color: Colors.muted
	}
];

export const subStatus: BadgeStatus[] = [
	{
		value: SubscriptionStatus.active,
		label: 'dropdown.active',
		title: 'active',
		color: Colors.primary
	},
	{
		value: SubscriptionStatus.pendingApproval,
		label: 'dropdown.pending-approval',
		title: 'pending-approval',
		color: Colors.warning
	},
	{
		value: SubscriptionStatus.pendingPayment,
		label: 'dropdown.pending-payment',
		title: 'pending-payment',
		color: Colors.warning
	},
	{
		value: SubscriptionStatus.rejected,
		label: 'dropdown.manually-rejected',
		title: 'manually-rejected',
		color: Colors.red
	}
];

export const requestStatus: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: '0',
		label: 'dropdown.pending',
		title: 'pending',
		color: Colors.warning
	},
	{
		value: '1',
		label: 'dropdown.approved',
		title: 'approved',
		color: Colors.success
	},
	{
		value: '2',
		label: 'dropdown.rejected',
		title: 'rejected',
		color: Colors.error
	},
	{
		value: '3',
		label: 'dropdown.canceled-by-user',
		title: 'canceled',
		color: Colors.muted
	}
];

export const eventStatus: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: '0',
		label: 'dropdown.draft',
		title: 'draft',
		color: Colors.yellow
	},
	{
		value: '1',
		label: 'dropdown.published',
		title: 'published',
		color: Colors.success
	},
	{
		value: '2',
		label: 'dropdown.canceled',
		title: 'canceled',
		color: Colors.error
	},
	{
		value: '99',
		label: 'dropdown.finished',
		title: 'finished',
		color: Colors.muted
	}
];

export const participantStatus: BadgeStatus[] = [
	{
		value: '',
		label: dropdown_all,
		title: 'all',
		color: Colors.primary
	},
	{
		value: '0',
		label: 'dropdown.accepted',
		title: 'accepted',
		color: Colors.success
	},
	{
		value: '1',
		label: 'dropdown.canceled',
		title: 'canceled',
		color: Colors.muted
	}
];

export const agentPartnerApprovalStatus: BadgeStatus[] = [
	{
		value: SubscriptionStatus.active,
		label: 'dropdown.active',
		title: 'active',
		color: Colors.primary
	},
	{
		value: SubscriptionStatus.pendingApproval,
		label: 'dropdown.pending-approval',
		title: 'pending-approval',
		color: Colors.warning
	},
	{
		value: SubscriptionStatus.pendingPayment,
		label: 'dropdown.pending-payment',
		title: 'pending-payment',
		color: Colors.warning
	},
	{
		value: SubscriptionStatus.rejected,
		label: 'dropdown.manually-rejected',
		title: 'manually-rejected',
		color: Colors.red
	}
];

export const paymentsStatus: BadgeStatus[] = [
	{
		value: SubscriptionPaymentStatus.Paid,
		label: 'dropdown.paid',
		title: 'paid',
		color: Colors.primary
	},
	{
		value: SubscriptionPaymentStatus.InProcess,
		label: 'dropdown.in-process',
		title: 'in-process',
		color: Colors.warning
	},
	{
		value: SubscriptionPaymentStatus.Pending,
		label: 'dropdown.pending',
		title: 'pending',
		color: Colors.warning
	},
	{
		value: SubscriptionPaymentStatus.Failed,
		label: 'dropdown.failed',
		title: 'failed',
		color: Colors.red
	},
	{
		value: SubscriptionPaymentStatus.Canceled,
		label: 'dropdown.Canceled',
		title: 'Canceled',
		color: Colors.red
	}
];
