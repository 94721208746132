import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { UserCategory } from 'Types';

interface Props extends PropsWithChildren {
	role: UserCategory; // the role that is allowed to access this route
}

export const PrivateRoute: FC<Props> = ({ role, children }) => {
	const appRole = localStorage.getItem('userRole');

	return <>{appRole === role ? children : <Navigate to="/" />}</>;
};
