import {
	Subscription,
	CommunityPartnerBankAccount,
	Voucher,
	VoucherDiscountType,
	SubscriptionDetails
} from 'Types';

export enum TransactionType {
	'payment' = 0,
	'credit' = 1,
	'invoice' = 2,
	'sell' = 3
}

export enum TransactionStatus {
	Pending = 0,
	Paid = 1,
	UnPaid = 2,
	Done = 3,
	Failed = 4,
	Donated = 5,
	SepaPendingPayment = 6
}

export interface Transaction {
	id: string;
	transactionCode: string;
	customerCode: string;
	performAt: string;
	currency: string;
	amount: number;
	taxPercent: number;
	totalAmount: number;
	taxAmount: number;
	donate: boolean;
	downloadUrl: string;
	taxIncluded: boolean;
	type: TransactionType;
	status: TransactionStatus;
	createdAt: string;
	agent: TransactionAgent;
	company: TransactionCompany;
	productInfo: ProductInfo;
	payment: TransactionPayment;
}

export interface TransactionDetails {
	id: string;
	transactionCode: string;
	customerCode: string;
	performAt: string;
	currency: string;
	amount: number;
	taxPercent: number;
	total: number;
	totalAmount: number;
	taxAmount: number;
	discountAmount: number;
	discountType: VoucherDiscountType;
	donate: boolean;
	taxIncluded: boolean;
	discount: number;
	downloadUrl: string;
	subTotal: number;
	type: TransactionType;
	status: TransactionStatus;
	createdAt: string;
	typeId: string;
	tenantId: string;
	companySubscription: SubscriptionDetails;
	agent: TransactionCustomer;
	company: TransactionCustomer;
	relatedTransaction: string;
	relatedTransactionCode: string;
	productInfo: ProductInfo;
	payment: TransactionPayment;
	applyVoucher: Voucher;
	invoiceNumber: string;
}

export interface AgentTransactionDetails {
	id: string;
	transactionCode: string;
	customerCode: string;
	performAt: string;
	currency: string;
	taxNumber: string;
	smallCompany: boolean;
	taxIncluded: boolean;
	amount: number;
	taxPercent: number;
	total: number;
	totalAmount: number;
	taxAmount: number;
	donate: boolean;
	discountAmount: number;
	discount: number;
	subTotal: number;
	type: TransactionType;
	status: TransactionStatus;
	createdAt: string;
	typeId: string;
	tenantId: string;
	companySubscription: Subscription;
	agent: TransactionCustomer;
	company: TransactionCustomer;
	relatedTransaction: string;
	relatedTransactionCode: string;
	productInfo: ProductInfo;
	payment: TransactionPayment;
	applyVoucher: Voucher;
	bankAccount: CommunityPartnerBankAccount;
	creditNumber: string;
}

export interface TransactionCustomer {
	id: string;
	name: string;
	email: string;
	logo: string;
	type: string;
}

export interface TransactionAgent {
	agentId: string;
	name: string;
	agentEmail: string;
}

export interface TransactionCompany {
	companyId: string;
	name: string;
	companyEmail: string;
}

export interface TransactionPayment {
	id: string;
	paymentCode: string;
	payUrl: string;
	paymentProvider: string;
	currency: string;
	amount: number;
	status: string;
	createdAt: string;
	paymentMethod: string;
	returnUrl: string;
	codingSystemId: string;
}

export interface AgentPaymentTransaction {
	id: string;
	name: string;
	email: string;
	logo: string;
	branch: string;
	totalAmount: number;
	planTitle: string;
	purchaseDate: string;
	type: TransactionType;
	status: TransactionStatus;
	transactionId: string;
	transactionCode: string;
	invoiceNumber: string;
	taxIncluded: string;
	donate: boolean;
}

export interface ProductInfo {
	code: string;
	name: string;
	type: string;
	idNumber: string;
}
