import { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Box } from 'Styles';
import { useTheme } from 'Hooks';
import { Contact, SocialNetwork, Logo } from 'Types';
import {
	FontAwesome,
	IconGrid,
	LoadingSpinner,
	ShimmerImage,
	SocialItem
} from 'Elements';
import styles from 'partials/homepage/section/profile-contact-section.module.scss';
import { clx } from 'src/helpers/Utils';

interface Props {
	isLoading: boolean;
	contact?: Contact;
	social: SocialNetwork[];
	address?: string;
	type: 'communityPartner' | 'businessPartner';
}

export const PreviewProfileContactSection: FC<Props> = ({
	contact,
	social,
	address,
	isLoading,
	type
}) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const defaultImage = '/assets/img/profiles/img-user-default.png';

	return (
		<section className="position-relative mb-5 mt-6">
			<Container>
				{isLoading && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && contact && (
					<Row className="justify-content-center">
						<Col lg={8} xl={6}>
							<div className="d-flex flex-column gap-4">
								<div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-4">
									<Box
										$hasShadow={false}
										$bgColor={theme[type].primaryColor}
										className={styles.imageWrapper}>
										<ShimmerImage
											src={
												contact.contactImage ||
												defaultImage
											}
											defaultImage={defaultImage}
											alt={contact.fullName}
											width={160}
											height={160}
											className={styles.image}
										/>
									</Box>
									<div className="d-flex flex-column gap-2">
										<div className="d-flex flex-column gap-1">
											<h4 className="h4 lh-base mb-0">
												{contact?.fullName ?? ''}
											</h4>
											<div className="fs-base lh-base text-break">
												{contact?.position ?? ''}
											</div>
										</div>
										<hr className="bg-gray-3 my-0" />
										{contact?.email && (
											<div className="d-flex align-items-center gap-3 lh-lg">
												<FontAwesome
													icon="envelope"
													size="lg"
												/>
												{contact?.email ?? ''}
											</div>
										)}
										{contact?.tel && (
											<div className="d-flex align-items-center gap-3 lh-lg">
												<FontAwesome
													icon="phone"
													size="lg"
												/>
												{contact?.tel ?? ''}
											</div>
										)}
										{address && (
											<div className="d-flex align-items-center gap-3 lh-lg">
												<FontAwesome
													icon="location-dot"
													size="lg"
													swapOpacity
												/>
												{address ?? ''}
											</div>
										)}
									</div>
								</div>
								<div className="d-flex align-items-center gap-3">
									<h4 className="h3 mb-0 flex-shrink-0">
										{t('title.contact')}
									</h4>
									<IconGrid
										className="flex-grow-1"
										fill={theme[type].primaryColor}
									/>
									<div className="d-inline-flex align-items-center ms-auto flex-shrink-0">
										<ul
											className={clx(
												'd-inline-flex align-items-center',
												styles.list
											)}>
											{social?.map((social, idx) => {
												return (
													<li
														key={`${social.socialNetwork}_${idx}_${social.socialNetworkUrl}`}>
														<SocialItem
															social={social}
														/>
													</li>
												);
											})}
										</ul>
									</div>
								</div>
								<p>{contact?.contactDescription ?? ''}</p>
							</div>
						</Col>
					</Row>
				)}
			</Container>
		</section>
	);
};
