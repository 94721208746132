import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
	SectionHeadline,
	LoadingSpinner,
	ReactSwitch,
	ProfileAddNewCard,
	Button,
	FontAwesome,
	ModalBoxSectionEditProfile,
	Collapsible
} from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';
import { Colors } from 'Types';
import { useModal } from 'src/hooks/useModal';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	employee: { enabled: boolean; headline: string };
};
export const BusinessPartnerEmployeeSection = ({
	isLoadingProfile,
	onChangeValue,
	employee
}: Props) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${styles.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700">
										{' '}
										{t('menu.employees')}
									</h5>
									<ReactSwitch
										size="md"
										checked={employee?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'employee',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-employees-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<SectionHeadline
							title={employee?.headline}
							titlePlaceholder={`[${t(
								'placeholder.our-employees'
							)}]`}
							titleColor="text-gray-5"
							className="mx-auto text-center"
							onUpdateTitle={(val) =>
								onChangeValue('employee', 'headline', val)
							}
						/>
						{employee && (
							<ProfileAddNewCard
								navigationUrl="/employees/create"
								title="title.add-new-employee"
							/>
						)}
					</Collapsible>
				)}
			</Container>
			{!isLoadingProfile && !employee?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
			<ModalBoxSectionEditProfile
				isOpen={openModal}
				onClose={toggleModal}
				title="info.employees-title"
				description="info.employees-description"
			/>
		</section>
	);
};
