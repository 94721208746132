import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { Sector } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useSectors = <T = Sector[]>(
	options?: UseQueryOptions<T, any, Sector[]>
): UseQueryResult<Sector[], unknown> => {
	return useQuery({
		queryKey: [queryKey.sectors],
		queryFn: () => fetch.sectorList(),
		...config(options)
	});
};
