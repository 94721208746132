import { useTranslation } from 'react-i18next';
import { Wizard, Steps, Step } from 'react-albus-react18';
import { clx } from 'Utils';
import { IStep } from 'Types';
import {
	useSelector
} from 'Hooks';
import {
	CreateSubscriptionCommunityPartner,
	CreateSubscriptionPaymentMethod,
	CreateSubscriptionPlan,
	CreateSubscriptionReview,
	StepContainer
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

export const CreateSubscription = () => {
	const { t } = useTranslation();
	const subscription = useSelector((state) => state.subscription);
	const steps: IStep[] = [
		{
			id: 'step1',
			title: t('wizard.step-subs-1')
		},
		{
			id: 'step2',
			title: t('wizard.step-subs-2')
		},
		{
			id: 'step3',
			title: t('wizard.step-preview')
		},
		{
			id: 'step4',
			title: t('wizard.payment-method')
		}
	];

	return (
		<Wizard
			render={({ step, ...props }) => (
				<div
					className={clx(
						styles.wizard,
						'd-flex flex-column flex-md-row'
					)}>
					<StepContainer
						steps={steps}
						currentIndex={props.steps.indexOf(step)}
					/>
					<Steps>
						<Step
							id="step1"
							render={({ next, push }) => (
								<CreateSubscriptionCommunityPartner
									selected={
										subscription.selectedCommunityPartner
									}
									onNext={next}
									goToStep={push}
								/>
							)}
						/>
						<Step
							id="step2"
							render={({ next, previous, push }) => (
								<CreateSubscriptionPlan
									fields={subscription.selectedPlan}
									selectedCommunityPartner={
										subscription.selectedCommunityPartner
									}
									onPrev={previous}
									onNext={next}
									goToStep={push}
								/>
							)}
						/>
						<Step
							id="step3"
							render={({ previous, push }) => (
								<CreateSubscriptionReview
									fields={subscription}
									onPrev={previous}
									goToStep={push}
								/>
							)}
						/>
						<Step
							id="step4"
							render={({ previous, push }) => (
								<CreateSubscriptionPaymentMethod
									fields={subscription}
									onPrev={previous}
									goToStep={push}
								/>
							)}
						/>
					</Steps>
				</div>
			)}
		/>
	);
};
